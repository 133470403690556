import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {LoadStates} from '../../../classes/enums/load-states.enum';
import {BluectrlApiService} from '../../../services/bluectrl-api.service';
import {UserManagementService} from '../../../services/user-management.service';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {DatabaseService} from '../../../services/database.service';
import {ConfirmMessageBoxComponent} from '../confirm-message-box/confirm-message-box.component';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import { combineLatest, forkJoin, switchMap, take, tap, throwError } from 'rxjs';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.css']
})
export class SplashScreenComponent {

  public currentState: LoadStates = LoadStates.INIT;
  public isReload = false;
  public retryCounter = 0;
  public DMSLoaded = false;
  public UserInfoLoaded = false;
  public CustomerModulesLoaded = false;
  public RecipesLoadedFlag = false;
  private alreadyClosed = false;

  constructor( private dialogRef: MatDialogRef<SplashScreenComponent>,
               @Inject(MAT_DIALOG_DATA) data,
               public bluectrlapi: BluectrlApiService,
               public usermanagement: UserManagementService,
               public servermanagement: ServerCommunicationService,
               public translate: BluectrlTranslateService,
               public moduleHandling: ModuleHandlingService,
               public dialog: MatDialog,
               public database: DatabaseService) {
    this.isReload = data as boolean;
    this.alreadyClosed = false;


    this._initData();
  }

  private _initData() {
    this.currentState = LoadStates.INIT;
    combineLatest([this.servermanagement.LoadDMS().pipe(switchMap((dms) => {
                this.currentState = LoadStates.LOADMODULES
                return this.servermanagement.LoadModules(dms)
              })), 
              this.bluectrlapi.getUserInfo().pipe(tap((res: any) => {
                this.currentState = LoadStates.LOADUSER
                res.id ? this.usermanagement.createUserFromResponse(res) : throwError(() => new Error())
              })
            )]
          ).pipe(take(1),
          switchMap(() => {
            this.currentState = LoadStates.LOADMODULES
            return this.servermanagement.LoadCustomerModules()
          }),
          switchMap(() => {
            this.currentState = LoadStates.LOADRECIPES
            return this.database.LoadRecipes()
          })).subscribe({
            next: async (recipes) => {
              await this.database.handleRecipes(recipes);
              this.Finished()
            },
            error: (e) => {
              if (this.alreadyClosed === false) {
                this.alreadyClosed = true;
                const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.NOCONNECTION');
                const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.NOCONNECTION');
                const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
                  {panelClass: 'panelclass', data: {header: msg, content: content}});
      
                dialogRef.afterClosed().subscribe(() => {
                  this.usermanagement.LogOut();
                  this.dialogRef.close(true);
                });
              }
            }
          })
  }

  public getStateText(state: LoadStates): String {
    const prefix: String = 'DIALOGS.LOADAPIDATA.';
    return prefix + state;
  }

  public Finished() {
    this.currentState = LoadStates.FINISHED;
    this.usermanagement.SetDataLoaded();
    this.moduleHandling.dataLoadedFinished();
    this.dialogRef.close(true);
  }

}
