import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {InitClusterActionList} from '../../../classes/init-action-list';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {InitStateMessage} from '../../../classes/init-state-message';
import {WifiServiceNotFoundComponent} from '../wifi-service-not-found/wifi-service-not-found.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {environment} from '../../../../environments/environment';
import {ClusterOnboardingErrors} from '../../../classes/enums/cluster-onboarding-errors';
import {MessageBoxComponent} from '../message-box/message-box.component';
import {ActionInitState} from '../../../classes/enums/action-init-state.enum';
import {ClusterOnboardingService} from '../../../services/v2/cluster-onboarding.service';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {WifiAutoChangeErrorComponent} from '../wifi-auto-change-error/wifi-auto-change-error.component';
import {QuestionBoxComponent} from '../question-box/question-box.component';
import {ConfirmMessageBoxComponent} from '../confirm-message-box/confirm-message-box.component';
import {OnboardingLogItem} from '../../../classes/onboarding-log-item';
import {OnboardingLogType} from '../../../classes/enums/onboarding-log-type';

@Component({
  selector: 'app-cluster-onboarding-dialog',
  templateUrl: './cluster-onboarding.component.html',
  styleUrls: ['./cluster-onboarding.component.css']
})
export class ClusterOnboardingComponent implements OnInit, AfterViewInit, AfterViewChecked {

  public actionList = InitClusterActionList;
  private unsubscribe: Subject<void> = new Subject<void>();
  private handler: any;
  private handler2: any;
  private counter: number;
  private active = false;
  public currentAction = 1;
  // SAFTY
  public secondsForSaftey = 14.0 * 60.0;
  public currentTime = 0;
  private finishRequested = false;
  private handlerSafety: any;
  private safetyRunning = false;
  public InitSucessfull = false;
  private retries = 0;
  private retryOngoing = false;
  private safetyRequired = true;
  public showLog = false;
  public scrolledManually = false;
  public logs: OnboardingLogItem[];

  @ViewChild('logdiv', { read: ElementRef, static: false}) public widgetsContent: ElementRef<any>;

  constructor(public server: ServerCommunicationService,
              public moduleHandling: ModuleHandlingService,
              private clusterOnboarding: ClusterOnboardingService,
              private translate: BluectrlTranslateService,
              private dialogRef: MatDialogRef<ClusterOnboardingComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) data,
              private cd: ChangeDetectorRef) {

    this.logs = [];
    this.currentTime = 0;
    this.finishRequested = false;
    this.safetyRunning = false;
    this.retries = 0;
    this.retryOngoing = false;

    for (const l of this.actionList) {
      l.state = 0;
    }

    if (this.moduleHandling.DemoView) {
      // CUSTOMER MODULE
      this.InitCluster();
    } else {
      this.clusterOnboarding.OnboardingProgress.pipe(takeUntil(this.unsubscribe)).subscribe(this.InitStateMessageReceived.bind(this));
      this.clusterOnboarding.ClusterOnboardingReinitRequested.pipe(takeUntil(this.unsubscribe))
        .subscribe(this.ReInitClusterRequested.bind(this));
      this.clusterOnboarding.SafetyRequired.pipe(takeUntil(this.unsubscribe)).subscribe(this.SafetyRequired.bind(this));
      this.clusterOnboarding.OnboardingError.pipe(takeUntil(this.unsubscribe)).subscribe(this.OnOnboardingError.bind(this));
      this.clusterOnboarding.NewLogMessage.pipe(takeUntil(this.unsubscribe)).subscribe(this.addLogMessage.bind(this));
    }
  }

  ngAfterViewInit() {
    if (!this.moduleHandling.DemoView) {
      this.InitCluster();
    }
  }

  ngAfterViewChecked() {
    if (this.clusterOnboarding.OnboardingRunning) {
      if (this.widgetsContent) {
        this.widgetsContent.nativeElement.scrollTop = this.widgetsContent.nativeElement.scrollHeight;
      }
    }
  }

  public scrolled() {
    this.scrolledManually = true;
  }

  private addLogMessage(logMessage: OnboardingLogItem) {
    this.logs.push(logMessage);
  }


  private OnOnboardingError(error: ClusterOnboardingErrors) {
    switch (error) {
      case ClusterOnboardingErrors.ONBOARDINGRUNING: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.ONBOARDINGISRUNING');
        this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
      case ClusterOnboardingErrors.MISSINGINFORMATIONS: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MISSINGONBOARDINGINFO');
        this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
      case ClusterOnboardingErrors.MODULESNOTCONFIGURED: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MODULENOTCONFIGURED');
        this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
    }
    this.retries = 4;
    for (const a of this.actionList) {
      a.state = ActionInitState.failed;
    }
  }

  public SafetyInitFinished() {
    if (this.safetyRunning) {
      if (!this.finishRequested) {
        this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.FINISHSAFETY'), OnboardingLogType.INFO));
        this.finishRequested = true;
        this.moduleHandling.safetyInitFinished = true;
        // Finish animation
        clearInterval(this.handlerSafety);
        const rest = this.secondsForSaftey - this.currentTime;
        const stp = rest / 3.0;
        this.handlerSafety = setInterval(et => {

          this.currentTime = this.currentTime + stp;
          if (this.currentTime > this.secondsForSaftey) {
            clearInterval(this.handlerSafety);
            this.finishRequested = false;
            this.RunFinishRoutine();
          }
        }, 1000);
      }
    }
  }

  public receivedInitFinished(state: boolean) {
    if (!this.finishRequested) {
      this.finishRequested = true;
      this.moduleHandling.safetyInitFinished = true;
      if (state === true) {
        // Finish animation
        clearInterval(this.handlerSafety);
        const rest = this.secondsForSaftey - this.currentTime;
        const stp = rest / 3.0;
        this.handlerSafety = setInterval(() => {

          this.currentTime = this.currentTime + stp;
          if (this.currentTime > this.secondsForSaftey) {
            clearInterval(this.handlerSafety);
            this.finishRequested = false;
            this.RunFinishRoutine();


          }
        }, 1000);
      }
    }
  }

  public RunFinishRoutine() {
    this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.CLUSTERONBOARDINGDONE'), OnboardingLogType.INFO));
    this.finishRequested = true;
    this.moduleHandling.safetyInitFinished = true;
    this.InitSucessfull = true;
    setTimeout(() => {
      this.CloseDialog();
    }, 3000);
  }

  public InitCluster() {
    if (this.moduleHandling.DemoView) {
      this.currentAction = 1;

      for (let i = 0; i < 10; i++) {
        this.addLogMessage(new OnboardingLogItem('Its an INFO', OnboardingLogType.INFO));
        this.addLogMessage(new OnboardingLogItem('Its an DEBUG', OnboardingLogType.DEBUG));
        this.addLogMessage(new OnboardingLogItem('Its an WARNING', OnboardingLogType.WARNING));
        this.addLogMessage(new OnboardingLogItem('Its an ERROR', OnboardingLogType.ERROR));
      }

      this.handler2 = setInterval(() => {
        const act = this.actionList.find(ex => ex.id === this.currentAction);

        if (act) {
          act.state = Math.floor(Math.random() * Math.floor(2)) + 1;
          this.currentAction = this.currentAction + 1;
          if (this.currentAction > 2) {
            clearInterval(this.handler2);
            this.currentTime = 0;
            this.handler2 = setInterval(() => {
              this.currentTime += 1;
              if (this.currentTime > this.secondsForSaftey) {
                clearInterval(this.handler2);
              }

              if (this.currentTime > 1) {
                this.InitSucessfull = true;
              }

            }, 1000);
          }
        } else {
          clearInterval(this.handler2);
        }
      }, 2000);
    } else {
      this.clusterOnboarding.resetOnboarding();
      this.SecondCounter();

      this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.STARTCLUSTERONBOARDING') +
        '. ' + this.translate.GetTranslation('LOGMESSAGES.TRYCOUNTER') + ' ' +
        (this.retries + 1).toString() + ' / 3', OnboardingLogType.INFO));

      this.clusterOnboarding.startClusterOnboarding(this.moduleHandling.MasterIp,
        this.moduleHandling.MasterSerialNumber,
        this.moduleHandling.CurrentClusterId,
        this.moduleHandling.ActiveProject,
        true);
    }
  }

  public SecondCounter() {
    this.counter = 90;

    this.handler = setInterval(() => {
      this.counter = this.counter - 1;
      if (this.counter <= 0) {
        clearInterval(this.handler);
      }
    }, 1000);
  }

  ngOnInit(): void {
    this.active = true;
    this.InitSucessfull = false;
  }

  CloseDialog() {

    if (!this.InitSucessfull) {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data =
        this.translate.GetTranslation('MESSAGEBOX.CONTENT.ABORTONBOARDING');
      const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          // CONNECT TO EXISTING CLUSTER
          this.unsubscribe.next();
          this.unsubscribe.complete();
          this.active = false;

          if (!this.clusterOnboarding.ClusterInitialized) {
            this.clusterOnboarding.CancelOnboarding();
          }

          this.dialogRef.close();
        } else {
          return;
        }
      });



    } else {
      this.unsubscribe.next();
      this.unsubscribe.complete();
      this.active = false;

      if (!this.clusterOnboarding.ClusterInitialized) {
        this.clusterOnboarding.CancelOnboarding();
      }

      this.dialogRef.close();
    }



  }

  public InitStateMessageReceived(stateMessage: InitStateMessage) {
    if (!this.active) {
      return;
    }

    if (stateMessage.ActionId === -1) {
      return;
    }

    const act = this.actionList.find(ex => ex.id === stateMessage.ActionId);
    if (act) {



      if (stateMessage.State === 2 && act.id > 0) {


        if (this.actionList.find(ex => ex.id === 1).state === 3 && act.id === 2 && stateMessage.State === 2) {

          // Manually change wifi
          this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.AUTOMATICWIFIFAILED'),
            OnboardingLogType.ERROR));
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = false;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'loginDialogGray';
          const splitter = this.moduleHandling.CurrentClusterId.split('-');
          dialogConfig.data = environment.ClusterWifiSurFix +  splitter[splitter.length - 1];
          const ref = this.dialog.open(WifiAutoChangeErrorComponent, dialogConfig);
          ref.afterClosed().subscribe((data: any) => {
          });
          this.retries = 4;
          const following = this.actionList.filter(ex => ex.id >= stateMessage.ActionId);
          for (const f of following) {
            f.state = 2;
          }
          this.clusterOnboarding.CancelOnboarding();
          return;

        } else if (this.retries <= 3) {
          // TIME
          this.retryOngoing = true;
          setTimeout(() => {

            this.RetryInit();
            this.retryOngoing = false;
            this.retries += 1;
          }, 5000);


        } else {
          act.state = stateMessage.State;
          const following = this.actionList.filter(ex => ex.id > stateMessage.ActionId);
          for (const f of following) {
            f.state = 2;
          }
          this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.CLUSTERONBOARDINGFAILED'),
            OnboardingLogType.ERROR));
        }
      } else {
        act.state = stateMessage.State;
        if (act.id === 1) {
          try {
            clearInterval(this.handler);
          } catch {

          }
        }

        if (act.id === 1 && act.state === 4) {
          // NO WIFI SERVICE
          this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.NOWIFISERVICE'), OnboardingLogType.WARNING));
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = false;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'loginDialogGray';
          const splitter = this.moduleHandling.CurrentClusterId.split('-');
          dialogConfig.data = environment.ClusterWifiSurFix +  splitter[splitter.length - 1];
          const ref = this.dialog.open(WifiServiceNotFoundComponent, dialogConfig);
          ref.afterClosed().subscribe((data: any) => {
            this.clusterOnboarding.continueWithoutWifiService();
          });
        }
      }
    }
  }

  public RetryInit() {

    this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.RESTARTONBOARDING'), OnboardingLogType.DEBUG));
    this.currentTime = 0;
    this.finishRequested = false;
    this.safetyRunning = false;

    if (this.handlerSafety) {
      clearInterval(this.handlerSafety);
    }

    this.actionList = InitClusterActionList;
      for (const l of this.actionList) {
        l.state = 0;
      }
      this.InitCluster();

  }

  public RetryPossible(): boolean {
    if (this.retryOngoing === true) {
      return false;
    }

    return this.actionList.filter(ex => ex.state === 2).length > 0;
  }

  arrayOne(): any[] {
    return Array((this.secondsForSaftey / 60) / 2);
  }

  public SafetyRequired(required: boolean) {
    this.safetyRequired = required;

    if (this.safetyRunning) {
      if (this.safetyRequired === false) {
        this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.SAFETYCONFIGFINISHED'),
          OnboardingLogType.INFO));
        this.SafetyInitFinished();
      }
    } else {
      if (this.safetyRequired === true) {

        this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.RUNSAFETYTIMER'),
          OnboardingLogType.DEBUG));
        if (this.actionList.find(ex => ex.id === 2 && ex.state === 1)) {
          this.safetyRunning = true;

          // START HANDLER
          this.handlerSafety = setInterval(et => {

            this.currentTime = this.currentTime + 1;

            if (this.currentTime >= this.secondsForSaftey) {
              if (!this.finishRequested && this.safetyRunning && this.safetyRequired) {
                const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.TIMEOUT');
                const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.SAFETYTIMEOUT');
                const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
                  {panelClass: 'panelclass', data: {header: msg, content: content}});
                // this.SafetyInitFinished();
                this.clusterOnboarding.finishOnboardingOnSafetyTimeout();
                this.RunFinishRoutine();
              }
              clearInterval(this.handlerSafety);
            }

          }, 1000);
        }
      } else {

        this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.NOSAFETYWAITREQUIRED'), OnboardingLogType.INFO));
        const act = this.actionList.find(ex => ex.id === 2);
        if (act) {
          act.state = 1;
        }
        this.safetyRunning = true;
        this.SafetyInitFinished();
      }

    }

  }

  public getCurrentTime(): string {
    if (this.secondsForSaftey - this.currentTime > 0) {
      const duration = moment.duration(this.secondsForSaftey - this.currentTime, 'seconds');
      return moment.utc(duration.asMilliseconds()).format('mm:ss');
    } else {
      return '00:00';
    }
  }

  public getMaxTime(): string {
    if (this.secondsForSaftey > 0) {
      const duration = moment.duration(this.secondsForSaftey, 'seconds');
      return moment.utc(duration.asMilliseconds()).format('mm:ss');
    } else {
      return '00:00';
    }
  }

  public ReInitClusterRequested() {
    if (this.active) {
      this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.RECONNECTCLUSTER'), OnboardingLogType.DEBUG));
      this.RetryInit();
    }

  }

  public getLogMessages(): OnboardingLogItem[] {
    if (environment.production === true) {
      return this.logs.filter(ex => ex.LogType !== OnboardingLogType.DEBUG);
    } else {
      return this.logs;
    }
  }

}
