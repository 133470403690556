import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-wifi-auto-change-error',
  templateUrl: './wifi-auto-change-error.component.html',
  styleUrls: ['./wifi-auto-change-error.component.css']
})
export class WifiAutoChangeErrorComponent implements OnInit {

  public requestedNetwork: string;
  constructor(
    private dialogRef: MatDialogRef<WifiAutoChangeErrorComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.requestedNetwork = data;
  }

  CloseDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
