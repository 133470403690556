import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ModuleComponent} from '../../../classes/module-component';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {ComponentVariable} from '../../../classes/component-variable';
import {ViewCode} from '../../../classes/enums/view-code.enum';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {MatLegacyCheckboxChange as MatCheckboxChange} from '@angular/material/legacy-checkbox';

@Component({
  selector: 'app-component-service',
  templateUrl: './component-service.component.html',
  styleUrls: ['./component-service.component.css']
})
export class ComponentServiceComponent implements OnInit {

  @Input() set SelectedComponent(value: string) {
    if (this.Component) {
      this._selectedComponent = value;

      this.OwnVisible = this._selectedComponent === this.Component.PlcKey;
    } else {
      this.OwnVisible = false;
    }

  }
  @Input() Component: ModuleComponent;

  @Output() ConfigurationChanged = new EventEmitter<ModuleComponent>();
  @Output() ServiceSelectionChanged = new EventEmitter<ModuleComponent>();
  @Output() ComponentSelected = new EventEmitter<string>();

  public OwnVisible = false;
  private _selectedComponent: string;

  get SelectedComponent(): string {
    return this._selectedComponent;
  }

  public isSelected = true;

  constructor(public moduleHandling: ModuleHandlingService,
              public translate: BluectrlTranslateService) { }

  ngOnInit(): void {
  }

  public SetSelectedComponent() {
    if (this.OwnVisible) {
      this.ComponentSelected.emit(null);
    } else {
      this.ComponentSelected.emit(this.Component.PlcKey);
    }

  }

  public DeselectComponent() {
    this.ComponentSelected.emit(null);
  }

  GetMaintenanceValues(): ComponentVariable[] {
    return this.Component.Maintenance.filter(ex => ex.ConstallationDependency === false);
  }

  public CheckBoxSelected(event: MatCheckboxChange): void {
    this.Component.ServiceSelected = event.checked;
    this.ServiceSelectionChanged.emit(this.Component);

  }

  public ShowConfig(deviceValue: ComponentVariable): boolean {

    return !deviceValue.ConstallationDependency;
  }

  public GetCurrentValue(moduledevicevalue: ComponentVariable): any {

    if (moduledevicevalue.States.length > 0) {
      if ((moduledevicevalue.CurrentValue !== null &&
        moduledevicevalue.CurrentValue !== undefined) ||
        moduledevicevalue.CurrentValue === 0) {


        const fnd = moduledevicevalue.States.find(ex => ex.State === moduledevicevalue.CurrentValue);
        if (fnd) {
          const translated =  this.translate.GetTranslation(fnd.GetTranslationID());
          if (translated !== fnd.GetTranslationID()) {
            return translated;
          } else {
            return fnd.GetName();
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return moduledevicevalue.CurrentValue;
    }
  }

  public GetUnitTranslation(moduledevicevalue: ComponentVariable): string {
    if (moduledevicevalue.getUnitId()) {
      return this.translate.GetTranslation(moduledevicevalue.getUnitId());
    } else {
      return '';
    }
  }

  public ShowConfigurator(deviceValue: ComponentVariable): boolean {
    if (this.moduleHandling.CurrentViewMode === ViewCode.live ||
      this.moduleHandling.CurrentViewMode === ViewCode.inventory) {
      return true;
    } else  {
      return deviceValue.DesignRelevant !== false;
    }
  }

  ValueChanged() {
      // this.ConfigurationChanged.emit(this.Component);
    this.Component.ServiceConfigurationChanged = true;
    this.Component.ServiceSelected = true;
    this.ServiceSelectionChanged.emit(this.Component);
  }

}
