export enum LoadStates {
  GENERIC = 'GENERIC',
  INIT = 'INIT',
  LOADDMS = 'LOADDMS',
  LOADUSER = 'LOADUSER',
  LOADMODULES = 'LOADMODULES',
  LOADRECIPES = 'LOADRECIPES',
  LOADLASTRECIPE = 'LOADLASTRECIPE',
  FINISHED = 'FINISHED'
}
