import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CustomerModule} from '../../../classes/customer-module';

@Component({
  selector: 'app-run-service-configuration',
  templateUrl: './run-service-configuration.component.html',
  styleUrls: ['./run-service-configuration.component.css']
})
export class RunServiceConfigurationComponent implements OnInit {

  public customerModule: CustomerModule;

  constructor(public DialogRef: MatDialogRef<RunServiceConfigurationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      const cs = data as CustomerModule;

      if (cs) {
        this.customerModule = cs;
      }
    }

    setTimeout(() => {this.Confirm(); }, 6000);

  }

  ngOnInit(): void {
  }

  public Confirm() {
    this.DialogRef.close(true);
  }

}
