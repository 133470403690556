import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CustomerModule} from '../../../classes/customer-module';
import {ModulePlan} from '../../../classes/module-plan';
import {StandaloneActionList} from '../../../classes/standalone-action-list';
import {Subject} from 'rxjs';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ModuleServiceService} from '../../../services/module-service.service';
import {ActionInitState} from '../../../classes/enums/action-init-state.enum';
import * as moment from 'moment';
import {environment} from '../../../../environments/environment';
import {WifiServiceNotFoundComponent} from '../wifi-service-not-found/wifi-service-not-found.component';
import {takeUntil} from 'rxjs/operators';
import {StandaloneActions} from '../../../classes/enums/standalone-actions.enum';
import {MessageBoxComponent} from '../message-box/message-box.component';
import {WaitingModuleStopComponent} from '../waiting-module-stop/waiting-module-stop.component';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {QuestionBoxComponent} from '../question-box/question-box.component';
import {ModuleOnboardingService} from '../../../services/v2/module-onboarding.service';
import {ClusterOnboardingService} from '../../../services/v2/cluster-onboarding.service';
import {ModuleOnboardingErrors} from '../../../classes/enums/module-onboarding-errors';
import {ClusterOnboardingErrors} from '../../../classes/enums/cluster-onboarding-errors';
import {WifiAutoChangeErrorComponent} from '../wifi-auto-change-error/wifi-auto-change-error.component';
import {InitStateMessage} from '../../../classes/init-state-message';
import {OnboardingLogItem} from '../../../classes/onboarding-log-item';
import {OnboardingLogType} from '../../../classes/enums/onboarding-log-type';

@Component({
  selector: 'app-standalone-cluster-init',
  templateUrl: './standalone-cluster-init.component.html',
  styleUrls: ['./standalone-cluster-init.component.css']
})
export class StandaloneClusterInitComponent implements OnInit, OnDestroy, AfterViewInit {

  public customerModule: CustomerModule;
  public modulePlan: ModulePlan;
  public actionList = StandaloneActionList;
  public currentAction = 1;
  public handler: any;
  public InitSucessfull = false;
  public ModuleInitSuccessFull = false;
  public secondsForSaftey = 14.0 * 60.0;
  public currentTime = 0;
  private handler2: any;
  private handlerSafety: any;
  private safetyRequired = true;
  private safetyRunning = false;
  private finishRequested = false;
  private resetDialogRef: any;
  private active = false;
  private unsubscribe: Subject<void> = new Subject<void>();
  private retries = 0;
  private retryOngoing = false;
  private onboardingStarted = false;
  public showLog = false;
  public logs: OnboardingLogItem[];

  constructor(private dialogRef: MatDialogRef<StandaloneClusterInitComponent>,
              @Inject(MAT_DIALOG_DATA) data,
              public dialog: MatDialog,
              public translate: BluectrlTranslateService,
              public moduleService: ModuleServiceService,
              public moduleOnboarding: ModuleOnboardingService,
              public clusterOnboarding: ClusterOnboardingService) {

    this.customerModule = data as CustomerModule;
    this.logs = [];
    if (!moduleService.Demo) {
      this.moduleOnboarding.OnboardingProgress.pipe(takeUntil(this.unsubscribe)).subscribe(this.InitStateMessageReceived.bind(this));
      this.moduleOnboarding.ModuleClusterReset.pipe(takeUntil(this.unsubscribe)).subscribe(this.ClusterResetRequested.bind(this));
      this.moduleOnboarding.ModuleClusterResetFinished.pipe(takeUntil(this.unsubscribe)).subscribe(this.ClusterResetReceived.bind(this));
      this.moduleOnboarding.WaitingForModuleStop.pipe(takeUntil(this.unsubscribe)).subscribe(this.WaitingForModuleStop.bind(this));
      this.moduleOnboarding.OnboardingError.pipe(takeUntil(this.unsubscribe)).subscribe(this.ModuleOnboardingError.bind(this));
      this.moduleOnboarding.NewLogMessage.pipe(takeUntil(this.unsubscribe)).subscribe(this.addLogMessage.bind(this));
      this.clusterOnboarding.OnboardingProgress.pipe(takeUntil(this.unsubscribe)).subscribe(this.InitStateMessageReceived.bind(this));
      this.clusterOnboarding.SafetyRequired.pipe(takeUntil(this.unsubscribe)).subscribe(this.SafetyRequired.bind(this));
      this.clusterOnboarding.OnboardingError.pipe(takeUntil(this.unsubscribe)).subscribe(this.OnClusterOnboardingError.bind(this));
      this.clusterOnboarding.NewLogMessage.pipe(takeUntil(this.unsubscribe)).subscribe(this.addLogMessage.bind(this));

    }
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.active = true;
    this.InitSucessfull = false;
    this.currentTime = 0;
    this.finishRequested = false;
    this.safetyRunning = false;

    for (const act of this.actionList) {
      act.state = ActionInitState.open;
    }

    this.StartInitAll();
  }

  ngOnDestroy() {
    this.active = false;
  }

  private addLogMessage(logMessage: OnboardingLogItem) {
    this.logs.push(logMessage);
  }

  CloseDialog() {
    if (this.moduleOnboarding.OnboardingRunning || this.onboardingStarted === true) {
      // ONBOARD ONGOING
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data =
        this.translate.GetTranslation('MESSAGEBOX.CONTENT.ONBOARDINGONGOING');
      const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          // CONNECT TO EXISTING CLUSTER
          this.moduleService.cancelOnboarding();
          this.closeReal();
        }
      });
    } else if (this.clusterOnboarding.OnboardingRunning) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data =
        this.translate.GetTranslation('MESSAGEBOX.CONTENT.ONBOARDINGONGOING');
      const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          // CONNECT TO EXISTING CLUSTER
          this.moduleService.cancelOnboarding();
          this.closeReal();
        }
      });
    } else {
      this.closeReal();
    }






  }

  private closeReal() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.active = false;
    if (this.handler) {
      clearInterval(this.handler);
    }
    if (this.handler2) {
      clearInterval(this.handler2);
    }
    this.dialogRef.close();
  }

  private ModuleOnboardingError(error: ModuleOnboardingErrors) {
    switch (error) {
      case ModuleOnboardingErrors.NoCustomerModule: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.NOCUSTOMERMODULE');
        this.resetDialogRef = this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
      case ModuleOnboardingErrors.OnboardingRunning: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.ONBOARDINGISRUNING');
        this.resetDialogRef = this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
      case ModuleOnboardingErrors.SerialNumberNotSet: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.SERIALNOTSET');
        this.resetDialogRef = this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
    }
    this.retries = 4;
    for (const a of this.actionList) {
      a.state = ActionInitState.failed;
    }
    this.moduleService.RemoveConnectionInfo();
  }

  private OnClusterOnboardingError(error: ClusterOnboardingErrors) {
    switch (error) {
      case ClusterOnboardingErrors.ONBOARDINGRUNING: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.ONBOARDINGISRUNING');
        this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
      case ClusterOnboardingErrors.MISSINGINFORMATIONS: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MISSINGONBOARDINGINFO');
        this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
      case ClusterOnboardingErrors.MODULESNOTCONFIGURED: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MODULENOTCONFIGURED');
        this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
    }
    this.retries = 4;
    for (const a of this.actionList) {
      a.state = ActionInitState.failed;
    }
    this.moduleService.RemoveConnectionInfo();
  }

  public StartInitAll() {
    if (this.moduleService.Demo) {
      this.currentAction = 1;
      this.currentTime = 0;
      this.handler = setInterval(() => {
        const act = this.actionList.find(ex => ex.id === this.currentAction);

        if (act) {
          act.state = Math.floor(Math.random() * Math.floor(2)) + 1;
          this.currentAction = this.currentAction + 1;
          this.actionList = this.actionList;
          if (this.currentAction > 6) {
            this.ModuleInitSuccessFull = true;
          }
          if (this.currentAction > 8) {
            this.InitSucessfull = true;
            clearInterval(this.handler);
            this.currentTime = 0;
            this.handler2 = setInterval(() => {
              this.currentTime += 1;
              if (this.currentTime > this.secondsForSaftey) {
                clearInterval(this.handler2);
              }

              if (this.currentTime > 1) {
                this.InitSucessfull = true;
              }

            }, 1000);
          }
        } else {
          clearInterval(this.handler);
        }
      }, 2000);
    } else {
      // CREATE MODULE PLAN
      // CREATE MODULE PLAN
      if (!this.modulePlan) {
        this.modulePlan = this.moduleService.CreateStandaloneModulePlan();
      }
      this.onboardingStarted = true;
      this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.STARTMODULEONBOARDING') +
        '. ' + this.translate.GetTranslation('LOGMESSAGES.TRYCOUNTER') + ' ' +
        (this.retries + 1).toString() + ' / 3', OnboardingLogType.INFO));
      this.moduleService.StartModuleInit();
    }
  }

  public StartInitCluster() {
    if (this.moduleService.Demo) {
      this.currentAction = 7;
      this.handler = setInterval(() => {
        const act = this.actionList.find(ex => ex.id === this.currentAction);

        if (act) {
          act.state = Math.floor(Math.random() * Math.floor(2)) + 1;
          this.currentAction = this.currentAction + 1;
          this.actionList = this.actionList;
          if (this.currentAction > 8) {
            this.InitSucessfull = true;
            clearInterval(this.handler);
            this.handler2 = setInterval(() => {
              this.currentTime += 1;
              if (this.currentTime > this.secondsForSaftey) {
                clearInterval(this.handler2);
              }

              if (this.currentTime > 1) {
                this.InitSucessfull = true;
              }

            }, 1000);
          }
        } else {
          clearInterval(this.handler);
        }
      }, 2000);
    } else {
      // CREATE MODULE PLAN
      if (!this.modulePlan) {
        this.StartInitAll();
      }
      this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.STARTCLUSTERONBOARDING') +
        '. ' + this.translate.GetTranslation('LOGMESSAGES.TRYCOUNTER') + ' ' +
        (this.retries + 1).toString() + ' / 3', OnboardingLogType.INFO));
      this.moduleService.RetryClusterOnboarding();
    }
  }

  public RetryPossible(): boolean {
    if (this.retryOngoing === true) {
      return false;
    }

    return this.actionList.filter(ex => ex.state === ActionInitState.failed).length > 0 ;
  }

  public RetryInit() {
    if (this.ModuleInitSuccessFull === false) {
      // RESET ALL
      for (const l of this.actionList) {
        l.state = ActionInitState.open;
      }
      this.StartInitAll();
    } else {
      // RESET ONLY CLUSTER INIT
      for (const l of this.actionList.filter(ex => ex.id >= 7)) {
        l.state = ActionInitState.open;
      }
      this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.RESTARTONBOARDING'), OnboardingLogType.DEBUG));
      this.StartInitCluster();
    }
  }

  arrayOne(): any[] {
    return Array((this.secondsForSaftey / 60) / 2);
  }

  public getCurrentTime(): string {
    if (this.secondsForSaftey - this.currentTime > 0) {
      const duration = moment.duration(this.secondsForSaftey - this.currentTime, 'seconds');
      return moment.utc(duration.asMilliseconds()).format('mm:ss');
    } else {
      return '00:00';
    }
  }

  public getMaxTime(): string {
    if (this.secondsForSaftey > 0) {
      const duration = moment.duration(this.secondsForSaftey, 'seconds');
      return  moment.utc(duration.asMilliseconds()).format('mm:ss');
    } else {
      return '00:00';
    }
  }

  public InitStateMessageReceived(stateMessage: InitStateMessage) {


    const Action: StandaloneActions = stateMessage.ActionId;
    const State: ActionInitState = stateMessage.State;

    if (stateMessage) {
      if (!this.active) {
        return;
      }

      if (stateMessage.ActionId === -1) {
        return;
      }

      const act = this.actionList.find(ex => ex.id === Action);
      if (act) {
        act.state = State;

        if (act.id === StandaloneActions.InitializeEnviroment && State === ActionInitState.success) {
          // Its done
          const actFin = this.actionList.find(ex => ex.id === 6);
          if (actFin) {
            actFin.state = ActionInitState.success;
          }

          this.customerModule.Initialized = true;
          this.ModuleInitSuccessFull = true;

          this.moduleService.finalizeStandaloneModuleOnboarding();
          // TODO: Init cluster
        }

        if (act.id === StandaloneActions.ConfigureCluster && State === ActionInitState.success) {
          this.InitSucessfull = true;
          this.moduleService.finalizeStandaloneClusterOnboarding();
        }

        if (act.state === ActionInitState.failed) {
          const following = this.actionList.filter(ex => ex.id > Action);
          for (const f of following) {
            f.state = ActionInitState.failed;
          }

          if (stateMessage.Forced === true) {
            this.retries = 4;

            this.modulePlan.RemoveOnboardingInfo(true);
            this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.CLUSTERONBOARDINGFAILED'),
              OnboardingLogType.ERROR));
          }

          if (this.retries <= 3) {
            this.retryOngoing = true;
            setTimeout(() => {
              this.RetryInit();
              this.retryOngoing = false;
              this.retries += 1;
            }, 5000);
          } else {
            this.onboardingStarted = false;

          }
        }


        // Check if first and second failed - if yes then request service
        if (!this.retryOngoing) {

          if (this.actionList.find(ex => ex.id === StandaloneActions.ConnectModuleWifi).state === 4) {
            this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.NOWIFISERVICE'),
              OnboardingLogType.WARNING));
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'loginDialogGray';
            dialogConfig.data = environment.ModuleWifiSurFix + this.customerModule.SerialNumber;
            const ref = this.dialog.open(WifiServiceNotFoundComponent, dialogConfig);
            ref.afterClosed().subscribe((data: any) => {
              this.moduleOnboarding.continueWithoutWifiService();
            });
          }

          if (this.actionList.find(ex => ex.id === StandaloneActions.ConnectModuleWifi).state === 3) {
            if (this.actionList.find(ex => ex.id === StandaloneActions.ConnectToModule).state === 2) {
              // Manually change wifi
              this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.AUTOMATICWIFIFAILED'),
                OnboardingLogType.WARNING));
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = false;
              dialogConfig.autoFocus = true;
              dialogConfig.panelClass = 'loginDialogGray';
              dialogConfig.data = environment.ModuleWifiSurFix + this.customerModule.SerialNumber;
              const ref = this.dialog.open(WifiAutoChangeErrorComponent, dialogConfig);
              ref.afterClosed().subscribe((data: any) => {
                this.moduleOnboarding.continueWithoutWifiService();
              });
            }
          }

          if (this.actionList.find(ex => ex.id === StandaloneActions.ConnectToCluster).state === 4) {
            // NO WIFI SERVICE
            this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.NOWIFISERVICE'),
              OnboardingLogType.WARNING));
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'loginDialogGray';
            const splitter = this.moduleService.CurrentClusterId.split('-');
            dialogConfig.data = environment.ClusterWifiSurFix + splitter[splitter.length - 1];
            const ref = this.dialog.open(WifiServiceNotFoundComponent, dialogConfig);
            ref.afterClosed().subscribe((data: any) => {
              this.clusterOnboarding.continueWithoutWifiService();
            });
          }

          if (this.actionList.find(ex => ex.id === StandaloneActions.ConnectToCluster).state === 3) {
            if (this.actionList.find(ex => ex.id === StandaloneActions.ConfigureCluster).state === 2) {
              // Manually change wifi
              this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.AUTOMATICWIFIFAILED'),
                OnboardingLogType.WARNING));
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = false;
              dialogConfig.autoFocus = true;
              dialogConfig.panelClass = 'loginDialogGray';

              const splitter = this.moduleService.CurrentClusterId.split('-');

              dialogConfig.data = environment.ClusterWifiSurFix + splitter[splitter.length - 1];
              const ref = this.dialog.open(WifiAutoChangeErrorComponent, dialogConfig);
              ref.afterClosed().subscribe((data: any) => {
                this.clusterOnboarding.continueWithoutWifiService();
              });
            }
          }

        }

      }
    }

  }

  public SafetyRequired(required: boolean) {

    this.safetyRequired = required;

    if (this.safetyRunning) {
      if (this.safetyRequired === false) {
        this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.SAFETYCONFIGFINISHED'),
          OnboardingLogType.INFO));
        this.SafetyInitFinished();
      }
    } else {
      if (this.safetyRequired === true) {

        this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.RUNSAFETYTIMER'),
          OnboardingLogType.DEBUG));
        this.safetyRunning = true;

          // START HANDLER
          this.handlerSafety = setInterval(et => {

            this.currentTime = this.currentTime + 1;

            if (this.currentTime >= this.secondsForSaftey) {
              clearInterval(this.handlerSafety);
            }
          }, 1000);
      } else {
        this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.NOSAFETYWAITREQUIRED'),
          OnboardingLogType.INFO));
        // UPADTE STATE
        const act = this.actionList.find(ex => ex.id === StandaloneActions.ConfigureCluster);
        if (act) {
          act.state = 1;
        }
        this.safetyRunning = true;
        this.SafetyInitFinished();
      }

    }
  }

  public SafetyInitFinished() {
    if (this.safetyRunning) {
      if (!this.finishRequested) {
        this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.FINISHSAFETY'), OnboardingLogType.INFO));
        this.finishRequested = true;
        // Finish animation
        clearInterval(this.handlerSafety);
        const rest = this.secondsForSaftey - this.currentTime;
        const stp = rest / 3.0;
        this.handlerSafety = setInterval(et => {

          this.currentTime = this.currentTime + stp;
          if (this.currentTime > this.secondsForSaftey) {
            clearInterval(this.handlerSafety);
            this.finishRequested = false;
            this.RunFinishRoutine();
          }
        }, 1000);
      }
    }
  }

  public RunFinishRoutine() {
    this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.CLUSTERONBOARDINGDONE'),
      OnboardingLogType.INFO));
    this.finishRequested = true;
    this.InitSucessfull = true;
    this.onboardingStarted = false;
    setTimeout(() => {
      this.CloseDialog();
    }, 3000);
  }

  public ClusterResetRequested() {
    // SHOW DIALOG FOR Please Wait
    if (!this.active) {
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'loginDialogGray';
    dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.CLUSTERRESET');
    this.resetDialogRef = this.dialog.open(MessageBoxComponent, dialogConfig);
  }

  public ClusterResetReceived() {
    if (!this.active) {
      return;
    }

    if (this.resetDialogRef) {
      this.resetDialogRef.close();
    }
  }

  public WaitingForModuleStop() {
    if (!this.active) {
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'loginDialogGray';
    const dialogRef = this.dialog.open(WaitingModuleStopComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {

      } else {
        this.moduleService.cancelOnboarding();
      }
    });

  }

  public getLogMessages(): OnboardingLogItem[] {
    if (environment.production === true) {
      return this.logs.filter(ex => ex.LogType !== OnboardingLogType.DEBUG);
    } else {
      return this.logs;
    }
  }
}
