<div class="main flex-col">
  <mat-dialog-content>
    <h2 class = "center-self" mat-dialog-title>{{'DIALOGS.PROJECTSAVEWARNING.TITLE' | i18next }}</h2>
    <div class="flex-col">
      <p class="content">{{'DIALOGS.PROJECTSAVEWARNING.TEXT' | i18next}}</p>
    </div>
  </mat-dialog-content>

  <div class="buttondiv flex-row">
    <button class="yesButton start-self" (click)="Change()">{{'DIALOGS.PROJECTSAVEWARNING.SAVE' | i18next }}</button>
    <button class="noButton end-self" (click)="Cancel()">{{'DIALOGS.PROJECTSAVEWARNING.TEMP' | i18next }}</button>
  </div>
</div>
