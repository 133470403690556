<!--flexAlign column??-->
<section [ngClass]="{'darkTheme': router.url !== '/live', 'grayTheme': router.url === '/live'}">
  <div class="user flex-row">
    <img class="usersvg" src="/assets/general/user.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
    <div class="userinfo flex-col" *ngIf="currentUser">
      <p class="username">{{currentUser.Fullname}}</p>
      <p class="company">{{currentUser.Customer}}</p>
    </div>
  </div>

  <div *ngIf="currentUser" class="flex-row" (click)="logout()" [ngClass]="{'logoff': router.url !== '/live', 'logoffDark': router.url === '/live'}">
    <img class="logoutSvg" src="/assets/general/logout.svg">
    <p class="logofftext">{{ 'COMPONENTS.OPTION.LOGOUT' | i18next }}</p>
  </div>

  <div *ngIf="currentUser && !connectivity.inOfflineMode">
    <div *ngIf="!currentUser.OfflineUser" class="flex-row" (click)="setAsOfflineUser()" [ngClass]="{'setOff': router.url !== '/live', 'setOffDark': router.url === '/live'}">
      <p class="setofftext">{{ 'COMPONENTS.OPTION.SETOFFLINE' | i18next }}</p>
    </div>
    <div *ngIf="currentUser.OfflineUser" class="flex-row" (click)="removeOfflineUser()" [ngClass]="{'setOff': router.url !== '/live', 'setOffDark': router.url === '/live'}">
      <p class="setofftext">{{ 'COMPONENTS.OPTION.REMOVEOFFLINE' | i18next }}</p>
    </div>
  </div>

  <div *ngIf="addPinActive">

  </div>

  <!--
  <div class="language" class="flex-row">
    <img class="usersvg" src="/assets/general/language_de.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
    <div class="langInfo" class="flex-col">
      <p class="langtxt">{{ 'COMPONENTS.OPTION.LANGUAGE' | i18next }}</p>
      <select #langSelect (change)="ChangeLanguage(langSelect.value)" *ngIf="showLanguageSelector" [ngClass]="{'languageSelector': router.url !== '/live', 'languageSelectorDark': router.url === '/live'}">
        <option *ngFor="let lang of languageList" [value]="lang.code" [selected]="lang.code === i18NextService.language">{{ lang.code }}</option>
      </select>
    </div>
  </div>
  !-->

  <mat-nav-list>
    <a mat-list-item  *ngIf="router.url === '/live' && moduleHandling.ClusterInitialized" (click)="GoToClusterLog()" class="activeColour">
      <img class="sideNavIcons" src="/assets/general/icon_log.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
      <p class="sidenavText" [ngClass]="{'sidenavTextWhite': router.url !== '/live', 'sidenavTextBlack': router.url === '/live'}">{{'COMPONENTS.MENU.CLUSTERLOG' | i18next }}</p>
    </a>
  </mat-nav-list>

  <div class="app flex-row">
    <img class="usersvg" src="/assets/general/info.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
    <div class="appInfo flex-col">
      <p class="apptxt">BLUECTRL App</p>
      <p class="versions">Version {{appVersion}}</p>
      <p class="versions">Build {{appBuild}}</p>
      <!-- <p class="apptxt">THEME Basic</p>
      <p class="versions">Version {{themeBasicVersion}}</p>
      <p class="versions">Build {{themeBasicBuild}}</p> -->
      <p class="apptxt">Dynamic Module Specification</p>
      <p class="versions">Version {{serverManagement.dmsVersion}}</p>
    </div>
  </div>
  
  <div *ngIf="currentUser && currentUser.isAdmin()" (click)="reportBug()"  id="report-bug" [ngClass]="{'logoff': router.url !== '/live', 'logoffDark': router.url === '/live'}"  class="flex-row btn-report-bug">
    <img class="bugSvg" src="/assets/general/bug.svg">
    <p class="logofftext">{{ 'COMPONENTS.OPTION.REPORTBUG' | i18next }}</p>
  </div>

  <div *ngIf="currentUser && currentUser.isAdmin()" (click)="addFeedback()"  id="add-feedback" [ngClass]="{'logoff': router.url !== '/live', 'logoffDark': router.url === '/live'}"  class="flex-row btn-add-feedback">
    <img class="feedbackSvg" src="/assets/general/megaphone.svg">
    <p class="logofftext">{{ 'COMPONENTS.OPTION.ADDFEEDBACK' | i18next }}</p>
  </div>
</section>
