<section class="flex-col">
  <div class="moduleheader flex-row">
    <p class="headerText">{{Group}}</p>
    <svg class="updownicon" *ngIf="!OwnVisible" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 4.58 9.16" (click)="OwnVisible = true">
      <defs>
        <style>.cls-1{fill:#0077ba;}</style>
      </defs>
      <title>aufklappen</title>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Bearbeiten_S4" data-name="Bearbeiten S4">
          <polygon class="cls-1" points="0 9.16 0 0 4.58 4.58 0 9.16"/>
        </g>
      </g>
    </svg>
    <svg class="updownicon" *ngIf="OwnVisible" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 9.16 4.58" (click)="OwnVisible = false">
      <defs>
        <style>.cls-1{fill:#0077ba;}</style>
      </defs>
      <title>zuklappen</title>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Bearbeiten_S4" data-name="Bearbeiten S4">
          <polygon class="cls-1" points="0 0 9.16 0 4.58 4.58 0 0"/>
        </g>
      </g>
    </svg>
  </div>
  <div class="line"></div>
  <div *ngIf="OwnVisible" class="containerforrow flex-row">
    <!-- ELEMENTS ! wrap missing after fxLayout Migration!!!?? lt-sm switch to column?-->
    <div class="menuCustomer gap-10 flex-row justify-start flex-wrap">
      <ng-container *ngFor="let md of _modules">
        <!--lt-sm flex 1!!-->
        <div class="item flex-0-1-50-100"  [ngClass]="{'itemOnline': md.Module.NetworkInfo, 'itemService': md.Module.NetworkInfo && md.Module.NetworkInfo.switchstate === 'service'}">
          <app-iventory-module-element [customerModule]="md" (ShowSettings)="CustomerModuleClicked($event)" (ShowDetailView)="emitShowDetailView($event)"></app-iventory-module-element>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="line"></div>
</section>
