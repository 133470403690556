import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import * as uuid from 'uuid';
import {SensorSortState} from '../../../classes/DatabaseObjects/sensor-sort-state';
import {DatabaseService} from '../../../services/database.service';
import {MessageHandlingService} from '../../../services/v2/message-handling.service';

@Component({
  selector: 'app-download-from-module-dialog',
  templateUrl: './download-from-module-dialog.component.html',
  styleUrls: ['./download-from-module-dialog.component.css']
})
export class DownloadFromModuleDialogComponent implements OnInit {

  public moduleSerial: string;
  public moduleType: string;
  public component: string;
  public options: any[];
  public variableName: string;
  public msgId: string;
  public displayPath: string;
  public valuePath: string;
  public selected: any;
  public downloadRequested = false;
  public message: string;

  constructor(public messaging: MessageHandlingService,
              public database: DatabaseService,
              private dialogRef: MatDialogRef<DownloadFromModuleDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {

    // this.messaging.OnModuleDownloadResponse.subscribe(this.DownloadRespone.bind(this));

    if (data) {
      this.moduleSerial = data.serial;
      this.moduleType = data.moduleType;
      this.component = data.component;
      this.variableName = data.variableName;
      this.options = data.options;
      this.displayPath = data.displayPath;
      this.valuePath = data.valuePath;
    }

  }

  public DownloadRespone(msg: any) {
    if (msg.msgId === this.msgId) {
      this.downloadRequested = false;
      if (msg.success === true) {
        this.message = 'Download finished';
        let data = '';
        const name = this.getValue();
        for (const d of msg.data) {
          data += d + ';';
        }
        // CREATE SENSOR DATA
        const snr = new SensorSortState();
        snr.id = uuid.v4();
        snr.name = name;
        snr.number = this.selected;
        snr.moduleSerial = this.moduleSerial;
        snr.originalModule = this.moduleType;
        snr.data = data;
        if (this.database.AddSortState(snr)) {
        } else {
        }
      } else {
        this.message = msg.errorCode;
      }
    }
  }

  ngOnInit(): void {
  }

  CloseDialog() {
    this.dialogRef.close();
  }

  public ValueChangedInSelector($event) {
    this.selected = $event;
  }

  public getValue() {
    if (this.selected) {
      for (const val of this.options) {
        if (val[this.valuePath].toString() === this.selected.toString()) {
          return val[this.displayPath];
        }
      }
    }

    return null;

  }

  public getOptionName(data: any) {
    return data[this.displayPath];
  }

  public getOptionValue(data: any) {
    return data[this.valuePath];
  }

  public StartDownload() {
    if (this.selected) {
      this.downloadRequested = true;
      this.msgId = uuid.v4();
      // if (this.messaging.SendDownloadRequest(this.msgId, this.moduleSerial, this.component, this.selected.toString())) {
      //
      // } else {
      //   this.message = 'No Connection to module';
      //   this.downloadRequested = false;
      // }

    }
  }

  public AddDemoData() {
    const snr = new SensorSortState();
    snr.id = uuid.v4();
    snr.name = 'Test2';
    snr.number = 2;
    snr.moduleSerial = 'B0701-001-0001';
    snr.originalModule = 'B0701';
    snr.data = 'dkfshdjkfhdskjfhsdj;dsjfhdkfshdjfkhsjdhf;dsjkfhsdfhsdjkfhks';

    if (this.database.AddSortState(snr)) {

    } else {

    }
  }


}
