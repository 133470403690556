<section class="flex-col stretch-col">
  <div class="header flex-row stretch-row">
    <h2 class="uppercaseText center-self" mat-dialog-title>{{'DIALOGS.TRANSFERRECIPE.TITLE' | i18next }}</h2>
    <div class = "flex-1 justify-end center-self">
      <button mat-button (click)="CloseDialog()">
        <img class="invertedWhite" src="/assets/general/close.svg">
      </button>
    </div>
  </div>


  <div class="inputs flex-col">
    <mat-dialog-content class="flex-col">
      <div class="selectorDiv flex-row">
        <p class="label">{{'DIALOGS.NEWMODULE.ORGANIZATION' | i18next }}</p>
        <mat-select class="selectElement"
                    [(ngModel)]="selectedOrganisation">
          <mat-select-trigger *ngIf="selectedOrganisation" class="selecttrigger">
            {{selectedOrganisation.name}}
          </mat-select-trigger>
          <mat-option *ngFor="let org of organisations"
                      [value]="org">{{org.name}}</mat-option>
        </mat-select>
      </div>
    </mat-dialog-content>

  </div>

  <div class="savediv flex-row center-self">
    <button mat-button (click)="transferRecipe()" *ngIf="savePossible()">
      <img class="invertedWhite" src="/assets/general/save.svg">
    </button>
  </div>

</section>
