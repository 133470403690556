import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {BluectrlApiService} from '../../../services/bluectrl-api.service';

@Component({
  selector: 'app-new-organzation',
  templateUrl: './new-organzation.component.html',
  styleUrls: ['./new-organzation.component.css']
})
export class NewOrganzationComponent implements OnInit {


  form: UntypedFormGroup;
  name: string;

  constructor(private fb: UntypedFormBuilder,
              public apiService: BluectrlApiService,
              private dialogRef: MatDialogRef<NewOrganzationComponent>,
              @Inject(MAT_DIALOG_DATA) data) {

    this.form = fb.group({
      Name: [this.name, Validators.required],
    });

  }

  ngOnInit(): void {
  }


  CloseDialog() {
    this.dialogRef.close();
  }

  public SaveOrganization() {
      const name = this.form.value.Name;
      if (name) {
        this.apiService.addNewOrganization(name).subscribe((response: any) => {
          this.dialogRef.close(true);
        }, error => {
          console.log(error);
        });
      }
  }


}
