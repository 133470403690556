import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomerModule} from '../../../classes/customer-module';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {UserManagementService} from '../../../services/user-management.service';
import {ModulePlan} from '../../../classes/module-plan';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {ViewCode} from '../../../classes/enums/view-code.enum';
import {environment} from '../../../../environments/environment';
import {ModuleGlobalState} from '../../../classes/enums/module-global-state.enum';
import {ModuleServiceService} from '../../../services/module-service.service';
import {WifiManagementService} from '../../../services/v2/wifi-management.service';
import * as uuidV4 from 'uuid';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy  {

  form: UntypedFormGroup;
  public searchText: string;
  customerModules: CustomerModule[];
  filteredModules: CustomerModule[];
  ModuleTypes: string[];
  customerId: string;
  public monitoringMsgId: string;
  public ShowModuleSettings = false;
  public ShowModuleDetail = false;
  public currentLocation = 'localhost';
  public ActiveModulePlan: ModulePlan;
  public WifiHandler: any;

  @ViewChild('deviceContainer', { read: ElementRef, static: true}) public widgetsContent: ElementRef<any>;

  constructor(public usermanagement: UserManagementService,
              private fb: UntypedFormBuilder,
              private cdRef: ChangeDetectorRef,
              public moduleHandling: ModuleHandlingService,
              public server: ServerCommunicationService,
              public moduleService: ModuleServiceService,
              public wifiservice: WifiManagementService) {
    this.moduleHandling.OnCloseModuleSettings.subscribe(this.CloseSettings.bind(this));
    // this.server.OnModulesLoaded.subscribe(this.UpdateModuleList.bind(this));
    this.moduleService.NewModuleSyncStarted.subscribe(this.OnNewModuleSync.bind(this));

    this.form = fb.group({
      searchtext: [this.searchText, Validators.minLength(3)]
    });

    usermanagement.DataLoadingFinished.subscribe(this.UserLogedIn.bind(this));

    if (this.usermanagement.DataLoaded) {
      this.customerId = this.usermanagement.currentUser.CustomerId;
      this.UpdateModuleList();
    }
  }

  ngAfterViewInit() {

    const location = window.location.href;

    if (location.indexOf('theme') >= 0) {
      this.currentLocation = 'theme';
    // }
    // else if (location.indexOf('localhost') >= 0) {
    //   this.currentLocation = 'localhost';
    } else {
      this.currentLocation = 'other';
    }

    if (this.currentLocation === 'other') {
      this.WifiHandler = setInterval(() => {
        // GET AVAILABLE SSIDs
        if (this.customerModules) {
          const handled: string[] = [];



          if (this.wifiservice.getAvailableSSIDs()) {
            for (const s of this.wifiservice.getAvailableSSIDs()) {
              let ss = s as string;
              if (ss.indexOf(environment.ModuleWifiSurFix) >= 0) {
                ss = ss.replace(environment.ModuleWifiSurFix, '');
              }
              const serav = this.customerModules.find(ex => ex.SerialNumber === ss);
              if (serav) {
                handled.push(serav.SerialNumber);
                serav.ModuleSSID = s;
              }
            }
          }

          for (const mdl of this.customerModules) {
            if (!handled.find(ex => ex === mdl.SerialNumber)) {
              mdl.ModuleSSID = null;
              mdl.ModuleGlobalState = ModuleGlobalState.Unknown;
            }
          }
        }
      }, 5000);
    }
  }

  ngOnDestroy() {
    if (this.WifiHandler) {
      clearInterval(this.WifiHandler);
    }
    this.moduleService.AbortModuleStateUpdate();
    this.moduleService.RemoveConnectionInfo();
  }

  private CloseSettings() {
    this.ShowModuleSettings = false;
    this.moduleService.messageHandling.unsubscribeModuleMonitoring(this.monitoringMsgId,
      this.ActiveModulePlan.customerModule.SerialNumber);
    this.monitoringMsgId = null;
    this.moduleHandling.InventoryModuleSettingsClosed();
  }

  OnNewModuleSync() {
    for (const mdl of this.customerModules) {
      mdl.ModuleGlobalState = ModuleGlobalState.Unknown;
      mdl.InventoryInfo = null;
    }
  }

  UserLogedIn() {
    if (this.usermanagement.currentUser !== null && this.usermanagement.currentUser !== undefined) {
      this.customerId = this.usermanagement.currentUser.CustomerId;
      this.UpdateModuleList();
    }
  }

  public UpdateModuleList() {
    if (this.usermanagement.currentUser && this.server.Loaded) {
        this.customerModules = this.server.GetCurrentCustomerModules().sort((a, b) => {
          if (a.SerialNumber > b.SerialNumber) {
            return 1;
          } else if (a.SerialNumber < b.SerialNumber) {
            return -1;
          } else {
            return 0;
          }
        });
        this.filteredModules = this.customerModules;

        this.ModuleTypes = [];

        for (const i of this.filteredModules) {
          if (this.ModuleTypes.indexOf(i.Module.Group) < 0) {
            this.ModuleTypes.push(i.Module.Group);
          }
        }
    }


  }

  public getModules(type: string): CustomerModule[] {
    return this.filteredModules.filter(ex => ex.Module.Group === type);
  }

  ngOnInit(): void {
    this.moduleHandling.SetViewMode(ViewCode.inventory);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  public ScrollDown() {
    this.widgetsContent.nativeElement.scrollTop =  this.widgetsContent.nativeElement.scrollTop + 50;
  }

  public ScrollUp() {
    this.widgetsContent.nativeElement.scrollTop =
      this.widgetsContent.nativeElement.scrollTop >= 0 ?
        this.widgetsContent.nativeElement.scrollTop - 50 :
        0;
  }

  public DownScrolling(): boolean {
    if (this.widgetsContent !== undefined && this.widgetsContent !== null ) {
      const off = this.widgetsContent.nativeElement.clientHeight + this.widgetsContent.nativeElement.scrollTop;
      if (off < this.widgetsContent.nativeElement.scrollHeight) {
        return true;
      }
    }
    return false;
  }

  public UpScrolling(): boolean {


    if (this.widgetsContent !== undefined && this.widgetsContent !== null ) {
      if (this.widgetsContent.nativeElement.scrollTop > 0) {
        return true;
      }
    }
    return false;
  }

  CustomerModuleSelected(customerModule: CustomerModule) {
    this.ActiveModulePlan = new ModulePlan(false, null, customerModule);

    this.moduleHandling.InventoryModuleSettingsOpen(this.ActiveModulePlan);
    this.monitoringMsgId = uuidV4.v4();
    this.moduleService.messageHandling.subscribeModuleMonitoring(this.monitoringMsgId,
      this.ActiveModulePlan.customerModule.SerialNumber);

    this.ShowModuleSettings = true;
  }

  showModuleDetailView(customerModule: CustomerModule) {
    this.ActiveModulePlan = new ModulePlan(false, null, customerModule);
    this.ShowModuleDetail = true;
  }

  closeDetailView() {
    this.ShowModuleDetail = false;
  }

  public SearchFor(event: any) {
    const txt = this.form.value.searchtext;
    if (txt.length > 0) {
      this.filteredModules = this.customerModules.filter(ex =>
        ex.SerialNumber.toUpperCase().indexOf(txt.toUpperCase()) >= 0 ||
        ex.Module.Group.toUpperCase().indexOf(txt.toUpperCase()) >= 0 ||
        ex.Module.Name.toUpperCase().indexOf(txt.toUpperCase()) >= 0 ||
        ex.Module.Type.toUpperCase().indexOf(txt.toUpperCase()) >= 0);

      this.ModuleTypes = [];

      for (const i of this.filteredModules) {
        if (this.ModuleTypes.indexOf(i.Module.Group) < 0) {
          this.ModuleTypes.push(i.Module.Group);
        }
      }
    } else {
      this.UpdateModuleList();
    }

  }

}
