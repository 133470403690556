<section class="flex-col stretch-col">
  <div class="header flex-row stretch-row">
    <h2 class="uppercaseText center-self" mat-dialog-title>{{'DIALOGS.NEWUSER.TITLE' | i18next }}</h2>
    <div class = "flex-1 justify-end center-self">
      <button mat-button (click)="CloseDialog()">
        <img class="invertedWhite" src="/assets/general/close.svg">
      </button>
    </div>
  </div>

  <div class="inputs flex-col">
    <mat-dialog-content class="flex-col">
      <div class="selectorDiv flex-row" >
        <p class="label">{{'DIALOGS.NEWUSER.ORGANIZATION' | i18next }}</p>
        <mat-select class="selectElement" required
                    [(ngModel)]="selectedOrganization">
          <mat-select-trigger *ngIf="selectedOrganization" class="selecttrigger">
            {{selectedOrganization.name}}
          </mat-select-trigger>
          <mat-option *ngFor="let org of organisations"
                      [value]="org">{{org.name}}</mat-option>
        </mat-select>
      </div>

      <mat-form-field class="inputblue">
        <input matInput required placeholder="{{'DIALOGS.NEWUSER.EMAIL' | i18next }}" [(ngModel)]="email">
      </mat-form-field>

      <mat-form-field class="inputblue">
        <input matInput required  placeholder="{{'DIALOGS.NEWUSER.FIRST' | i18next }}" [(ngModel)]="first">
      </mat-form-field>

      <mat-form-field class="inputblue">
        <input matInput required  placeholder="{{'DIALOGS.NEWUSER.LAST' | i18next }}" [(ngModel)]="last">
      </mat-form-field>


    </mat-dialog-content>

    <div *ngIf="selectedOrganization">
      <p label="errortext"  *ngIf="errorText">{{errorText}}</p>
    </div>

  </div>

  <div class="savediv flex-row center-self">
    <button mat-button (click)="SaveUser()" *ngIf="savePossible()">
      <img class="invertedWhite" src="/assets/general/save.svg">
    </button>
  </div>

</section>
