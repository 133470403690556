<div class="element flex-col" (click)="opened = !opened" [ngClass]="{'warning': Notification.type === 'Warning' && opened, 'error': Notification.type === 'Error' && opened,'info': Notification.type === 'Info' && opened}">
  <div class="flex-row">
    <p class="componentText">{{Notification.ComponentTranslateId | i18next}} {{GetNotificationComponentNumber(Notification)}}:</p>
    <p class="messageText">{{GetNotificationText(Notification)}}</p>

    <svg class="updownicon" *ngIf="!opened" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 4.58 9.16" >
      <defs>
        <style>.cls-1{fill:#1eb4eb;}</style>
      </defs>
      <title>clap</title>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Bearbeiten_S4" data-name="Bearbeiten S4">
          <polygon class="cls-1" points="0 9.16 0 0 4.58 4.58 0 9.16"/>
        </g>
      </g>
    </svg>
    <svg class="updownicon" *ngIf="opened" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 9.16 4.58" >
      <defs>
        <style>.cls-1{fill:#1eb4eb;}</style>
      </defs>
      <title>unclap</title>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Bearbeiten_S4" data-name="Bearbeiten S4">
          <polygon class="cls-1" points="0 0 9.16 0 4.58 4.58 0 0"/>
        </g>
      </g>
    </svg>
  </div>

  <div class="flex-row" *ngIf="opened">
    <p class="componentText">{{'COMPONENTS.NOTIFICATIONVIEW.ERRORCODE' | i18next}}: {{GetNotificationCode(Notification)}}</p>
    <!--
    <p class="messageText">{{'COMPONENTS.NOTIFICATIONVIEW.BMK' | i18next}}: {{GetNotificationBmK(Notification)}}</p>
    !-->
    <div class="updownicon"></div>
  </div>

</div>
