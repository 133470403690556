<section class="flex-col stretch-self">
  <div class="header flex-row stretch-row">
    <!-- <h2 fxFlexAlign="center" mat-dialog-title class="onboardingText">{{'DIALOGS.CLUSTERONBOARDING.TITLE' | i18next }}</h2> -->
    <div class="justify-end center-self flex-1">
      <button mat-button (click)="CloseDialog()"*ngIf="!InitSucessfull">
        <img class="invertSVG" src="/assets/general/close.svg">
      </button>
      <button mat-button (click)="CloseDialog()" *ngIf="InitSucessfull">
        <img src="/assets/general/ok_green.svg">
      </button>
    </div>
  </div>

  <div class="connectionState flex-row center-self">
    <div class="actionList flex-row stretch-row" *ngFor="let a of actionList">
      <div class="flex-col" [ngClass]="{'actiondivstart': a.id === 1, 'actiondivend': a.id === 2}">
        <p class="generellTxt" [ngClass]="{'actiontextstart': a.id === 1, 'actiontextend specialPosition': a.id === 2}">{{a.action | i18next}}</p>
        <div [ngSwitch]="a.id">
          <div *ngSwitchCase="1" class="actionSvgContainer">
            <img *ngIf="a.state === 0" class="actionimgStart progressIcon" src="/assets/stepIcon_start_b.svg">
            <img *ngIf="a.state === 1" class="actionimgStart progressIcon" src="/assets/stepIcon_start_d.svg">
            <img *ngIf="a.state >= 2" class="actionimgStart progressIcon" src="/assets/stepIcon_start_r.svg">
          </div>
          <div *ngSwitchCase="2" class="actionSvgContainer">
            <img *ngIf="a.state === 0" class="actionimgEnd progressIcon" src="/assets/stepIcon_end_b.svg">
            <img *ngIf="a.state === 1" class="actionimgEnd progressIcon" src="/assets/stepIcon_end_d.svg">
            <img *ngIf="a.state >= 2" class="actionimgEnd progressIcon" src="/assets/stepIcon_end_r.svg">
          </div>
        </div>
      </div>
    </div>

    <div class="actionList flex-row stretch-row" *ngFor="let itm of arrayOne(); let i = index">
      <div class="actiondivdot flex-col">
        <p class="generellTxt2"></p>
        <img *ngIf="currentTime < (i+1) * 60" class="actionimgdot progressIcon" src="/assets/connectdot_b.svg">
        <img *ngIf="currentTime >= (i+1) * 60" class="actionimgdot progressIcon" src="/assets/connectdot_d.svg">
      </div>
    </div>

    <div class="actionList flex-row stretch-row">
      <div class="actiondivwatch flex-col">
        <p class="generellTxt3">max. {{getMaxTime()}} min {{getCurrentTime()}} min</p>
        <img class="clockIcon" src="/assets/general/clock.svg">
      </div>
    </div>

    <div class="actionList flex-row stretch-row" *ngFor="let itm of arrayOne(); let i = index">
      <div class="actiondivdot flex-col">
        <p class="generellTxt2"></p>
        <img *ngIf="currentTime < (i+1) * 60 + (secondsForSaftey/2)" class="actionimgdot progressIcon" src="/assets/connectdot_b.svg">
        <img *ngIf="currentTime >= (i+1) * 60 + (secondsForSaftey/2)" class="actionimgdot progressIcon" src="/assets/connectdot_d.svg">
      </div>
    </div>

    <div class="actionList flex-row stretch-row">
      <div class="actiondivsingle flex-col">
        <p class="generellTxt actiontextend centerLabel">{{'ACTIONS.FINALCHECK' | i18next}}</p>
        <img *ngIf="currentTime < secondsForSaftey" class="actionimgEnd progressIcon singleQuare" src="/assets/stepIcon_single_b.svg">
        <img *ngIf="currentTime >= secondsForSaftey" class="actionimgEnd progressIcon singleQuare" src="/assets/stepIcon_single_d.svg">
      </div>
    </div>
  </div>

  <button class="retryButton" mat-button (click)="RetryInit()" *ngIf="RetryPossible()">
    {{'COMMON.RETRY' | i18next }}
  </button>

  <section class="logsection flex-col">
    <div class="flex-row">
      <p class="detailheader">Details</p>
      <svg class="updownicon" *ngIf="!showLog" xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 4.58 9.16" (click)="showLog = !showLog">
        <defs>
          <style>.cls-1{fill:#1eb4eb;}</style>
        </defs>
        <title>clap</title>
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Bearbeiten_S4" data-name="Bearbeiten S4">
            <polygon class="cls-1" points="0 9.16 0 0 4.58 4.58 0 9.16"/>
          </g>
        </g>
      </svg>
      <svg class="updownicon" *ngIf="showLog" xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 9.16 4.58" (click)="showLog = !showLog">
        <defs>
          <style>.cls-1{fill:#1eb4eb;}</style>
        </defs>
        <title>unclap</title>
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Bearbeiten_S4" data-name="Bearbeiten S4">
            <polygon class="cls-1" points="0 0 9.16 0 4.58 4.58 0 0"/>
          </g>
        </g>
      </svg>
    </div>
    <div class="logview flex-col" *ngIf="showLog" #logdiv>
      <div class="flex-col" *ngFor="let l of getLogMessages()">
        <p [ngClass]="{'pInfo': l.LogType === 0, 'pWarn': l.LogType === 1, 'pError': l.LogType === 2}">{{l.Text}}</p>
      </div>
    </div>
  </section>
</section>
