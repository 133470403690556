import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModeSwitch} from '../../../classes/enums/mode-switch.enum';
import {ModuleComponent} from '../../../classes/module-component';
import {ComponentVariable} from '../../../classes/component-variable';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {ViewCode} from '../../../classes/enums/view-code.enum';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';

@Component({
  selector: 'app-module-device-container-config',
  templateUrl: './component-config.component.html',
  styleUrls: ['./component-config.component.css']
})
export class ComponentConfigComponent implements OnInit {

  private _ModeSw: ModeSwitch;
  private _inventoryView = false;
  private _editing = false;

  @Input() set inventoryView(value: boolean) {
    this._inventoryView = value;
  }
  @Input() device: ModuleComponent;
  @Input() set switchModeString(value: string) {

    if (value === null) {
      this._ModeSw = ModeSwitch.NONE;
    } else if (value === 'service') {
      this._ModeSw = ModeSwitch.SERVICE;
    } else {
      this._ModeSw = ModeSwitch.AUTOMATIC;
    }
  }
  @Input() set switchMode(value: ModeSwitch) {
    this._ModeSw = value;
  }
  @Input() SelectedComponent: string;

  @Output() OnSendToModule = new EventEmitter<ModuleComponent>();
  @Output() ConfigurationChanged = new EventEmitter<ModuleComponent>();
  @Output() DownloadRequested = new EventEmitter<ModuleComponent>();
  @Output() UploadRequested = new EventEmitter<ModuleComponent>();

  @ViewChild('value_input') value_input: ElementRef;

  get switchMode(): ModeSwitch {
    return this._ModeSw;
  }

  OwnVisible = false;

  constructor(public moduleHandling: ModuleHandlingService,
              public translate: BluectrlTranslateService) {
    this.moduleHandling.OnModuleDeviceSelected.subscribe(this.ModuleDeviceSelected.bind(this));
  }

  ngOnInit(): void {
  }

  GetConfigValues(): ComponentVariable[] {
    return this.device.Configs.filter(ex => ex.ConstallationDependency === false &&
      ex.Excluded === false &&
      ex.Visible === true &&
      (!ex.Mode || ex.Mode !== 'Service'));
  }

  SendToModule() {
    this.OnSendToModule.emit(this.device);
  }

  public ShowConfig(deviceValue: ComponentVariable): boolean {

    if (this._inventoryView && this._ModeSw !== ModeSwitch.SERVICE) {
      return false;
    }
    return !deviceValue.ConstallationDependency;
  }

  public ModuleDeviceSelected(moduleDevice: ModuleComponent) {
    this.OwnVisible = moduleDevice.PlcKey === this.device.PlcKey;
  }

  public GetCurrentValue(moduledevicevalue: ComponentVariable): any {

    if (moduledevicevalue.States.length > 0) {
      if ((moduledevicevalue.CurrentValue !== null &&
        moduledevicevalue.CurrentValue !== undefined) ||
        moduledevicevalue.CurrentValue === 0) {


        const fnd = moduledevicevalue.States.find(ex => ex.State === moduledevicevalue.CurrentValue);
        if (fnd) {
          const translated =  this.translate.GetTranslation(fnd.GetTranslationID());
          if (translated !== fnd.GetTranslationID()) {
            return translated;
          } else {
            return fnd.GetName();
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return moduledevicevalue.CurrentValue;
    }
  }

  public GetUnitTranslation(moduledevicevalue: ComponentVariable): string {
    if (moduledevicevalue.getUnitId()) {
      return this.translate.GetTranslation(moduledevicevalue.getUnitId());
    } else {
      return '';
    }
  }

  public startEditing(dv: ComponentVariable): void {
    this._editing = true;
    dv.CurrentValue = this.GetCurrentValue(dv); // Pre-fill input with current value
    setTimeout(() => {
      this.value_input?.nativeElement.focus(); // Focus input with a slight delay
    });
  }
  
  public stopEditing(dv: ComponentVariable): void {
    this._editing = false;
    this.ValueChanged(dv)
  }

  ValueChanged(deviceValue: ComponentVariable) {
    if (deviceValue.CurrentValue < deviceValue.MinValue)
      deviceValue.CurrentValue = deviceValue.MinValue
    if (deviceValue.CurrentValue > deviceValue.MaxValue)
      deviceValue.CurrentValue = deviceValue.MaxValue
    if (this._ModeSw !== ModeSwitch.SERVICE) {
      this.ConfigurationChanged.emit(this.device);
    }

  }

  public ShowConfigurator(deviceValue: ComponentVariable): boolean {


    if (this.moduleHandling.CurrentViewMode === ViewCode.live) {
      return true;
    } else  {
      return deviceValue.DesignRelevant !== false;
    }
  }

  public HaveDownloadFeature(): boolean {
    for (const cnf of this.device.Configs) {
      if (cnf.DownloadPossible === true) {
        return true;
      }
    }
    return false;
  }

  public HaveUploadFeature(): boolean {
    for (const cnf of this.device.Configs) {
      if (cnf.UploadPossible === true) {
        return true;
      }
    }
    return false;
  }

  public RequestDownload() {
    this.DownloadRequested.emit(this.device);
  }

  public RequestUpload() {
    this.UploadRequested.emit(this.device);
  }

}
