import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewChecked,
  ChangeDetectionStrategy
} from '@angular/core';
import {Conveyor} from '../../../classes/conveyor';
import {UserManagementService} from '../../../services/user-management.service';
import {CustomerModule} from '../../../classes/customer-module';
import {ServerCommunicationService} from '../../../services/server-communication.service';

import {ModuleDesignComponent} from '../../elements/module-design/module-design.component';
import {ModuleDefinition} from '../../../classes/module-definition';
import {CatalogModule} from '../../../classes/catalog-module';
import {ModulePlan} from '../../../classes/module-plan';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {environment} from '../../../../environments/environment';
import {WifiServiceNotFoundComponent} from '../../dialogs/wifi-service-not-found/wifi-service-not-found.component';
import {ConveyorSelectionDialogComponent} from '../../dialogs/conveyor-selection-dialog/conveyor-selection-dialog.component';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {ModuleConnections} from '../../../classes/enums/module-connections.enum';
import {ConveyorTypes} from '../../../classes/conveyor-types';

@Component({
  selector: 'app-module-catalog',
  templateUrl: './module-catalog.component.html',
  styleUrls: ['./module-catalog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModuleCatalogComponent implements OnInit, AfterViewChecked {

  @Output() closeModuleSelection = new EventEmitter();
  @Output() OnCustomerModuleSelected = new EventEmitter<{customerModule: CustomerModule, conveyorType: ConveyorTypes}>();
  @Output() OnModuleSelected = new EventEmitter<{module: ModuleDefinition, conveyorType: ConveyorTypes}>();
  @Output() OnNotConnectedSelected = new EventEmitter();
  @Output() OnRemoveConnectionSelected = new EventEmitter();

  @Input() selectedConveyor: Conveyor;
  @Input() set currentModules(value: CustomerModule[]) {
    if (!this._currentModules) {
      this._currentModules = value;
      this.OwnModules = this.GetOwnCustomerModules();
      this.OtherModules = this.GetOtherCustomerModules();
      this.cdRef.markForCheck();
    } else if (value.length !== this._currentModules.length) {
      this._currentModules = value;
      this.OwnModules = this.GetOwnCustomerModules();
      this.OtherModules = this.GetOtherCustomerModules();
      this.cdRef.markForCheck();
    } else {
      this._currentModules = value;
    }
  }


  private _activeModule: ModulePlan;

  @Input() set activeModule(value: ModulePlan) {
    this._activeModule = value;
  }

  get activeModule(): ModulePlan {
    return this._activeModule;
  }

  @Input() set Visible(value: boolean) {
    this.OwnModules = this.GetOwnCustomerModules();
    this.OtherModules = this.GetOtherCustomerModules();
    this.cdRef.markForCheck();
  }

  @ViewChild('deviceContainer', { read: ElementRef, static: true}) public widgetsContent: ElementRef<any>;

  @Input() set removeable(value: boolean) {
    this._removeable = value;
  }

  get removeable(): boolean {
    return this._removeable;
  }

  private _removeable = false;

  get CurrentModules(): CustomerModule[] {
    return this._currentModules;
  }



  private _currentModules:  CustomerModule[] = [];
  customerModules: CustomerModule[];
  public OwnModules: CatalogModule[];
  public OtherModules: CatalogModule[];
  modules: ModuleDefinition[];
  extModules: ModuleDefinition[];
  customerId: string;
  OwnVisible = true;
  IfeVisible = false;
  ExtVisible = true;

  constructor(public usermanagement: UserManagementService,
              public moduleHandling: ModuleHandlingService,
              public server: ServerCommunicationService,
              private cdRef: ChangeDetectorRef,
              public dialog: MatDialog) {

    usermanagement.DataLoadingFinished.subscribe(this.UserLogedIn.bind(this));
    // server.OnModulesLoaded.subscribe(this.ModulesLoaded.bind(this));


    if (this.server.Loaded === true && this.usermanagement.DataLoaded) {
      if (this.usermanagement.currentUser !== null && this.usermanagement.currentUser !== undefined) {
        this.customerId = this.usermanagement.currentUser.CustomerId;
          this.customerModules = this.server.GetCurrentCustomerModules().filter(ex => ex.Module.SupportModule === false);

      }
      this.modules = this.server.GetModules().filter(ex => ex.SupportModule === false);
    }
  }

  UserLogedIn() {
    if (this.server.Loaded === true) {
      if (this.usermanagement.DataLoaded) {
        this.customerId = this.usermanagement.currentUser.CustomerId;
          this.customerModules = this.server.GetCurrentCustomerModules().filter(ex => ex.Module.SupportModule === false);
        this.modules = this.server.GetModules().filter(ex => ex.SupportModule === false);
          this.cdRef.markForCheck();
      }
    }
  }

  ngOnInit() {
  }

  CloseClick() {
    this.closeModuleSelection.emit();
  }

  ModuleSelected(module: ModuleDefinition) {
    if (!module) {

      let allowedConveyorTypes = this.getAllowedConveyorTypes(false);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'conveyorSelectionBG';
      dialogConfig.data = {
        conveyorTypes: allowedConveyorTypes
      };
      const ref = this.dialog.open(ConveyorSelectionDialogComponent, dialogConfig);
      ref.afterClosed().subscribe((data: any) => {

        if (data) {
          this.OnModuleSelected.emit({
            module: module,
            conveyorType: data
          });
          this.closeModuleSelection.emit();
        }
      });

    } else {
      let allowedConveyorTypes = this.getAllowedConveyorTypes(true);
      if (this.moduleHandling.ActiveProject?.Modules?.length > 0) {

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'conveyorSelectionBG';
        dialogConfig.data = {
          conveyorTypes: allowedConveyorTypes
        };
        const ref = this.dialog.open(ConveyorSelectionDialogComponent, dialogConfig);
        ref.afterClosed().subscribe((data: any) => {

          if (data) {
            this.OnModuleSelected.emit({
              module: module,
              conveyorType: data
            });
            this.closeModuleSelection.emit();
          }
        });
      } else {
        this.OnModuleSelected.emit({
          module: module, conveyorType: null
        });
        this.closeModuleSelection.emit();
      }
    }


  }

  private getAllowedConveyorTypes(onlyLong: boolean): ConveyorTypes[] {


    // let connectionPoint = ModuleConnections.center;
    // const dev = this.moduleHandling.getModuleComponentFromActiveModulePlan();

    if (this.selectedConveyor != null) {
      for (const mdplan of this.moduleHandling.Modules.map(ex => ex._modulePlan)) {
        for(const con of mdplan.connections) {
          if (con.conveyor?.UniqueId === this.selectedConveyor.UniqueId) {
            const allowConf = mdplan.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === con.moduleConnection);
            if (allowConf) {
              if (onlyLong) {
                return allowConf.AllowedConveyorBeltTypes.filter(ex => ex.Name.startsWith('L'));
              } else {
                return allowConf.AllowedConveyorBeltTypes;
              }

            }
          }
        }
      }
    } else {
      const dev = this.moduleHandling.getModuleComponentFromActiveModulePlan();
      let connectionPoint = ModuleConnections.center;
      if (dev && dev.activeButtonSide) {
          const allowConf = dev._modulePlan.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === dev.activeButtonSide);
          if (allowConf) {
            if (onlyLong) {
              return allowConf.AllowedConveyorBeltTypes.filter(ex => ex.Name.startsWith('L'));
            } else {
              return allowConf.AllowedConveyorBeltTypes;
            }



          }


      }


    }
      return [];

  }

  CustomerModuleSelected(customerModule: CustomerModule) {
    let convsize = 60;
    if (this.moduleHandling.ActiveProject?.Modules?.length > 0) {
      let allowedConveyorTypes = this.getAllowedConveyorTypes(true);

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'conveyorSelectionBG';
      dialogConfig.data = {
        conveyorTypes: allowedConveyorTypes
      };
      const ref = this.dialog.open(ConveyorSelectionDialogComponent, dialogConfig);
      ref.afterClosed().subscribe((data: any) => {

        if (data) {
          this.OnCustomerModuleSelected.emit({
            customerModule,
            conveyorType: data
          });
          this.closeModuleSelection.emit();
        }
      });
    } else {
      this.OnCustomerModuleSelected.emit({
        customerModule,
        conveyorType: null
      });
      this.closeModuleSelection.emit();
    }
  }

  NotConnectedSelected() {
    this.OnNotConnectedSelected.emit();
    this.closeModuleSelection.emit();
  }

  RemoveConnection() {
    this.OnRemoveConnectionSelected.emit();
    this.closeModuleSelection.emit();
  }



  GetOwnCustomerModules(): CatalogModule[] {

    const modules = this.GetCustomerModules();

    if (modules.length > 0 && this.usermanagement.currentUser) {
      // OWN
      return modules.filter(ex => ex.CustomerModule.Ward.Id === this.usermanagement.currentUser.CustomerId ||
        ex.CustomerModule.Holder === this.usermanagement.currentUser.CustomerId ||
        ex.CustomerModule.Owner === this.usermanagement.currentUser.CustomerId);
    } else {
      return [];
    }

  }

  GetOtherCustomerModules(): CatalogModule[] {

    const modules = this.GetCustomerModules();

    if (modules.length > 0 && this.usermanagement.currentUser) {
      // OWN
      return modules.filter(ex => ex.CustomerModule.Ward.Id !== this.usermanagement.currentUser.CustomerId);
    } else {
      return [];
    }

  }

  GetOwnCustomerModulesByWard(wardid: string): CatalogModule[] {

    const modules = this.OtherModules;

    if (modules.length > 0 && this.usermanagement.currentUser) {
      // OWN
      return modules.filter(ex => ex.CustomerModule.Ward.Id === wardid);
    } else {
      return [];
    }

  }

  GetCustomerModules(): CatalogModule[] {

    let modules: CustomerModule[] = this.customerModules;

    if (this.customerModules && this.customerModules.length > 0) {

      if (this._currentModules !== null && this._currentModules !== undefined && this._currentModules.length > 0) {

        for (const cm of this._currentModules) {

          const fts = modules.find(ex => ex.CustomerModuleId === cm.CustomerModuleId);

          if (fts) {
            modules = modules.filter(ex => ex.CustomerModuleId !== cm.CustomerModuleId);
          } else {
            const ftsKey = modules.find(ex => ex.Module.Key === cm.Module.Key && ex.Customer === cm.Customer);
            if (ftsKey) {
              ftsKey.CustomerModuleId = cm.CustomerModuleId;
              modules = modules.filter(ex => ex.CustomerModuleId !== cm.CustomerModuleId);
            }
          }
        }
        const modulesToUse: CatalogModule[] = [];
        for (const m of modules) {
          if (!modulesToUse.find(ex => ex.CustomerModule.Module.Type === m.Module.Type &&
            ex.CustomerModule.Module.Version === m.Module.Version &&
            ex.CustomerModule.Ward.Id === m.Ward.Id)) {
            modulesToUse.push(new CatalogModule(m));
          } else {
            const mdl = modulesToUse.find(ex => ex.CustomerModule.Module.Type === m.Module.Type &&
              ex.CustomerModule.Module.Version === m.Module.Version &&
              ex.CustomerModule.Ward.Id === m.Ward.Id);
            mdl.Counter += 1;
          }
        }
        this.cdRef.markForCheck();
        return modulesToUse;
      } else {

        const modulesToUse: CatalogModule[] = [];

        for (const m of this.customerModules) {
          if (!modulesToUse.find(ex => ex.CustomerModule.Module.Type === m.Module.Type &&
            ex.CustomerModule.Module.Version === m.Module.Version &&
            ex.CustomerModule.Ward.Id === m.Ward.Id)) {
            modulesToUse.push(new CatalogModule(m));
          } else {
            const mdl = modulesToUse.find(ex => ex.CustomerModule.Module.Type === m.Module.Type &&
              ex.CustomerModule.Module.Version === m.Module.Version &&
              ex.CustomerModule.Ward.Id === m.Ward.Id);
            mdl.Counter += 1;
          }
        }
        this.cdRef.markForCheck();
        return modulesToUse;
      }

    }
    this.cdRef.markForCheck();
    return [];

  }

  public ScrollDown() {
    this.widgetsContent.nativeElement.scrollTop =  this.widgetsContent.nativeElement.scrollTop + 50;
    this.cdRef.markForCheck();
  }

  public ScrollUp() {
    this.widgetsContent.nativeElement.scrollTop =
      this.widgetsContent.nativeElement.scrollTop >= 0 ?
        this.widgetsContent.nativeElement.scrollTop - 50 :
        0;
    this.cdRef.markForCheck();
  }

  public DownScrolling(): boolean {


    if (this.widgetsContent !== undefined && this.widgetsContent !== null ) {
      const off = this.widgetsContent.nativeElement.clientHeight + this.widgetsContent.nativeElement.scrollTop;
      if (off < this.widgetsContent.nativeElement.scrollHeight) {
        return true;
      }
    }
    return false;
  }

  public UpScrolling(): boolean {


    if (this.widgetsContent !== undefined && this.widgetsContent !== null ) {
      if (this.widgetsContent.nativeElement.scrollTop > 0) {

        return true;
      }
    }
    return false;

  }

  ngAfterViewChecked(): void {
    // this.cdRef.detectChanges();
    this.cdRef.markForCheck();
  }

}
