<section class="flex-col stretch-col">
  <div class="header flex-row stretch-row">
    <h2 class="center-self" mat-dialog-title class="onboardingText">MODUL STATUS DEMO</h2>
    <div class = "flex-row flex-1 justify-end center-self">
      <button mat-button (click)="CloseDialog()">
        <img class="invertSVG" src="/assets/general/close.svg">
      </button>
    </div>
  </div>

  <div class="flex-row stretch-row">
    <div class="elementColumn flex-col">
      <h3 class = "center-self" mat-dialog-title class="onboardingText">LIGHT STATE</h3>
      <div class="flex-row">
        AKTUELL: {{this.currentState}}
      </div>

      <button class="selectButton" (click)="changeState(0)">
        0 - FEHLER
      </button>
      <button class="selectButton" (click)="changeState(1)">
        1 - GELB
      </button>
      <button class="selectButton" (click)="changeState(2)">
        2 -BEREIT
      </button>
      <button class="selectButton" (click)="changeState(3)">
        3 - GELB
      </button>
      <button class="selectButton" (click)="changeState(4)">
        4 - LÄUFT
      </button>
      <button class="selectButton" (click)="changeState(6)">
        6 - STARTET
      </button>
      <button class="selectButton" (click)="changeState(7)">
        7 - SERVICE
      </button>
      <button class="selectButton" (click)="changeState(8)">
        8 - SAFETY
      </button>
      <button class="selectButton" (click)="changeState(9)">
        9 - START
      </button>
      <button class="selectButton" (click)="changeState(10)">
        10 - STOP
      </button>

    </div>

    <div class="elementColumn flex-col">
      <h3 class = "center-self" mat-dialog-title class="onboardingText">SWITCH STATE</h3>
      <div class="flex-row">
        AKTUELL: {{this.currentMode}}
      </div>

      <button class="selectButton" (click)="changeModeState('auto')">
        auto
      </button>
      <button class="selectButton" (click)="changeModeState('service')">
        service
      </button>


    </div>

    <div class="elementColumn flex-col">
      <h3 class = "center-self" mat-dialog-title class="onboardingText">EMERGENCY TEST</h3>
      <div class="flex-row">
        AKTUELL: {{this.currentEmergencyState}}
      </div>

      <button class="selectButton" (click)="changeEmergencyTestRequired(true)">
        AN
      </button>
      <button class="selectButton" (click)="changeEmergencyTestRequired(false)">
        AUS
      </button>


    </div>

    <div class="elementColumn flex-col">
      <h3 class = "center-self" mat-dialog-title class="onboardingText">EMERGENCY OK TEST</h3>
      <div class="flex-row">
        AKTUELL: {{this.currentEmergencyStateOk}}
      </div>

      <button class="selectButton" (click)="changeEmergencyStopOk(true)">
        FREIGEGEBEN
      </button>
      <button class="selectButton" (click)="changeEmergencyStopOk(false)">
        GEDRÜCKT
      </button>


    </div>

  </div>
  <div class="flex-row stretch-row">
    <button class="selectButton" (click)="openModuleSettings()">
      Einstellungen öffnen
    </button>
  </div>

</section>
