<section class="flex-col">
  <img class="logo" src="/assets/general/logo_bluectrl_colour.svg">
  <h3>{{'DIALOGS.LOADAPIDATA.TITLE' | i18next }}</h3>
  <div class="spinner flex-row">
    <mat-progress-spinner class="spinner" mode="indeterminate"></mat-progress-spinner>
  </div>
  <div class="currentState">
    <p> {{ getStateText(currentState) | i18next }}</p>
  </div>
</section>
