import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {UserManagementService} from '../../../services/user-management.service';
import {User} from '../../../classes/user';
import {Router} from '@angular/router';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {LoginDialogComponent} from '../../dialogs/login-dialog/login-dialog.component';
import {VERSION} from '../../../../environments/version';
import {SplashScreenComponent} from '../../dialogs/splash-screen/splash-screen.component';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {defaultInterpolationFormat, I18NEXT_SERVICE, I18NextModule, ITranslationService} from 'angular-i18next';
import {InitOfflineUserDialogComponent} from '../../dialogs/init-offline-user-dialog/init-offline-user-dialog.component';
import {DatabaseService} from '../../../services/database.service';
import {ConnectivityService} from '../../../services/connectivity.service';
import {environment} from '../../../../environments/environment';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import * as Sentry from "@sentry/browser";

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.css']
})
export class OptionComponent implements OnInit {

  @Output() sideoptionClose = new EventEmitter();

  public currentUser: User;
  public appVersion = VERSION.version;
  public appFullVersion = VERSION.semverString;
  public appBuild = VERSION.suffix;
  // public themeBasicVersion = THEME_VERSION.version;
  // public themeBasicFullVersion = THEME_VERSION.semverString;
  // public themeBasicBuild = THEME_VERSION.suffix;
  public dmsVersion = 'NONE';
  public addPinActive = false;
  private feedbackInitialized = false;


  constructor(public usermngmt: UserManagementService,
              public router: Router,
              public dialog: MatDialog,
              public connectivity: ConnectivityService,
              public serverManagement: ServerCommunicationService,
              public moduleHandling: ModuleHandlingService,
              private database: DatabaseService
              ) {
    this.usermngmt.newUserLogin.subscribe(this.NewUser.bind(this));
    this.usermngmt.userLoggedOut.subscribe(this.loggedOut.bind(this));
  }

  ngOnInit(): void {
    this.currentUser = this.usermngmt.currentUser;
    this.initFeedbackForm(this.currentUser)
  }

  private NewUser(user: User) {
    this.currentUser = user;
    this.initFeedbackForm(this.currentUser);
  }

  private initFeedbackForm(user: User) {
    // add feedback option form for admin users
    if (user?.isAdmin() && !this.feedbackInitialized) {
      // set default email address for feedback
      Sentry.setUser({
        email: user.UserName,
        fullName: user.FirstName + ' ' + user.LastName,
      });
      // initialize feedback form and overwrite sentry's default values
      let feedback = Sentry.feedbackIntegration({
        useSentryUser: { email: "email" , name: "fullName"},
        triggerLabel: "Verbesserung einmelden",
        formTitle: "Verbesserung einmelden",
        messageLabel: "Beschreibung der Verbesserung",
        messagePlaceholder: "Beschreiben Sie den Verbesserungswunsch.",
        addScreenshotButtonLabel: "Screenshot hinzufügen",
        submitButtonLabel: "Absenden",
        cancelButtonLabel: "Abbrechen",
        successMessageText:"Vielen Dank für Ihre Meldung. Wir werden uns umgehend darum kümmern.",
      });
      // create eventlistener for sentry feedback form
      const button = document.querySelector("#add-feedback");
      if(button){
        feedback.attachTo(button, {
        });
        this.feedbackInitialized = true;
      }
    }
  }

  public setAsOfflineUser() {
    this.addPinActive = true;

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'loginDialogGray';
    const dialogRef = this.dialog.open(InitOfflineUserDialogComponent, dialogConfig);

  }

  public removeOfflineUser() {
    this.usermngmt.OnOfflineUser(null);
    this.database.RemoveOfflineUser(this.currentUser.UserName);
  }

  public logout() {
    this.usermngmt.LogOut();
    this.sideoptionClose.emit();

  }

  public loggedOut(currentUser: User) {

    if (!this.connectivity.isOnline()) {
      this.connectivity.inOfflineMode = true;
    } else {
      this.connectivity.inOfflineMode = false;
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (this.router.url !== '/live') {
      dialogConfig.panelClass = 'loginDialogGray';
    } else {
      dialogConfig.panelClass = 'loginDialogGray';
    }
    const dialogRef = this.dialog.open(LoginDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data === true) {
        // GET USER FROM API
        // OPEN LOAD
        const dialogConfig2 = new MatDialogConfig();

        dialogConfig2.disableClose = true;
        dialogConfig2.autoFocus = true;
        if (this.router.url !== '/live') {
          dialogConfig2.panelClass = 'loginDialogGray';
        } else {
          dialogConfig2.panelClass = 'loginDialogGray';
        }
        dialogConfig2.data = false;
        this.dialog.open(SplashScreenComponent, dialogConfig2);

      }

      // const cur2 = this.usermngmt.GetCurrentUser();

      // if (cur2) {
      //  this.cookieHandling.SetCurrentUser(cur2);
      // } else {
      // }
    });
  }

  public GoToClusterLog() {
    if (this.moduleHandling.ClusterInitialized) {
      const clusterId = this.moduleHandling.CurrentClusterId;
      // const orgId = this.usermanagement.currentUser.CustomerId;
      window.open(environment.GrafanaClusterLogUrl + clusterId,'_blank');
    }
  }

  public addFeedback() {
      // Needed if F5 is pressed as the form is not initialized correctly
      if (!this.feedbackInitialized) {
        this.initFeedbackForm(this.usermngmt.currentUser);
        const button = document.getElementById('add-feedback') as HTMLElement
        button.click();
      }
    }

  public reportBug() {
    try {
      // create event ID for manual error - this way sentry receives the error with a sort of title
      const eventId = Sentry.captureException(new Error(`${this.currentUser.FirstName + ' ' + this.currentUser.LastName} - Manual error for report dialog: ${new Date().toLocaleString()}`));
      Sentry.showReportDialog({ 
        eventId,
        user: { email: this.currentUser.UserName, name: this.currentUser.FirstName + ' ' + this.currentUser.LastName},
       });
    } catch (err) {
      console.error("Error capturing event: ", err);
    }
  }
}