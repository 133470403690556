<section class="flex-row">
  <div class="tasktab flex-col">
    <p class="taskText">{{'COMPONENTS.HANDOVERPOINTS.TEXT' | i18next }}</p>

    <!--
    <button *ngIf="!onboardingComp.handsoverDone; else elseBlock" (click)="onboardingComp.handsoverDone = true" class="doneButton">{{'COMMON.DONE' | i18next }}</button>
    -->

    <ng-template #elseBlock >
    </ng-template>
  </div>
</section>
