import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  OnDestroy,
  AfterViewChecked
} from '@angular/core';
import {ModulePlan} from '../../../classes/module-plan';
import {InitActionList} from '../../../classes/init-action-list';
import {Subject} from 'rxjs';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {takeUntil} from 'rxjs/operators';
import {MessageBoxComponent} from '../../dialogs/message-box/message-box.component';
import {WaitingModuleStopComponent} from '../../dialogs/waiting-module-stop/waiting-module-stop.component';
import {InitStateMessage} from '../../../classes/init-state-message';
import {WifiServiceNotFoundComponent} from '../../dialogs/wifi-service-not-found/wifi-service-not-found.component';
import {environment} from '../../../../environments/environment';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {SerialListElement} from '../../../classes/serial-list-element';
import {ModuleOnboardingService} from '../../../services/v2/module-onboarding.service';
import {WifiManagementService} from '../../../services/v2/wifi-management.service';
import {ModuleOnboardingErrors} from '../../../classes/enums/module-onboarding-errors';
import {ActionInitState} from '../../../classes/enums/action-init-state.enum';
import {WifiAutoChangeErrorComponent} from '../../dialogs/wifi-auto-change-error/wifi-auto-change-error.component';
import {DemoHandlingService} from '../../../services/demo-handling.service';
import {OnboardingLogItem} from '../../../classes/onboarding-log-item';
import {OnboardingLogType} from '../../../classes/enums/onboarding-log-type';

@Component({
  selector: 'app-module-onboarding-tab',
  templateUrl: './module-onboarding-tab.component.html',
  styleUrls: ['./module-onboarding-tab.component.css']
})
export class ModuleOnboardingTabComponent implements OnInit, OnDestroy, AfterViewChecked {

  public wifiHandler: any;
  private retries = 0;
  private retryOngoing = false;
  public _modulePlan: ModulePlan;
  private _showOnBoarding = false;
  public _serialNumbers: SerialListElement[];
  public _selectedSerialNumber: string;
  public scannerEnabled = false;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  currentIndex: number;
  public FoundInfo = this.translate.GetTranslation('INFO.ONBOARDING.SCANQR');
  public actionList = InitActionList;
  public currentAction = 1;
  public handler: any;
  public demo = false;
  private unsubscribe: Subject<void> = new Subject<void>();
  private resetDialogRef: any;
  private active = false;
  private _serialPattern = /(B[0-9]{4}-[0-9]{3}-[0-9]{4})/i;
  private _serialPatternBox = /(B[0-9]{4}-[A-Z]{4}-[0-9]{3}-[0-9]{4})/i;
  public ScannedSerial: string;
  public InitSucessfull = false;
  protected currentOnboardingId: any;
  public showLog = false;
  public logs: OnboardingLogItem[];
  private waitForOnboardingCancled = false;
  private reonboardingRequested = false;

  private noSerialCounter = 0;

  public showWaitScreen = false;

  @Input() set ShowOnBoarding(value: boolean) {
    if (value !== this._showOnBoarding) {
      this._showOnBoarding = value;
    } else {
      return;
    }

    if (value === true) {

      this.retryOngoing = false;
      this.retries = 0;
      this.demo = this.moduleHandling.DemoView;
      if (!this.demo && !environment.demo === true) {
        this.moduleOnboarding.OnboardingProgress.pipe(takeUntil(this.unsubscribe)).subscribe(this.InitStateMessageReceived.bind(this));
        this.moduleOnboarding.ModuleClusterReset.pipe(takeUntil(this.unsubscribe)).subscribe(this.ClusterResetRequested.bind(this));
        this.moduleOnboarding.ModuleClusterResetFinished.pipe(takeUntil(this.unsubscribe)).subscribe(this.ClusterResetReceived.bind(this));
        this.moduleOnboarding.WaitingForModuleStop.pipe(takeUntil(this.unsubscribe)).subscribe(this.WaitingForModuleStop.bind(this));
        this.moduleOnboarding.ClusterReinitRequested.pipe(takeUntil(this.unsubscribe)).subscribe(this.ReInitClusterRequested.bind(this));
        this.moduleOnboarding.OnboardingError.pipe(takeUntil(this.unsubscribe)).subscribe(this.ModuleOnboardingError.bind(this));
        this.moduleOnboarding.NewOnboardingId.pipe(takeUntil(this.unsubscribe)).subscribe(this.getNewOnboardingId.bind(this));
        this.moduleOnboarding.NewLogMessage.pipe(takeUntil(this.unsubscribe)).subscribe(this.addLogMessage.bind(this));
        this.moduleOnboarding.OnboardingCancled.pipe(takeUntil(this.unsubscribe)).subscribe(this.onOnboardingCancled.bind(this));
      }

      this.active = true;
      this.InitSucessfull = false;
      this.noSerialCounter = 0;

      if (this._modulePlan) {
        if (this._modulePlan.customerModule.Initialized) {
          this.InitSucessfull = true;

        }
      }

      if (!this.demo && !environment.demo) {
        this.wifiHandler = setInterval(() => {
          if (this.wifiService.getAvailableSSIDs()) {
            for (const s of this.wifiService.getAvailableSSIDs()) {
              let ss = s as string;
              if (ss.indexOf(environment.ModuleWifiSurFix) >= 0) {
                ss = ss.replace(environment.ModuleWifiSurFix, '');
              }
              const serav = this._serialNumbers.find(ex => ex.SerialNumber === ss);
              if (serav) {
                serav.NetworkAvailable = true;
              }
            }
            this.cd.markForCheck();
          }
        }, 3000);
      } else {
        if (this.wifiHandler) {
          clearInterval(this.wifiHandler);
        }

      }

      if (this._modulePlan.customerModule.SerialNumberSetted) {

        if (this.scannerEnabled) {
          this.scannerEnabled = false;
          this.showWaitScreenView();
        }
      } else {
        if (this.scannerEnabled) {
          this.scannerEnabled = false;
          this.showWaitScreenView();
        }
      }

    } else {
      this.waitForOnboardingCancled = false;
      this.reonboardingRequested = false;
      this.noSerialCounter = 0;
      this.moduleOnboarding.CancelOnboarding();
      this.unsubscribe.next();
      this.unsubscribe.complete();
      this.active = false;

      if (this.scannerEnabled) {
        this.scannerEnabled = false;
        this.showWaitScreenView();
      }
    }
  }

  @Input() set SelectedModule(value: ModulePlan) {
    this._modulePlan = value;
    this.currentOnboardingId = null;
    this.demo = this.moduleHandling.DemoView;

    this.updateSerialNumberList();

    if (!this._modulePlan.customerModule.Initialized) {
      for (const a of this.actionList) {
        a.state = 0;
      }
    }
  }

  @Output() CloseRequested = new EventEmitter();
  @Output() RequestClusterReinit = new EventEmitter();

  @ViewChild('serialContainer', { read: ElementRef, static: false}) public widgetsContent: ElementRef<any>;

  @ViewChild('logdiv', { read: ElementRef, static: false}) public logwidget: ElementRef<any>;

  constructor(public server: ServerCommunicationService,
              public moduleHandling: ModuleHandlingService,
              public moduleOnboarding: ModuleOnboardingService,
              public wifiService: WifiManagementService,
              public demoHandling: DemoHandlingService,
              public dialog: MatDialog,
              public translate: BluectrlTranslateService,
              private cd: ChangeDetectorRef) {
    if (this._modulePlan) {
      if (!this._modulePlan.customerModule.Initialized) {
        for (const a of this.actionList) {
          a.state = 0;
        }
      }
    } else {
      for (const a of this.actionList) {
        a.state = 0;
      }
    }


  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.waitForOnboardingCancled = false;
    this.reonboardingRequested = false;
    this.noSerialCounter = 0;
    this.moduleOnboarding.CancelOnboarding();
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.active = false;
  }

  ngAfterViewChecked() {
    if (this.moduleOnboarding.OnboardingRunning) {
      if (this.logwidget) {
        this.logwidget.nativeElement.scrollTop = this.logwidget.nativeElement.scrollHeight;
      }
    }
  }

  private showWaitScreenView() {
    this.showWaitScreen = true;
    setTimeout(() => {
      this.showWaitScreen = false;
    }, 1500);
  }

  private onOnboardingCancled() {
    if (this.waitForOnboardingCancled) {
      this.waitForOnboardingCancled = false;
    }
    if (this.reonboardingRequested === true) {
      this.reonboardingRequested = false;
      if (this.ShowOnBoarding === true) {
        if (!this.active) {
          this.active = true;
        }
      }

      setTimeout(() => {
        this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.STARTMODULEONBOARDING') +
          '. ' + this.translate.GetTranslation('LOGMESSAGES.TRYCOUNTER') + ' ' +
          (this.retries + 1).toString() + ' / 3', OnboardingLogType.INFO));
        this.moduleOnboarding.StartOnboarding(this._modulePlan);
      }, 3000);
    }
  }

  private updateSerialNumberList() {
    if (!this.demo) {

      this.retries = 0;
      const mdls = this.server.GetCurrentCustomerModules().filter(ex => ex.Module.Key === this._modulePlan.modul.Key);
      this._serialNumbers = [];
      for (const m of mdls) {
        this._serialNumbers.push(new SerialListElement(m.SerialNumber));
      }
      this._serialNumbers = this._serialNumbers.filter(ex => ex.SerialNumber != null);

      let alreadyUsed = this.moduleHandling.ActiveProject.Modules.filter(ex => ex.customerModule.SerialNumberSetted);

      for (const usd of alreadyUsed) {
        this._serialNumbers = this._serialNumbers.filter(ex => ex.SerialNumber !== usd.customerModule.SerialNumber);
      }

      if (this.moduleHandling.ActiveProject.SupportModules) {
        alreadyUsed = this.moduleHandling.ActiveProject.SupportModules.filter(ex => ex.customerModule.SerialNumberSetted);

        for (const usd of alreadyUsed) {
          this._serialNumbers = this._serialNumbers.filter(ex => ex.SerialNumber !== usd.customerModule.SerialNumber);
        }
      }

      this.cd.markForCheck();

      if (this._modulePlan) {
        if (this._modulePlan.customerModule) {

          if (this._modulePlan.customerModule.Initialized) {
            if (!this._modulePlan.modul.NetworkInfo) {
              // WE LOST CONNECTION
              this._modulePlan.customerModule.Initialized = false;
              this._modulePlan.modul.ServiceTaskRequired = true;
              this._modulePlan.modul.PositioningRequired = true;
              this._modulePlan.customerModule.FullyConfigured = false;
              this._modulePlan.ConfigFinishedSended = false;
            }
          }



          if (!this._modulePlan.customerModule.Initialized) {
            if (this._modulePlan.customerModule.SerialNumberSetted === true) {
              this._selectedSerialNumber = this._modulePlan.customerModule.SerialNumber;
              this.ValidSerialSelected();
            }
            this._modulePlan.ConfigFinishedSended = false;
          }
        }
      }

    } else {

      // CUSTOMER MODULE
      this._serialNumbers = [];
      this._serialNumbers.push(new SerialListElement('BXXXX-XXX-XXX1'));
      this._serialNumbers.push(new SerialListElement('BXXXX-XXX-XXX2'));
      this._serialNumbers.push(new SerialListElement('BXXXX-XXX-XXX3'));
      this._serialNumbers.push(new SerialListElement('BXXXX-XXX-XXX4'));
      this._serialNumbers.push(new SerialListElement('BXXXX-XXX-XXX5'));
      this._serialNumbers.push(new SerialListElement('BXXXX-XXX-XXX6'));
      this._serialNumbers.push(new SerialListElement('BXXXX-XXX-XXX7'));
      this._serialNumbers.push(new SerialListElement('BXXXX-XXX-XXX8'));
      this._serialNumbers.push(new SerialListElement('BXXXX-XXX-XXX9'));
      this._serialNumbers.push(new SerialListElement('BXXXX-XXX-XX10'));
      this._serialNumbers.push(new SerialListElement('BXXXX-XXX-XX11'));
    }
  }

  private addLogMessage(logMessage: OnboardingLogItem) {
    if (this.moduleOnboarding.OnboardingRunning && !this.waitForOnboardingCancled) {
      if (!this.logs) {
        this.logs = [];
      }

      this.logs.push(logMessage);
    }
  }

  private getNewOnboardingId(id: any) {
    this.currentOnboardingId = id;
  }

  private ModuleOnboardingError(error: ModuleOnboardingErrors) {
    switch (error) {
      case ModuleOnboardingErrors.NoCustomerModule: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.NOCUSTOMERMODULE');
        this.resetDialogRef = this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
      case ModuleOnboardingErrors.OnboardingRunning: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.ONBOARDINGISRUNING');
        this.resetDialogRef = this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
      case ModuleOnboardingErrors.SerialNumberNotSet: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.SERIALNOTSET');
        this.resetDialogRef = this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
    }
    this.retries = 4;
    for (const a of this.actionList) {
      a.state = ActionInitState.failed;
    }
  }

  public ReInitClusterRequested(data: any) {
    if (this._showOnBoarding) {
      this.moduleHandling.MasterIp = data.MasterIpAddress;
      this.moduleHandling.MasterSerialNumber = data.MasterSerialNumber;
      this.moduleHandling.CurrentClusterId = data.ClusterId;
      // this.CloseRequested.emit();
      this.RequestClusterReinit.emit();
      this.CloseDialog(true);
    }
  }

  public IsOnline(ser: string): boolean {

    if (!this.demo) {
      const seren = this._serialNumbers.find(ex => ex.SerialNumber === ser);

      if (seren) {
        return seren.NetworkAvailable;
      }
    } else {
      const rm = Math.floor(Math.random() * Math.floor(2));
      return rm === 1;
    }

    return false;
  }

  CloseDialog(withclose = false) {
    if (this.moduleOnboarding.OnboardingRunning) {
      this.moduleOnboarding.CancelOnboarding();
      this.active = false;
      if (withclose === true) {

      }
    }
  }

  public SelectSerial(serial: string) {
    this._selectedSerialNumber = serial;
    this.ScannedSerial = null;
    this._modulePlan.customerModule.SerialNumber = this._selectedSerialNumber;
    this._modulePlan.customerModule.SerialNumberSetted = true;
    // this.dialogRef.close();
    this.ValidSerialSelected();
  }

  public ScrollDown() {
    this.widgetsContent.nativeElement.scrollTop = this.widgetsContent.nativeElement.scrollTop + 50;
  }

  public ScrollUp() {
    this.widgetsContent.nativeElement.scrollTop =
      this.widgetsContent.nativeElement.scrollTop >= 0 ? this.widgetsContent.nativeElement.scrollTop - 50 : 0;
  }

  public ScannedDeviceValid(): boolean {
    const ser = this._serialNumbers.find(ex => ex.SerialNumber === this.ScannedSerial);
    return !!ser;
  }

  public DownScrolling(): boolean {

    if (this.widgetsContent !== undefined && this.widgetsContent !== null) {
      const off = this.widgetsContent.nativeElement.clientHeight + this.widgetsContent.nativeElement.scrollTop;
      if (off < this.widgetsContent.nativeElement.scrollHeight) {
        return true;
      }
    }
    return false;
  }

  public UpScrolling(): boolean {
    if (this.widgetsContent !== undefined && this.widgetsContent !== null) {
      if (this.widgetsContent.nativeElement.scrollTop > 0) {
        return true;
      }
    }
    return false;
  }

  public scanSuccessHandler($event: any) {

    const serr = $event as string;
    let match = serr.trim().match(this._serialPattern);

    if (!match) {
      match = serr.trim().match(this._serialPatternBox);
    }

    if (match) {

      this.ScannedSerial = $event as string;
      if (this.ScannedDeviceValid()) {

        this.FoundInfo = this.translate.GetTranslation('INFO.ONBOARDING.SCANSUCCESS');
        this._selectedSerialNumber = null;
        this._modulePlan.customerModule.SerialNumber = this.ScannedSerial;
        this._modulePlan.customerModule.SerialNumberSetted = true;
        // this.dialogRef.close();
        this.ValidSerialSelected();

      } else {
        this.ScannedSerial = null;
        this.FoundInfo = this.translate.GetTranslation('INFO.ONBOARDING.WRONGSERIAL');
      }


    } else {
      this.FoundInfo = this.translate.GetTranslation('INFO.ONBOARDING.NOBLUELINESERIAL');
    }

    this.cd.markForCheck();
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;

    if (this.availableDevices && this.availableDevices.length > 0) {
      this.currentDevice = this.availableDevices[0];
      this.currentIndex = 0;
      this.cd.markForCheck();
    }
  }

  public switchCam() {
    if (this.availableDevices.length > this.currentIndex + 1) {
      this.currentIndex = this.currentIndex + 1;
      this.currentDevice = this.availableDevices[this.currentIndex];
    } else if (this.availableDevices.length > 1) {
      this.currentIndex = 0;
      this.currentDevice = this.availableDevices[this.currentIndex];
    }
    this.cd.markForCheck();
  }

  public ValidSerialSelected() {
    this.logs = [];

    if (!this.active) {
      this.active = true;
    }

    if (this.demo) {
      this.currentAction = 1;
      this.handler = setInterval(() => {
        const act = this.actionList.find(ex => ex.id === this.currentAction);

        if (act) {
          act.state = Math.floor(Math.random() * Math.floor(2)) + 1;
          this.currentAction = this.currentAction + 1;
          this.actionList = this.actionList;
          this.cd.markForCheck();
          if (this.currentAction > 6) {
            this.InitSucessfull = true;
            this._modulePlan.customerModule.Initialized = true;
            clearInterval(this.handler);
          }
        } else {
          clearInterval(this.handler);
        }
      }, 2000);
    }
    else if (environment.demo) {
      this.currentAction = 1;
      this.handler = setInterval(() => {

        if (this._modulePlan.customerModule.SerialNumberSetted) {
          const act = this.actionList.find(ex => ex.id === this.currentAction);

          if (act) {
            act.state = 1;
            this.currentAction = this.currentAction + 1;
            this.actionList = this.actionList;
            this.cd.markForCheck();
            if (this.currentAction > 6) {
              this.InitSucessfull = true;
              this.demoHandling.SetModuleOnboardingInformations(this._modulePlan);
              this._modulePlan.customerModule.Initialized = true;
              clearInterval(this.handler);
            }
          } else {
            clearInterval(this.handler);
          }
        } else {
          clearInterval(this.handler);
        }
      }, 1000);
    }
    else {

      if (this._modulePlan.customerModule.SerialNumber && !this._modulePlan.customerModule.SerialNumberSetted) {
        this._modulePlan.customerModule.SerialNumberSetted = true;

      }


      if (this.waitForOnboardingCancled) {
        this.reonboardingRequested = true;
      } else {
        this.logs.push(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.STARTMODULEONBOARDING') +
          '. ' + this.translate.GetTranslation('LOGMESSAGES.TRYCOUNTER') + ' ' +
          (this.retries + 1).toString() + ' / 3', OnboardingLogType.INFO));
        this.moduleOnboarding.StartOnboarding(this._modulePlan);
      }

    }

    if (this.scannerEnabled) {
      this.scannerEnabled = false;
      this.showWaitScreenView();
    }

  }

  public ClusterResetRequested() {
    // SHOW DIALOG FOR Please Wait
    if (!this.active) {
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'loginDialogGray';
    dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.CLUSTERRESET');
    this.resetDialogRef = this.dialog.open(MessageBoxComponent, dialogConfig);
  }

  public ClusterResetReceived() {
    if (!this.active) {
      return;
    }

    if (this.resetDialogRef) {
      this.resetDialogRef.close();
    }
  }

  public WaitingForModuleStop() {
    if (!this.active) {
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'loginDialogGray';
    const dialogRef = this.dialog.open(WaitingModuleStopComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {

      } else {
        this.moduleOnboarding.CancelOnboarding();
      }
    });

  }

  public InitStateMessageReceived(stateMessage: InitStateMessage) {

    if (this.moduleOnboarding.currentOnboardingId && this.currentOnboardingId && this.moduleOnboarding.currentOnboardingId === this.currentOnboardingId) {


      if (this._modulePlan.customerModule.SerialNumber || this.noSerialCounter < 3) {

        if (!this._modulePlan.customerModule.SerialNumber) {
          this.noSerialCounter += 1;
        } else {
          this.noSerialCounter = 0;
        }

        if (!this._showOnBoarding) {
          return;
        }

        if (stateMessage.ActionId === -1) {
          return;
        }


        const act = this.actionList.find(ex => ex.id === stateMessage.ActionId);
        if (act) {


          if (this.actionList.find(ex => ex.id === 1).state === 3) {
            if (act.id === 2 && stateMessage.State === 2) {
              // Manually change wifi
              this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.AUTOMATICWIFIFAILED'),
                OnboardingLogType.ERROR));
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = false;
              dialogConfig.autoFocus = true;
              dialogConfig.panelClass = 'loginDialogGray';
              dialogConfig.data = environment.ModuleWifiSurFix + this._modulePlan.customerModule.SerialNumber;
              const ref = this.dialog.open(WifiAutoChangeErrorComponent, dialogConfig);
              ref.afterClosed().subscribe((data: any) => {
                // this.moduleOnboarding.continueWithoutWifiService();

                // STOP REAL
              });
              this.retries = 4;
              const following = this.actionList.filter(ex => ex.id >= stateMessage.ActionId);
              for (const f of following) {
                f.state = 2;
              }
              this._modulePlan.RemoveOnboardingInfo(true);
              return;
            }
          }

          if (stateMessage.State === 2 && act.id >= 2) {
            if (stateMessage.Forced === true) {
              this.retries = 4;
              const following = this.actionList.filter(ex => ex.id >= stateMessage.ActionId);
              for (const f of following) {
                f.state = 2;
              }
              this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.MODULEONBOARDINGFAILED'),
                OnboardingLogType.ERROR));
              this._modulePlan.RemoveOnboardingInfo(true);
            } else {
              if (this.retries <= 3) {
                this.retryOngoing = true;
                setTimeout(() => {
                  this.RetryInit();
                  this.retryOngoing = false;
                  this.retries += 1;
                }, 5000);
              } else {
                const following = this.actionList.filter(ex => ex.id >= stateMessage.ActionId);
                for (const f of following) {
                  f.state = 2;
                }
                this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.MODULEONBOARDINGFAILED'),
                  OnboardingLogType.ERROR));
                this.retries = 0;
              }
            }
          } else {

            act.state = stateMessage.State;

            if (act.id === 5 && stateMessage.State === 1) {
              // Its done
              this._modulePlan.customerModule.Initialized = true;
              const act2 = this.actionList.find(ex => ex.id === 6);
              if (act2) {
                act2.state = 1;
                this.InitSucessfull = true;
              }
              this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.MODULEONBOARDINGSUCCESSFULL'),
                OnboardingLogType.INFO));
              this.CloseDialog();
            }

          }

          // if (act.state === 2) {
          //   const following = this.actionList.filter(ex => ex.id > stateMessage.ActionId);
          //   for (const f of following) {
          //     f.state = 2;
          //   }
          //
          //   if (stateMessage.Forced === true) {
          //     this.retries = 4;
          //     this._modulePlan.RemoveOnboardingInfo(true);
          //   }
          //
          //   // RETRY
          //   if (this.retries <= 3) {
          //     this.retryOngoing = true;
          //     setTimeout(() => {
          //       this.RetryInit();
          //       this.retryOngoing = false;
          //       this.retries += 1;
          //     }, 5000);
          //   }
          // }
          // Check if first and second failed - if yes then request service
          if (!this.retryOngoing) {
            if (this.actionList.find(ex => ex.id === 1).state === 4) {
              // NO WIFI SERVICE
              this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.NOWIFISERVICE'),
                OnboardingLogType.WARNING));
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = false;
              dialogConfig.autoFocus = true;
              dialogConfig.panelClass = 'loginDialogGray';
              dialogConfig.data = environment.ModuleWifiSurFix + this._modulePlan.customerModule.SerialNumber;
              const ref = this.dialog.open(WifiServiceNotFoundComponent, dialogConfig);
              ref.afterClosed().subscribe((data: any) => {
                this.moduleOnboarding.continueWithoutWifiService();
              });
            }


          }
        }
        //   }
        // }
      } else {

        if (this.noSerialCounter >= 3) {
          this.moduleOnboarding.CancelOnboarding();
        } else {
          this.noSerialCounter += 1;
        }
      }
    } else {
      console.log('wrong onboarding Id'); //clearing cache and restrating server works - i suppose clearing cache should suffice
    }

    this.cd.markForCheck();
  }

  public RetryInit() {
    if (this.actionList.filter(ex => ex.state === 2).length > 0) {
      this.actionList = InitActionList;
      for (const l of this.actionList) {
        l.state = 0;
      }
    }
    this.retries = 0;
    this.ValidSerialSelected();
  }

  public RetryPossible(): boolean {

    if (this.retryOngoing === true) {
      return false;
    }

    // if (this._modulePlan.customerModule.Initialized) {
    //   return true;
    // }

    return this.actionList.filter(ex => ex.state === 2).length > 0;
  }

  public getLogMessages(): OnboardingLogItem[] {
    if (environment.production === true) {
      return this.logs.filter(ex => ex.LogType !== OnboardingLogType.DEBUG);
    } else {
      return this.logs;
    }
  }

  public changeSerial() {
    if (this.moduleOnboarding.OnboardingRunning) {
      this.moduleOnboarding.CancelOnboarding();
      this.waitForOnboardingCancled = true;
    }

    this.actionList = InitActionList;
    for (const l of this.actionList) {
      l.state = 0;
    }
    this.retryOngoing = false;
    this.InitSucessfull = false;
    this._modulePlan.RemoveOnboardingInfo(true);
    this._modulePlan.customerModule.SerialNumberSetted = false;
    this._modulePlan.customerModule.SerialNumber = null;

    this.updateSerialNumberList();

  }

  public ActiveDeactivateScanner() {
    if (this.scannerEnabled) {
      this.scannerEnabled = false;
      this.showWaitScreenView();
    } else {
      this.scannerEnabled = true;
      this.showWaitScreenView();
    }
  }

}
