import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {BluectrlApiService} from '../../../services/bluectrl-api.service';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {

  public organisations: any[];
  public users: any[];

  public errorText: string;
  public email: string;
  public first: string;
  public last: string;
  public selectedOrganization: any;

  private _emailPattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;


  constructor(private fb: UntypedFormBuilder,
              public apiService: BluectrlApiService,
              private dialogRef: MatDialogRef<NewUserComponent>,
              @Inject(MAT_DIALOG_DATA) data) { }

  ngOnInit(): void {
    this.loadUsers();
    this.loadOrganisations();
  }

  CloseDialog() {
    this.dialogRef.close();
  }

  private loadUsers() {
    this.apiService.getUsers().subscribe(async (users: any) => {
      if (users.length > 0) {
        this.users = users;
      }
    });
  }

  private loadOrganisations() {
    this.apiService.getOrganizations().subscribe(async (organizations: any) => {
      if (organizations.length > 0) {
        this.organisations = organizations;
      }
    });
  }

  public savePossible(): boolean {

    this.errorText = null;
    if (this.selectedOrganization) {
      if (this.email) {
        const match = this.email.trim().match(this._emailPattern);


        if (!match) {
          this.errorText = 'Please enter valid e-mail';
        } else {
          if (this.users.find(ex => ex.email === this.email)) {
            this.errorText = 'Entered E-mail address already exists';
          } else {
            if (this.first) {
              if (this.last) {
                return true;
              } else {
                this.errorText = 'Please enter last name';
              }
            } else {
              this.errorText = 'Please enter first name';
            }
          }
        }
      } else {
        this.errorText = 'Please enter email';
      }
    } else {
      this.errorText = 'Please select organization';
    }

    return false;
  }

  public SaveUser() {
    if (this.selectedOrganization &&
        this.email && this.first && this.last) {
      this.apiService.addNewUser(this.email, this.first, this.last, this.selectedOrganization.id).subscribe((response: any) => {
        if (response) {
          this.dialogRef.close(true);
        }
      }, error => {
        console.log(error);
      });
    }
  }

}
