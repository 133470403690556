import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {UserManagementService} from '../../../services/user-management.service';
import {DatabaseService} from '../../../services/database.service';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatCalendar} from '@angular/material/datepicker';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {Moment} from 'moment';
import {User} from '../../../classes/user';
import {Batch} from '../../../classes/batch';
import {Project} from '../../../classes/project';
import {Material} from '../../../classes/material';
import {ModeSwitch} from '../../../classes/enums/mode-switch.enum';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {MessageHandlingService} from '../../../services/v2/message-handling.service';
import * as uuidV4 from 'uuid';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-send-recipe',
  templateUrl: './send-recipe.component.html',
  styleUrls: ['./send-recipe.component.css']
})
export class SendRecipeComponent implements OnInit, AfterViewInit, OnDestroy {


  form: UntypedFormGroup;
  @ViewChild('userlist', { read: ElementRef, static: true}) public widgetsContent: ElementRef<any>;
  @ViewChild('materiallist', { read: ElementRef, static: true}) public widgetsContentMaterial: ElementRef<any>;
  @ViewChild('calendar', {static: true}) calendar: MatCalendar<Moment>;
  public users: User[] = [];
  public materials: Material[] = [];
  private currentUser: User;
  private batches: Batch[] = [];
  public filteredBatches: Batch[] = [];
  public _selectedUser: User;
  public selectedBatch = null;
  public project: Project;
  public UploadActive = false;
  public UpdateSucessfull = false;
  public UploadInfo: string[] = [];
  public displayedColumns = ['Name', 'Date', 'Material', 'Author', 'Supplier'];
  public dataSource = new MatTableDataSource<Batch>();
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  public MessageID: string;
  public lastRevisions: any[] = [];
  public ErrorText: string;
  public handler: any;
  public waitingForRestart: any[] = [];
  public UpdateInfo: string[] = [];

  private closeTimeout = 2000;
  constructor(
    public server: ServerCommunicationService,
    public moduleHandling: ModuleHandlingService,
    public messageHandling: MessageHandlingService,
    public userMngmt: UserManagementService,
    public database: DatabaseService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public translate: BluectrlTranslateService,
    private dialogRef: MatDialogRef<SendRecipeComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {

    this.project = data;

    this.currentUser = this.userMngmt.currentUser;
    this.database.batches.toArray().then(bt => {
      this.batches = bt;
      this.batches = this.batches.filter(ex => ex.customer === this.currentUser.CustomerId);
      this.filteredBatches = this.batches;
      this.dataSource = new MatTableDataSource<Batch>(this.filteredBatches);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

    });

    this.messageHandling.RecipeReceived.subscribe(this.ReciveConfirm.bind(this));
    this.messageHandling.RecipeUpdated.subscribe(this.ReciveFinished.bind(this));
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  CloseDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.handler) {
      clearInterval(this.handler);
    }
  }

  UploadRecipe() {

    if (environment.demo === true) {

      let validateErrors = this.moduleHandling.ValidateProject(this.moduleHandling.ActiveProject, true);
      if (validateErrors.length > 0) {

        this.ErrorText = validateErrors[0].module + ': ' + validateErrors[0].text;
      } else {
        this.UpdateSucessfull = false;
        this.UploadActive = true;

        setTimeout(() => {
          this.UploadActive = false;
          this.UpdateSucessfull = true;

          // CLOSE
          setTimeout(()=> {
            this.dialogRef.close();
          }, this.closeTimeout);

        }, 1500);

      }



    } else {

      this.UploadActive = true;
      this.UploadInfo = [];
      this.UpdateSucessfull = false;
      this.ErrorText = null;
      let haveErrors = false;

      if (this.selectedBatch) {
        this.project.batch = this.selectedBatch;
        this.selectedBatch = null;
      }


      for (const md of this.project.Modules) {
        if (!md.modul.Connected) {
          this.UploadInfo.push(this.translate.GetTranslation('INFO.SENDRECIPE.MODULE') + md.modul.Group + ' ' +
            md.modul.Name + ' ' + md.customerModule.SerialNumber +
            this.translate.GetTranslation('INFO.SENDRECIPE.NOTONLINE'));
          haveErrors = true;
        }


        if (md.modul.CurrentMode !== ModeSwitch.AUTOMATIC) {
          if (md.modul.CurrentMode === ModeSwitch.SERVICE) {
            this.UploadInfo.push(this.translate.GetTranslation('INFO.SENDRECIPE.MODULE') + md.modul.Group + ' ' +
              md.modul.Name + ' ' + md.customerModule.SerialNumber +
              this.translate.GetTranslation('INFO.SENDRECIPE.INSERVICEMODE'));
          } else {
            this.UploadInfo.push(this.translate.GetTranslation('INFO.SENDRECIPE.MODULE') + md.modul.Group + ' ' +
              md.modul.Name + ' ' + md.customerModule.SerialNumber +
              this.translate.GetTranslation('INFO.SENDRECIPE.INCRANMODE'));
          }
          haveErrors = true;
        }
      }

      let validateErrors = this.moduleHandling.ValidateProject(this.moduleHandling.ActiveProject, true);
      if (validateErrors.length > 0) {
        haveErrors = true;
        this.ErrorText = validateErrors[0].module + ': ' + validateErrors[0].text;
        this.UpdateSucessfull = false;
        this.UploadActive = false;
      }

      if (!haveErrors) {
        const res = this.moduleHandling.CreateRecipe(this.project);

        const strft = JSON.parse(res);

        this.MessageID = uuidV4.v4();
        this.messageHandling.SendRecipe(this.MessageID, this.moduleHandling.CurrentClusterId, strft);

        if (this.handler) {
          clearInterval(this.handler);
        }

        this.handler = setInterval(() => {

          this.ErrorText = this.translate.GetTranslation('INFO.SENDRECIPE.TIMEOUT');
          this.UploadActive = false;
          clearInterval(this.handler);

        }, 30000);
      }

    }

  }

  public ReciveConfirm(data: any) {

     // SAVE CLUSTER STATE

    if (data) {
      if (data.msgId && data.msgId === this.MessageID) {
        this.lastRevisions = data.modules;

        let notFound = false;

        for (const md of this.project.Modules) {
          if (md.customerModule) {
            const rev = this.lastRevisions.find(ex => ex.module === md.customerModule.SerialNumber);

            if (!rev) {
              this.UploadInfo.push(this.translate.GetTranslation('INFO.SENDRECIPE.MODULE') + md.modul.Group + ' ' +
                md.modul.Name + ' ' + md.customerModule.SerialNumber +
                this.translate.GetTranslation('INFO.SENDRECIPE.NORECIPE'));
              notFound = true;
            } else {
              md.customerModule.CurrentRevision = rev.revision;

            }
          }
        }
        if (notFound) {
          return;
        }
      }
    }
  }

  public ReciveFinished(data: any) {
    // CHECK DATA
    this.UpdateInfo = [];

    if (data) {
      if (data.msgId && data.msgId === this.MessageID) {

        const result = data.modules;

        let notFound = false;
        let notUpdated = false;

        for (const nw of this.lastRevisions) {
          const fnd = result.find(ex => ex.module === nw.module);

          if (fnd) {
              if (fnd.revision <= nw.revision) {
                const md = this.project.Modules.find(ex => ex.customerModule.SerialNumber === nw.module);
                if (md) {
                  this.UploadInfo.push(this.translate.GetTranslation('INFO.SENDRECIPE.MODULE') + md.modul.Group +
                    ' ' + md.modul.Name + ' ' + md.customerModule.SerialNumber +
                    this.translate.GetTranslation('INFO.SENDRECIPE.UPDATED'));
                }
                notUpdated = true;
              } else {
                const md = this.project.Modules.find(ex => ex.customerModule.SerialNumber === nw.module);
                if (md) {
                  md.customerModule.CurrentRevision = fnd.revision;
                  md.customerModule.UpdateRevision = fnd.revision;
                }
              }
          } else {
            const md = this.project.Modules.find(ex => ex.customerModule.SerialNumber === nw.module);
            if (md) {
              this.UploadInfo.push(this.translate.GetTranslation('INFO.SENDRECIPE.MODULE') + md.modul.Group + ' ' +
                md.modul.Name + ' ' + md.customerModule.SerialNumber +
                this.translate.GetTranslation('INFO.SENDRECIPE.NORECIPE'));
            }
            notFound = true;
          }
        }

        if (notFound) {
          return;
        }

        if (notUpdated) {
          this.ErrorText = this.translate.GetTranslation('INFO.SENDRECIPE.NOUPDATED');
        } else {

          if ( this.waitingForRestart.length <= 0) {
            this.UpdateSucessfull = true;
            this.project.currentRevision = result[0].revision;

            // CLOSE
            setTimeout(()=> {
              this.dialogRef.close();
            }, this.closeTimeout);

          }
          if (this.handler) {
            clearInterval(this.handler);
          }
        }

        this.UploadActive = false;

      }
    }


  }
}
