import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {DatabaseService} from '../../../services/database.service';
import {UserManagementService} from '../../../services/user-management.service';

@Component({
  selector: 'app-init-offline-user-dialog',
  templateUrl: './init-offline-user-dialog.component.html',
  styleUrls: ['./init-offline-user-dialog.component.css']
})
export class InitOfflineUserDialogComponent implements OnInit {

  hide = true;
  hideRepeat = true;
  saveOngoing = false;
  errorText: string;

  pinForm: UntypedFormGroup = new UntypedFormGroup({
    pin: new UntypedFormControl('', [Validators.required ]),
    repeatPin: new UntypedFormControl('', [Validators.required ])
  });

  get passwordInput() { return this.pinForm.get('pin'); }
  get passwordRepeatInput() { return this.pinForm.get('repeatPin'); }

  constructor(public dialog: MatDialog,
              public translation: BluectrlTranslateService,
              public database: DatabaseService,
              public usermng: UserManagementService,
              private dialogRef: MatDialogRef<InitOfflineUserDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data
              ) { }

  ngOnInit(): void {
  }

  public setOfflineUser() {
      if (this.passwordInput.value && this.passwordRepeatInput.value) {
        if (this.passwordInput.value === this.passwordRepeatInput.value) {
          this.errorText = null;
          this.saveOngoing = true;

          this.database.apiservice.getUserInfo().subscribe((data: any) => {
            if (data.id) {
              this.database.AddOfflineUser(data.email, this.passwordInput.value, data);

              this.usermng.OnOfflineUser({Pin: this.passwordInput.value});

              this.database.apiservice.GetUserModules(null).subscribe(async (data: any) => {
                if (data) {
                  this.database.saveCustomerModules(this.usermng.currentUser.CustomerId, data);
                }

                let counter = 0;
                let max = this.usermng.currentUser.UserWards.length - 1;

                for (const wrd of this.usermng.currentUser.UserWards) {
                  this.database.apiservice.GetUserModules(wrd.Id).subscribe(async (data: any) => {
                    if (data) {
                      this.database.saveCustomerModules(wrd.Id, data);
                    }

                    counter += 1;

                    if (counter >= max) {
                      this.saveOngoing = false;
                      this.dialogRef.close(true);
                    }
                  }, () => {


                  });
                }

              }, () => {



                });




            }
            // this.CurrentUser = this.usermanagement.currentUser;
          }, error => {
            this.saveOngoing = false;
          });

        } else {
          this.errorText = this.translation.GetTranslation('DIALOGS.OFFLINEUSER.ERRORMATCH');
        }

      } else {
          this.errorText = this.translation.GetTranslation('DIALOGS.OFFLINEUSER.ERRORNOVALUE');
      }
  }

  CloseDialog() {
    this.dialogRef.close();
  }

}
