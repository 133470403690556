<section class="flex-row" *ngIf="SelectedModule">
  <div class="tasktab flex-col">
    <p class="taskText">{{'COMPONENTS.POSITIONINGTAB.INFO' | i18next }}</p>
    <img class="posimage" src="/assets/general/positioning_graphic.svg">
    <!-- <button *ngIf="SelectedModule.modul.PositioningRequired; else elseBlock" (click)="SetPositioningDone()" class="doneButton">{{'COMMON.DONE' | i18next }}</button>
    -->
    <ng-template #elseBlock >
    </ng-template>
  </div>
</section>
