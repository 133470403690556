import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {User} from '../../../classes/user';
import {UserManagementService} from '../../../services/user-management.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {DatabaseService} from '../../../services/database.service';
import {LoginDialogComponent} from '../../dialogs/login-dialog/login-dialog.component';
import {Router} from '@angular/router';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {ViewCode} from '../../../classes/enums/view-code.enum';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {QuestionBoxComponent} from '../../dialogs/question-box/question-box.component';
import {MessageBoxComponent} from '../../dialogs/message-box/message-box.component';
import {BluectrlApiService} from '../../../services/bluectrl-api.service';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {SplashScreenComponent} from '../../dialogs/splash-screen/splash-screen.component';
import {ModulePlan} from '../../../classes/module-plan';
import {WebsocketService} from '../../../services/v2/websocket.service';
import {ClusterOnboardingService} from '../../../services/v2/cluster-onboarding.service';
import {ConfirmMessageBoxComponent} from '../../dialogs/confirm-message-box/confirm-message-box.component';
import {RecipeValidationErrorDialogComponent} from '../../dialogs/recipe-validation-error-dialog/recipe-validation-error-dialog.component';
import {environment} from '../../../../environments/environment';
import {ProjectSaveWarningComponent} from '../../dialogs/project-save-warning/project-save-warning.component';
import {defaultInterpolationFormat, I18NEXT_SERVICE, I18NextModule, ITranslationService} from 'angular-i18next';
import {ConnectivityService} from '../../../services/connectivity.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  @Output() public sidenavToggle = new EventEmitter();
  @Output() public optionToogle = new EventEmitter();


  @ViewChild('nameInput', {static: false}) inputEl: ElementRef;

  CurrentUser: User;
  private currentIp: string;
  public isConnected = false;
  public EditActive = false;
  form: UntypedFormGroup;
  name: string;
  public ShowTestRecipeIcon = false;
  public HaveInternet = true;
  public SelectedInventoryModule: ModulePlan;
  // public handler: any;
  selectedLanguage: any;
  languageList = [
    { code: 'en', label: 'English', image: 'assets/general/flags/English.png' },
    { code: 'de', label: 'Deutsch', image: 'assets/general/flags/German.png' },
  ];
  public showLanguageSelector = true;

  public languageChangeActive = false;

  private waitForProjectSaved = false;

  public i18nextOptions = {
    whitelist: ['en', 'de'],
    fallbackLng: 'en',
    debug: false, // set debug?
    returnEmptyString: true,
    ns: [
      'translation'
    ],
    interpolation: {
      format: I18NextModule.interpolationFormat(defaultInterpolationFormat)
    },
    // backend plugin options
    backend: {
      loadPath: 'assets/locale/{{lng}}.{{ns}}.json'
    },
    // lang detection plugin options
    detection: {
      // order and from where user language should be detected
      order: ['cookie'],

      // keys or params to lookup language from
      lookupCookie: 'lang',

      // cache user language on
      caches: ['cookie'],

      // optional expire and domain for set cookie
      cookieMinutes: 10080 // 7 days
    }
  };


  constructor(public usermanagement: UserManagementService,
              private bluectrlapi: BluectrlApiService,
              public websocket: WebsocketService,
              public connectivity: ConnectivityService,
              public dialog: MatDialog,
              public database: DatabaseService,
              public router: Router,
              public moduleHandling: ModuleHandlingService,
              public clusterOnboarding: ClusterOnboardingService,
              private fb: UntypedFormBuilder,
              public translate: BluectrlTranslateService,
              @Inject(I18NEXT_SERVICE) public i18NextService: ITranslationService) {
    const location = window.location.href;

    if (location.indexOf('localhost') >= 0 ||
      location.indexOf('dev.') >= 0 ||
      location.indexOf('theme.') >= 0 ||
      location.indexOf('test.') >= 0 ||
      location.indexOf('stage.') >= 0)  {
      this.showLanguageSelector = true;
    }
    this.usermanagement.newUserLogin.subscribe(this.NewUser.bind(this));
    this.websocket.WebsocketConnected.subscribe(this.SetConnectionState.bind(this));
    this.websocket.Disconnected.subscribe(this.Disconnected.bind(this));
    this.websocket.WebsocketClosed.subscribe(this.Disconnected.bind(this));
    this.connectivity.InternetConnectionState.subscribe(this.InternetStateChanged.bind(this));
    this.moduleHandling.InventoryShowSettings.subscribe(this.InventoryModuleSelected.bind(this));
    this.moduleHandling.ProjectSavingFinished.subscribe(this.projectSaved.bind(this));

  }

  private projectSaved() {
    if (this.waitForProjectSaved === true) {
      this.waitForProjectSaved = false;
      this.router.navigate(['/live']);
    }
  }

  public InventoryModuleSelected(plan: ModulePlan) {
    this.SelectedInventoryModule = plan;
  }

  ngAfterViewInit(): void {

    this.selectedLanguage = this.languageList.find(ex => ex.code === this.i18NextService.language);

    setTimeout(() => {
      const cur = this.usermanagement.GetCurrentUser();

      if (!this.connectivity.isOnline()) {
        if(this.usermanagement.DataLoaded === false) {
          this.connectivity.inOfflineMode = true;

          // CHECK IF OFFLINE MODE IS POSSIBLE
          if (!this.connectivity.OfflineModePossible) {
          } else {
            this.ShowLogin();
          }
        }
      } else {
        if (this.usermanagement.currentToken) {

          if (this.usermanagement.currentUser) {
            this.CurrentUser = this.usermanagement.currentUser;
          }

          if (this.usermanagement.DataLoaded === false) {
            const dialogConfig2 = new MatDialogConfig();

            dialogConfig2.disableClose = true;
            dialogConfig2.autoFocus = true;
            if (this.router.url !== '/live') {
              dialogConfig2.panelClass = 'loginDialogGray';
            } else {
              dialogConfig2.panelClass = 'loginDialogGray';
            }
            dialogConfig2.data = true;
            this.dialog.open(SplashScreenComponent, dialogConfig2);
          }
        } else {
          this.ShowLogin();
        }
      }
    }, 2000);
  }

  public ShowLogin() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (this.router.url !== '/live') {
      dialogConfig.panelClass = 'loginDialogGray';
    } else {
      dialogConfig.panelClass = 'loginDialogGray';
    }

    const dialogRef = this.dialog.open(LoginDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data === true) {
        const dialogConfig2 = new MatDialogConfig();

        dialogConfig2.disableClose = true;
        dialogConfig2.autoFocus = true;
        if (this.router.url !== '/live') {
          dialogConfig2.panelClass = 'loginDialogGray';
        } else {
          dialogConfig2.panelClass = 'loginDialogGray';
        }
        dialogConfig2.data = false;
        this.dialog.open(SplashScreenComponent, dialogConfig2);
      }
      // const cur2 = this.usermanagement.GetCurrentUser();
      // if (cur2) {
      //   this.cookieHandling.SetCurrentUser(cur2);
      // }
    });
  }

  ngOnInit() {
    this.CurrentUser = this.usermanagement.GetCurrentUser();

    setInterval(() => {

      if (!this.isConnected) {
        // Reconnect
        // this.updateConnection();
      }
    }, 10000);
  }

  public InternetStateChanged(state: boolean) {
    this.HaveInternet = state;

    if (!this.HaveInternet) {
      if (!this.usermanagement.currentUser) {
        this.connectivity.inOfflineMode = true;
      }
    } else if (this.connectivity.inOfflineMode) {
      if (!this.usermanagement.currentUser) {
        this.connectivity.inOfflineMode = false;
      }
    }

  }

  private NewUser(user: User) {
    this.CurrentUser = user;
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  public onToggleOptions = () => {
    this.optionToogle.emit();
  }

  SetConnectionState() {
    this.isConnected = true;
  }

  Disconnected() {
    this.isConnected = false;
  }

  public isDemo(): boolean {
    return environment.demo;
  }


  ChangeMode() {
    if (this.moduleHandling.CurrentViewMode === ViewCode.live) {
      if (this.moduleHandling.ActiveProject) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.CHANGEMODERESET');
        const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(data => {
          if (data === true) {
            // this.moduleHandling.SetViewMode(ViewCode.design);
            this.router.navigate(['/design']);
          }
        });
      } else {
        this.router.navigate(['/design']);
      }
    } else {


      if (this.moduleHandling.ActiveProject.name === 'temp') {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data =
          this.translate.GetTranslation('MESSAGEBOX.CONTENT.SAVEBEFORELIVE');
        const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            // CONNECT TO EXISTING CLUSTER
            this.ChangeToLiveMode();
          } else {
            return;
          }
        });
      } else {
        this.ChangeToLiveMode();
      }


    }
  }

  private ChangeToLiveMode() {
    const validationInfo = this.moduleHandling.ValidateProject();

    if (validationInfo.length <= 0) {
      if (this.moduleHandling.ActiveProject.id &&
        this.moduleHandling.ActiveProject.changed === false &&
        this.moduleHandling.ActiveProject.essentialChange === false) {
        // CHECK IF CHANGED
        this.moduleHandling.saveTempProject();
        this.router.navigate(['/live']);

      } else if (this.moduleHandling.ActiveProject.essentialChange === true || this.moduleHandling.ActiveProject.changed === true) {
        if (this.moduleHandling.ActiveProject.tempSaved === true || this.moduleHandling.ActiveProject.name === 'temp') {
          this.moduleHandling.saveTempProject();
          this.router.navigate(['/live']);
        } else {
          // ASK WHATS TO DO
          const dialogRef = this.dialog.open(ProjectSaveWarningComponent,
            {panelClass: 'panelclass'});

          dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
              this.waitForProjectSaved = true;
              this.moduleHandling.saveCurrentProject(this.moduleHandling.ActiveProject.name, this.moduleHandling.ActiveProject.description);

            } else {
              this.moduleHandling.ActiveProject.name = 'temp';
              this.moduleHandling.ActiveProject.id = null;
              this.moduleHandling.ActiveProject.tempSaved = true;
              this.moduleHandling.saveTempProject(true);
              this.router.navigate(['/live']);
            }
          });
        }
      } else {
        const dialogRef = this.dialog.open(MessageBoxComponent,
          {width: 200 + 'px', panelClass: 'panelclass', data: this.translate.GetTranslation('MESSAGEBOX.CONTENT.SAVEBEFOREMODECHANGE')});
        dialogRef.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});

      }
    } else {
      // const dialogRef = this.dialog.open(MessageBoxComponent,
      //   {width: 200 + 'px', panelClass: 'panelclass', data: this.translate.GetTranslation('MESSAGEBOX.CONTENT.NOTVALIDFORLIVE')});
      // dialogRef.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});


      const dialogRef = this.dialog.open(RecipeValidationErrorDialogComponent,
        {panelClass: 'panelclass', data: validationInfo});

    }
  }

  public EditProjectName() {
    if (this.moduleHandling.ActiveProject && this.moduleHandling.CurrentViewMode === ViewCode.design && this.EditActive === false) {
      if (this.moduleHandling.ActiveProject.name !== 'temp') {

        this.name = this.moduleHandling.ActiveProject.name;
        // this.form.value.ProcessName = this.moduleHandling.ActiveProject.name;
        this.EditActive = true;
        this.form = this.fb.group({
          ProcessName: [this.name, Validators.required],
        });
        setTimeout(() => {
          this.inputEl.nativeElement.focus();
          this.inputEl.nativeElement.select();
        });
      }
    } else if (this.moduleHandling.ActiveProject && this.moduleHandling.CurrentViewMode === ViewCode.design) {
      this.SaveChangedName();
      // SAVE PROJECT
      // this.moduleHandling.saveTempProject()
    }
  }

  public SaveChangedName() {
    const name = this.form.value.ProcessName;
    if (name.length > 3) {
      if (this.moduleHandling.ActiveProject.name !== name) {
        this.moduleHandling.ActiveProject.name = name;

        if (name.indexOf('temp') < 0) {
          this.moduleHandling.ActiveProject.tempSaved = false;
          this.moduleHandling.saveTempProject(true);
          // this.moduleHandling.saveCurrentProject(name, this.moduleHandling.ActiveProject.description);
        } else {
          this.moduleHandling.saveTempProject(true);
        }
      }
    }
    this.EditActive = false;
  }

  public ChangeLanguage($event) {
    console.log($event);
    const key = $event.value;
     if (key !== this.i18NextService.language) {
       const langs = this.languageList.find(ex => ex.code === key);
       if (langs) {
         this.selectedLanguage = langs;
         this.i18NextService.changeLanguage(key).then(x => {

           document.location.reload();
           // this.i18NextService.reloadResources().then(res => {
           //   console.log(res);
           //   this.i18NextService.init(this.i18nextOptions).then(resx => {
           //     console.log(resx);
           //     this.router.navigate([this.router.url]);
           //
           //     this.languageChangeActive = true;
           //     setTimeout(() => {
           //       this.languageChangeActive = false;
           //     }, 1);
           //
           //   });
           // });
         });
       }
     }
  }

}
