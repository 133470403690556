import {AfterViewInit, Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ModulePlan} from '../../../classes/module-plan';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {MessageHandlingService} from '../../../services/v2/message-handling.service';
import * as uuidV4 from 'uuid';

@Component({
  selector: 'app-leveling-tab',
  templateUrl: './leveling-tab.component.html',
  styleUrls: ['./leveling-tab.component.css']
})
export class LevelingTabComponent implements OnInit, OnDestroy, AfterViewInit {

  private messageId = uuidV4.v4();
  private levelingSubscribed = false;


  @Input() SelectedModule: ModulePlan;
  @Input() set ShowLevelingInput(value: boolean) {
    if (this._showLeveling !== value) {
      this._showLeveling = value;
      if (this._showLeveling === true) {
        // SUBSCRIBE
        if (this.SelectedModule) {
          if (this.SelectedModule.modul) {
            if (this.SelectedModule.modul.NetworkInfo) {

             this.messaging.SubscribeLeveling(this.messageId, this.SelectedModule.customerModule.SerialNumber);
             this.levelingSubscribed = true;
            }
          }
        }
      } else {
        // UNSUBSRCIBE
        this.levelingSubscribed = false;
        if (this.SelectedModule) {
          if (this.SelectedModule.modul) {
            if (this.SelectedModule.modul.NetworkInfo) {
              if (this.messageId) {

                this.messaging.UnsubscribeLeveling(this.messageId, this.SelectedModule.customerModule.SerialNumber);
              }
            }
          }
        }
      }
    }
  }

  public levelling: number[];
  private _showLeveling = false;

  constructor(public moduleHandling: ModuleHandlingService,
              private messaging: MessageHandlingService) {
    this.messaging.LevelingInformationReceived.subscribe(this.HandleMessage.bind(this));

    if (this.moduleHandling.DemoView) {
      setInterval(() => {
        const val1 = 0;
        const val2 = Math.floor(Math.random() * 1000) / 10.0;
        const val3 = Math.floor(Math.random() * 1000) / 10.0;
        const val4 = Math.floor(Math.random() * 1000) / 10.0;

        this.levelling = [
          val1,
          val2,
          val3,
          val4
        ];

      }, 2000);
    }

  }

  ngOnDestroy() {
    this.levelingSubscribed = false;
    if (this.SelectedModule) {
      if (this.SelectedModule.modul) {
        if (this.SelectedModule.modul.NetworkInfo) {
          if (this.messageId) {
            this.messaging.UnsubscribeLeveling(this.messageId, this.SelectedModule.customerModule.SerialNumber);
          }
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this._showLeveling === true && !this.levelingSubscribed) {
      // SUBSCRIBE
      if (this.SelectedModule) {
        if (this.SelectedModule.modul) {
          if (this.SelectedModule.modul.NetworkInfo) {

            this.messaging.SubscribeLeveling(this.messageId, this.SelectedModule.customerModule.SerialNumber);
            this.levelingSubscribed = true;
          }
        }
      }
    }
  }

  ngOnInit(): void {
  }

  public HandleMessage(data: any) {
    if (data.levelling) {
      this.levelling = data.levelling;
    }
  }

  ShowLeveling() {
    if (this.moduleHandling.DemoView) {
      return true;
    } else {
      if (this.SelectedModule) {
        if (this.SelectedModule.modul) {
          if (this.SelectedModule.modul.NetworkInfo) {
            return true;
          }
        }
      }
    }

    return false;
  }

}
