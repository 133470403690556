import {ModuleDesignComponent} from '../components/elements/module-design/module-design.component';
import {ModuleRotations} from './enums/module-rotations.enum';
import {ModuleConnections} from './enums/module-connections.enum';
import {ConveyorLenghtType} from './enums/conveyor-lenght-type.enum';
import {EndPointTypes} from './enums/end-point-types.enum';

export class HelperFunctions {

  public static CreateHitBox(top: number,
                             left: number,
                             module: ModuleDesignComponent,
                             moduleWidht: number,
                             moduleHeightOrg: number,
                             collisionOffset: number,
                             sinkSpace: number
  ): string {

    let path = '';
    let total = '<path class="hitbox" stroke-width="3" stroke="transparent" fill="red" fill-opacity="0.0" d=""/>';
    if (module) {
      const moduleLenght = 303 * (moduleWidht / 303);
      const moduleHeight = 122 * ( moduleHeightOrg / 122);


      if (module.modulePlan.rotation === ModuleRotations.degree_0) {


        path = this.CreateHitBox_Zero(top, left, module, moduleWidht, moduleHeightOrg, collisionOffset, sinkSpace);
        total = '<path class="hitbox" stroke-width="3" stroke="transparent" fill="red" fill-opacity="0.0" d="' + path + '"/>';

      } else if (module.modulePlan.rotation === ModuleRotations.degree_180) {
        path = this.CreateHitBox_180(top, left, module, moduleWidht, moduleHeightOrg, collisionOffset, sinkSpace);
        total = '<path class="hitbox" stroke-width="3" stroke="transparent" fill="red" fill-opacity="0.0" d="' + path + '"/>';

      } else if (module.modulePlan.rotation === ModuleRotations.degree_90) {

        //endregion
        path = this.CreateHitBox_90(top, left, module, moduleWidht, moduleHeightOrg, collisionOffset, sinkSpace);
        total = '<path class="hitbox" stroke-width="3" transform="rotate(90, ' +
          ((moduleLenght / 2) + left) + ', ' +
          ((moduleHeight / 2) + top) + ')" stroke="transparent" fill="red" fill-opacity="0.0" d="' + path + '"/>';

      } else if (module.modulePlan.rotation === ModuleRotations.degree_270) {


        path = this.CreateHitBox_270(top, left, module, moduleWidht, moduleHeightOrg, collisionOffset, sinkSpace);

        total = '<path class="hitbox" stroke-width="3" transform="rotate(90, ' +
          ((moduleLenght / 2) + left) +
          ', ' +
          ((moduleHeight / 2) +
            top) +
          ')" stroke="transparent" fill="red" fill-opacity="0.0" d="' + path + '"/>';
      }
    }
    return total;
  }

  public static CreateHitBox_270(top: number,
                                left: number,
                                module: ModuleDesignComponent,
                                moduleWidht: number,
                                moduleHeightOrg: number,
                                collisionOffset: number,
                                sinkSpace: number
  ): string {

    let path = '';

    const moduleLenght = 303 * (moduleWidht / 303);
    const moduleHeight = 122 * (moduleHeightOrg / 122);
    const scaleFactor = 6058 / moduleLenght;
    const Conveyor1 = (4319) / scaleFactor;  // ((4.319 * 50) / (303 / module.rawWidth) - ((45 / 2) / (303 / module.rawWidth)));
    const Conveyor2 = (5319) / scaleFactor; // ((5.319 * 50) / (303 / module.rawWidth) - ((45 / 2) / (303 / module.rawWidth)));
    const Conveyor3 = (2220) / scaleFactor; // ((2.220 * 50) / (303 / module.rawWidth) - ((45 / 2) / (303 / module.rawWidth)));
    const conveyorHeight = 4505 / scaleFactor;

    path = 'M' + (left + collisionOffset + moduleLenght) + ',' + ( top - collisionOffset);
    let lefts = left + collisionOffset + moduleLenght;
    let tops = top - collisionOffset;
    path += ' ' + lefts + ',' + tops;
    tops = tops + ( moduleHeightOrg + (collisionOffset * 2));
    path += ' ' + lefts + ',' + tops;
    let horopen = module.rawWidth + (collisionOffset * 2);
    let verifconv = 0;
    let horoffsetForConveyor = 0;
    let horforCon3 = 0;

    // Module Bottom

    //region Left
    // CONNECTION POINT 3
    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.left_3) &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_3) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }


        horopen = Conveyor3 - (conv.getConveryorWidth(scaleFactor) / 2) + (50 / scaleFactor);
        verifconv = convHeight;
        horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
        horforCon3 = horopen + horoffsetForConveyor;
      }
    }

    let conf: any;
    if (module.modulePlan.connections.find(ex =>
      (ex.moduleConnection === ModuleConnections.left_3) &&
      ex.conveyor !== null &&
      ex.conveyor !== undefined)) {
      conf = module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.left_3) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined).conveyor;

      if (conf) {
        if (conf.EndPointType === EndPointTypes.module) {
          verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conf.EndPointType === EndPointTypes.sink) {
          verifconv += (sinkSpace * 0.6);
        }
      }
    }

    if (horforCon3 > 0) {
      lefts -= horopen;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts -= horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;

      horoffsetForConveyor = 0;
      horopen = 0;
      verifconv = 0;
    }

    let horLastAddOn = 0;

    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.left_2 ||
        ex.moduleConnection === ModuleConnections.left_1) &&
      ex.conveyor).length > 1) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_1) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
        if (conv.EndPointType === EndPointTypes.module) {
          convHeight -= ((2 * collisionOffset) + (600) / scaleFactor);
        } else if (conv.EndPointType === EndPointTypes.sink) {
          convHeight += (sinkSpace * 0.6);
        }


        horopen = Conveyor1 - (conv.getConveryorWidth(scaleFactor) / 2) + (50 / scaleFactor) - horforCon3;
        verifconv = convHeight;
        horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
      }

      lefts -= horopen;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts -= horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;

      horLastAddOn = - (horoffsetForConveyor + horopen);

      const conv2 = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_2) &&
        ex.conveyor)[0].conveyor;

      convHeight = conveyorHeight;



      if (conv2) {
        if (conv2.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen =  ((Conveyor2 - Conveyor1) - ( conv2.getConveryorWidth(scaleFactor) ) - (50 / scaleFactor) + horforCon3);
      verifconv = convHeight;
      horoffsetForConveyor = conv2.getConveryorWidth(scaleFactor) + (collisionOffset * 2);


    } else if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.left_2 ||
        ex.moduleConnection === ModuleConnections.left) &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_2 ||
          ex.moduleConnection === ModuleConnections.left) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor2 - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor) - horforCon3;
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    } else if (module.modulePlan.connections.filter(ex =>
      ex.moduleConnection === ModuleConnections.left_1 &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_1) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor1 - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor) - horforCon3;
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    }

    if (module.modulePlan.connections.find(ex =>
      (ex.moduleConnection === ModuleConnections.left_2 ||
        ex.moduleConnection === ModuleConnections.left ||
        ex.moduleConnection === ModuleConnections.left_1) &&
      ex.conveyor !== null &&
      ex.conveyor !== undefined)) {
      conf = module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.left_2 ||
          ex.moduleConnection === ModuleConnections.left ||
          ex.moduleConnection === ModuleConnections.left_1) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined).conveyor;

      if (conf) {
        if (conf.EndPointType === EndPointTypes.module) {
          verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conf.EndPointType === EndPointTypes.sink) {
          verifconv += (sinkSpace * 0.6);
        }
      }
    }

    let horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor - horforCon3 + horLastAddOn;

    lefts -= horopen;
    path += ' ' + lefts + ',' + tops;
    tops += verifconv;
    path += ' ' + lefts + ',' + tops;
    lefts -= horoffsetForConveyor;
    path += ' ' + lefts + ',' + tops;
    tops -= verifconv;
    path += ' ' + lefts + ',' + tops;
    lefts -= horlast;
    path += ' ' + lefts + ',' + tops;
    // path += ' h ' + horlast;
    //endregion

    let horconv = 0;
    let vertopen = module.rawHeight + (collisionOffset * 2);
    let vertOffsetForConveyor = 0;
    let vertlast = 0;

    // MODULE FRONT

    //region BOTTOM
    if (module.modulePlan.connections.filter(ex =>
      ex.moduleConnection === ModuleConnections.center &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.center) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horconv = convHeight;
      vertopen = ( moduleHeightOrg - conv.getConveryorWidth(scaleFactor)) / 2 + (50 / scaleFactor);
      vertOffsetForConveyor = conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
      vertlast = module.rawHeight + (collisionOffset * 2) - vertopen - vertOffsetForConveyor;
      // Check if next module is connected
      if (module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.center) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined)) {
        conf = module.modulePlan.connections.find(ex =>
          (ex.moduleConnection === ModuleConnections.center) &&
          ex.conveyor !== null &&
          ex.conveyor !== undefined).conveyor;

        if (conf) {
          if (conf.EndPointType === EndPointTypes.module) {
            horconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
          } else if (conf.EndPointType === EndPointTypes.sink) {
            horconv += (sinkSpace * 0.6);
          }
        }
      }
    }

    tops -= vertopen;
    path += ' ' + lefts + ',' + tops;
    lefts -= horconv;
    path += ' ' + lefts + ',' + tops;
    tops -= vertOffsetForConveyor;
    path += ' ' + lefts + ',' + tops;
    lefts += horconv;
    path += ' ' + lefts + ',' + tops;
    tops -= vertlast;
    path += ' ' + lefts + ',' + tops;
    //endregion

    horopen = module.rawWidth + (collisionOffset * 2);
    verifconv = 0;
    horoffsetForConveyor = 0;

    // MODULE TOP

    horLastAddOn = 0;

    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.right_2 ||
        ex.moduleConnection === ModuleConnections.right_1) &&
      ex.conveyor).length > 1) {

      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_2) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
        if (conv.EndPointType === EndPointTypes.module) {
          convHeight -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conv.EndPointType === EndPointTypes.sink) {
          convHeight += (sinkSpace * 0.60);
        }
      }


      horopen = Conveyor2 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
      horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor;

      lefts += horlast;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts += horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops;

      horLastAddOn = -(moduleWidht - conv.getConveryorWidth(scaleFactor) - (Conveyor2 - Conveyor1));

      const conv2 = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_1) &&
        ex.conveyor)[0].conveyor;

       convHeight = conveyorHeight;

      if (conv2) {
        if (conv2.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = (Conveyor2 - Conveyor1) - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor = conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);


    } else if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.right_2 ||
        ex.moduleConnection === ModuleConnections.right) &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_2 ||
          ex.moduleConnection === ModuleConnections.right) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor2 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    } else if (module.modulePlan.connections.filter(ex =>
      ex.moduleConnection === ModuleConnections.right_1 &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_1) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor1 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    }

    if (module.modulePlan.connections.find(ex =>
      (ex.moduleConnection === ModuleConnections.right_1 ||
        ex.moduleConnection === ModuleConnections.right ||
        ex.moduleConnection === ModuleConnections.right_2) &&
      ex.conveyor !== null &&
      ex.conveyor !== undefined)) {
      conf = module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.right_1 ||
          ex.moduleConnection === ModuleConnections.right ||
          ex.moduleConnection === ModuleConnections.right_2) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined).conveyor;

      if (conf) {
        if (conf.EndPointType === EndPointTypes.module) {
          verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conf.EndPointType === EndPointTypes.sink) {
          verifconv += (sinkSpace * 0.60);
        }
      }
    }

    horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor + horLastAddOn;
    const horTopLastConf = horlast + horoffsetForConveyor;

    lefts += horlast;
    path += ' ' + lefts + ',' + tops;
    tops -= verifconv;
    path += ' ' + lefts + ',' + tops;
    lefts += horoffsetForConveyor;
    path += ' ' + lefts + ',' + tops;
    tops += verifconv;
    path += ' ' + lefts + ',' + tops;

    horlast = 0;
    verifconv = 0;
    horoffsetForConveyor = 0;
    horforCon3 = 0;

    // CONNECTION POINT 3

    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.right_3) &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_3) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor3 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
      horforCon3 = horopen + horoffsetForConveyor;
    }

    if (horforCon3 > 0) {
      if (module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.right_3) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined)) {
        conf = module.modulePlan.connections.find(ex =>
          (ex.moduleConnection === ModuleConnections.right_3) &&
          ex.conveyor !== null &&
          ex.conveyor !== undefined).conveyor;

        if (conf) {
          if (conf.EndPointType === EndPointTypes.module) {
            verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
          } else if (conf.EndPointType === EndPointTypes.sink) {
            verifconv += (sinkSpace * 0.60);
          }
        }
      }

      horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor - horTopLastConf;

      lefts += horlast;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts += horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops + 'z';
    } else {
      path += 'z';
    }

    return path;
  }

  public static CreateHitBox_90(top: number,
                                 left: number,
                                 module: ModuleDesignComponent,
                                 moduleWidht: number,
                                 moduleHeightOrg: number,
                                 collisionOffset: number,
                                 sinkSpace: number
  ): string {

    let path = '';

    const moduleLenght = 303 * (moduleWidht / 303);
    const moduleHeight = 122 * (moduleHeightOrg / 122);
    const scaleFactor = 6058 / moduleLenght;
    const Conveyor1 = (4319) / scaleFactor;  // ((4.319 * 50) / (303 / module.rawWidth) - ((45 / 2) / (303 / module.rawWidth)));
    const Conveyor2 = (5319) / scaleFactor; // ((5.319 * 50) / (303 / module.rawWidth) - ((45 / 2) / (303 / module.rawWidth)));
    const Conveyor3 = (2220) / scaleFactor; // ((2.220 * 50) / (303 / module.rawWidth) - ((45 / 2) / (303 / module.rawWidth)));
    const conveyorHeight = 4505 / scaleFactor;


    path = 'M' + (left - collisionOffset ) + ',' + ( top - collisionOffset);
    let lefts = left - collisionOffset;
    let tops = top - collisionOffset;
    path += ' ' + lefts + ',' + tops;
    tops = tops + ( moduleHeightOrg + (collisionOffset * 2));
    path += ' ' + lefts + ',' + tops;

    let horopen = module.rawWidth + (collisionOffset * 2);
    let verifconv = 0;
    let horoffsetForConveyor = 0;

    let horforCon3 = 0;

    // CONNECTION POINT 3

    //region Left



    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.right_3) &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_3) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor3 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
      horforCon3 = horopen + horoffsetForConveyor;
    }

    let conf: any;
    if (module.modulePlan.connections.find(ex =>
      (ex.moduleConnection === ModuleConnections.right_3) &&
      ex.conveyor !== null &&
      ex.conveyor !== undefined)) {
      conf = module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.right_3) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined).conveyor;

      if (conf) {
        if (conf.EndPointType === EndPointTypes.module) {
          verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conf.EndPointType === EndPointTypes.sink) {
          verifconv += (sinkSpace * 0.6);
        }
      }
    }

    if (horforCon3 > 0) {
      lefts += horopen;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts += horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;

      horopen = 0;
      horoffsetForConveyor = 0;
      verifconv = 0;
    }

    // Module Bottom
    let horLastAddOn = 0;

    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.right_2 ||
        ex.moduleConnection === ModuleConnections.right_1) &&
      ex.conveyor).length > 1) {

      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_1) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
        if (conv.EndPointType === EndPointTypes.module) {
          convHeight -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conv.EndPointType === EndPointTypes.sink) {
          convHeight += (sinkSpace * 0.6);
        }
      }

      horopen = Conveyor1 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor) - horforCon3;
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);

      lefts += horopen;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts += horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;

      horLastAddOn = - (horopen + horoffsetForConveyor);

      const conv2 = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_2) &&
        ex.conveyor)[0].conveyor;

      convHeight = conveyorHeight;

      if (conv2) {
        if (conv2.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = ((Conveyor2 - Conveyor1) - ((conv2.getConveryorWidth(scaleFactor))) - (50 / scaleFactor));
      verifconv = convHeight;
      horoffsetForConveyor = conv2.getConveryorWidth(scaleFactor) + (collisionOffset * 2);


    } else if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.right_2 ||
        ex.moduleConnection === ModuleConnections.right) &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_2 ||
          ex.moduleConnection === ModuleConnections.right) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor2 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor) - horforCon3;
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    } else if (module.modulePlan.connections.filter(ex =>
      ex.moduleConnection === ModuleConnections.right_1 && ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_1) && ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor1 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor) - horforCon3;
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    }
    if (module.modulePlan.connections.find(ex =>
      (ex.moduleConnection === ModuleConnections.right_2 ||
        ex.moduleConnection === ModuleConnections.right ||
        ex.moduleConnection === ModuleConnections.right_1) &&
      ex.conveyor !== null &&
      ex.conveyor !== undefined)) {
      conf = module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.right_2 ||
          ex.moduleConnection === ModuleConnections.right ||
          ex.moduleConnection === ModuleConnections.right_1) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined).conveyor;

      if (conf) {
        if (conf.EndPointType === EndPointTypes.module) {
          verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conf.EndPointType === EndPointTypes.sink) {
          verifconv += (sinkSpace * 0.6);
        }
      }
    }

    let horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor - horforCon3 + horLastAddOn;

    lefts += horopen;
    path += ' ' + lefts + ',' + tops;
    tops += verifconv;
    path += ' ' + lefts + ',' + tops;
    lefts += horoffsetForConveyor;
    path += ' ' + lefts + ',' + tops;
    tops -= verifconv;
    path += ' ' + lefts + ',' + tops;
    lefts += horlast;
    path += ' ' + lefts + ',' + tops;
    //endregion

    let horconv = 0;
    let vertopen = module.rawHeight + (collisionOffset * 2);
    let vertOffsetForConveyor = 0;
    let vertlast = 0;

    // MODULE FRONT
    //region TOP
    if (module.modulePlan.connections.filter(ex =>
      ex.moduleConnection === ModuleConnections.center &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.center) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horconv = convHeight;
      vertopen = ( moduleHeightOrg - conv.getConveryorWidth(scaleFactor)) / 2 - (50 / scaleFactor);
      vertOffsetForConveyor = conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
      vertlast = module.rawHeight + (collisionOffset * 2) - vertopen - vertOffsetForConveyor;
      // Check if next module is connected
      if (module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.center) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined)) {
        conf = module.modulePlan.connections.find(ex =>
          (ex.moduleConnection === ModuleConnections.center) &&
          ex.conveyor !== null &&
          ex.conveyor !== undefined).conveyor;

        if (conf) {
          if (conf.EndPointType === EndPointTypes.module) {
            horconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
          } else if (conf.EndPointType === EndPointTypes.sink) {
            horconv += (sinkSpace * 0.6);
          }
        }
      }

    }

    tops -= vertopen;
    path += ' ' + lefts + ',' + tops;
    lefts += horconv;
    path += ' ' + lefts + ',' + tops;
    tops -= vertOffsetForConveyor;
    path += ' ' + lefts + ',' + tops;
    lefts -= horconv;
    path += ' ' + lefts + ',' + tops;
    tops -= vertlast;
    path += ' ' + lefts + ',' + tops;
    //endregion

    horopen = module.rawWidth + (collisionOffset * 2);
    verifconv = 0;
    horoffsetForConveyor = 0;

    // MODULE TOP

    //region Left
    horLastAddOn = 0;


    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.left_2 ||
        ex.moduleConnection === ModuleConnections.left_1) &&
      ex.conveyor).length > 1) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_2) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
        if (conv.EndPointType === EndPointTypes.module) {
          convHeight -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conv.EndPointType === EndPointTypes.sink) {
          convHeight += (sinkSpace * 0.6);
        }
      }
      horopen = Conveyor2 - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);

      horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor;

      lefts -= horlast;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts -= horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops;

      horLastAddOn = - (moduleWidht - (horlast + (Conveyor2 - Conveyor1) + ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor) ));

      const conv2 = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_1) &&
        ex.conveyor)[0].conveyor;

      convHeight = conveyorHeight;



      if (conv2) {
        if (conv2.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = (Conveyor2 - Conveyor1) - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor = conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);


    } else if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.left_2 ||
        ex.moduleConnection === ModuleConnections.left) &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_2 ||
          ex.moduleConnection === ModuleConnections.left) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }
      horopen = Conveyor2 - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    } else if (module.modulePlan.connections.filter(ex =>
      ex.moduleConnection === ModuleConnections.left_1 &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_1) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor1 - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    }

    if (module.modulePlan.connections.find(ex =>
      (ex.moduleConnection === ModuleConnections.left_1 ||
        ex.moduleConnection === ModuleConnections.left ||
        ex.moduleConnection === ModuleConnections.left_2) &&
      ex.conveyor !== null &&
      ex.conveyor !== undefined)) {
      conf = module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.left_1 ||
          ex.moduleConnection === ModuleConnections.left ||
          ex.moduleConnection === ModuleConnections.left_2) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined).conveyor;

      if (conf) {
        if (conf.EndPointType === EndPointTypes.module) {
          verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conf.EndPointType === EndPointTypes.sink) {
          verifconv += (sinkSpace * 0.6);
        }
      }
    }

    horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor + horLastAddOn;
    const horTopLastConf = horlast + horoffsetForConveyor;


    lefts -= horlast;
    path += ' ' + lefts + ',' + tops;
    tops -= verifconv;
    path += ' ' + lefts + ',' + tops;
    lefts -= horoffsetForConveyor;
    path += ' ' + lefts + ',' + tops;
    tops += verifconv;
    path += ' ' + lefts + ',' + tops;
    verifconv = 0;
    horforCon3 = 0;
    horlast = 0;
    horoffsetForConveyor = 0;


    // CONNECTION POINT 3

    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.left_3) &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_3) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }
      horopen = Conveyor3 - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
      horforCon3 = horopen + horoffsetForConveyor;
    }

    if (horforCon3 > 0) {
      if (module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.left_3) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined)) {
        conf = module.modulePlan.connections.find(ex =>
          (ex.moduleConnection === ModuleConnections.left_3) &&
          ex.conveyor !== null &&
          ex.conveyor !== undefined).conveyor;

        if (conf) {
          if (conf.EndPointType === EndPointTypes.module) {
            verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
          } else if (conf.EndPointType === EndPointTypes.sink) {
            verifconv += (sinkSpace * 0.6);
          }
        }
      }

      horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor - horTopLastConf;


      lefts -= horlast;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts -= horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops + 'z';
    } else {
      path += 'z';
    }

    return path;
  }


  public static CreateHitBox_180(top: number,
                                  left: number,
                                  module: ModuleDesignComponent,
                                  moduleWidht: number,
                                  moduleHeightOrg: number,
                                  collisionOffset: number,
                                  sinkSpace: number
  ): string {

    let path = '';

    const moduleLenght = 303 * (moduleWidht / 303);
    const moduleHeight = 122 * (moduleHeightOrg / 122);
    const scaleFactor = 6058 / moduleLenght;
    const Conveyor1 = (4319) / scaleFactor;  // ((4.319 * 50) / (303 / module.rawWidth) - ((45 / 2) / (303 / module.rawWidth)));
    const Conveyor2 = (5319) / scaleFactor; // ((5.319 * 50) / (303 / module.rawWidth) - ((45 / 2) / (303 / module.rawWidth)));
    const Conveyor3 = (2220) / scaleFactor; // ((2.220 * 50) / (303 / module.rawWidth) - ((45 / 2) / (303 / module.rawWidth)));
    const conveyorHeight = 4505 / scaleFactor;


    path = 'M' + (left + collisionOffset + moduleLenght) + ',' + ( top - collisionOffset);
    let lefts = left + collisionOffset + moduleLenght;
    let tops = top - collisionOffset;
    path += ' ' + lefts + ',' + tops;
    tops = tops + ( moduleHeightOrg + (collisionOffset * 2));
    path += ' ' + lefts + ',' + tops;
    let horopen = module.rawWidth + (collisionOffset * 2);
    let verifconv = 0;
    let horoffsetForConveyor = 0;

    let horforCon3 = 0;

    // CONNECTION BOTTOM 3
    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.left_3) &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_3) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor3 - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
      horforCon3 = horopen + horoffsetForConveyor;

    }

    let conf: any;
    if (module.modulePlan.connections.find(ex =>
      (ex.moduleConnection === ModuleConnections.left_3) &&
      ex.conveyor !== null &&
      ex.conveyor !== undefined)) {
      conf = module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.left_3) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined).conveyor;

      if (conf) {
        if (conf.EndPointType === EndPointTypes.module) {
          verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conf.EndPointType === EndPointTypes.sink) {
          verifconv += (sinkSpace * 0.6);
        }
      }
    }

    if (horforCon3 > 0) {
      lefts -= horopen;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts -= horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;


      horopen = 0;
      horoffsetForConveyor = 0;
      verifconv = 0;
    }

    let horLastAddOn = 0;

    // Module Bottom
    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.left_2 ||
        ex.moduleConnection === ModuleConnections.left_1)
      && ex.conveyor).length > 1) {

      const conv = module.modulePlan.connections.filter(ex =>
        ( ex.moduleConnection === ModuleConnections.left_1) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
        if (conv.EndPointType === EndPointTypes.module) {
          convHeight -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conv.EndPointType === EndPointTypes.sink) {
          convHeight += (sinkSpace * 0.6);
        }
      }

      horopen = Conveyor1 - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);

      lefts -= horopen;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts -= horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;



      horLastAddOn =  -(Conveyor1 + (conv.getConveryorWidth(scaleFactor) / 2) + (50 / scaleFactor) + (collisionOffset * 4));

      const conv2 = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_2) && ex.conveyor)[0].conveyor;

      convHeight = conveyorHeight;

      if (conv2) {
        if (conv2.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = (Conveyor2 - Conveyor1) - (conv2.getConveryorWidth(scaleFactor) + collisionOffset * 4) + (50 / scaleFactor) - horforCon3;
      verifconv = convHeight;
      horoffsetForConveyor = conv2.getConveryorWidth(scaleFactor) + (collisionOffset * 2);


    } else if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.left_2 ||
        ex.moduleConnection === ModuleConnections.left) &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_2 ||
          ex.moduleConnection === ModuleConnections.left) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor2 - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor) - horforCon3;
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);

    } else if (module.modulePlan.connections.filter(ex =>
      ex.moduleConnection === ModuleConnections.left_1 && ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_1) && ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor1 - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor) - horforCon3;
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    }

    if (module.modulePlan.connections.find(ex =>
      (ex.moduleConnection === ModuleConnections.left_2 ||
        ex.moduleConnection === ModuleConnections.left ||
        ex.moduleConnection === ModuleConnections.left_1) &&
      ex.conveyor !== null &&
      ex.conveyor !== undefined)) {
      conf = module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.left_2 ||
          ex.moduleConnection === ModuleConnections.left ||
          ex.moduleConnection === ModuleConnections.left_1) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined).conveyor;

      if (conf) {
        if (conf.EndPointType === EndPointTypes.module) {
          verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conf.EndPointType === EndPointTypes.sink) {
          verifconv += (sinkSpace * 0.6);
        }
      }
    }

    let horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor - horforCon3 + horLastAddOn;
    let horTopLastConf = horlast + horoffsetForConveyor;

    lefts -= horopen;
    path += ' ' + lefts + ',' + tops;
    tops += verifconv;
    path += ' ' + lefts + ',' + tops;
    lefts -= horoffsetForConveyor;
    path += ' ' + lefts + ',' + tops;
    tops -= verifconv;
    path += ' ' + lefts + ',' + tops;
    lefts -= horlast;
    path += ' ' + lefts + ',' + tops;

    let horconv = 0;
    let vertopen = module.rawHeight + (collisionOffset * 2);
    let vertOffsetForConveyor = 0;
    let vertlast = 0;

    // MODULE FRONT
    if (module.modulePlan.connections.filter(ex => ex.moduleConnection === ModuleConnections.center && ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.center) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }


      horconv = convHeight;
      vertopen = ( moduleHeightOrg - conv.getConveryorWidth(scaleFactor)) / 2 + (50 / scaleFactor);
      vertOffsetForConveyor = conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
      vertlast = module.rawHeight + (collisionOffset * 2) - vertopen - vertOffsetForConveyor;
      // Check if next module is connected
      if (module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.center) &&
        ex.conveyor !== null && ex.conveyor !== undefined)) {
        conf = module.modulePlan.connections.find(ex =>
          (ex.moduleConnection === ModuleConnections.center) &&
          ex.conveyor !== null && ex.conveyor !== undefined).conveyor;

        if (conf) {
          if (conf.EndPointType === EndPointTypes.module) {
            horconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
          } else if (conf.EndPointType === EndPointTypes.sink) {
            horconv += (sinkSpace * 0.6);
          }
        }
      }
    }

    tops -= vertopen;
    path += ' ' + lefts + ',' + tops;
    lefts -= horconv;
    path += ' ' + lefts + ',' + tops;
    tops -= vertOffsetForConveyor;
    path += ' ' + lefts + ',' + tops;
    lefts += horconv;
    path += ' ' + lefts + ',' + tops;
    tops -= vertlast;
    path += ' ' + lefts + ',' + tops;

    horopen = module.rawWidth + (collisionOffset * 2);
    verifconv = 0;
    horoffsetForConveyor = 0;

    horLastAddOn = 0;



    // MODULE TOP
    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.right_2 ||
        ex.moduleConnection === ModuleConnections.right_1) &&
      ex.conveyor).length > 1) {

      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_2) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
        if (conv.EndPointType === EndPointTypes.module) {
          convHeight -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conv.EndPointType === EndPointTypes.sink) {
          convHeight += (sinkSpace * 0.6);
        }
      }


      horopen = Conveyor2 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
      horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor;

      lefts += horlast;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts += horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops;

      horLastAddOn = - ((Conveyor2 - Conveyor1) - (horoffsetForConveyor / 2) - (collisionOffset * 2) - horlast - (50 / scaleFactor));

      const conv2 = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_1) &&
        ex.conveyor)[0].conveyor;

      convHeight = conveyorHeight;

      if (conv2) {
        if (conv2.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor2 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor = conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    } else if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.right_2 ||
        ex.moduleConnection === ModuleConnections.right) &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_2 ||
          ex.moduleConnection === ModuleConnections.right) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor2 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    } else if (module.modulePlan.connections.filter(ex =>
      ex.moduleConnection === ModuleConnections.right_1 &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_1) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor1 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    }

    if (module.modulePlan.connections.find(ex =>
      (ex.moduleConnection === ModuleConnections.right_1 ||
        ex.moduleConnection === ModuleConnections.right ||
        ex.moduleConnection === ModuleConnections.right_2) &&
      ex.conveyor !== null &&
      ex.conveyor !== undefined)) {
      conf = module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.right_1 ||
          ex.moduleConnection === ModuleConnections.right ||
          ex.moduleConnection === ModuleConnections.right_2) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined).conveyor;

      if (conf) {
        if (conf.EndPointType === EndPointTypes.module) {
          verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conf.EndPointType === EndPointTypes.sink) {
          verifconv += (sinkSpace * 0.6);
        }
      }
    }

    horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor + horLastAddOn;
    horTopLastConf = horlast + horoffsetForConveyor;

    lefts += horlast;
    path += ' ' + lefts + ',' + tops;
    tops -= verifconv;
    path += ' ' + lefts + ',' + tops;
    lefts += horoffsetForConveyor;
    path += ' ' + lefts + ',' + tops;
    tops += verifconv;
    path += ' ' + lefts + ',' + tops;

    horforCon3 = 0;
    horlast = 0;
    verifconv = 0;
    horoffsetForConveyor = 0;

    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.right_3) &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_3) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor3 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
      horforCon3 = horopen + horoffsetForConveyor;
    }

    if (horforCon3 > 0) {
      if (module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.right_3) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined)) {
        conf = module.modulePlan.connections.find(ex =>
          (ex.moduleConnection === ModuleConnections.right_3) &&
          ex.conveyor !== null &&
          ex.conveyor !== undefined).conveyor;

        if (conf) {
          if (conf.EndPointType === EndPointTypes.module) {
            verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
          } else if (conf.EndPointType === EndPointTypes.sink) {
            verifconv += (sinkSpace * 0.6);
          }
        }
      }

      horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor - horTopLastConf;


      lefts += horlast;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts += horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops + 'z';
    } else {
      path += 'z';
    }


    return path;
  }

  public static CreateHitBox_Zero(top: number,
                                  left: number,
                                  module: ModuleDesignComponent,
                                  moduleWidht: number,
                                  moduleHeightOrg: number,
                                  collisionOffset: number,
                                  sinkSpace: number
  ): string {

    let path = '';

    const moduleLenght = 303 * (moduleWidht / 303);
    const moduleHeight = 122 * ( moduleHeightOrg / 122);
    const scaleFactor = 6058 / moduleLenght;
    const Conveyor1 =   (4319) / scaleFactor;  // ((4.319 * 50) / (303 / module.rawWidth) - ((45 / 2) / (303 / module.rawWidth)));
    const Conveyor2 = (5319) / scaleFactor; // ((5.319 * 50) / (303 / module.rawWidth) - ((45 / 2) / (303 / module.rawWidth)));
    const Conveyor3 = (2220) / scaleFactor; // ((2.220 * 50) / (303 / module.rawWidth) - ((45 / 2) / (303 / module.rawWidth)));
    const conveyorHeight =  4505 / scaleFactor;

    //region Zero Degree
    // Left Module Part
    path = 'M' + (left - collisionOffset ) + ',' + ( top - collisionOffset);
    let lefts = left - collisionOffset;
    let tops = top - collisionOffset;
    path += ' ' + lefts + ',' + tops;
    tops = tops + ( moduleHeightOrg + (collisionOffset * 2));
    path += ' ' + lefts + ',' + tops;

    let horopen = module.rawWidth + (collisionOffset * 2);
    let verifconv = 0;
    let horoffsetForConveyor = 0;
    let horforCon3 = 0;

    // CONNECTION POINT 3
    //region BOTTOM
    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.right_3)
      && ex.conveyor).length > 0) {

      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_3) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
        horopen = Conveyor3 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor);
        verifconv = convHeight;
        horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);

        horforCon3 = horopen + horoffsetForConveyor;
      } else {
        horopen = 0;
      }


    }

    let conf: any;
    if (module.modulePlan.connections.find(ex =>
      (ex.moduleConnection === ModuleConnections.right_3) &&
      ex.conveyor !== null && ex.conveyor !== undefined)) {
      conf = module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.right_3) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined).conveyor;

      if (conf) {
        if (conf.EndPointType === EndPointTypes.module) {
          verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conf.EndPointType === EndPointTypes.sink) {
          verifconv += (sinkSpace * 0.6);
        }
      }
    }

    if (horforCon3 > 0) {
      lefts += horopen;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts += horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;


      horopen = 0;
      horoffsetForConveyor = 0;
      verifconv = 0;
    }

    let horLastAddOn = 0;

    // Module Bottom
    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.right_2 ||
        ex.moduleConnection === ModuleConnections.right_1)
      && ex.conveyor).length > 1) {

      const conv = module.modulePlan.connections.filter(ex =>
        ( ex.moduleConnection === ModuleConnections.right_1) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;
      verifconv = convHeight;
      if (conv) {
        verifconv = convHeight;
        if (conv.LenghtType === ConveyorLenghtType.short) {
          verifconv = convHeight / 2.0;
        }
        if (conv.EndPointType === EndPointTypes.module) {
          verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conv.EndPointType === EndPointTypes.sink) {
          verifconv += (sinkSpace * 0.6);
        }
      }



      // ADD FIRST BELT ALSO
      horopen = Conveyor1 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor) - horforCon3;
      // verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
      lefts += horopen;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts += horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;

      horLastAddOn = - (horopen + horoffsetForConveyor);

      const conv2 = module.modulePlan.connections.filter(ex =>
        ( ex.moduleConnection === ModuleConnections.right_2) &&
        ex.conveyor)[0].conveyor;

      convHeight = conveyorHeight;
      verifconv = convHeight;
      if (conv2) {
        verifconv = convHeight;
        if (conv2.LenghtType === ConveyorLenghtType.short) {
          verifconv = convHeight / 2.0;
        }

      }

      horopen = ((Conveyor2 - Conveyor1) - ((conv.getConveryorWidth(scaleFactor))) - (50 / scaleFactor));
      //
      horoffsetForConveyor = (((conv.getConveryorWidth(scaleFactor)) + (collisionOffset * 2)));
    } else if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.right_2 ||
        ex.moduleConnection === ModuleConnections.right)
      && ex.conveyor).length > 0) {

      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.right_2 ||
          ex.moduleConnection === ModuleConnections.right) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor2 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor) - horforCon3;
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    } else if (module.modulePlan.connections.filter(ex =>
      ex.moduleConnection === ModuleConnections.right_1 && ex.conveyor).length > 0) {


      const conv = module.modulePlan.connections.filter(ex =>
        ex.moduleConnection === ModuleConnections.right_1 &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }

      }


      horopen = Conveyor1 - ( conv.getConveryorWidth(scaleFactor) / 2 ) - (50 / scaleFactor)  - horforCon3;
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    }

    if (module.modulePlan.connections.find(ex =>
      (ex.moduleConnection === ModuleConnections.right_2 ||
        ex.moduleConnection === ModuleConnections.right ||
        ex.moduleConnection === ModuleConnections.right_1) &&
      ex.conveyor !== null && ex.conveyor !== undefined)) {
      conf = module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.right_2 ||
          ex.moduleConnection === ModuleConnections.right ||
          ex.moduleConnection === ModuleConnections.right_1) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined).conveyor;

      if (conf) {
        if (conf.EndPointType === EndPointTypes.module) {
          verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conf.EndPointType === EndPointTypes.sink) {
          verifconv += (sinkSpace * 0.6);
        }
      }
    }

    let horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor - horforCon3 + horLastAddOn;

    lefts += horopen;
    path += ' ' + lefts + ',' + tops;
    tops += verifconv;
    path += ' ' + lefts + ',' + tops;
    lefts += horoffsetForConveyor;
    path += ' ' + lefts + ',' + tops;
    tops -= verifconv;
    path += ' ' + lefts + ',' + tops;
    lefts += horlast;
    path += ' ' + lefts + ',' + tops;

    //endregion

    let horconv = 0;
    let vertopen = module.rawHeight + (collisionOffset * 2);
    let vertOffsetForConveyor = 0;
    let vertlast = 0;


    // MODULE FRONT
    //region Center
    if (module.modulePlan.connections.filter(ex =>
      ex.moduleConnection === ModuleConnections.center &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.center) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }


      horconv = convHeight;
      vertopen = ( moduleHeightOrg - conv.getConveryorWidth(scaleFactor)) / 2 - (50 / scaleFactor);
      vertOffsetForConveyor = conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
      vertlast = module.rawHeight + (collisionOffset * 2) - vertopen - vertOffsetForConveyor;
      // Check if next module is connected
      if (module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.center) &&
        ex.conveyor !== null &&
        ex.conveyor !== undefined)) {
        conf = module.modulePlan.connections.find(ex =>
          (ex.moduleConnection === ModuleConnections.center) &&
          ex.conveyor !== null &&
          ex.conveyor !== undefined).conveyor;

        if (conf) {
          if (conf.EndPointType === EndPointTypes.module) {
            horconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
          } else if (conf.EndPointType === EndPointTypes.sink) {
            horconv += sinkSpace * 0.6;
          }
        }
      }

    }

    tops -= vertopen;
    path += ' ' + lefts + ',' + tops;
    lefts += horconv;
    path += ' ' + lefts + ',' + tops;
    tops -= vertOffsetForConveyor;
    path += ' ' + lefts + ',' + tops;
    lefts -= horconv;
    path += ' ' + lefts + ',' + tops;
    tops -= vertlast;
    path += ' ' + lefts + ',' + tops;
    //endregion

    horopen = module.rawWidth + (collisionOffset * 2);
    verifconv = 0;
    horoffsetForConveyor = 0;


    // MODULE TOP

    horLastAddOn = 0;


    //region TOP
    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.left_2 ||
        ex.moduleConnection === ModuleConnections.left_1)
      && ex.conveyor).length > 1) {

      const conv = module.modulePlan.connections.filter(ex =>
        ( ex.moduleConnection === ModuleConnections.left_2) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
        if (conv.EndPointType === EndPointTypes.module) {
          convHeight -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conv.EndPointType === EndPointTypes.sink) {
          convHeight += (sinkSpace * 0.6);
        }
      }

      horopen = Conveyor2 - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);

      horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor;


      lefts -= horlast;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts -= horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops;

      horLastAddOn = - ((collisionOffset * 2) + horopen + horoffsetForConveyor);

      const conv2 = module.modulePlan.connections.filter(ex =>
        ( ex.moduleConnection === ModuleConnections.left_1) &&
        ex.conveyor)[0].conveyor;

      convHeight = conveyorHeight;

      if (conv2) {
        if (conv2.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = (Conveyor1 - Conveyor2 ) + (conv.getConveryorWidth(scaleFactor) / 2) + (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor = conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);


    } else if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.left_2 ||
        ex.moduleConnection === ModuleConnections.left) &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_2 ||
          ex.moduleConnection === ModuleConnections.left) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor2 - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    } else if (module.modulePlan.connections.filter(ex =>
      ex.moduleConnection === ModuleConnections.left_1 && ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_1) && ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor1 - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);
    }

    if (module.modulePlan.connections.find(ex =>
      (ex.moduleConnection === ModuleConnections.left_1 ||
        ex.moduleConnection === ModuleConnections.left ||
        ex.moduleConnection === ModuleConnections.left_2) &&
      ex.conveyor !== null && ex.conveyor !== undefined)) {
      conf = module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.left_1 ||
          ex.moduleConnection === ModuleConnections.left ||
          ex.moduleConnection === ModuleConnections.left_2) &&
        ex.conveyor !== null && ex.conveyor !== undefined).conveyor;

      if (conf) {
        if (conf.EndPointType === EndPointTypes.module) {
          verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
        } else if (conf.EndPointType === EndPointTypes.sink) {
          verifconv += (sinkSpace * 0.6);
        }
      }
    }

    horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor + horLastAddOn;

    const horTopLastConf = horlast + horoffsetForConveyor;

    lefts -= horlast;
    path += ' ' + lefts + ',' + tops;
    tops -= verifconv;
    path += ' ' + lefts + ',' + tops;
    lefts -= horoffsetForConveyor;
    path += ' ' + lefts + ',' + tops;
    tops += verifconv;
    path += ' ' + lefts + ',' + tops;

    // CHECK FOR LEFT 3
    horforCon3 = 0;
    horlast = 0;
    verifconv = 0;
    horoffsetForConveyor = 0;

    if (module.modulePlan.connections.filter(ex =>
      (ex.moduleConnection === ModuleConnections.left_3) &&
      ex.conveyor).length > 0) {
      const conv = module.modulePlan.connections.filter(ex =>
        (ex.moduleConnection === ModuleConnections.left_3) &&
        ex.conveyor)[0].conveyor;

      let convHeight = conveyorHeight;

      if (conv) {
        if (conv.LenghtType === ConveyorLenghtType.short) {
          convHeight = convHeight / 2.0;
        }
      }

      horopen = Conveyor3 - ( conv.getConveryorWidth(scaleFactor) / 2 ) + (50 / scaleFactor);
      verifconv = convHeight;
      horoffsetForConveyor += conv.getConveryorWidth(scaleFactor) + (collisionOffset * 2);

      horforCon3 = horopen + horoffsetForConveyor;

    }

    if (horforCon3 > 0) {
      if (module.modulePlan.connections.find(ex =>
        (ex.moduleConnection === ModuleConnections.left_3) &&
        ex.conveyor !== null && ex.conveyor !== undefined)) {
        conf = module.modulePlan.connections.find(ex =>
          (ex.moduleConnection === ModuleConnections.left_3) &&
          ex.conveyor !== null && ex.conveyor !== undefined).conveyor;

        if (conf) {
          if (conf.EndPointType === EndPointTypes.module) {
            verifconv -= (( 2 * collisionOffset) + (600) / scaleFactor);
          } else if (conf.EndPointType === EndPointTypes.sink) {
            verifconv += (sinkSpace * 0.6);
          }
        }
      }
      horlast = module.rawWidth + (collisionOffset * 2) - horopen - horoffsetForConveyor - horTopLastConf;

      lefts -= horlast;
      path += ' ' + lefts + ',' + tops;
      tops -= verifconv;
      path += ' ' + lefts + ',' + tops;
      lefts -= horoffsetForConveyor;
      path += ' ' + lefts + ',' + tops;
      tops += verifconv;
      path += ' ' + lefts + ',' + tops + 'z';



    } else {
      path += 'z';
    }
    //endregion

    return path;


    //endregion
  }
}
