<section class="flex-col center-self">
  <div class="flex-row flex-container gap-5 center-self">
    <!--DELETE!-->
    <button mat-button (click)="moduleHandling.DeleteModule()" *ngIf="moduleHandling.CurrentViewMode === 0" [disabled]="!moduleHandling.IsModuleActive()">
      <img class="invertSVG activeColour" src="/assets/general/delete.svg" [ngClass]="{'svgActive': moduleHandling.IsModuleActive() && router.url !== '/live', 'svgActiveGrayTheme': moduleHandling.IsModuleActive() && router.url === '/live', 'svgInactive' : !moduleHandling.IsModuleActive()}">
    </button>
    <!--Edit!-->
    <button mat-button (click)="moduleHandling.ChangeModule()" *ngIf="moduleHandling.CurrentViewMode === 0" [disabled]="!moduleHandling.IsModuleActive()">
      <img class="invertSVG activeColour" src="/assets/general/edit.svg" [ngClass]="{'svgActive': moduleHandling.IsModuleActive() && router.url !== '/live', 'svgActiveGrayTheme': moduleHandling.IsModuleActive() && router.url === '/live', 'svgInactive' : !moduleHandling.IsModuleActive()}">
    </button>
    <!--Rotate Right!-->
    <button  mat-button (click)="moduleHandling.RotateModuleClockwise()" *ngIf="moduleHandling.CurrentViewMode === 0"  [disabled]="!moduleHandling.IsModuleActive()">
      <img class="invertSVG activeColour" src="/assets/general/turn_right.svg" [ngClass]="{'svgActive': moduleHandling.IsModuleActive() && router.url !== '/live', 'svgActiveGrayTheme': moduleHandling.IsModuleActive() && router.url === '/live', 'svgInactive' : !moduleHandling.IsModuleActive()}">
    </button>
    <!--Rotate Left!-->
    <button  mat-button (click)="moduleHandling.RotateModuleCounterClockwise()" *ngIf="moduleHandling.CurrentViewMode === 0"  [disabled]="!moduleHandling.IsModuleActive()">
      <img class="invertSVG activeColour" src="/assets/general/turn_left.svg" [ngClass]="{'svgActive': moduleHandling.IsModuleActive() && router.url !== '/live', 'svgActiveGrayTheme': moduleHandling.IsModuleActive() && router.url === '/live', 'svgInactive' : !moduleHandling.IsModuleActive()}">
    </button>
    <!-- CREATE RECIPE TEST !-->
    <button  mat-button (click)="moduleHandling.createTestRecipe()" *ngIf="ShowTestRecipeIcon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" [ngClass]="{'svgActive': router.url !== '/live', 'svgActiveGrayTheme': router.url === '/live'}">
        <title>Create test recipe</title>
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Menu">
            <path d="M 0.001953125 0.013671875 L 0.001953125 0.95898438 L 0.001953125 30.060547 L 30.050781 30.060547 L 30.050781 0.013671875 L 0.001953125 0.013671875 z M 1.8925781 1.9023438 L 28.160156 1.9023438 L 28.160156 28.171875 L 1.8925781 28.171875 L 1.8925781 1.9023438 z M 8.1445312 4.3808594 C 7.3918991 4.3808594 6.7792969 4.9686949 6.7792969 5.6914062 L 6.7792969 24.037109 C 6.7792969 24.759821 7.3918991 25.347656 8.1445312 25.347656 L 21.791016 25.347656 C 22.543647 25.347656 23.15625 24.759821 23.15625 24.037109 L 23.15625 9.3496094 L 17.978516 4.3808594 L 8.1445312 4.3808594 z M 8.1445312 5.6914062 L 17.013672 5.6914062 L 17.013672 10.298828 L 21.791016 10.298828 L 21.791016 24.037109 L 8.1445312 24.037109 L 8.1445312 5.6914062 z M 18.378906 6.6171875 L 20.847656 8.9882812 L 18.378906 8.9882812 L 18.378906 6.6171875 z M 10.873047 12.242188 C 10.49639 12.242188 10.191406 12.536755 10.191406 12.898438 C 10.191406 13.260121 10.49639 13.552734 10.873047 13.552734 L 19.060547 13.552734 C 19.437204 13.552734 19.744141 13.260122 19.744141 12.898438 C 19.744141 12.536754 19.437204 12.242188 19.060547 12.242188 L 10.873047 12.242188 z M 10.873047 14.863281 C 10.49639 14.863281 10.191406 15.157848 10.191406 15.519531 C 10.191406 15.881214 10.49639 16.173828 10.873047 16.173828 L 17.013672 16.173828 C 17.390329 16.173828 17.697266 15.881214 17.697266 15.519531 C 17.697266 15.157848 17.390329 14.863281 17.013672 14.863281 L 10.873047 14.863281 z M 10.873047 17.484375 C 10.496394 17.484375 10.191406 17.77898 10.191406 18.140625 C 10.191406 18.502309 10.496394 18.794922 10.873047 18.794922 L 15.650391 18.794922 C 16.027049 18.794922 16.332031 18.502309 16.332031 18.140625 C 16.332031 17.77898 16.027049 17.484375 15.650391 17.484375 L 10.873047 17.484375 z "/>
          </g>
        </g>
      </svg>
    </button>

    <button  mat-button (click)="moduleHandling.openModuleStateDemo()" *ngIf="isDemo()">
      <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 32 32">
        <title>Modulestate</title>
        <g>
          <path id="path1" transform="rotate(0,16,16) translate(0,4.19999980926514) scale(1,1)  " fill="#343232" d="M13.399963,6.4000004L13.399963,11.8 13.399963,17.200001 18,14.499997 22.599976,11.8 18,9.100004z M0,0L32,0 32,23.6 0,23.6z" />
        </g>
      </svg>
    </button>

    <button  mat-button (click)="moduleHandling.openSupportModuleStateDemo()" *ngIf="isDemo() && hasSupport()">
      <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 32 32">
        <title>Modulestate</title>
        <g>
          <path id="path1" transform="rotate(0,16,16) translate(0,4.19999980926514) scale(1,1)  " fill="#343232" d="M13.399963,6.4000004L13.399963,11.8 13.399963,17.200001 18,14.499997 22.599976,11.8 18,9.100004z M0,0L32,0 32,23.6 0,23.6z" />
        </g>
      </svg>
      Support
    </button>
  </div>
</section>
