import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {InitClusterActionList} from '../../../classes/init-action-list';
import {Subject} from 'rxjs';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {takeUntil} from 'rxjs/operators';
import {InitStateMessage} from '../../../classes/init-state-message';
import {environment} from '../../../../environments/environment';
import {WifiServiceNotFoundComponent} from '../../dialogs/wifi-service-not-found/wifi-service-not-found.component';
import * as moment from 'moment';
import {ClusterOnboardingService} from '../../../services/v2/cluster-onboarding.service';
import {ActionInitState} from '../../../classes/enums/action-init-state.enum';
import {ClusterOnboardingErrors} from '../../../classes/enums/cluster-onboarding-errors';
import {MessageBoxComponent} from '../../dialogs/message-box/message-box.component';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {WebsocketService} from '../../../services/v2/websocket.service';
import {WifiAutoChangeErrorComponent} from '../../dialogs/wifi-auto-change-error/wifi-auto-change-error.component';
import {DemoHandlingService} from '../../../services/demo-handling.service';
import {ConfirmMessageBoxComponent} from '../../dialogs/confirm-message-box/confirm-message-box.component';
import {OnboardingLogItem} from '../../../classes/onboarding-log-item';
import {OnboardingLogType} from '../../../classes/enums/onboarding-log-type';
import {DecisionMessageBoxComponent} from '../../dialogs/decision-message-box/decision-message-box.component';

@Component({
  selector: 'app-cluster-onboarding-tab',
  templateUrl: './cluster-onboarding-tab.component.html',
  styleUrls: ['./cluster-onboarding-tab.component.css']
})
export class ClusterOnboardingTabComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {

  public actionList = InitClusterActionList;
  private unsubscribe: Subject<void> = new Subject<void>();
  private handler: any;
  private handler2: any;
  private counter: number;
  private active = false;
  public currentAction = 1;
  // SAFTY
  public secondsForSaftey = 14.0 * 60.0;
  public currentTime = 0;
  private finishRequested = false;
  private handlerSafety: any;
  private safetyRunning = false;
  public InitSucessfull = false;
  private _showOnboarding = false;
  private retries = 0;
  private retryOngoing = false;
  private safetyRequired = true;
  private waitForWebsocketClosed = false;
  private _reinitRequested = false;
  public showLog = false;

  public manuallReonboard = 0;

  public logs: OnboardingLogItem[];

  @Input() set ReinitRequested(value: boolean) {
    this._reinitRequested = value;
  }
  @Input() set ShowOnBoarding(value: boolean) {

    this.logs = [];

    if (value !== this._showOnboarding) {

      this._showOnboarding = value;
    } else {
      return;
    }

    if (value === true) {

      if (!this.moduleHandling.ClusterInitialized) {
        this.retries = 0;
        this.retryOngoing = false;
        this.currentTime = 0;
        this.finishRequested = false;
        this.safetyRunning = false;

        for (const l of this.actionList) {
          l.state = 0;
        }
      }

      if (this.moduleHandling.DemoView || environment.demo === true) {
        // CUSTOMER MODULE

      } else {

        // CLUSTER ONBOARDING EVENTS
        this.clusterOnboarding.OnboardingProgress.pipe(takeUntil(this.unsubscribe)).subscribe(this.InitStateMessageReceived.bind(this));
        this.clusterOnboarding.ClusterOnboardingReinitRequested.pipe(takeUntil(this.unsubscribe))
          .subscribe(this.ReInitClusterRequested.bind(this));
        this.clusterOnboarding.SafetyRequired.pipe(takeUntil(this.unsubscribe)).subscribe(this.SafetyRequired.bind(this));
        this.clusterOnboarding.OnboardingError.pipe(takeUntil(this.unsubscribe)).subscribe(this.OnOnboardingError.bind(this));
        this.clusterOnboarding.NewLogMessage.pipe(takeUntil(this.unsubscribe)).subscribe(this.addLogMessage.bind(this));

      }
      if (!this.moduleHandling.ClusterInitialized) {
          this.InitCluster();
      }
    } else {
      this.unsubscribe.next();
      this.unsubscribe.complete();
      this.active = false;
    }
  }
  @Output() CloseRequested = new EventEmitter();
  @ViewChild('logdiv', { read: ElementRef, static: false}) public logwidget: ElementRef<any>;

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.active = false;
    if (this.handler) {
      clearInterval(this.handler);
    }
    if (this.handler2) {
      clearInterval(this.handler2);
    }
    if (this.handlerSafety) {
      clearInterval(this.handlerSafety);
    }
  }




  constructor(public server: ServerCommunicationService,
              public moduleHandling: ModuleHandlingService,
              private clusterOnboarding: ClusterOnboardingService,
              private translate: BluectrlTranslateService,
              public demoHandling: DemoHandlingService,
              private websocket: WebsocketService,
              public dialog: MatDialog) {

  }

  ngAfterViewInit() {
  }

  ngAfterViewChecked() {
    if (this.clusterOnboarding.OnboardingRunning) {
      if (this.logwidget) {
        this.logwidget.nativeElement.scrollTop = this.logwidget.nativeElement.scrollHeight;
      }
    }
  }

  private onAppendTolastMessage(value: string) {
    if (this.logs.length > 0) {
      this.logs[this.logs.length - 1].Text += value;
    }
  }

  private addLogMessage(logMessage: OnboardingLogItem) {
    this.logs.push(logMessage);
  }

  private OnOnboardingError(error: ClusterOnboardingErrors) {
    switch (error) {
      case ClusterOnboardingErrors.ONBOARDINGRUNING: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.ONBOARDINGISRUNING');
        this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
      case ClusterOnboardingErrors.MISSINGINFORMATIONS: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MISSINGONBOARDINGINFO');
        this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
      case ClusterOnboardingErrors.MODULESNOTCONFIGURED: {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MODULENOTCONFIGURED');
        this.dialog.open(MessageBoxComponent, dialogConfig);
        break;
      }
    }
    this.retries = 4;
    for (const a of this.actionList) {
      a.state = ActionInitState.failed;
    }
  }

  // SAFETY

  public InitCluster(reinit = false) {

    if (this.moduleHandling.DemoView) {
      this.currentAction = 1;
      this.handler2 = setInterval(et => {
        const act = this.actionList.find(ex => ex.id === this.currentAction);

        if (act) {
          act.state = Math.floor(Math.random() * Math.floor(2)) + 1;
          this.currentAction = this.currentAction + 1;
          if (this.currentAction > 2) {
            clearInterval(this.handler2);
            this.currentTime = 0;
            this.handler2 = setInterval(el => {
              this.currentTime += 1;
              if (this.currentTime > this.secondsForSaftey) {
                clearInterval(this.handler2);
              }

              if (this.currentTime > 1) {
                this.InitSucessfull = true;
              }

            }, 1000);
          }
        } else {
          clearInterval(this.handler2);
        }
      }, 2000);
    } else if (environment.demo === true) {




      this.addLogMessage(new OnboardingLogItem('Cluster Onboarding wird gestartet', OnboardingLogType.INFO));
      this.addLogMessage(new OnboardingLogItem('Versuch ' + (this.retries + 1).toString() + ' / 3', OnboardingLogType.INFO));

      this.currentAction = 1;
      this.handler2 = setInterval(et => {
        const act = this.actionList.find(ex => ex.id === this.currentAction);

        if (act) {
          act.state = 1;

          this.addLogMessage(new OnboardingLogItem('Action ' + this.currentAction + ' erfolgreich', OnboardingLogType.INFO));

          this.currentAction = this.currentAction + 1;
          if (this.currentAction > 2) {
            // SET DEMO INIT INFORMATIONS for CLUSTER

            this.demoHandling.SetClusterOnboardingInformations(this.moduleHandling.ActiveProject.Modules);
            this.clusterOnboarding.ClusterConnected = true;
            this.clusterOnboarding.ClusterInitialized = true;


            clearInterval(this.handler2);
            this.currentTime = 0;

            this.addLogMessage(new OnboardingLogItem('Starte Timer für Sicherheitskonfiguration', OnboardingLogType.INFO));

            this.handler2 = setInterval(el => {
              this.currentTime += 1;
              if (this.currentTime > this.secondsForSaftey) {
                clearInterval(this.handler2);
              }

              if (this.currentTime > 10) {
                this.InitSucessfull = true;
                this.addLogMessage(new OnboardingLogItem('Sicherheitskonfiguration erfolgreich abgeschlossen', OnboardingLogType.INFO));
                this.addLogMessage(new OnboardingLogItem('Cluster bereit für Betrieb', OnboardingLogType.INFO));
                clearInterval(this.handler2);
                setTimeout(() => this.CloseRequested.emit(), 2000);
              }

            }, 1000);
          }
        } else {
          clearInterval(this.handler2);
        }
      }, 1000);
    } else {
      this.SecondCounter();
      this.clusterOnboarding.resetOnboarding();

      this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.STARTCLUSTERONBOARDING') +
        '. ' + this.translate.GetTranslation('LOGMESSAGES.TRYCOUNTER') + ' ' +
        (this.retries + 1).toString() + ' / 3', OnboardingLogType.INFO));

      this.clusterOnboarding.startClusterOnboarding(this.moduleHandling.MasterIp,
        this.moduleHandling.MasterSerialNumber,
        this.moduleHandling.CurrentClusterId,
        this.moduleHandling.ActiveProject,
        (reinit || this._reinitRequested));
    }
  }

  public SecondCounter() {
    this.counter = 90;

    this.handler = setInterval(v => {
      this.counter = this.counter - 1;
      if (this.counter <= 0) {
        clearInterval(this.handler);
      }
    }, 1000);
  }

  ngOnInit(): void {
    this.active = true;
    this.InitSucessfull = false;
  }

  CloseDialog() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.active = false;

  }

  public InitStateMessageReceived(stateMessage: InitStateMessage) {

    if (!this.active) {
      return;
    }

    if (stateMessage.ActionId === -1) {
      return;
    }

    const act = this.actionList.find(ex => ex.id === stateMessage.ActionId);
    if (act) {
      if (stateMessage.State === 2 && act.id > 0) {
        if (this.actionList.find(ex => ex.id === 1).state === 3 && act.id === 2 && stateMessage.State === 2) {

            // Manually change wifi
            this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.AUTOMATICWIFIFAILED'),
              OnboardingLogType.ERROR));
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'loginDialogGray';
            const splitter = this.moduleHandling.CurrentClusterId.split('-');
            dialogConfig.data = environment.ClusterWifiSurFix +  splitter[splitter.length - 1];
            const ref = this.dialog.open(WifiAutoChangeErrorComponent, dialogConfig);
            ref.afterClosed().subscribe((data: any) => {
            });
          this.retries = 4;
          const following = this.actionList.filter(ex => ex.id >= stateMessage.ActionId);
          for (const f of following) {
            f.state = 2;
          }
          this.clusterOnboarding.CancelOnboarding();
          return;

        } else if (this.retries <= 3) {
          // TIME
          this.retryOngoing = true;
          setTimeout(() => {

            this.RetryInit();
            this.retryOngoing = false;
            this.retries += 1;
            this.manuallReonboard += 1;
          }, 5000);


        } else {
          act.state = stateMessage.State;
          const following = this.actionList.filter(ex => ex.id > stateMessage.ActionId);
          for (const f of following) {
            f.state = 2;
          }
          this.manuallReonboard += 1;
          this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.CLUSTERONBOARDINGFAILED'), OnboardingLogType.ERROR));
        }

        if (this.manuallReonboard > 3 && !this.retryOngoing) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'loginDialogGray';
          dialogConfig.data = {
            content: this.translate.GetTranslation('MESSAGEBOX.CONTENT.ONBOARDINGFAILED_1') + this.clusterOnboarding.MasterSerial + this.translate.GetTranslation('MESSAGEBOX.CONTENT.ONBOARDINGFAILED_2'),
            trueOption: this.translate.GetTranslation('MESSAGEBOX.CONTENT.ONBOARDINGFAILED_RESET'),
            falseOption: this.translate.GetTranslation('MESSAGEBOX.CONTENT.ONBOARDINGFAILED_RETRY'),
          }

          const dialogRef = this.dialog.open(DecisionMessageBoxComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
              // CONNECT TO EXISTING CLUSTER
              this.clusterOnboarding.resetOnboarding();
              this.moduleHandling.resetOnboardingPerModule();
              this.CloseRequested.emit();
            } else {
              this.manuallReonboard = 0;
              this.retries = 0;
              this.RetryInit();
            }
          });
        }

      } else {
        act.state = stateMessage.State;
        if (act.id === 1) {
          try {
            clearInterval(this.handler);
          } catch {

          }
        }

        if (act.id === 1 && act.state === 4) {
          // NO WIFI SERVICE
          this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.NOWIFISERVICE'), OnboardingLogType.WARNING));
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = false;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'loginDialogGray';
          const splitter = this.moduleHandling.CurrentClusterId.split('-');
          dialogConfig.data = environment.ClusterWifiSurFix +  splitter[splitter.length - 1];
          const ref = this.dialog.open(WifiServiceNotFoundComponent, dialogConfig);
          ref.afterClosed().subscribe((data: any) => {
            this.clusterOnboarding.continueWithoutWifiService();
          });
        }



      }
    }
  }

  public RetryInit() {
    this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.RESTARTONBOARDING'), OnboardingLogType.DEBUG));
    this.currentTime = 0;
    this.finishRequested = false;
    this.safetyRunning = false;

    if (this.handlerSafety) {
      clearInterval(this.handlerSafety);
    }

    this.actionList = InitClusterActionList;
    for (const l of this.actionList) {
      l.state = 0;
    }
    this.InitCluster();

  }

  public RetryPossible(): boolean {
    if (this.retryOngoing === true) {
      return false;
    }

    if (this.actionList.filter(ex => ex.state === 2).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  arrayOne(): any[] {
    return Array((this.secondsForSaftey / 60) / 2);
  }

  public SafetyRequired(required: boolean) {
    this.safetyRequired = required;

    if (this.safetyRunning) {
      if (this.safetyRequired === false) {
        this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.SAFETYCONFIGFINISHED'),
          OnboardingLogType.INFO));
        this.SafetyInitFinished();

      }
    } else {
      if (this.safetyRequired === true) {

        this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.RUNSAFETYTIMER'),
          OnboardingLogType.DEBUG));
        // if (this.actionList.find(ex => ex.id === 2 && ex.state === 1)) {
          this.safetyRunning = true;

          // START HANDLER
          if (this.handlerSafety) {
            clearInterval(this.handlerSafety);
          }


          this.handlerSafety = setInterval(et => {

            this.currentTime = this.currentTime + 1;

            if (this.currentTime >= this.secondsForSaftey) {
              if (!this.finishRequested && this.safetyRunning && this.safetyRequired) {
                const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.TIMEOUT');
                const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.SAFETYTIMEOUT');
                const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
                  {panelClass: 'panelclass', data: {header: msg, content: content}});
                // this.SafetyInitFinished();
                this.clusterOnboarding.finishOnboardingOnSafetyTimeout();
                this.RunFinishRoutine();
              }
              clearInterval(this.handlerSafety);
            }

          }, 1000);
        // }
      } else {
        // this.currentTime = this.secondsForSaftey;
        // this.moduleHandling.safetyInitFinished = true;
        // UPADTE STATE

        this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.NOSAFETYWAITREQUIRED'),
          OnboardingLogType.INFO));

        const act = this.actionList.find(ex => ex.id === 2);
        if (act) {
          act.state = 1;
        }
        this.safetyRunning = true;
        this.SafetyInitFinished();
      }

    }
  }

  public SafetyInitFinished() {
    if (this.safetyRunning) {
      if (!this.finishRequested) {
        this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.FINISHSAFETY'), OnboardingLogType.INFO));
        this.safetyRunning = false;
        this.finishRequested = true;
        this.moduleHandling.safetyInitFinished = true;
        // Finish animation
        clearInterval(this.handlerSafety);
        const rest = this.secondsForSaftey - this.currentTime;
        const stp = rest / 3.0;
        this.handlerSafety = setInterval(et => {

          this.currentTime = this.currentTime + stp;
          if (this.currentTime > this.secondsForSaftey) {

            this.finishRequested = false;
            this.RunFinishRoutine();
            clearInterval(this.handlerSafety);
          }
        }, 1000);
      }
    }
  }

  public RunFinishRoutine() {
    this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.CLUSTERONBOARDINGDONE'), OnboardingLogType.INFO));
    this.finishRequested = true;
    this.moduleHandling.safetyInitFinished = true;
    this.InitSucessfull = true;
    const hdnl = setTimeout(ex => {
      this.CloseRequested.emit();
    }, 3000);
  }

  public getCurrentTime(): string {
    if (this.secondsForSaftey - this.currentTime > 0) {
      const duration = moment.duration(this.secondsForSaftey - this.currentTime, 'seconds');
      const resultstring = moment.utc(duration.asMilliseconds()).format('mm:ss');
      return resultstring;
    } else {
      return '00:00';
    }
  }

  public getMaxTime(): string {
    if (this.secondsForSaftey > 0) {
      const duration = moment.duration(this.secondsForSaftey, 'seconds');
      const resultstring = moment.utc(duration.asMilliseconds()).format('mm:ss');
      return resultstring;
    } else {
      return '00:00';
    }
  }

  public ReInitClusterRequested() {

    if (this._showOnboarding) {
      this.addLogMessage(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.RECONNECTCLUSTER'), OnboardingLogType.DEBUG));
      this.InitCluster(true);
    }
  }

  public getLogMessages(): OnboardingLogItem[] {
    if (environment.production === true) {
      return this.logs.filter(ex => ex.LogType !== OnboardingLogType.DEBUG);
    } else {
      return this.logs;
    }
  }
}
