import {Component, Inject, OnInit} from '@angular/core';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MessageHandlingService} from '../../../services/v2/message-handling.service';
import {ModeSwitch} from '../../../classes/enums/mode-switch.enum';
import {ModulePlan} from '../../../classes/module-plan';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-demo-module-state',
  templateUrl: './demo-module-state.component.html',
  styleUrls: ['./demo-module-state.component.css']
})
export class DemoModuleStateComponent implements OnInit {


  public currentState: number;
  public currentMode: string;
  public currentEmergencyState: boolean;

  public currentEmergencyStateOk: boolean;

  public moduleplan: ModulePlan;

  constructor(public moduleHandling: ModuleHandlingService,
              private dialogRef: MatDialogRef<DemoModuleStateComponent>,
              public dialog: MatDialog,
              public messageHandling: MessageHandlingService,
              @Inject(MAT_DIALOG_DATA) data) {
    if (data === 'support') {
      const support = this.moduleHandling.getSupportModuleForModulePlan(this.moduleHandling.ActiveModule);
      this.moduleplan = support;
    } else {
      this.moduleplan = this.moduleHandling.ActiveModule;
    }

  }

  ngOnInit(): void {
    if ( this.moduleplan && environment.demo === false) {
      // CREATE NETWORK INFO
      // GET SERIAL
      const fakeSerial =  this.moduleplan.customerModule.Module.Key + '-xxxx';

      const internalBelts: any[] = [];

      for (const blt of  this.moduleplan.customerModule.Module.ConveyorBelts) {
        internalBelts.push({
          name: blt.RelatedInternalBelt,
          position: 'middle'
        });
      }


       this.moduleplan.customerModule.SerialNumberSetted = true;
       this.moduleplan.customerModule.SerialNumber = fakeSerial;

       this.moduleplan.customerModule.Initialized = true;
       this.moduleplan.customerModule.FullyConfigured = true;
       this.moduleplan.ConfigFinishedSended = true;

      const networkInfo = {
        serialnumber: fakeSerial,
        ipaddress: '127.0.0.1',
        nodeversion: '1.1.1',
        specversion: '1.1.1',
        plcversion: '1.1.1',
        revision: 0,
        statemodule: 0,
        switchstate: 'auto',
        emergencystoprequired: false,
        emergencystopok: true,
        levelingrequired: false,
        belts: internalBelts,
        additionalModuleInformations: []
      };

      if (this.moduleplan.customerModule.Module.Group === 'SENSOR') {

        networkInfo.additionalModuleInformations.push({
          name: 'IDE01.MMI.SZ',
          values: 'SZ1;SZ2;SZ3;SZ4;SZ5;SZ6;SZ7'
        });
      }


       this.moduleplan.setModuleNetworkState(networkInfo);
      this.currentState = 0;
      this.currentMode = 'auto';
      this.currentEmergencyState = false;
      this.currentEmergencyStateOk = true;
      this.moduleHandling.setClusterInitialized(true);
      this.moduleHandling.setClusterConnected(true);


    }

    // if(this.moduleplan.customerModule.SerialNumber.startsWith('B0802-003')) {
    //   const msg = {
    //     type: 'message',
    //     context: 'module',
    //     msgId: '34873294',
    //     ctxId:  this.moduleplan.customerModule.SerialNumber,
    //     component: 'ALV01',
    //     parameter: 'SingleDedustCycle',
    //     value: false
    //   };

    //   this.messageHandling.demoParameterChange(msg);
    // }

  }

  public changeState(state: number) {
    this.currentState = state;

    const msg = {
      type: 'message',
      context: 'module',
      msgId: '1111',
      ctxId:  this.moduleplan.customerModule.SerialNumber,
      entity: 'autostate',
      state: state
    };

    this.messageHandling.demoChangeModuleState(msg);
  }

  public changeModeState(state: string) {
    this.currentMode = state;

    const msg = {
      type: 'message',
      context: 'module',
      msgId: '1111',
      ctxId:  this.moduleplan.customerModule.SerialNumber,
      entity: 'modechange',
      state: state
    };

    this.messageHandling.demoModeSwitch(msg);
  }

  public changeEmergencyTestRequired(state: boolean) {
    const msg = {
      type: 'message',
      context: 'module',
      msgId: '1111',
      ctxId:  this.moduleplan.customerModule.SerialNumber,
      entity: 'emergencyrequired',
      state: state
    };
    this.currentEmergencyState = state;
    this.messageHandling.demoEmergency(msg);

  }

  public changeEmergencyStopOk(state: boolean) {
    this.moduleplan.modul.NetworkInfo.emergencystopok = state;
    this.currentEmergencyStateOk = state;
  }

  public openModuleSettings() {
    if (this.moduleplan.modul.SupportModule) {
      this.moduleHandling.OpenSupportModuleSettings();
    } else {
      this.moduleHandling.OpenModuleSettings();
    }
  }

  CloseDialog() {
    this.dialogRef.close();
  }

}
