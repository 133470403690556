<section class="flex-col stretch-col" [ngClass]="{'darkSection': moduleHandling.CurrentViewMode === 0 || moduleHandling.CurrentViewMode === 4, 'lightSection': moduleHandling.CurrentViewMode === 1, 'inventorySection': moduleHandling.CurrentViewMode === 3}">
  <div class="moduleheader flex-row" (click)="OwnVisible = !OwnVisible">
    <p [ngClass]="{'darkVersion': moduleHandling.CurrentViewMode === 0 || moduleHandling.CurrentViewMode === 4, 'whiteVersion': moduleHandling.CurrentViewMode === 1, 'inventoryTheme': moduleHandling.CurrentViewMode === 3}">{{device.GetTranslationId() | i18next}} {{device.getComponentNumber()}}: {{device.PlcKey}}</p>
    <svg class="updownicon" *ngIf="!OwnVisible" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 4.58 9.16" (click)="OwnVisible = true">
      <defs>
        <style>.cls-1{fill:#1eb4eb;}</style>
      </defs>
      <title>clap</title>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Bearbeiten_S4" data-name="Bearbeiten S4">
          <polygon class="cls-1" points="0 9.16 0 0 4.58 4.58 0 9.16"/>
        </g>
      </g>
    </svg>
    <svg class="updownicon" *ngIf="OwnVisible" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 9.16 4.58" (click)="OwnVisible = false">
      <defs>
        <style>.cls-1{fill:#1eb4eb;}</style>
      </defs>
      <title>unclap</title>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Bearbeiten_S4" data-name="Bearbeiten S4">
          <polygon class="cls-1" points="0 0 9.16 0 4.58 4.58 0 0"/>
        </g>
      </g>
    </svg>
  </div>

  <div class="contentd flex-col start-self" *ngIf="OwnVisible">
    <table *ngFor="let dv of GetMonitorValues()" class="elements flex-col">
      <tr class="elementRow flex-row stretch-row" [ngClass]="{'elementRowConf': ShowConfig(dv)}">
        <td style="width: 40%" class="elementText" *ngIf="dv.States.length === 0"><p class="contentp" [ngClass]="{'darkVersion': moduleHandling.CurrentViewMode === 0 || moduleHandling.CurrentViewMode === 4, 'whiteVersion': moduleHandling.CurrentViewMode === 1, 'inventoryTheme': moduleHandling.CurrentViewMode === 3}">{{dv.TranslationId | i18next}}</p></td>
        <td style="width: 40%" class="elementText" *ngIf="dv.States.length > 0"><p class="contentp2" [ngClass]="{'darkVersion': moduleHandling.CurrentViewMode === 0 || moduleHandling.CurrentViewMode === 4, 'whiteVersion': moduleHandling.CurrentViewMode === 1, 'inventoryTheme': moduleHandling.CurrentViewMode === 3}">{{dv.TranslationId | i18next}}</p></td>
        <td style="width: 40%" class="elementText"><p class="contentp" [ngClass]="{'darkVersion': moduleHandling.CurrentViewMode === 0 || moduleHandling.CurrentViewMode === 4, 'whiteVersion': moduleHandling.CurrentViewMode === 1, 'inventoryTheme': moduleHandling.CurrentViewMode === 3}">{{GetCurrentValue(dv)}}</p></td>
        <td style="width: 10%" class="elementText" *ngIf="dv.States.length === 0"><p class="contentp" [ngClass]="{'darkVersion': moduleHandling.CurrentViewMode === 0 || moduleHandling.CurrentViewMode === 4, 'whiteVersion': moduleHandling.CurrentViewMode === 1, 'inventoryTheme': moduleHandling.CurrentViewMode === 3}">{{GetUnitTranslation(dv)}}</p></td>
      </tr>
    </table>
  </div>
</section>
