import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {ViewCode} from '../../../classes/enums/view-code.enum';
import {Conveyor} from '../../../classes/conveyor';
import {ModulePlan} from '../../../classes/module-plan';
import {Project} from '../../../classes/project';
import {Subject} from 'rxjs';
import {MenuActionService} from '../../../services/menu-action.service';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {takeUntil} from 'rxjs/operators';
import {NetworkDevice} from '../../../classes/messaging/network-device';
import {ModuleDesignComponent} from '../../elements/module-design/module-design.component';
import {Coordinates} from '../../../classes/coordinates';
import {ModuleConnections} from '../../../classes/enums/module-connections.enum';
import {EndPointTypes} from '../../../classes/enums/end-point-types.enum';
import {ModuleRotations} from '../../../classes/enums/module-rotations.enum';
import {SaveProjectDialogComponent} from '../../dialogs/save-project-dialog/save-project-dialog.component';
import {SendRecipeComponent} from '../../dialogs/send-recipe/send-recipe.component';
import {SimplePoint} from '../../../classes/simple-point';
import {ShareDialogComponent} from '../../dialogs/share-dialog/share-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {UserManagementService} from '../../../services/user-management.service';
import {ClusterOnboardingComponent} from '../../dialogs/cluster-onboarding/cluster-onboarding.component';
import {ConveyorLenghtType} from '../../../classes/enums/conveyor-lenght-type.enum';
import {ConfirmMessageBoxComponent} from '../../dialogs/confirm-message-box/confirm-message-box.component';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {MessageHandlingService} from '../../../services/v2/message-handling.service';
import {ClusterOnboardingService} from '../../../services/v2/cluster-onboarding.service';
import * as uuidV4 from 'uuid';
import {HelperFunctions} from '../../../classes/helper-functions';
import {SupportModulePlan} from '../../../classes/support-module-plan';
import {LineCollider} from '../../../classes/Collider/line-collider';
import {QuestionBoxComponent} from '../../dialogs/question-box/question-box.component';
import {OnboardingLogItem} from '../../../classes/onboarding-log-item';
import {OnboardingLogType} from '../../../classes/enums/onboarding-log-type';

@Component({
    selector: 'app-live-plate',
    templateUrl: './live-plate.component.html',
    styleUrls: ['./live-plate.component.css']
})
export class LivePlateComponent implements OnInit, OnDestroy, AfterViewInit {


  private dialogOpen = false;
  public moduleplan: ModulePlan;
  public moduleWidht = 303;
  public moduleHeight = 122;
  private maxWidth = window.innerWidth;
  private maxHeight = window.innerHeight - 45;
  private minTop = 0;
  private minLeft = 0;
  private maxTop = 0;
  private maxLeft = 0;
  private modulezi = 0;
  private startModuleTop = 0;
  private startModuleLeft = 0;
  public settingModul: ModulePlan;
  public monitoringMsgId: string;
  private unsubscribe: Subject<void> = new Subject();
  private activemodule: ModuleDesignComponent;
  public ShowModuleSettings = false;
  private collisionDetected = false;
  private sinkSpace = 50;
  private collisionOffset = 1;
  public lnDistance = 50;
  public scaleFactor = 1;
  public horizontals: number[];
  public verticals: number[];
  private toleranceX = 0;
  private toleranceY = 0;
  public ShowLeft = false;
  public ShowRight = false;
  public ShowTop = false;
  public ShowBottom = false;
  public TopOffset = 0;
  public LeftOffset = 0;
  public StepSize = 100;
  public showOnboarding = false;
  private _clusterInitOngoing = false;
  public showLoadRecipe = false;
  private connectionLostDialogOpen = false;

  private InventoryOnboardingRequested = false;
  private loadOnboardingRecipeRequested = false;


  public get viewbox() {
    return '{0, 0, ' + this.maxWidth + ', ' + this.maxHeight + '}';
  }

  @ViewChild('modulePlate', {read: ViewContainerRef, static: true}) container;
  @ViewChild('svgGroup', {read: ElementRef, static: true}) svgGroup;

  constructor(public menuAction: MenuActionService,
              public moduleHandling: ModuleHandlingService,
              public resolver: ComponentFactoryResolver,
              public server: ServerCommunicationService,
              private route: ActivatedRoute,
              public userMngmt: UserManagementService,
              public translate: BluectrlTranslateService,
              private messageHandling: MessageHandlingService,
              public clusterOnboarding: ClusterOnboardingService,
              public router: Router,
              public dialog: MatDialog) {

    this.route.params.subscribe(event => {
      if (event.token) {
        const rec = atob(event.token);
        this.moduleHandling.LoadRecipeOnFinished(rec);

      }
    });

    this.startModuleTop = 0;
    this.startModuleLeft = 0;
    this.minTop = window.innerHeight;
    this.minLeft = window.innerWidth;
    this.maxLeft = 0;
    this.maxTop = 0;
  }

  // VIEW BEHEVIOR

  ngOnInit() {
    this.moduleHandling.SetViewMode(ViewCode.live);
    this.moduleHandling.OnSendRecipe.pipe(takeUntil(this.unsubscribe)).subscribe(this.sendRecipe.bind(this));
    this.menuAction.OnSaveProject.pipe(takeUntil(this.unsubscribe)).subscribe(this.SaveProject.bind(this));
    this.menuAction.OnLoadProject.pipe(takeUntil(this.unsubscribe)).subscribe(this.LoadProject.bind(this));
    this.menuAction.OnShareProject.pipe(takeUntil(this.unsubscribe)).subscribe(this.ShareProject.bind(this));

    this.clusterOnboarding.ClusterOnboardingReinitRequested
      .pipe(takeUntil(this.unsubscribe)).subscribe(this.CheckIfInitFinishedOnEvent.bind(this));
    this.clusterOnboarding.NewLogMessage
      .pipe(takeUntil(this.unsubscribe)).subscribe(this.OnReconnectLogMessage.bind(this));
    this.messageHandling.ModuleOnlineStateChanged.pipe(takeUntil(this.unsubscribe)).subscribe(this.ModuleOnlineStateChanged.bind(this));
    this.messageHandling.InternalBeltConveyorChanged
      .pipe(takeUntil(this.unsubscribe)).subscribe(this.InternalBeltStateChanged.bind(this));
    this.clusterOnboarding.ClusterInitializingFinished
      .pipe(takeUntil(this.unsubscribe)).subscribe(this.ClusterOnboardingFinished.bind(this));
    this.messageHandling.EmergencyTestStateChanged
      .pipe(takeUntil(this.unsubscribe)).subscribe(this.EmergencyTestRequiredChanged.bind(this));
    this.messageHandling.LevelingRequiredChanged.pipe(takeUntil(this.unsubscribe)).subscribe(this.LevelingRequiredChanged.bind(this));
    this.moduleHandling.OnOpenModuleSettings.pipe(takeUntil(this.unsubscribe)).subscribe(this.OpenModuleSettings.bind(this));
    this.moduleHandling.OnOpenSupportModuleSettings.pipe(takeUntil(this.unsubscribe)).subscribe(this.OpenSupportModuleSettings.bind(this));
    this.moduleHandling.OnCloseModuleSettings.pipe(takeUntil(this.unsubscribe)).subscribe(this.CloseModuleSettings.bind(this));

    this.moduleHandling.OnUserLoggedIn.pipe(takeUntil(this.unsubscribe)).subscribe(this.UserLoggedIn.bind(this));
    this.moduleHandling.OnUserLoggedOut.pipe(takeUntil(this.unsubscribe)).subscribe(this.UserLoggedOut.bind(this));
    this.moduleHandling.OnProjectLoaded.pipe(takeUntil(this.unsubscribe)).subscribe(this.OnProjectLoaded.bind(this));

    this.moduleHandling.ModuleOnboardingRecipeLoaded.pipe(takeUntil(this.unsubscribe)).subscribe(this.OnboardingRecipeLoaded.bind(this));

    if (!this.moduleHandling.LoadRecipeFromInventoryInfo) {
      if (this.userMngmt.DataLoaded) {
        this.moduleHandling.SetProjectFromCookie();
      }

      if (this.moduleHandling.ActiveProject) {
        this.drawElements();
      }

    } else {
      const inventoryRec = this.moduleHandling.LoadRecipeFromInventoryInfo;
      this.loadOnboardingRecipeRequested = true;
      this.InventoryOnboardingRequested = true;
      this.moduleHandling.LoadOnboardingClusterRecipe(inventoryRec.recipe,
        inventoryRec.clusterid,
        inventoryRec.masterSerial,
        inventoryRec.masterIp);

      this.moduleHandling.LoadRecipeFromInventoryInfo = null;
    }

    this.CreateCounters();
  }

  private OnReconnectLogMessage(message: OnboardingLogItem) {
    if (this.clusterOnboarding.reconnectActive) {

      this.clusterOnboarding.lastLogMessage = message.Text;
      if (message.LogType === OnboardingLogType.ERROR) {
        console.error(message.Text);
      } else if (message.LogType === OnboardingLogType.WARNING) {
        console.warn(message.Text);
      } else {
        console.log(message.Text);
      }
    }
  }

  private OnboardingRecipeLoaded(data: any) {
    if (this.loadOnboardingRecipeRequested === true) {
      this.loadOnboardingRecipeRequested = false;

      this.moduleHandling.CurrentClusterId = data.ClusterId;
      this.moduleHandling.MasterSerialNumber = data.MasterSerialNumber;
      this.moduleHandling.MasterIp = data.MasterIpAddress;
      // ONBOARDING
      this.CheckIfInitFinishedOnEvent(false);

    }
  }

  private LevelingRequiredChanged(msg: any) {
    const mdl = this.moduleHandling.ActiveProject.Modules.find(ex => ex.customerModule && ex.customerModule.SerialNumber === msg.ctxId);

    if (mdl) {
      if (mdl.customerModule.Module.NetworkInfo) {
        mdl.customerModule.Module.NetworkInfo.levellingrequired = msg.levellingrequired;
        mdl.customerModule.Module.LevelingRequired = msg.levellingrequired;
        this.drawElements();
      }
    } else if (this.moduleHandling.ActiveProject.SupportModules) {


      const mdls = this.moduleHandling.ActiveProject.SupportModules.find(ex => ex.customerModule && ex.customerModule.SerialNumber === msg.ctxId);

      if (mdls) {
        if (mdls.customerModule.Module.NetworkInfo) {
          mdls.customerModule.Module.NetworkInfo.levellingrequired = msg.levellingrequired;
          mdls.customerModule.Module.LevelingRequired = msg.levellingrequired;
          this.drawElements();
        }
      }
    }
  }

  private EmergencyTestRequiredChanged(msg: any) {
    const mdl = this.moduleHandling.ActiveProject.Modules.find(ex => ex.customerModule && ex.customerModule.SerialNumber === msg.ctxId);

    if (mdl) {
      if (mdl.customerModule.Module.NetworkInfo) {
        mdl.customerModule.Module.NetworkInfo.emergencystoprequired = msg.state;
        mdl.customerModule.Module.EmergencyStopRequired = msg.state;
        this.drawElements();
      }
    } else if (this.moduleHandling.ActiveProject.SupportModules) {


      const mdls = this.moduleHandling.ActiveProject.SupportModules.find(ex => ex.customerModule && ex.customerModule.SerialNumber === msg.ctxId);

      if (mdls) {
        if (mdls.customerModule.Module.NetworkInfo) {
          mdls.customerModule.Module.NetworkInfo.emergencystoprequired = msg.state;
          mdls.customerModule.Module.EmergencyStopRequired = msg.state;
          this.drawElements();
        }
      }
    }
  }

  private InternalBeltStateChanged(msg: any) {
    const mdl = this.moduleHandling.ActiveProject.Modules.find(ex => ex.customerModule && ex.customerModule.SerialNumber === msg.ctxId);

    if (mdl) {
      if (mdl.customerModule.Module.NetworkInfo) {
        const blt = mdl.customerModule.Module.NetworkInfo.belts.find(ex => ex.name === msg.belt.name);
        if (blt) {
          blt.position = msg.belt.position;
        } else {
          mdl.customerModule.Module.NetworkInfo.belts.push(msg.belt);
        }
        this.drawElements();
      }
    }

  }

  private ModuleOnlineStateChanged(msg: any) {
    const mdl = this.moduleHandling.ActiveProject.Modules.find(ex => ex.customerModule && ex.customerModule.SerialNumber === msg.ctxId);

    if (mdl) {
      if (msg.online) {
        const networkinfo = new NetworkDevice(msg.ctxId,
          msg.ipaddress,
          msg.revision,
          msg.statemodule,
          msg.switchstate,
          msg.belts,
          msg.emergencystoprequired,
          msg.emergencystopok,
          msg.levellingrequired,
          msg.nodeversion,
          msg.plcversion,
          msg.specversion);

        mdl.setModuleNetworkState(networkinfo);


      } else {
        mdl.clusterConnectionLost();
      }

      this.drawElements();
    } else if (this.moduleHandling.ActiveProject.SupportModules) {
      const mdls = this.moduleHandling.ActiveProject.SupportModules.find(ex => ex.customerModule && ex.customerModule.SerialNumber === msg.ctxId);

      if (mdls) {
        if (msg.online) {
          const networkinfo = new NetworkDevice(msg.ctxId,
            msg.ipaddress,
            msg.revision,
            msg.statemodule,
            msg.switchstate,
            msg.belts,
            msg.emergencystoprequired,
            msg.emergencystopok,
            msg.levellingrequired,

            msg.nodeversion,
            msg.plcversion,
            msg.specversion);

          mdls.setModuleNetworkState(networkinfo);


        } else {
          mdls.clusterConnectionLost();
        }

        this.drawElements();
      }
    }

  }

  private ClusterOnboardingFinished() {
    this.drawElements();
  }

  public UploadAllowed(): boolean {

    const location = window.location.href;

    if (location.indexOf('localhost') >= 0 || location.indexOf('dev') >= 0 || location.indexOf('theme') >= 0) {
      return true;
    }


    if (!this.moduleHandling.ActiveProject) {
      return false;
    }

    if (!this.moduleHandling.ActiveProject.Modules) {
      return false;
    }

    if (this.moduleHandling.ActiveProject.Modules.length <= 0) {
      return false;

    }

    return this.clusterOnboarding.ClusterInitialized;
  }

  public OnProjectLoaded() {

    if (!this.showLoadRecipe) {
      if (this.moduleHandling.ActiveProject !== null) {
        if(this.moduleHandling.ValidateProject().length > 0) {
          const msg = this.translate.GetTranslation('MESSAGEBOX.CONTENT.INVALIDRECIPEINCOOKIE');
          // const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.RECIPENOTVALID');
          const dialogRef = this.dialog.open(QuestionBoxComponent,
            {panelClass: 'panelclass', data: msg});
          dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
              this.router.navigate(['/design']);
            } else {
              this.moduleHandling.NewProject();
            }

          });

        } else {
          this.drawElements();
        }


        // this.CheckIfInitFinished();
      }
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.ShowModuleSettings === true) {
      this.CloseModuleSettings();
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    this.maxWidth = window.innerWidth;
    this.maxHeight = window.innerHeight - 45;
    this.lnDistance = 50 * this.scaleFactor;
    this.CreateCounters();
    this.drawElements();
  }

  // EVENTS

  public ShareProject() {
    if (this.moduleHandling.ActiveProject) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data = this.moduleHandling.ActiveProject;
      this.dialog.open(ShareDialogComponent, dialogConfig);

      return false;
    }
  }

  public UserLoggedOut() {
    this.Reset();
    this.moduleHandling.ActiveProject = null;
    const elem = this.svgGroup.nativeElement;
    if (elem) {
      elem.innerHTML = '';
    }
    this.drawElements();

  }

  public UserLoggedIn() {
    if (this.moduleHandling.ActiveProject !== null) {
      this.drawElements();
    }
  }

  public CheckIfInitFinishedOnEvent(withMessage = true) {
    if (this.showOnboarding === false && this.moduleHandling.CurrentViewMode === ViewCode.live) {
      // RECONNECT INFO
      if (withMessage) {
        let content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.CLUSTERCONNECTIONLOST');

        if (!this.clusterOnboarding.hasInternet) {
          content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.INTERNETCONNECTIONLOST');
        }


        const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.MODULECONNTECTIONLOST');
        const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
          {panelClass: 'panelclass', data: {header: msg, content: content}});

        if (this.connectionLostDialogOpen === false) {
          this.connectionLostDialogOpen = true;
          dialogRef.afterClosed().subscribe(() => {
            this.connectionLostDialogOpen = false;
            this.showOnboarding = false;
            this.drawElements();
            this.CheckIfInitFinished(false);
          });
        }
      } else {
        this.drawElements();
        this.CheckIfInitFinished(false);
      }
    }
  }

  public CheckIfInitFinished(wait = true) {
    let timeout = 5000;
    if (!wait) {
      timeout = 10;
    }
    if (this.moduleHandling.CheckIfModuleInitFinished()) {
      if (this.clusterOnboarding.ClusterInitialized) {
        if (!this._clusterInitOngoing) {
          if (!this.clusterOnboarding.ClusterConnected) {
            this._clusterInitOngoing = true;
            const hdnl = setTimeout(() => {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
              dialogConfig.panelClass = 'loginDialogGray';
              const dialogRef = this.dialog.open(ClusterOnboardingComponent, dialogConfig);
              dialogRef.afterClosed().subscribe(() => {
                this._clusterInitOngoing = false;
              });
              clearTimeout(hdnl);
            }, timeout);
          }
        } else {
          console.warn('cluster init is ongoing?');
        }
      } else {
        if (!this._clusterInitOngoing && !this.clusterOnboarding.OnboardingRunning && this.InventoryOnboardingRequested) {
          this._clusterInitOngoing = true;
          this.InventoryOnboardingRequested = false;
          const hdnl = setTimeout(() => {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'loginDialogGray';
            const dialogRef = this.dialog.open(ClusterOnboardingComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(() => {
              this._clusterInitOngoing = false;
            });
            clearTimeout(hdnl);
          }, timeout);
        } else if (!this._clusterInitOngoing) {
          // CLUSTER INIT FAILED

          this.moduleHandling.resetOnboardingPerModule();
        }
      }
    } else {

    }
  }


  // DRAWING
  public GoLeft() {
    this.LeftOffset += this.StepSize;
    this.drawElements(false, true);
  }

  public GoRight() {
    this.LeftOffset -= this.StepSize;
    this.drawElements(false, true);
  }

  public GoTop() {
    this.TopOffset += this.StepSize;
    this.drawElements(false, true);
  }

  public GoBottom() {
    this.TopOffset -= this.StepSize;
    this.drawElements(false, true);
  }

  public ShowModuleOnboarding() {
    this.moduleHandling.activeOnboardingServiceModulePlan = null;
    this.showOnboarding = true;
  }

  public ShowSupportModuleOnboarding(support: SupportModulePlan) {
    this.moduleHandling.activeOnboardingServiceModulePlan = support;
    this.showOnboarding = true;
  }


  public CloseModuleOnboarding() {
    this.showOnboarding = false;
    // CHECK IF FINISHED
    if (!this.moduleHandling.ClusterInitialized) {

      if (this.moduleHandling.activeOnboardingServiceModulePlan) {
        if (this.moduleHandling.activeOnboardingServiceModulePlan.checkIfConfigFinished()) {
          if (!this.moduleHandling.activeOnboardingServiceModulePlan.ConfigFinishedSended) {
            this.messageHandling.sendModuleInitFinished(uuidV4.v4(),
              this.moduleHandling.activeOnboardingServiceModulePlan.customerModule.SerialNumber);
            this.moduleHandling.activeOnboardingServiceModulePlan.ConfigFinishedSended = true;
            if (this.moduleHandling.sequenceNumberToOnboard === this.moduleHandling.activeOnboardingServiceModulePlan.seqno) {
              this.moduleHandling.sequenceNumberToOnboard++;
            }
          } else {
          }
        } else {
          console.error('module is not configurated');
        }
      } else if (this.moduleHandling.ActiveModule.checkIfConfigFinished()) {
        if (!this.moduleHandling.ActiveModule.ConfigFinishedSended) {
          this.messageHandling.sendModuleInitFinished(uuidV4.v4(), this.moduleHandling.ActiveModule.customerModule.SerialNumber);
          this.moduleHandling.ActiveModule.ConfigFinishedSended = true;
          if (this.moduleHandling.sequenceNumberToOnboard === this.moduleHandling.ActiveModule.seqno) {
            this.moduleHandling.sequenceNumberToOnboard++;
          }
        } else {
        }

        const support = this.moduleHandling.getSupportModuleForModulePlan(this.moduleHandling.ActiveModule);
        if (support) {
          if (!support.customerModule.FullyConfigured) {
            // IS LAST?
            support.setModuleSequenceNumber(this.moduleHandling.ActiveModule, this.moduleHandling.ActiveModule.seqno);

            if (support.isLastModule(this.moduleHandling.ActiveModule)) {
              setTimeout(() => {
                this.moduleHandling.activeOnboardingServiceModulePlan = support;
                this.showOnboarding = true;
                return;
              }, 2000);
            }
          }
        }
      } else {
        console.error('module is not configurated');
      }
      this.CheckIfInitFinished();
      this.drawElements();
    }

    this.moduleHandling.activeOnboardingServiceModulePlan = null;
  }

  drawElements(initial = false, redraw: boolean = true): void {

    if (redraw && this.moduleHandling.ActiveModule) {
      this.activemodule = this.moduleHandling.getModuleComponentFromActiveModulePlan();
    } else {
      this.activemodule = null;
    }

    this.toleranceX = 0;
    this.toleranceY = 0;
    this.moduleHandling.RemoveAllModules();
    this.container.clear();
    this.moduleHandling.SetProjectToCookie();

    this.modulezi = 800;

    if (!this.moduleHandling.ActiveProject) {
      return;
    }

    const initialModulePlan = this.moduleHandling.ActiveProject.Modules.find(module => module.isInialModule === true);

    this.minTop = Number.MAX_VALUE;
    this.minLeft = Number.MAX_VALUE;
    this.maxTop = Number.MIN_VALUE;
    this.maxLeft = Number.MIN_VALUE;

    if (initialModulePlan != null) {

      const factory = this.resolver.resolveComponentFactory(ModuleDesignComponent);
      const componentRef = this.container.createComponent(factory);
      const baseCoor = new Coordinates(0, 0);

      componentRef.instance.modulePlan = initialModulePlan;
      componentRef.instance.top = this.startModuleTop + 'px';
      componentRef.instance.leftval = this.startModuleLeft + 'px';
      componentRef.instance.width = this.moduleWidht + 'px';
      componentRef.instance.height = this.moduleHeight + 'px';
      componentRef.instance.leftKP = baseCoor.left + 'px';
      componentRef.instance.topKP = baseCoor.top + 'px';
      componentRef.instance.zindex = this.modulezi;
      componentRef.instance.numericalTop = this.startModuleTop;
      componentRef.instance.numericalLeft = this.startModuleLeft;
      componentRef.instance.RotationChanged.subscribe(this.RotationChanged.bind(this));
      componentRef.instance.ShowModuleOnboarding.subscribe(this.ShowModuleOnboarding.bind(this));
      componentRef.instance.ShowSupportModuleOnboarding.subscribe(this.ShowSupportModuleOnboarding.bind(this));
      componentRef.instance.rawWidth = 303 * (this.moduleWidht / 303); // this.moduleWidht * 0.9 - 70;
      componentRef.instance.rawHeight = 122 * (this.moduleHeight / 122);
      componentRef.instance.drawElements();

      this.CreateHitBox(this.startModuleTop, this.startModuleLeft, componentRef.instance);

      if (this.activemodule) {
        if (initialModulePlan.customerModule.CustomerModuleId === this.activemodule.modulePlan.customerModule.CustomerModuleId) {
          this.activemodule = componentRef.instance;
        }
      }

      let maxLeft = 0;
      let maxTop = 0;
      let minTop = 0;
      let minLeft = 0;

      this.modulezi--;

      const moduleLenght = 303 * (this.moduleWidht / 303);
      const moduleHeight = 122 * (this.moduleHeight / 122);
      const scaleFactor = 6058 / moduleLenght;
      const conveyorHeight = 4505 / scaleFactor;
      const shortconveyorHeight = (4505 / 2) / scaleFactor;
      const moduleStartPointLeft = this.moduleWidht - moduleLenght;

      let connectCenter = true; // if true -> Conveyor connected or could be connected
      let connectLeft = true;
      let connectRight = true;
      let maxCenterConveyor = 'S';
      let maxLeftConveyor = 'S';
      let maxRightConveyor = 'S';


      if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.center)) {
        if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.center).conveyor === null) {
          connectCenter = false;
        } else {
          if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.center).conveyor.EndPointType !== EndPointTypes.sink) {
          } else if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.center).conveyor.LenghtType !== ConveyorLenghtType.short) {
            maxCenterConveyor = 'L';
          }
        }
      } else if (!initialModulePlan.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.center)) {
        connectCenter = false;
      }

      if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left ||
        ex.moduleConnection === ModuleConnections.left_1 ||
        ex.moduleConnection === ModuleConnections.left_3 ||
        ex.moduleConnection === ModuleConnections.left_2)) {

        let oneOfThem = false;

        if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left) &&
          initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left).conveyor !== null) {
          oneOfThem = true;
          if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left).conveyor !== null &&
            initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left)
              .conveyor.LenghtType !== ConveyorLenghtType.short) {
            maxLeftConveyor = 'L';
          }

        }

        if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_1) &&
          initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_1).conveyor !== null) {
          oneOfThem = true;
          if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_1).conveyor !== null &&
            initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_1).conveyor.LenghtType !== ConveyorLenghtType.short) {
            maxLeftConveyor = 'L';
          }
        }
        if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_2) &&
          initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_2).conveyor !== null) {
          oneOfThem = true;
          if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_2).conveyor !== null &&
            initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_2).conveyor.LenghtType !== ConveyorLenghtType.short) {
            maxLeftConveyor = 'L';
          }
        }
        if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_3) &&
          initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_3).conveyor !== null) {
          oneOfThem = true;

          if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_3).conveyor !== null &&
            initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_3).conveyor.LenghtType !== ConveyorLenghtType.short) {
            maxLeftConveyor = 'L';
          }
        }

        if (!oneOfThem) {
          connectLeft = false;
        }
      } else {
        connectLeft = false;
      }

      if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right ||
        ex.moduleConnection === ModuleConnections.right_1 ||
        ex.moduleConnection === ModuleConnections.right_3 ||
        ex.moduleConnection === ModuleConnections.right_2)) {

        let oneOfThem = false;

        if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right) &&
          initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right).conveyor !== null) {
          oneOfThem = true;
          if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right).conveyor !== null &&
            initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right).conveyor.LenghtType !== ConveyorLenghtType.short) {
            maxRightConveyor = 'L';
          }
        }

        if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_1) &&
          initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_1).conveyor !== null) {
          oneOfThem = true;
          if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_1).conveyor !== null &&
            initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_1).conveyor.LenghtType !== ConveyorLenghtType.short) {
            maxRightConveyor = 'L';
          }
        }
        if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_2) &&
          initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_2).conveyor !== null) {
          oneOfThem = true;
          if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_2).conveyor !== null &&
            initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_2).conveyor.LenghtType !== ConveyorLenghtType.short) {
            maxRightConveyor = 'L';
          }
        }
        if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_3) &&
          initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_3).conveyor !== null) {
          oneOfThem = true;
          if (initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_3).conveyor !== null &&
            initialModulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_3).conveyor.LenghtType !== ConveyorLenghtType.short) {
            maxRightConveyor = 'L';
          }
        }

        if (!oneOfThem) {
          connectRight = false;
        }
      } else {
        connectRight = false;
      }
      switch (initialModulePlan.rotation) {
        case ModuleRotations.degree_0: {

          const centerConveyorLength = (maxCenterConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
          const leftConveyorLength = (maxLeftConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
          const rightConveyorLength = (maxRightConveyor === 'L' ? conveyorHeight : shortconveyorHeight);


          maxLeft = this.startModuleLeft + moduleLenght + centerConveyorLength + this.sinkSpace;
          maxTop = this.startModuleTop + moduleHeight + rightConveyorLength + this.sinkSpace;
          minTop = this.startModuleTop - leftConveyorLength - this.sinkSpace;
          minLeft = this.startModuleLeft;

          if (!connectCenter) {

            maxLeft -= (centerConveyorLength + this.sinkSpace);
          }
          if (!connectRight) {
            maxTop -= (rightConveyorLength + this.sinkSpace);
          }
          if (!connectLeft) {
            minTop += (leftConveyorLength + this.sinkSpace);
          }
          break;
        }
        case ModuleRotations.degree_180: {

          const centerConveyorLength = (maxCenterConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
          const leftConveyorLength = (maxLeftConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
          const rightConveyorLength = (maxRightConveyor === 'L' ? conveyorHeight : shortconveyorHeight);

          maxLeft = this.startModuleLeft + this.moduleWidht;
          maxTop = this.startModuleTop + this.moduleHeight + leftConveyorLength + this.sinkSpace;
          minTop = this.startModuleTop - rightConveyorLength - this.sinkSpace;
          minLeft = this.startModuleLeft + moduleStartPointLeft - centerConveyorLength - this.sinkSpace;

          if (!connectCenter) {
            minLeft += (centerConveyorLength + this.sinkSpace);
          }
          if (!connectRight) {
            minTop += (rightConveyorLength + this.sinkSpace);
          }
          if (!connectLeft) {
            maxTop -= (leftConveyorLength + this.sinkSpace);
          }

          break;
        }
        case ModuleRotations.degree_90: {

          const centerConveyorLength = (maxCenterConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
          const leftConveyorLength = (maxLeftConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
          const rightConveyorLength = (maxRightConveyor === 'L' ? conveyorHeight : shortconveyorHeight);

          let topOffset = conveyorHeight + this.sinkSpace;
          maxLeft = this.startModuleLeft + moduleLenght + centerConveyorLength + this.sinkSpace;
          maxTop = this.startModuleTop + moduleHeight + conveyorHeight + this.sinkSpace;
          minTop = this.startModuleTop - conveyorHeight - this.sinkSpace;
          minLeft = this.startModuleLeft;

          if (!connectCenter) {
            maxLeft -= (centerConveyorLength + this.sinkSpace);
          }
          if (!connectRight) {
            maxTop -= (conveyorHeight + this.sinkSpace);
          }
          if (!connectLeft) {
            minTop += (conveyorHeight + this.sinkSpace);
            topOffset = 0;
          }

          // ROTATE


          const lt = new SimplePoint(minLeft, minTop);
          const rb = new SimplePoint(maxLeft, maxTop);

          const centerPoint = new SimplePoint(minLeft + (moduleLenght / 2), minTop + (moduleHeight / 2) + topOffset);

          lt.rotatePoint(90, centerPoint);
          rb.rotatePoint(90, centerPoint);


          maxTop = rb.y;
          minTop = lt.y;

          maxLeft = lt.x;
          minLeft = rb.x;

          if (connectRight && maxRightConveyor === 'S') {
            minLeft += (rightConveyorLength);
          }
          if (connectLeft && maxLeftConveyor === 'S') {
            maxLeft -= (leftConveyorLength);
            topOffset = 0;
          }
          break;
        }
        case ModuleRotations.degree_270: {

          const centerConveyorLength = (maxCenterConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
          const leftConveyorLength = (maxLeftConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
          const rightConveyorLength = (maxRightConveyor === 'L' ? conveyorHeight : shortconveyorHeight);

          let topOffset = conveyorHeight + this.sinkSpace;
          maxLeft = this.startModuleLeft + moduleLenght + centerConveyorLength + this.sinkSpace;
          maxTop = this.startModuleTop + moduleHeight + conveyorHeight + this.sinkSpace;
          minTop = this.startModuleTop - conveyorHeight - this.sinkSpace;
          minLeft = this.startModuleLeft;

          if (!connectCenter) {
            maxLeft -= (centerConveyorLength + this.sinkSpace);
          }
          if (!connectRight) {
            maxTop -= (conveyorHeight + this.sinkSpace);
          }
          if (!connectLeft) {
            minTop += (conveyorHeight + this.sinkSpace);
            topOffset = 0;
          }

          // ROTATE


          const lt = new SimplePoint(minLeft, minTop);
          const rb = new SimplePoint(maxLeft, maxTop);

          const centerPoint = new SimplePoint(minLeft + (moduleLenght / 2), minTop + (moduleHeight / 2) + topOffset);

          lt.rotatePoint(-90, centerPoint);
          rb.rotatePoint(-90, centerPoint);


          minTop = rb.y;
          maxTop = lt.y;

          minLeft = lt.x;
          maxLeft = rb.x;

          if (connectRight && maxRightConveyor === 'S') {
            maxLeft -= (rightConveyorLength);
          }
          if (connectLeft && maxLeftConveyor === 'S') {
            minLeft += (leftConveyorLength);
            topOffset = 0;
          }

          break;
        }
      }

      // minTop =  minTop - 55;


      this.SetSizeValues(minTop, minLeft, maxTop, maxLeft, false);

      this.drawAdditionalModuls(factory, initialModulePlan, this.startModuleTop, this.startModuleLeft);


      if (this.activemodule && redraw) {

        if (this.moduleHandling.sequenceNumberToOnboard < this.activemodule.modulePlan.seqno) {
          const mdls = this.moduleHandling.ActiveProject.Modules.find(ex => ex.isInialModule === true);
          if (mdls) {
            const comp = this.moduleHandling.getModuleComponentFromModulePlan(mdls);
            if (comp) {
              this.moduleHandling.SetModuleActive(comp);
            }
          }
        } else {
          this.moduleHandling.SetModuleActive(this.activemodule);
        }
      }

      if (!this.calculateCheckSize()) {
        this.drawElements(redraw);
        return;
      }

      // this.moduleHandling.calculateMasterModule();
      this.moduleHandling.calculateMasterModuleByHops();
      this.moduleHandling.ReDraw();
      this.CreateContainer();


    }


  }

  CreateHitBox(top: number, left: number, module: ModuleDesignComponent, addon: boolean = false) {

    const elem = this.svgGroup.nativeElement;

    if (addon === false) {
      elem.innerHTML = '';
    }

    const total = HelperFunctions.CreateHitBox(top,
      left,
      module,
      this.moduleWidht,
      this.moduleHeight,
      this.collisionOffset,
      this.sinkSpace);

    elem.innerHTML += total;
    this.CheckCollision();
  }

  CreateContainer() {
    const elem = this.svgGroup.nativeElement;

    for (let i = elem.children.length - 1; i >= 0; i--) {
      let id = '';
      const pathe = elem.children.item(i) as SVGPathElement;

      if (!pathe) {
        const pathe2 = elem.children.item(i) as SVGTextElement;
        id = pathe2.getAttribute('class');
      } else {
        id = pathe.getAttribute('class');
      }

      if (id) {
        if (id.toString() === 'border') {
          elem.removeChild(elem.children.item(i));
        }
      }
    }

    if (this.moduleHandling.ActiveProject && this.moduleHandling.ActiveProject.Modules.length > 0) {

      const scale = 50 / this.sinkSpace;

      const widthInM = ((this.maxLeft - this.minLeft) * scale) / 50;
      const heightInM = ((this.maxTop - this.minTop) * scale) / 50;
      let drawLeft = this.minLeft - 60;

      if (drawLeft <= 0) {
        drawLeft = 30;
      }
      let drawBottom = this.maxTop + 60;
      if (drawBottom >= (window.innerHeight * 0.9)) {
        drawBottom = window.innerHeight * 0.9;
      }

      // VERTICAL LINE
      let path = 'M' + drawLeft + ',' + this.minTop + ' ' +
        drawLeft + ',' + this.maxTop +
        ' M' + (drawLeft - 10) + ',' + this.minTop + ' ' +
        (drawLeft + 10) + ',' + this.minTop +
        ' M' + (drawLeft - 10) + ',' + this.maxTop + ' ' +
        (drawLeft + 10) + ',' + this.maxTop;
      let total = '<path class="border" stroke-width="1" stroke="black" fill="red" fill-opacity="0.0" d="' + path + '"/>';
      elem.innerHTML += total;
      // VERTICAL TEXT
      let textV = (Math.round(heightInM * 10) / 10) + 'm';

      if (this.toleranceY > 0) {
        textV += ' ±' + this.toleranceY + 'm';
      }


      total = '<text class="border" x="' +
        (drawLeft - 10) +
        '" y="' +
        ((this.minTop + ((this.maxTop - this.minTop) / 2)) + 45) +
        '"  transform="rotate(-90, ' + (drawLeft - 10) +
        ', ' + ((this.minTop + ((this.maxTop - this.minTop) / 2)) +
          45) + ')" font-family="sans-serif" font-size="20px" fill="black">' +
        textV + '</text>';
      elem.innerHTML += total;

      // HORIZONTAL LINE
      path = 'M' + this.minLeft + ',' + drawBottom + ' ' +
        this.maxLeft + ',' + drawBottom +
        ' M' + this.minLeft + ',' + (drawBottom - 10) + ' ' +
        this.minLeft + ',' + (drawBottom + 10) +
        ' M' + this.maxLeft + ',' + (drawBottom - 10) + ' ' +
        this.maxLeft + ',' + (drawBottom + 10);

      total = '<path class="border" stroke-width="1" stroke="black" fill="black" fill-opacity="0.0" d="' + path + '"/>';
      elem.innerHTML += total;

      // HORIZONTAL TEXT

      textV = (Math.round(widthInM * 10) / 10) + 'm';

      if (this.toleranceX > 0) {
        textV += ' ±' + this.toleranceX + 'm';
      }

      total = '<text class="border" y="' + (drawBottom + 20) + '" x="' + ((this.minLeft + ((this.maxLeft - this.minLeft) / 2)) - 50) + '" font-family="sans-serif" font-size="20px" fill="black">' + textV + '</text>';
      elem.innerHTML += total;
    }
  }

  public CheckCollision() {

    const elem = this.svgGroup.nativeElement;

    this.collisionDetected = false;

    for (const e of elem.children) {
      const pathe = e as SVGPathElement;
      pathe.setAttribute('stroke', 'transparent');
      pathe.setAttribute('fill-opacity', '0');

    }

    let index = 0;

    for (const e of elem.children) {

      const pathe = e as SVGPathElement;
      const pathdclass = pathe.getAttribute('class');

      if (pathdclass && pathdclass.toString() === 'hitbox') {
        const pathed = pathe.getAttribute('d');
        const lineCollider = new LineCollider(pathe);
        for (let i = index + 1; i < elem.children.length; i++) {
          const pathcom = elem.children[i] as SVGPathElement;
          const pathcomd = pathcom.getAttribute('d');
          const pathcomclass = pathcom.getAttribute('class');

          if (pathcomclass && pathcomclass.toString() === 'hitbox') {
            if (pathcomd && pathed) {
              if (pathed.toString() !== pathcomd.toString()) {
                // CHECK FOR OVERLAP
                const lineCollider2 = new LineCollider(pathcom);
                if (lineCollider.collidesWith(lineCollider2)) {
                  this.collisionDetected = true;
                  pathe.setAttribute('stroke', 'red');
                  pathcom.setAttribute('stroke', 'red');
                  pathe.setAttribute('fill-opacity', '1');
                  pathcom.setAttribute('fill-opacity', '1');
                }
              }
            }
          }
        }
      }
      index++;
    }
  }

  calculateCheckSize(): boolean {
    const absHeight = Math.abs(this.minTop - this.maxTop) + 100;
    const absWidth = Math.abs(this.minLeft - this.maxLeft) + 100;

    if ((absHeight > window.innerHeight || absWidth > (window.innerWidth)) && this.moduleWidht > (171)) {
      // scale
      this.moduleWidht = this.moduleWidht * 0.75;
      this.moduleHeight = this.moduleHeight * 0.75;
      this.scaleFactor = this.scaleFactor * 0.75;
      this.sinkSpace = this.sinkSpace * 0.75;
      this.collisionOffset = this.collisionOffset * 0.75;
      this.lnDistance = 50 * this.scaleFactor;
      this.ShowBottom = false;
      this.ShowTop = false;
      this.ShowLeft = false;
      this.ShowRight = false;
      this.TopOffset = 0;
      this.LeftOffset = 0;
      this.CreateCounters();

      return false;
    } else if ((absHeight > window.innerHeight || absWidth > (window.innerWidth))) {
      if (absWidth > (window.innerWidth)) {
        this.ShowRight = true;
      }
      if (absHeight > window.innerHeight) {
        this.ShowBottom = true;
      }
    } else if (absHeight * (1.0 / 0.75) < window.innerHeight && absWidth * (1.0 / 0.75) < window.innerWidth && this.scaleFactor < 1) {
      this.moduleWidht = this.moduleWidht * (1.0 / 0.75);
      this.moduleHeight = this.moduleHeight * (1.0 / 0.75);
      this.scaleFactor = this.scaleFactor * (1.0 / 0.75);
      this.sinkSpace = this.sinkSpace * (1.0 / 0.75);
      this.collisionOffset = this.collisionOffset * (1.0 / 0.75);
      this.lnDistance = 50 * this.scaleFactor;
      this.ShowBottom = false;
      this.ShowTop = false;
      this.ShowLeft = false;
      this.ShowRight = false;
      this.TopOffset = 0;
      this.LeftOffset = 0;
      this.CreateCounters();
    } else {
      this.ShowBottom = false;
      this.ShowTop = false;
      this.ShowLeft = false;
      this.ShowRight = false;
      this.TopOffset = 0;
      this.LeftOffset = 0;
    }


    // calculate Start position first module
    // const startModuleLeftOld = this.startModuleLeft;
    // const startModuleTopOld = this.startModuleTop;
    // const maxWidth = window.innerWidth - 120;
    // const maxHeight = window.innerHeight;

    const mx = (window.innerWidth - 120) / 2;
    const my = window.innerHeight / 2;
    const Mx = (((this.maxLeft - this.minLeft)) / 2 + this.minLeft);
    const My = (((this.maxTop - this.minTop)) / 2 + this.minTop);

    const offsetMPx = mx - Mx;
    const offsetMPy = my - My;

    const offsetEPx = Mx - this.startModuleLeft;
    const offsetEPy = My - this.startModuleTop;

    const x1 = Mx + offsetMPx;
    const y1 = My + offsetMPy;

    let x2 = x1 - offsetEPx;
    let y2 = y1 - offsetEPy;

    if (x2 < 10) {
      x2 = 30;
    }

    if (y2 < 10) {
      y2 = 100;
    }

    if (this.LeftOffset !== 0) {
      x2 += this.LeftOffset;
      this.ShowLeft = this.LeftOffset <= -100;
    }

    if (this.TopOffset !== 0) {
      y2 += this.TopOffset;
      this.ShowTop = this.TopOffset <= -100;
    }

    if ((absHeight + this.TopOffset + 100) < window.innerHeight) {
      this.ShowBottom = false;
    }

    if ((this.minTop - this.TopOffset) < 0 && this.minTop < 30) {
      this.ShowTop = true;
    }

    if ((this.minLeft - this.LeftOffset) < 0 && this.minLeft < 30) {
      this.ShowLeft = true;
    }

    if ((absWidth + this.LeftOffset + 100) < window.innerWidth) {
      this.ShowRight = false;
    }
    // DRAW BOX
    // const elem = this.borderbox.nativeElement;
    // const path = 'M ' + this.minLeft + ',' + this.minTop + ' ' + this.maxLeft + ',' + this.minTop +
    // ' ' + this.maxLeft + ',' + this.maxTop + ' ' + this.minLeft + ',' + this.maxTop + 'z';
    // const total = '<path class="border" stroke-width="1" stroke="transparent" fill="transparent" fill-opacity="0.1" d="' + path + '"/>';
    // elem.innerHTML = total;
    if (Math.abs(this.startModuleTop - y2) > 10 || Math.abs(this.startModuleLeft - x2) > 10) {
      this.startModuleLeft = x2;
      this.startModuleTop = y2;
      return false;
    }
    return true;
  }

  SetSizeValues(minTop: number, minLeft: number, maxTop: number, maxLeft: number, addTolerance = true) {

    let addXTolerance = false;
    let addYTolerance = false;

    if (this.minLeft > minLeft) {
      addXTolerance = true;
      this.minLeft = minLeft;
    }

    if (this.minTop > minTop) {
      addYTolerance = true;
      this.minTop = minTop;
    }

    if (this.maxLeft < maxLeft) {
      addXTolerance = true;
      this.maxLeft = maxLeft;
    }

    if (this.maxTop < maxTop) {
      addYTolerance = true;
      this.maxTop = maxTop;
    }

    if (addTolerance) {
      if (addXTolerance) {
        this.toleranceX += 0.5;
      }

      if (addYTolerance) {
        this.toleranceY += 0.5;
      }
    }
  }

  drawAdditionalModuls(factory: ComponentFactory<ModuleDesignComponent>, moduleplan: ModulePlan, top: number, left: number): void {

    for (const con of moduleplan.connections) {
      if (con.conveyor !== null) {
        if (con.conveyor.EndPointType === EndPointTypes.module) {
          const module = con.conveyor.EndPoint;

          let UniqueId = module.CustomerModuleId;



          const planForModule: ModulePlan = this.moduleHandling.ActiveProject.Modules.find(modulep => modulep.customerModule.CustomerModuleId === UniqueId);

          if (planForModule != null) {
            let topNeu = 0;
            let leftNeu = 0;

            const moduleLenght = 303 * (this.moduleWidht / 303);
            const moduleHeight = 122 * (this.moduleHeight / 122);
            const scaleFactor = 6058 / moduleLenght;
            const conveyorHeight = (4505 - 500) / scaleFactor;
            const shortconveyorHeight = ((4505 / 2) - 500) / scaleFactor;
            const moduleStartPointLeft = 0;

            const baseCoor = this.GetBaseCoordinatesNew(top, left, con.conveyor, moduleplan, con.moduleConnection);
            const realCoor = this.GetConnectedModuleCoordinatesNew(con.moduleConnection, moduleplan.rotation, planForModule);

            topNeu = baseCoor.top - realCoor.top;
            leftNeu = baseCoor.left - realCoor.left;

            let connectCenter = true; // if true -> Conveyor connected or could be connected
            let connectLeft = true;
            let connectRight = true;

            let connectCenterModule = false;
            let connectLeftModule = false;
            let connectRightModule = false;
            let maxCenterConveyor = 'S';
            let maxLeftConveyor = 'S';
            let maxRightConveyor = 'S';

            if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.center)) {
              if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.center).conveyor === null) {
                connectCenter = false;
              } else {
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.center).conveyor.EndPointType !== EndPointTypes.sink) {
                  connectCenterModule = true;
                } else if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.center).conveyor.LenghtType !== ConveyorLenghtType.short) {
                  maxCenterConveyor = 'L';
                }
              }
            } else if (!planForModule.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.center)) {
              connectCenter = false;
            }

            if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left ||
              ex.moduleConnection === ModuleConnections.left_1 ||
              ex.moduleConnection === ModuleConnections.left_3 ||
              ex.moduleConnection === ModuleConnections.left_2)) {

              let oneOfThem = false;

              if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left) &&
                planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left).conveyor !== null) {
                oneOfThem = true;
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left).conveyor !== null &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left).conveyor.LenghtType !== ConveyorLenghtType.short) {
                  maxLeftConveyor = 'L';
                }
              }

              if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_1) &&
                planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_1).conveyor !== null) {
                oneOfThem = true;
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_1).conveyor !== null &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_1).conveyor.LenghtType !== ConveyorLenghtType.short) {
                  maxLeftConveyor = 'L';
                }
              }
              if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_2) &&
                planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_2).conveyor !== null) {
                oneOfThem = true;
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_2).conveyor !== null &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_2).conveyor.LenghtType !== ConveyorLenghtType.short) {
                  maxLeftConveyor = 'L';
                }
              }
              if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_3) &&
                planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_3).conveyor !== null) {
                oneOfThem = true;
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_3).conveyor !== null &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_3).conveyor.LenghtType !== ConveyorLenghtType.short) {
                  maxLeftConveyor = 'L';
                }
              }

              if (!oneOfThem) {
                connectLeft = false;
              } else {
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left) &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left).conveyor &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left).conveyor.EndPointType !== EndPointTypes.sink) {
                  connectLeftModule = true;
                }
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_1) &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_1).conveyor &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_1).conveyor.EndPointType !== EndPointTypes.sink) {
                  connectLeftModule = true;
                }
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_2) &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_2).conveyor &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_2).conveyor.EndPointType !== EndPointTypes.sink) {
                  connectLeftModule = true;
                }
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_3) &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_3).conveyor &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.left_3).conveyor.EndPointType !== EndPointTypes.sink) {
                  connectLeftModule = true;
                }
              }
            } else {
              connectLeft = false;
            }

            if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right ||
              ex.moduleConnection === ModuleConnections.right_1 ||
              ex.moduleConnection === ModuleConnections.right_3 ||
              ex.moduleConnection === ModuleConnections.right_2)) {

              let oneOfThem = false;

              if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right) &&
                planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right).conveyor !== null) {
                oneOfThem = true;
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right).conveyor !== null &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right).conveyor.LenghtType !== ConveyorLenghtType.short) {
                  maxRightConveyor = 'L';
                }
              }

              if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_1) &&
                planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_1).conveyor !== null) {
                oneOfThem = true;
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_1).conveyor !== null &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_1).conveyor.LenghtType !== ConveyorLenghtType.short) {
                  maxRightConveyor = 'L';
                }
              }
              if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_2) &&
                planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_2).conveyor !== null) {
                oneOfThem = true;
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_2).conveyor !== null &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_2).conveyor.LenghtType !== ConveyorLenghtType.short) {
                  maxRightConveyor = 'L';
                }
              }
              if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_3) &&
                planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_3).conveyor !== null) {
                oneOfThem = true;
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_3).conveyor !== null &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_3).conveyor.LenghtType !== ConveyorLenghtType.short) {
                  maxRightConveyor = 'L';
                }
              }

              if (!oneOfThem) {
                connectRight = false;
              } else {
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right) &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right).conveyor &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right).conveyor.EndPointType !== EndPointTypes.sink) {
                  connectRightModule = true;
                }
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_1) &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_1).conveyor &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_1).conveyor.EndPointType !== EndPointTypes.sink) {
                  connectRightModule = true;
                }
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_2) &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_2).conveyor &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_2).conveyor.EndPointType !== EndPointTypes.sink) {
                  connectRightModule = true;
                }
                if (planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_3) &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_3).conveyor &&
                  planForModule.connections.find(ex => ex.moduleConnection === ModuleConnections.right_3).conveyor.EndPointType !== EndPointTypes.sink) {
                  connectRightModule = true;
                }
              }
            } else {
              connectRight = false;
            }


            let moduleMinTop = 0;
            let moduleMaxTop = 0;
            let moduleMinLeft = 0;
            let moduleMaxLeft = 0;

            switch (planForModule.rotation) {
              case ModuleRotations.degree_0: {

                const centerConveyorLength = (maxCenterConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
                const leftConveyorLength = (maxLeftConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
                const rightConveyorLength = (maxRightConveyor === 'L' ? conveyorHeight : shortconveyorHeight);

                moduleMaxLeft = leftNeu + moduleLenght + centerConveyorLength + this.sinkSpace;
                moduleMaxTop = topNeu + moduleHeight + rightConveyorLength + this.sinkSpace;
                moduleMinTop = topNeu - leftConveyorLength - this.sinkSpace;
                moduleMinLeft = leftNeu;

                if (!connectCenter) {
                  moduleMaxLeft -= (centerConveyorLength + this.sinkSpace);
                }
                if (!connectRight) {
                  moduleMaxTop -= (rightConveyorLength + this.sinkSpace);
                }
                if (!connectLeft) {
                  moduleMinTop += (leftConveyorLength + this.sinkSpace);
                }
                break;
              }
              case ModuleRotations.degree_180 : {
                const centerConveyorLength = (maxCenterConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
                const leftConveyorLength = (maxLeftConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
                const rightConveyorLength = (maxRightConveyor === 'L' ? conveyorHeight : shortconveyorHeight);

                moduleMaxLeft = leftNeu + this.moduleWidht;
                moduleMaxTop = topNeu + this.moduleHeight + leftConveyorLength + this.sinkSpace;
                moduleMinTop = topNeu - rightConveyorLength - this.sinkSpace;
                moduleMinLeft = leftNeu + moduleStartPointLeft - centerConveyorLength - this.sinkSpace;

                if (!connectCenter) {
                  moduleMinLeft += (centerConveyorLength + this.sinkSpace);
                }
                if (!connectRight) {
                  moduleMinTop += (rightConveyorLength + this.sinkSpace);
                }
                if (!connectLeft) {
                  moduleMaxTop -= (leftConveyorLength + this.sinkSpace);
                }

                break;

              }
              case ModuleRotations.degree_270: {

                const centerConveyorLength = (maxCenterConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
                const leftConveyorLength = (maxLeftConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
                const rightConveyorLength = (maxRightConveyor === 'L' ? conveyorHeight : shortconveyorHeight);

                let topOffset = conveyorHeight + this.sinkSpace;
                moduleMaxLeft = leftNeu + moduleLenght + centerConveyorLength + this.sinkSpace;
                moduleMaxTop = topNeu + moduleHeight + conveyorHeight + this.sinkSpace;
                moduleMinTop = topNeu - conveyorHeight - this.sinkSpace;
                moduleMinLeft = leftNeu;

                if (!connectCenter) {
                  moduleMaxLeft -= (centerConveyorLength + this.sinkSpace);
                }
                if (!connectRight) {
                  moduleMaxTop -= (conveyorHeight + this.sinkSpace);
                }
                if (!connectLeft) {
                  moduleMinTop += (conveyorHeight + this.sinkSpace);
                  topOffset = 0;
                }

                // ROTATE
                const lt = new SimplePoint(moduleMinLeft, moduleMinTop);
                const rb = new SimplePoint(moduleMaxLeft, moduleMaxTop);
                const centerPoint = new SimplePoint(moduleMinLeft + (moduleLenght / 2), moduleMinTop + (moduleHeight / 2) + topOffset);
                lt.rotatePoint(-90, centerPoint);
                rb.rotatePoint(-90, centerPoint);
                moduleMinTop = rb.y;
                moduleMaxTop = lt.y;
                moduleMinLeft = lt.x;
                moduleMaxLeft = rb.x;

                if (connectRight && maxRightConveyor === 'S') {
                  moduleMaxLeft -= (rightConveyorLength);
                }
                if (connectLeft && maxLeftConveyor === 'S') {
                  moduleMinLeft += (leftConveyorLength);
                  topOffset = 0;
                }


                break;
              }
              case ModuleRotations.degree_90: {

                const centerConveyorLength = (maxCenterConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
                const leftConveyorLength = (maxLeftConveyor === 'L' ? conveyorHeight : shortconveyorHeight);
                const rightConveyorLength = (maxRightConveyor === 'L' ? conveyorHeight : shortconveyorHeight);

                let topOffset = conveyorHeight + this.sinkSpace;
                moduleMaxLeft = leftNeu + moduleLenght + centerConveyorLength + this.sinkSpace;
                moduleMaxTop = topNeu + moduleHeight + conveyorHeight + this.sinkSpace;
                moduleMinTop = topNeu - conveyorHeight - this.sinkSpace;
                moduleMinLeft = leftNeu;

                if (!connectCenter) {
                  moduleMaxLeft -= (centerConveyorLength + this.sinkSpace);
                }
                if (!connectRight) {
                  moduleMaxTop -= (conveyorHeight + this.sinkSpace);
                }
                if (!connectLeft) {
                  moduleMinTop += (conveyorHeight + this.sinkSpace);
                  topOffset = 0;
                }

                // ROTATE
                const lt = new SimplePoint(moduleMinLeft, moduleMinTop);
                const rb = new SimplePoint(moduleMaxLeft, moduleMaxTop);
                const centerPoint = new SimplePoint(moduleMinLeft + (moduleLenght / 2), moduleMinTop + (moduleHeight / 2) + topOffset);
                lt.rotatePoint(90, centerPoint);
                rb.rotatePoint(90, centerPoint);
                moduleMaxTop = rb.y;
                moduleMinTop = lt.y;
                moduleMaxLeft = lt.x;
                moduleMinLeft = rb.x;

                if (connectRight && maxRightConveyor === 'S') {
                  moduleMinLeft += (rightConveyorLength);
                }
                if (connectLeft && maxLeftConveyor === 'S') {
                  moduleMaxLeft -= (leftConveyorLength);
                  topOffset = 0;
                }

                break;
              }
            }


            this.SetSizeValues(moduleMinTop, moduleMinLeft, moduleMaxTop, moduleMaxLeft);
            const componentRef = this.container.createComponent(factory);
            componentRef.instance.modulePlan = planForModule;
            componentRef.instance.top = topNeu + 'px';
            componentRef.instance.leftval = leftNeu + 'px';
            componentRef.instance.numericalTop = topNeu;
            componentRef.instance.numericalLeft = leftNeu;
            componentRef.instance.width = this.moduleWidht + 'px';
            componentRef.instance.height = this.moduleHeight + 'px';
            componentRef.instance.zindex = this.modulezi;
            componentRef.instance.RotationChanged.subscribe(this.RotationChanged.bind(this));
            componentRef.instance.ShowModuleOnboarding.subscribe(this.ShowModuleOnboarding.bind(this));
            componentRef.instance.ShowSupportModuleOnboarding.subscribe(this.ShowSupportModuleOnboarding.bind(this));
            componentRef.instance.rawWidth = 303 * (this.moduleWidht / 303);
            componentRef.instance.rawHeight = 122 * (this.moduleHeight / 122);
            componentRef.instance.drawElements();

            this.CreateHitBox(topNeu, leftNeu, componentRef.instance, true);
            // this.CreateContainerPerModule(moduleMinLeft, moduleMinTop, moduleMaxLeft, moduleMaxTop);

            if (this.activemodule) {
              if (planForModule.customerModule.CustomerModuleId === this.activemodule.modulePlan.customerModule.CustomerModuleId) {
                this.activemodule = componentRef.instance;
              }
            }

            this.modulezi--;

            this.drawAdditionalModuls(factory, planForModule, topNeu, leftNeu);

          }
        }
      }
    }
  }

  public setNextModuleRotations(modulePlan: ModulePlan) {
    for (const conv of modulePlan.connections) {
      if (conv.conveyor !== null) {
        if (conv.conveyor.EndPointType === EndPointTypes.module) {
          const module = conv.conveyor.EndPoint;
          if (module !== null) {
            let UniqueId = module.CustomerModuleId;

            const rotateModulePln = this.moduleHandling.ActiveProject.Modules.find(ex => ex.customerModule.CustomerModuleId === UniqueId);
            if (rotateModulePln !== null) {
              if (modulePlan.lastRotation === 1) {
                rotateModulePln.rotateClockwise();
              } else if (modulePlan.lastRotation === -1) {
                rotateModulePln.rotateCounterClockwise();
              }
              this.setNextModuleRotations(rotateModulePln);
            }
          }
        }
      }
    }
  }

  GetBaseCoordinatesNew(top: number, left: number, conveyor: Conveyor,
                        modulePlan: ModulePlan, connectionPoint: ModuleConnections): Coordinates {

    const moduleLenght = 303 * (this.moduleWidht / 303);
    const moduleHeight = 122 * (this.moduleHeight / 122);
    const scaleFactor = 6058 / moduleLenght;
    const conveyorHeight = (4505 - 500) / scaleFactor;
    const moduleStartPoint = 0;
    const conveyorOffset = 0;
    const middlePointOffsetTop = +this.moduleHeight / 2;
    const middlePointOffsetLeft = +this.moduleWidht / 2;

    const bowmessure = ((2 * Math.PI) / 360) * modulePlan.rotation;
    let baseTop = 0;
    let baseLeft = 0;

    let dl = 0;

    switch (connectionPoint) {
      case ModuleConnections.left:
      case ModuleConnections.right: {
        dl = (5319 / scaleFactor) / moduleLenght; // 0.75;
        break;
      }
      case ModuleConnections.left_1:
      case ModuleConnections.right_1: {
        dl = (4319 / scaleFactor) / moduleLenght; // 0.65;
        break;
      }
      case ModuleConnections.left_2:
      case ModuleConnections.right_2: {
        dl = (5319 / scaleFactor) / moduleLenght; // 0.85;
        break;
      }
      case ModuleConnections.left_3:
      case ModuleConnections.right_3: {
        dl = (2220 / scaleFactor) / moduleLenght; // 0.85;
        break;
      }
    }

    switch (connectionPoint) {
      case ModuleConnections.left:
      case ModuleConnections.left_1:
      case ModuleConnections.left_3:
      case ModuleConnections.left_2: {

        baseTop = -(moduleHeight / 2 + conveyorHeight - conveyorOffset);
        baseLeft = -this.moduleWidht / 2 + moduleStartPoint + moduleLenght * dl;

        break;
      }
      case ModuleConnections.center: {

        baseTop = 0;
        baseLeft = -this.moduleWidht / 2 + moduleStartPoint + moduleLenght + conveyorHeight - conveyorOffset;

        break;
      }
      case ModuleConnections.right:
      case ModuleConnections.right_1:
      case ModuleConnections.right_3:
      case ModuleConnections.right_2: {

        baseTop = +(moduleHeight / 2 + conveyorHeight - conveyorOffset);
        baseLeft = -this.moduleWidht / 2 + moduleStartPoint + moduleLenght * dl;

        break;
      }

    }

    const newTop = top + (baseTop * Math.cos(bowmessure) + baseLeft * Math.sin(bowmessure)) + middlePointOffsetTop;
    const newLeft = left + (baseLeft * Math.cos(bowmessure) - baseTop * Math.sin(bowmessure)) + middlePointOffsetLeft;

    return new Coordinates(newTop, newLeft);

  }

  GetConnectedModuleCoordinatesNew(connectionPoint: ModuleConnections,
                                   baseRotation: ModuleRotations,
                                   connectedModulePlan: ModulePlan
  ): Coordinates {

    let rot = 0;

    switch (connectionPoint) {
      case ModuleConnections.left:
      case ModuleConnections.left_1:
      case ModuleConnections.left_3:
      case ModuleConnections.left_2: {

        switch (baseRotation) {
          case ModuleRotations.degree_0: {
            rot = 90;

            if (connectedModulePlan.rotation === ModuleRotations.degree_90) {
              connectedModulePlan.rotation = ModuleRotations.degree_180;

            }
            break;
          }
          case ModuleRotations.degree_90: {
            rot = 0;
            if (connectedModulePlan.rotation === ModuleRotations.degree_180) {
              connectedModulePlan.rotation = ModuleRotations.degree_270;
            }
            break;
          }
          case ModuleRotations.degree_180: {
            rot = 270;
            if (connectedModulePlan.rotation === ModuleRotations.degree_270) {
              connectedModulePlan.rotation = ModuleRotations.degree_0;
            }
            break;
          }
          case ModuleRotations.degree_270: {
            rot = 180;
            if (connectedModulePlan.rotation === ModuleRotations.degree_0) {
              connectedModulePlan.rotation = ModuleRotations.degree_90;
            }
            break;
          }
        }
        break;
      }
      case ModuleConnections.right:
      case ModuleConnections.right_1:
      case ModuleConnections.right_3:
      case ModuleConnections.right_2: {

        switch (baseRotation) {
          case ModuleRotations.degree_0: {
            rot = 270;
            if (connectedModulePlan.rotation === ModuleRotations.degree_270) {
              connectedModulePlan.rotation = ModuleRotations.degree_0;
            }
            break;
          }
          case ModuleRotations.degree_90: {
            rot = 0;
            if (connectedModulePlan.rotation === ModuleRotations.degree_0) {
              connectedModulePlan.rotation = ModuleRotations.degree_90;
            }
            break;
          }
          case ModuleRotations.degree_180: {
            rot = 90;
            if (connectedModulePlan.rotation === ModuleRotations.degree_90) {
              connectedModulePlan.rotation = ModuleRotations.degree_180;
            }
            break;
          }
          case ModuleRotations.degree_270: {
            rot = 180;
            if (connectedModulePlan.rotation === ModuleRotations.degree_180) {
              connectedModulePlan.rotation = ModuleRotations.degree_270;
            }
            break;
          }
        }
        break;
      }
      case ModuleConnections.center: {

        switch (baseRotation) {
          case ModuleRotations.degree_0: {
            rot = 0;
            if (connectedModulePlan.rotation === ModuleRotations.degree_180) {
              connectedModulePlan.rotation = ModuleRotations.degree_270;
            }
            break;
          }
          case ModuleRotations.degree_90: {
            rot = 90;
            if (connectedModulePlan.rotation === ModuleRotations.degree_270) {
              connectedModulePlan.rotation = ModuleRotations.degree_0;
            }
            break;
          }
          case ModuleRotations.degree_180: {
            rot = 180;
            if (connectedModulePlan.rotation === ModuleRotations.degree_0) {
              connectedModulePlan.rotation = ModuleRotations.degree_90;
            }
            break;
          }
          case ModuleRotations.degree_270: {
            rot = 270;
            if (connectedModulePlan.rotation === ModuleRotations.degree_90) {
              connectedModulePlan.rotation = ModuleRotations.degree_180;
            }
            break;
          }
        }
        break;
      }
    }
    const moduleLenght = 303 * (this.moduleWidht / 303);
    const moduleHeight = 122 * (this.moduleHeight / 122);
    const moduleStartPoint = 0;
    const middlePointOffsetTop = this.moduleHeight / 2;
    const middlePointOffsetLeft = this.moduleWidht / 2;

    const x1Top = -moduleHeight / 2;
    const x1Left = -(this.moduleWidht / 2 - moduleStartPoint - moduleHeight / 2);
    const x2Top = 0;
    const x2Left = -(this.moduleWidht / 2 - moduleStartPoint);
    const x3Top = (moduleHeight / 2);
    const x3Left = -(this.moduleWidht / 2 - moduleStartPoint - moduleHeight / 2);
    let baseTop = 0;
    let baseLeft = 0;

    switch (connectionPoint) {
      case ModuleConnections.left:
      case ModuleConnections.left_1:
      case ModuleConnections.left_3:
      case ModuleConnections.left_2: {
        switch (baseRotation) {
          case ModuleRotations.degree_0: {
            switch (connectedModulePlan.rotation) {
              case ModuleRotations.degree_0: {
                baseTop = x3Top;
                baseLeft = x3Left;
                break;
              }
              case ModuleRotations.degree_180: {
                baseTop = x1Top;
                baseLeft = x1Left;
                break;
              }
              case ModuleRotations.degree_270: {
                baseTop = x2Top;
                baseLeft = x2Left;
                break;
              }
            }
            break;
          }
          case ModuleRotations.degree_90: {
            switch (connectedModulePlan.rotation) {
              case ModuleRotations.degree_0: {
                baseTop = x2Top;
                baseLeft = x2Left;
                break;
              }
              case ModuleRotations.degree_90: {
                baseTop = x3Top;
                baseLeft = x3Left;
                break;
              }
              case ModuleRotations.degree_270: {
                baseTop = x1Top;
                baseLeft = x1Left;
                break;
              }
            }
            break;
          }
          case ModuleRotations.degree_180: {
            switch (connectedModulePlan.rotation) {
              case ModuleRotations.degree_0: {
                baseTop = x1Top;
                baseLeft = x1Left;
                break;
              }
              case ModuleRotations.degree_90: {
                baseTop = x2Top;
                baseLeft = x2Left;
                break;
              }
              case ModuleRotations.degree_180: {
                baseTop = x3Top;
                baseLeft = x3Left;
                break;
              }
            }
            break;
          }
          case ModuleRotations.degree_270: {
            switch (connectedModulePlan.rotation) {
              case ModuleRotations.degree_90: {
                baseTop = x1Top;
                baseLeft = x1Left;
                break;
              }
              case ModuleRotations.degree_180: {
                baseTop = x2Top;
                baseLeft = x2Left;
                break;
              }
              case ModuleRotations.degree_270: {
                baseTop = x3Top;
                baseLeft = x3Left;
                break;
              }
            }
            break;
          }
        }
        break;
      }
      case ModuleConnections.center: {
        switch (baseRotation) {
          case ModuleRotations.degree_0: {
            switch (connectedModulePlan.rotation) {
              case ModuleRotations.degree_0: {
                baseTop = x2Top;
                baseLeft = x2Left;
                break;
              }
              case ModuleRotations.degree_90: {
                baseTop = x3Top;
                baseLeft = x3Left;
                break;
              }
              case ModuleRotations.degree_270: {
                baseTop = x1Top;
                baseLeft = x1Left;
                break;
              }
            }
            break;
          }
          case ModuleRotations.degree_90: {
            switch (connectedModulePlan.rotation) {
              case ModuleRotations.degree_0: {
                baseTop = x1Top;
                baseLeft = x1Left;
                break;
              }
              case ModuleRotations.degree_90: {
                baseTop = x2Top;
                baseLeft = x2Left;
                break;
              }
              case ModuleRotations.degree_180: {
                baseTop = x3Top;
                baseLeft = x3Left;
                break;
              }
            }
            break;
          }
          case ModuleRotations.degree_180: {
            switch (connectedModulePlan.rotation) {
              case ModuleRotations.degree_90: {
                baseTop = x1Top;
                baseLeft = x1Left;
                break;
              }
              case ModuleRotations.degree_180: {
                baseTop = x2Top;
                baseLeft = x2Left;
                break;
              }
              case ModuleRotations.degree_270: {
                baseTop = x3Top;
                baseLeft = x3Left;
                break;
              }
            }
            break;
          }
          case ModuleRotations.degree_270: {
            switch (connectedModulePlan.rotation) {
              case ModuleRotations.degree_0: {
                baseTop = x3Top;
                baseLeft = x3Left;
                break;
              }
              case ModuleRotations.degree_180: {
                baseTop = x1Top;
                baseLeft = x1Left;
                break;
              }
              case ModuleRotations.degree_270: {
                baseTop = x2Top;
                baseLeft = x2Left;
                break;
              }
            }
            break;
          }
        }
        break;
      }
      case ModuleConnections.right:
      case ModuleConnections.right_1:
      case ModuleConnections.right_3:
      case ModuleConnections.right_2: {
        switch (baseRotation) {
          case ModuleRotations.degree_0: {
            switch (connectedModulePlan.rotation) {
              case ModuleRotations.degree_0: {
                baseTop = x1Top;
                baseLeft = x1Left;
                break;
              }
              case ModuleRotations.degree_90: {
                baseTop = x2Top;
                baseLeft = x2Left;
                break;
              }
              case ModuleRotations.degree_180: {
                baseTop = x3Top;
                baseLeft = x3Left;
                break;
              }
            }
            break;
          }
          case ModuleRotations.degree_90: {
            switch (connectedModulePlan.rotation) {
              case ModuleRotations.degree_90: {
                baseTop = x1Top;
                baseLeft = x1Left;
                break;
              }
              case ModuleRotations.degree_180: {
                baseTop = x2Top;
                baseLeft = x2Left;
                break;
              }
              case ModuleRotations.degree_270: {
                baseTop = x3Top;
                baseLeft = x3Left;
                break;
              }
            }
            break;
          }
          case ModuleRotations.degree_180: {
            switch (connectedModulePlan.rotation) {
              case ModuleRotations.degree_0: {
                baseTop = x3Top;
                baseLeft = x3Left;
                break;
              }
              case ModuleRotations.degree_270: {
                baseTop = x2Top;
                baseLeft = x2Left;
                break;
              }
              case ModuleRotations.degree_180: {
                baseTop = x1Top;
                baseLeft = x1Left;
                break;
              }
            }
            break;
          }
          case ModuleRotations.degree_270: {
            switch (connectedModulePlan.rotation) {
              case ModuleRotations.degree_90: {
                baseTop = x3Top;
                baseLeft = x3Left;
                break;
              }
              case ModuleRotations.degree_0: {
                baseTop = x2Top;
                baseLeft = x2Left;
                break;
              }
              case ModuleRotations.degree_270: {
                baseTop = x1Top;
                baseLeft = x1Left;
                break;
              }
            }
            break;
          }
        }
      }
    }

    let resultRot = 0;

    switch (connectedModulePlan.rotation) {
      case ModuleRotations.degree_0: {
        resultRot = 0;
        break;
      }
      case ModuleRotations.degree_90: {
        resultRot = 270;

        break;
      }
      case ModuleRotations.degree_180: {
        resultRot = 180;
        break;
      }
      case ModuleRotations.degree_270: {
        resultRot = 90;
        break;
      }
    }

    const bowmessure = ((2 * Math.PI) / 360) * (connectedModulePlan.rotation);

    // baseTop = this.moduleHeight / 2;
    // baseLeft = this.moduleWidht / 2;
    const newTop = (baseTop * Math.cos(bowmessure) + baseLeft * Math.sin(bowmessure)) + middlePointOffsetTop;
    const newLeft = (baseLeft * Math.cos(bowmessure) - baseTop * Math.sin(bowmessure)) + middlePointOffsetLeft;


    return new Coordinates(newTop, newLeft);

  }

  RotationChanged(modulePlan: ModulePlan) {
    const modulepl = this.moduleHandling.ActiveProject.Modules.find(ex => ex.customerModule.CustomerModuleId === modulePlan.customerModule.CustomerModuleId);
    if (modulepl != null) {

      modulepl.rotation = modulePlan.rotation;
      this.moduleHandling.ActiveProject.Modules = this.moduleHandling.ActiveProject.Modules.filter(ex =>
        ex.customerModule.CustomerModuleId !== modulePlan.customerModule.CustomerModuleId);
      this.moduleHandling.ActiveProject.Modules.push(modulepl);

      // Rotate next one to
      this.setNextModuleRotations(modulePlan);


      this.Reset();
      this.drawElements();
    }
  }

  // ACTIONS

  public OpenSupportModuleSettings() {

    const supportModule = this.moduleHandling.getSupportModuleForModulePlan(this.moduleHandling.ActiveModule);

    if (supportModule) {

      if (this.moduleHandling.CheckIfModuleInitFinished()) {
        if (this.clusterOnboarding.ClusterInitialized) {

          this.monitoringMsgId = uuidV4.v4();
          this.messageHandling.subscribeModuleMonitoring(this.monitoringMsgId,
            supportModule.customerModule.SerialNumber);
          this.ShowModuleSettings = true;
        } else {
          if (!this._clusterInitOngoing && !this.InventoryOnboardingRequested) {
            // CLUSTER INIT FAILED
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'loginDialogGray';
            dialogConfig.data =
              this.translate.GetTranslation('MESSAGEBOX.CONTENT.RETRYCLUSTERONBOARDING');
            const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(result => {
              if (result === true) {
                this._clusterInitOngoing = true;
                const hdnl = setTimeout(() => {
                  const dialogConfig2 = new MatDialogConfig();
                  dialogConfig2.disableClose = true;
                  dialogConfig2.autoFocus = true;
                  dialogConfig2.panelClass = 'loginDialogGray';
                  const dialogRef2 = this.dialog.open(ClusterOnboardingComponent, dialogConfig);
                  dialogRef2.afterClosed().subscribe(() => {
                    this._clusterInitOngoing = false;
                  });
                  clearTimeout(hdnl);
                }, 1000);
              }
            });

          }
        }
      }
    }
  }

  public OpenModuleSettings() {

    if (this.moduleHandling.CheckIfModuleInitFinished()) {
      if (this.clusterOnboarding.ClusterInitialized) {
        this.monitoringMsgId = uuidV4.v4();
        this.messageHandling.subscribeModuleMonitoring(this.monitoringMsgId,
          this.moduleHandling.ActiveModule.customerModule.SerialNumber);
        this.ShowModuleSettings = true;
      } else {
        if (!this._clusterInitOngoing && !this.InventoryOnboardingRequested) {
          // CLUSTER INIT FAILED
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'loginDialogGray';
          dialogConfig.data =
            this.translate.GetTranslation('MESSAGEBOX.CONTENT.RETRYCLUSTERONBOARDING');
          const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
              this._clusterInitOngoing = true;
              const hdnl = setTimeout(() => {
                const dialogConfig2 = new MatDialogConfig();
                dialogConfig2.disableClose = true;
                dialogConfig2.autoFocus = true;
                dialogConfig2.panelClass = 'loginDialogGray';
                const dialogRef2 = this.dialog.open(ClusterOnboardingComponent, dialogConfig);
                dialogRef2.afterClosed().subscribe(() => {
                  this._clusterInitOngoing = false;
                });
                clearTimeout(hdnl);
              }, 1000);
            }
          });

        }
      }
    }


  }

  public CloseModuleSettings() {
    this.ShowModuleSettings = false;
    this.moduleHandling.SupportSettingsToShow = false;
    this.messageHandling.unsubscribeModuleMonitoring(this.monitoringMsgId,
      this.moduleHandling.ActiveModule.customerModule.SerialNumber);
    this.monitoringMsgId = null;
  }

  public clickOnPlate($event) {

    if (this.dialogOpen) {
      return;
    }

    if (!this.moduleHandling.ActiveProject) {
      this.LoadProject();
    } else {
      if ($event.target.className.toString() === 'designplate') {
        this.moduleHandling.SetModuleInactive(null);
      }
    }
  }

  Reset() {
    // this.currentStoredProject = new StoredRecipes();
    this.startModuleTop = 0;
    this.startModuleLeft = 0;
    this.minTop = window.innerHeight;
    this.minLeft = window.innerWidth;
    this.maxLeft = 0;
    this.sinkSpace = 50;
    this.collisionOffset = 5;
    this.maxTop = 0;
    this.moduleWidht = 303;
    this.moduleHeight = 122;
    this.scaleFactor = 1;
    this.lnDistance = 50;
    this.ShowBottom = false;
    this.ShowTop = false;
    this.ShowLeft = false;
    this.ShowRight = false;
    this.TopOffset = 0;
    this.LeftOffset = 0;
    this.CreateCounters();

    const elem = this.svgGroup.nativeElement;
    elem.innerHTML = '';
  }

  public SaveProject() {
    // dialog
    if (this.moduleHandling.ActiveProject !== null && this.moduleHandling.ActiveProject !== undefined) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data = this.moduleHandling.ActiveProject;
      const dialogRef = this.dialog.open(SaveProjectDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        if (data) {

          this.moduleHandling.ActiveProject = data as Project;
          this.moduleHandling.SetProjectToCookie();
        }
      });
    }
  }

  public LoadProject() {
    this.showLoadRecipe = true;
  }

  public sendRecipe() {
    if (this.moduleHandling.ActiveProject) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data = this.moduleHandling.ActiveProject;
      const dialogRef = this.dialog.open(SendRecipeComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(() => {
        // if (data !== undefined && data.recipe !== undefined) {
        // }
      });
    }
  }

  // OTHERS
  public GetSvgWidth() {
    return this.maxWidth + 'px';
  }

  public GetSvgHeight() {
    return this.maxHeight + 'px';
  }

  public CreateCounters() {
    this.horizontals = [];
    this.verticals = [];
    for (let i = 0; i < this.maxWidth; i = i + Math.round(this.lnDistance)) {
      this.verticals.push(i);
    }

    for (let i = 0; i < this.maxHeight; i = i + Math.round(this.lnDistance)) {
      this.horizontals.push(i);
    }
  }

  public GetPathVertical(pos): string {
    return 'M ' + pos + ',0 V ' + this.maxHeight;
  }

  public GetPathHorizontal(pos): string {
    return 'M 0,' + pos + ' H ' + this.maxWidth;
  }

  public OpenDemoModuleSettings() {
    if (this.moduleHandling.ActiveModule) {
      this.ShowModuleSettings = true;
    }
  }

  public CloseLoadRecipe(project: Project) {
    this.showLoadRecipe = false;
    if (project) {
      // LOAD IT

      if (project.valid === false) {
        this.moduleHandling.ActiveProject = project;
        const result = this.moduleHandling.ValidateProject();
        if (result.length > 0) {
          this.moduleHandling.ActiveProject = null;
        } else {
          project.valid = true;
        }
      }

      this.clusterOnboarding.resetOnboarding();
      this.moduleHandling.ActiveProject = project;
      this.moduleHandling.SetProjectToCookie();
      // RECHECK VALIDATION
      const validate = this.moduleHandling.ValidateProject();
      if (validate.length > 0) {
        // MESSAGE BOX NOT VALID
        const header = this.translate.GetTranslation('MESSAGEBOX.HEADERS.RECIPENOTVALID');
        const message = this.translate.GetTranslation('MESSAGEBOX.CONTENT.RECIPENOTVALID');

        const dialogRef2 = this.dialog.open(ConfirmMessageBoxComponent,
          {panelClass: 'panelclass', data: {header: header, content: message}});

        dialogRef2.afterClosed().subscribe(() => {
          this.moduleHandling.DeleteProject();
          this.Reset();

          this.drawElements();
          return;
        });
      } else {
        this.drawElements();
      }


    }
  }

}
