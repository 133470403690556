<section class="flex-row">
  <div class="line"></div>
  <div class="content flex-col stretch-self">
    <div class="header flex-row start-self">
      <p class="headerp">{{'COMPONENTS.MODULECATALOG.TITLE' | i18next }}</p>
      <div class = "flex-1 justify-end center-self">
        <button mat-button (click)="CloseClick()">
          <img class="invertSVG" src="/assets/general/close.svg">
        </button>
      </div>
    </div>
    <!-- NotConnected!-->
    <div class="flex-row" *ngIf="activeModule">
      <div class="sink flex-row">
        <div class="notconnecteddiv flex-col" (click)="ModuleSelected(null)">
          <img src="/assets/senke.svg">
          <p class="notcontext">{{'COMPONENTS.MODULECATALOG.SINK' | i18next }}</p>
        </div>
      </div>

      <div class="notconnected flex-row">
        <div (click)="NotConnectedSelected()" class="centerIcon flex-col">
          <img src="/assets/general/disconnected_icon.svg">
          <p class="notcontext">{{'COMPONENTS.MODULECATALOG.NOTCONNECTED' | i18next }}</p>
        </div>
      </div>

      <div class="notconnected flex-row" *ngIf="removeable">
        <div (click)="RemoveConnection()" class="centerIcon flex-col">
          <img src="/assets/general/remove_icon.svg">
          <p class="notcontext">{{'COMPONENTS.MODULECATALOG.REMOVECONNECTION' | i18next }}</p>
        </div>
      </div>
    </div>

    <div class="downscrollarrow flex-col center-self">
      <button mat-button class="arrowbtn" (click)="ScrollUp()" *ngIf="UpScrolling()">
        <svg class="svgarrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.56 4.24">
          <defs><style>.cls-1{fill:white;}</style></defs>
          <title>Element 34</title>
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Menu">
              <path class="cls-1" d="m 7.155861,0.7077706 a 0.23335842,0.19198841 0 0 1 0.2083558,0 l 5.9673082,2.427282 -0.27503,0.459401 -5.7422836,-2.3312879 a 0.12501344,0.10285094 0 0 0 -0.108345,0 l -5.7339493,2.3312879 -0.27503,-0.459401 z"/>
            </g>
          </g>
        </svg>
      </button>
    </div>

    <div class="deviceContainer flex-col" #deviceContainer >
      <div class="flex-col" *ngIf="OwnModules.length > 0">
        <div class="moduleheader flex-row">
          <p>{{'COMPONENTS.MODULECATALOG.OWNMODULES' | i18next }}</p>
          <img class="invertSVG"  *ngIf="!OwnVisible" src="/assets/general/arrow_right_open.svg" (click)="OwnVisible = true">
          <img *ngIf="OwnVisible" src="/assets/general/arrow_down.svg" (click)="OwnVisible = false">
        </div>
        <app-module-catalog-row *ngIf="OwnVisible" [customerModule]="OwnModules" (CustomerModuleSelected)="CustomerModuleSelected($event)" ></app-module-catalog-row>
      </div>
      <div *ngIf="usermanagement.currentUser && usermanagement.currentUser.UserWards">
        <div *ngFor="let ward of usermanagement.currentUser.UserWards">
          <div class="flex-col" *ngIf="GetOwnCustomerModulesByWard(ward.Id).length > 0">
            <div class="moduleheader flex-row" (click)="OwnVisible = !OwnVisible">
              <p>{{ward.Name}}</p>
              <img class="invertSVG"  *ngIf="!OwnVisible" src="/assets/general/arrow_right_open.svg">
              <img *ngIf="OwnVisible" src="/assets/general/arrow_down.svg">
            </div>
            <app-module-catalog-row *ngIf="OwnVisible" [customerModule]="GetOwnCustomerModulesByWard(ward.Id)" (CustomerModuleSelected)="CustomerModuleSelected($event)" ></app-module-catalog-row>
          </div>
        </div>
      </div>
      <!--LINETEC!-->

    </div>

    <div class="downscrollarrow flex-col center-self">
      <button mat-button class="arrowbtn" (click)="ScrollDown()" *ngIf="DownScrolling()">
        <svg class="svgarrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.56 4.24">
          <defs><style>.cls-1{fill:white;}</style></defs>
          <title>Element 34</title>
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Menu">
              <path class="cls-1" d="m 7.3725511,3.5742601 a 0.23335842,0.19198841 0 0 1 -0.2083558,0 L 1.1968871,1.1469781 1.4719167,0.68757717 7.2142007,3.018865 a 0.12501344,0.10285094 0 0 0 0.108345,0 l 5.7339493,-2.33128783 0.27503,0.45940093 z"/>
            </g>
          </g>
        </svg>
      </button>
    </div>
  </div>
</section>
