<div class="dlg">
  <div class="header flex-row stretch-row">
    <h2  class="uppercaseText center-self" mat-dialog-title>{{'DIALOGS.OFFLINEUSER.TITLE' | i18next }}</h2>
    <div class = "flex-1 justify-end center-self">
      <button mat-button (click)="CloseDialog()">
        <img class="invertedWhite" src="assets/general/close.svg">
      </button>
    </div>
  </div>

  <mat-dialog-content [formGroup]="pinForm" class="flex-col">

    <mat-form-field>
      <input autocomplete="off" minlength="6" maxlength="6" pattern="^([0-9]{6})$" type="password" matInput name="peter" placeholder="{{'COMMON.PIN' | i18next }}" formControlName="pin" required [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-hint *ngIf="!passwordInput.value">{{'DIALOGS.OFFLINEUSER.ENTERPIN' | i18next }}</mat-hint>
    </mat-form-field>
    <div *ngIf="passwordInput.invalid && (passwordInput.dirty || passwordInput.touched)"
         class="alert alert-danger">

      <div *ngIf="passwordInput.errors?.['required']">
        <p class="errortxt">PIN is required</p>
      </div>
      <div *ngIf="passwordInput.errors?.['pattern']">
        <p class="errortxt">PIN should have 6 numerical digits</p>
      </div>
    </div>
    <mat-form-field>
      <input autocomplete="off" minlength="6" maxlength="6" pattern="^([0-9]{6})$" type="password" matInput name="peter2" placeholder="{{'COMMON.REPEATPIN' | i18next }}" formControlName="repeatPin" [type]="hideRepeat ? 'password' : 'text'" required (keydown.enter)="setOfflineUser()">
      <mat-icon matSuffix (click)="hideRepeat = !hideRepeat">{{hideRepeat ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-hint *ngIf="!passwordRepeatInput.value">{{'DIALOGS.OFFLINEUSER.REPEATPIN' | i18next }}</mat-hint>
    </mat-form-field>
  </mat-dialog-content>
  <p class="errortxt">{{errorText}}</p>
  <mat-dialog-actions *ngIf="!saveOngoing">
    <button class="mat-raised-button" [disabled]="!passwordRepeatInput.value || !passwordInput.value" (click)="setOfflineUser()">{{'DIALOGS.OFFLINEUSER.SAVE' | i18next }}</button>
  </mat-dialog-actions>
  <div class="spinner flex-row">
    <mat-progress-spinner class="spinner" *ngIf="saveOngoing" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
