import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import {ModulePlan} from '../../../classes/module-plan';
import {BluectrlApiService} from '../../../services/bluectrl-api.service';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {Wards} from '../../../classes/wards';
import {UserManagementService} from '../../../services/user-management.service';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {ModuleGlobalState} from '../../../classes/enums/module-global-state.enum';
import {ModuleServiceService} from '../../../services/module-service.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {InitStateMessage} from '../../../classes/init-state-message';
import {ActionInitState} from '../../../classes/enums/action-init-state.enum';
import {ModuleSyncActions} from '../../../classes/enums/module-sync-actions.enum';
import {environment} from '../../../../environments/environment';
import {WifiServiceNotFoundComponent} from '../../dialogs/wifi-service-not-found/wifi-service-not-found.component';
import {ConfirmMessageBoxComponent} from '../../dialogs/confirm-message-box/confirm-message-box.component';
import {DatabaseService} from '../../../services/database.service';
import {Router} from '@angular/router';
import {QuestionBoxComponent} from '../../dialogs/question-box/question-box.component';
import {StandaloneClusterInitComponent} from '../../dialogs/standalone-cluster-init/standalone-cluster-init.component';
import {ModeSwitch} from '../../../classes/enums/mode-switch.enum';
import {CustomerModule} from '../../../classes/customer-module';
import {ConnectivityService} from '../../../services/connectivity.service';



@Component({
  selector: 'app-module-detail-view',
  templateUrl: './module-detail-view.component.html',
  styleUrls: ['./module-detail-view.component.css']
})
export class ModuleDetailViewComponent implements OnInit, OnDestroy {

  public _modulePlan: ModulePlan;
  public finished = true;
  private unsubscribe: Subject<void> = new Subject<void>();
  private active = false;

  public editOwner = false;
  public editHolder = false;

  public organisations: any[];
  public selectedOwner: any;
  public selectedHolder: any;


  public syncTries = 0;
  public moduleSyncRunning = false;
  private _standAloneInitOngoing = false;

  @Output() CloseDetailView = new EventEmitter();
  @Output() ShowSettings = new EventEmitter<CustomerModule>();
  @Input() set SelectedModule(value: ModulePlan) {
    this._modulePlan = value;
    if (!this.moduleService.SetCustomerModule(this._modulePlan.customerModule)) {
      // TODO: info can not start sync
    } else {

    }

    if (this._modulePlan.customerModule.ModuleSSID) {
      this.startSync();
    }

    this.selectedHolder = {
      id: this._modulePlan.customerModule.Holder,
      name: this._modulePlan.customerModule.HolderName
    };

    this.selectedOwner = {
      id: this._modulePlan.customerModule.Owner,
      name: this._modulePlan.customerModule.OwnerName
    };

  }

  constructor(public apiService: BluectrlApiService,
              public modulehandling: ModuleHandlingService,
              public usermanagement: UserManagementService,
              public translation: BluectrlTranslateService,
              public moduleService: ModuleServiceService,
              public database: DatabaseService,
              public moduleHandling: ModuleHandlingService,
              public connectivity: ConnectivityService,
              public router: Router,
              public dialog: MatDialog) { }

  ngOnInit(): void {

    this.moduleService.ModuleSyncProgress.pipe(takeUntil(this.unsubscribe)).subscribe(this.OnModuleStateInfo.bind(this));
    if (this.modulehandling.dataLoadedFinishedIndicator === true) {
      this.loadData();
    } else {
      this.modulehandling.DataLoadingFinished.subscribe(this.loadData.bind(this));
    }
    this.active = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.active = false;
    this.stopSync();
  }

  private loadData() {
    if (!this.connectivity.inOfflineMode) {

      this.loadOrganisations();

    }

  }
  private loadOrganisations() {
    this.apiService.getOrganizations().subscribe(async (organizations: any) => {
      if (organizations.length > 0) {
        this.organisations = organizations;
      }
    });
  }

  public OwnerChanged($event) {
    console.log($event);
    this.selectedOwner = this.organisations.find(ex => ex.id === $event);

  }

  public HolderChanged($event) {
    console.log($event);
    this.selectedHolder = this.organisations.find(ex => ex.id === $event);
  }

  public SaveNewOwner() {
    if (this.selectedOwner) {

      this.apiService.updateOwner(this.selectedOwner.id, this._modulePlan.customerModule.SerialNumber).subscribe((response: any) => {
        if (response.serial) {
          if (response.serial === this._modulePlan.customerModule.SerialNumber) {
            this.updateData(response);
            this.editOwner = false;
          }
        }

      }, error => {
        console.log(error);
      });

    }
  }

  private updateData(data: any) {
    if (data.holder) {
      this._modulePlan.customerModule.Holder = data.holder.id;
      this._modulePlan.customerModule.HolderName = data.holder.name;

      this._modulePlan.customerModule.Ward = new Wards(data.holder.id, data.holder.name);
    }
    if (data.owner) {
      this._modulePlan.customerModule.Owner = data.owner.id;
      this._modulePlan.customerModule.OwnerName = data.owner.name;
    }
  }

  public SaveNewHolder() {
    if (this.selectedHolder) {
      this.apiService.updateHolder(this.selectedHolder.id, this._modulePlan.customerModule.SerialNumber).subscribe((response: any) => {
        if (response.serial) {
          if (response.serial === this._modulePlan.customerModule.SerialNumber) {
            this.updateData(response);
            this.editHolder = false;
          }
        }

      }, error => {
        console.log(error);
      });
    }
  }

  public getModuleGlobalState(): string {
    switch (this._modulePlan.customerModule.ModuleGlobalState) {
      case ModuleGlobalState.Cluster: {
        return this.translation.GetTranslation('COMMON.GLOBALSTATECLUSTER');
      }
      case ModuleGlobalState.Service: {
        return this.translation.GetTranslation('COMMON.GLOBALSTATESERVICE');
      }
      case ModuleGlobalState.ClusterService: {
        return this.translation.GetTranslation('COMMON.GLOBALSTATESERVICECLUSTER');
      }
      case ModuleGlobalState.NoState: {
        return this.translation.GetTranslation('COMMON.GLOBALSTATENOSTATE');
      }
      case ModuleGlobalState.Unknown: {
        return this.translation.GetTranslation('COMMON.GLOBALSTATEUNKNOWN');
      }
      default: {
        return '';
      }
    }
  }

  public ResetSyncParameters() {
    this.syncTries = 1;
    this.moduleSyncRunning = false;
  }

  public OnModuleStateInfo(data: InitStateMessage) {
    if (data.ActionId && data.State) {


        if (data.State === ActionInitState.failed && data.ActionId === ModuleSyncActions.connectToWifi) {


          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = false;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'loginDialogGray';
          dialogConfig.data = environment.ModuleWifiSurFix + this._modulePlan.customerModule.SerialNumber;
          this.dialog.open(WifiServiceNotFoundComponent, dialogConfig);

        } else if (data.State === ActionInitState.success && data.ActionId === ModuleSyncActions.getModuleInfo) {
          setTimeout(() => {
            this.ResetSyncParameters();

            this._modulePlan.customerModule.InventoryInfo = this.moduleService.ServiceModule.InventoryInfo;
          }, 3000);
        }

      } else {
        this.syncTries += 1;
        this.retrySync();

      }

  }

  public retrySync() {
    if (this.syncTries < 3) {
      this.startSync();
    } else {
      this.ResetSyncParameters();
    }
  }

  public startSync() {
    if (!this.moduleSyncRunning) {
      this.moduleSyncRunning = true;
    }

    if (!this.moduleService.StartSync()) {
      // TODO: Info no sync
    }
  }

  public stopSync() {
    if (this.moduleSyncRunning) {
      this.ResetSyncParameters();
      this.moduleService.AbortModuleStateSync();
    }
  }

  public StandaloneClusterInitialized(): boolean {
    if (this.moduleService.ServiceModule) {
      if (this.moduleService.StandaloneClusterInitialized === true) {
        if (this.moduleService.ServiceModule.SerialNumber === this._modulePlan.customerModule.SerialNumber) {
          return true;
        }
      }
    }
    return false;
  }

  public CheckLoadCluster() {
    if (this._modulePlan.customerModule.ModuleGlobalState === ModuleGlobalState.Cluster ||
      this._modulePlan.customerModule.ModuleGlobalState === ModuleGlobalState.ClusterService) {
      if (this._modulePlan.customerModule.InventoryInfo) {
        if (this._modulePlan.customerModule.InventoryInfo.RecipeId) {
          // CHECK IF RECIPE
          this.database.recipes.toArray().then(rec => {
            const lookedfor = rec.find(ex => ex.id === this._modulePlan.customerModule.InventoryInfo.RecipeId);
            if (lookedfor) {
              const dt = JSON.parse(JSON.stringify(lookedfor));

              if (dt.recipe) {
                const reci = JSON.parse(dt.recipe);
                if (reci.cluster) {
                  if (this._modulePlan.customerModule.ModuleGlobalState === ModuleGlobalState.ClusterService) {
                    // ASK FOR
                    this.ConnectToCurrentCluster(dt);
                    return;
                  } else {
                    this.ConnectToExistingCluster(dt);
                    return;
                  }
                } else {
                  if (this._modulePlan.customerModule.ModuleGlobalState === ModuleGlobalState.Cluster) {
                    const msg = this.translation.GetTranslation('MESSAGEBOX.HEADERS.NOTFOUND');
                    const content = this.translation.GetTranslation('MESSAGEBOX.CONTENT.INVENTORY.RECIPENOTFOUND');
                    const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
                      {panelClass: 'panelclass', data: {header: msg, content: content}});
                  } else {
                    this.CreateStandaloneCluster();
                    return;
                  }
                }
              } else {
                if (this._modulePlan.customerModule.ModuleGlobalState === ModuleGlobalState.Cluster) {
                  const msg = this.translation.GetTranslation('MESSAGEBOX.HEADERS.NOTFOUND');
                  const content = this.translation.GetTranslation('MESSAGEBOX.CONTENT.INVENTORY.RECIPENOTFOUND');
                  const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
                    {panelClass: 'panelclass', data: {header: msg, content: content}});
                } else {
                  this.CreateStandaloneCluster();
                  return;
                }
              }

            } else if (this._modulePlan.customerModule.ModuleGlobalState === ModuleGlobalState.ClusterService) {
              this.CreateStandaloneCluster();
              return;
            } else {
              // CAN'T CONNECT TO CLUSTER
              const msg = this.translation.GetTranslation('MESSAGEBOX.HEADERS.NOTFOUND');
              const content = this.translation.GetTranslation('MESSAGEBOX.CONTENT.INVENTORY.RECIPENOTFOUND');
              const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
                {panelClass: 'panelclass', data: {header: msg, content: content}});
            }
          });
        }
      }
    } else if (this._modulePlan.customerModule.ModuleGlobalState === ModuleGlobalState.Service) {
      this.CreateStandaloneCluster();
      return;
    }
  }

  public ConnectToCurrentCluster(recipe: any) {
    if (recipe) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data =
        this.translation.GetTranslation('MESSAGEBOX.CONTENT.INVENTORY.PARTOFCLUSTER');
      const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          // CONNECT TO EXISTING CLUSTER
          this.ConnectToExistingCluster(recipe);
        } else {
          this.CreateStandaloneCluster();
        }
      });
    }
  }

  public ConnectToExistingCluster(recipe: any) {

    this.moduleHandling.LoadRecipeFromInventoryInfo = {
      recipe: recipe,
      clusterid: this._modulePlan.customerModule.InventoryInfo.ClusterId,
      masterSerial: this._modulePlan.customerModule.InventoryInfo.MasterSerial,
      masterIp: this._modulePlan.customerModule.InventoryInfo.MasterIp
    };
    this.moduleService.RemoveConnectionInfo();
    this.router.navigate(['/live']);
  }

  public CreateStandaloneCluster() {
    if (this._standAloneInitOngoing === false) {
      this._standAloneInitOngoing = true;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data = this._modulePlan.customerModule;
      const dialogRef = this.dialog.open(StandaloneClusterInitComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        this._standAloneInitOngoing = false;
      });
    }
  }

  public ShowServiceDetailView(): boolean {

    if (this.StandaloneClusterInitialized()) {
      if (this._modulePlan.customerModule.Module.CurrentMode === ModeSwitch.SERVICE && this._modulePlan.customerModule.Module.EmergencyStopRequired === false) {
        return true;
      }
    }
    return false;
  }

  public ShowEmergencyStopRequired(): boolean {

    if (this.StandaloneClusterInitialized()) {
      if (this._modulePlan.customerModule.Module.EmergencyStopRequired === true) {
        return true;
      }
    }
    return false;
  }

  public IsStandaloneOnline(): boolean {

    if (this.moduleService.StandaloneClusterInitialized) {
      if (this.moduleService.StandaloneModulePlan.customerModule.SerialNumber === this._modulePlan.customerModule.SerialNumber) {
        return true;
      }
    }
    return false;
  }



}
