<section class="flex-col stretch-self">
  <div class="header flex-row gap-10 stretch-row">
    <div class="flex-col center-self">
      <h2  class="uppercaseText">{{'DIALOGS.CONVEYORSELECTION.TITLE' | i18next }}</h2>
    </div>
    <div class="flex-row flex-1 justify-end center-self">
      <button mat-button (click)="CloseDialog()">
        <img class="conveyorSelectionClose" src="/assets/general/close.svg">
      </button>

    </div>
  </div>

  <div class="flex-row gap-10 stretch-row">
    <div *ngFor="let tp of conveyorTypes">
      <div class="conveyor long flex-col" (click)="selected(tp)">
        <img class="conveyorImage" src="{{getPath(tp)}}">
        <p class="conveyorName">{{tp.Name}}</p>
      </div>
    </div>
  </div>

</section>
