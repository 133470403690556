<section>
  <svg class="backimage" [attr.height]="GetSvgHeight()" [attr.width]="GetSvgWidth()">
    <g *ngFor="let pos of verticals">
      <path [attr.d]="GetPathVertical(pos)" stroke-width="1" stroke="black"></path>
    </g>
    <g *ngFor="let pos of horizontals">
      <path [attr.d]="GetPathHorizontal(pos)" stroke-width="1" stroke="black"></path>
    </g>
  </svg>
  <app-module-settings *ngIf="ShowModuleSettings" [SelectedModule]="moduleHandling.ActiveModule" [monitoringMsgId]="monitoringMsgId"></app-module-settings>
  <app-module-onboarding *ngIf="moduleHandling.ActiveModule && showOnboarding" [SelectedModulePlan]="(moduleHandling.activeOnboardingServiceModulePlan ? moduleHandling.activeOnboardingServiceModulePlan : moduleHandling.ActiveModule)" [ShowOnboarding]="showOnboarding" (CloseOnboarding)="CloseModuleOnboarding()"></app-module-onboarding>
  <div class="designplate">
    <svg #svgGroup [attr.height]="GetSvgHeight()" [attr.width]="GetSvgWidth()">
    </svg>
  </div>
  <div class="designplate" (click)="clickOnPlate($event)">
    <template #modulePlate>
    </template>
  </div>
  <div class="scrollIconsRight" *ngIf="ShowRight" (click)="GoRight()">
    <button mat-button class="scrollButton">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 60" width="30px" height="60px" fill="transparent">
        <title>Element 34</title>
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Menu">
            <path d="M 3.7880721,-0.10407654 26.137697,29.821693 3.7880721,60.126269" stroke="black" stroke-width="3px"/>
          </g>
        </g>
      </svg>
    </button>
  </div>
  <div class="scrollIconsLeft" *ngIf="ShowLeft"  (click)="GoLeft()">
    <button mat-button class="scrollButton">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 60" width="30px" height="60px" fill="transparent">
        <title>Element 34</title>
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Menu">
            <path d="M 26.137697,60 3.7880721,29.821693 26.137697,0.14846159"  stroke="black" stroke-width="3px"/>
          </g>
        </g>
      </svg>
    </button>
  </div>
  <div class="scrollIconsTop" *ngIf="ShowTop"  (click)="GoTop()">
    <button mat-button>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" width="60px" height="30px" fill="transparent">
        <title>Element 34</title>
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Menu">
            <path d="M 0,22.423856 30.178307,7.271568 59.977807,22.423856" stroke="black" stroke-width="3px"/>
          </g>
        </g>
      </svg>
    </button>
  </div>
  <div class="scrollIconsBottom" *ngIf="ShowBottom"  (click)="GoBottom()">
    <button mat-button>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" width="60px" height="30px" fill="transparent">
        <title>Element 34</title>
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Menu">
            <path d="M 0,7.271568 30.178307,22.423856 60.104077,7.271568" stroke="black" stroke-width="3px"/>
          </g>
        </g>
      </svg>
    </button>
  </div>
  <app-modulemenubar></app-modulemenubar>
  <div class="uplaodModule" (click)="sendRecipe()" *ngIf="UploadAllowed()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 23.36" width="204" height="69">
      <defs>
        <style>.cls-1{fill:#0077ba;}.cls-2{fill:#f2f2f2;}</style>
      </defs>
      <title>upload_neu_1</title>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Menu">
          <polygon class="cls-1" points="10.77 7.45 10.77 15.25 12.77 15.25 12.77 7.54 15.12 9.83 16.52 8.4 13.1 5.07 13.1 5.07 11.66 3.67 6.93 8.52 8.37 9.92 10.77 7.45"/>
          <rect class="cls-1" x="5.4" y="17.28" width="12.97" height="2"/>
          <path class="cls-1" d="M112,0H2.83A2.83,2.83,0,0,0,0,2.83v17.7a2.83,2.83,0,0,0,2.83,2.83H65a2.84,2.84,0,0,0,2.84-2.83V2.83A2.84,2.84,0,0,0,65,0ZM21.65,20.19a1.42,1.42,0,0,1-1.42,1.41h-17A1.42,1.42,0,0,1,1.8,20.19v-17A1.42,1.42,0,0,1,3.22,1.76h17a1.42,1.42,0,0,1,1.42,1.42Z"/>
          <path class="cls-2" d="M32.71,13.19a1.46,1.46,0,0,1-.33,1,1.22,1.22,0,0,1-.94.36,1.21,1.21,0,0,1-.94-.36,1.46,1.46,0,0,1-.33-1V9.31h-.85v3.85a2.68,2.68,0,0,0,.25,1.22,1.66,1.66,0,0,0,.72.76,2.3,2.3,0,0,0,1.15.27,2.33,2.33,0,0,0,1.15-.27,1.77,1.77,0,0,0,.72-.76,2.68,2.68,0,0,0,.25-1.22V9.31h-.85Z"/>
          <path class="cls-2" d="M38.68,9.54a1.94,1.94,0,0,0-1-.23H35.4v6h.84V13h1.49a1.93,1.93,0,0,0,1-.22,1.6,1.6,0,0,0,.64-.65,2.16,2.16,0,0,0,0-1.94A1.54,1.54,0,0,0,38.68,9.54Zm-.12,2.17a.86.86,0,0,1-.8.49H36.24V10.12h1.52a.86.86,0,0,1,.48.13.8.8,0,0,1,.32.36,1.32,1.32,0,0,1,0,1.1Z"/>
          <polygon class="cls-2" points="41.96 9.31 41.11 9.31 41.11 15.35 41.54 15.35 41.96 15.35 44.99 15.35 44.99 14.54 41.96 14.54 41.96 9.31"/>
          <path class="cls-2" d="M49.52,9.52a2.23,2.23,0,0,0-1.11-.27,2.29,2.29,0,0,0-1.12.27,1.92,1.92,0,0,0-.74.78,2.46,2.46,0,0,0-.26,1.17v1.71a2.41,2.41,0,0,0,.26,1.17,1.8,1.8,0,0,0,.74.78,2.19,2.19,0,0,0,1.12.28,2.13,2.13,0,0,0,1.11-.28,1.8,1.8,0,0,0,.74-.78,2.41,2.41,0,0,0,.27-1.17V11.47a2.46,2.46,0,0,0-.27-1.17A1.92,1.92,0,0,0,49.52,9.52Zm.14,3.69a1.53,1.53,0,0,1-.16.72,1.2,1.2,0,0,1-.44.48,1.23,1.23,0,0,1-.65.16,1.29,1.29,0,0,1-.66-.16,1.2,1.2,0,0,1-.44-.48,1.66,1.66,0,0,1-.16-.72V11.44a1.58,1.58,0,0,1,.16-.71,1.08,1.08,0,0,1,.44-.48,1.3,1.3,0,0,1,.66-.17,1.23,1.23,0,0,1,.65.17,1.08,1.08,0,0,1,.44.48,1.46,1.46,0,0,1,.16.71Z"/>
          <path class="cls-2" d="M53.84,9.31l-2.22,6h.91L53,14h2.44l.45,1.33h.92l-2.23-6Zm-.58,3.91.94-2.74.94,2.74Z"/>
          <path class="cls-2" d="M61.28,9.56A2.91,2.91,0,0,0,60,9.31H58.13v6H60a2.81,2.81,0,0,0,1.25-.26,1.75,1.75,0,0,0,.78-.74,2.35,2.35,0,0,0,.27-1.17V11.47a2.41,2.41,0,0,0-.27-1.17A1.8,1.8,0,0,0,61.28,9.56Zm.19,3.66a1.28,1.28,0,0,1-.37,1,1.47,1.47,0,0,1-1,.35H59V10.12h1.09a1.47,1.47,0,0,1,1,.34,1.3,1.3,0,0,1,.37,1Z"/>
        </g>
      </g>
    </svg>
  </div>
  <app-dialog-demo-menu (OpenModuleSettings)="OpenDemoModuleSettings()" (OpenModuleOnboarding)="ShowModuleOnboarding()"></app-dialog-demo-menu>
  <app-load-project [ShowLoad]="showLoadRecipe" *ngIf="showLoadRecipe" (CloseLoadProject)="CloseLoadRecipe($event)">
  </app-load-project>

  <div class="reconnectView" *ngIf="clusterOnboarding.reconnectActive">
    <div class="reconnectInfo flex-col">
      <p class="reconnectHeader">
        {{'COMPONENTS.LIVEVIEW.CONNECTIONLOST' | i18next}}
      </p>
      <div class="spinner flex-row">
        <mat-progress-spinner class="spinner" mode="indeterminate"></mat-progress-spinner>
      </div>
      <p class="reconnectLine">
        {{'COMPONENTS.LIVEVIEW.CONNECTTOCLUSTER' | i18next }} {{clusterOnboarding.currentReconnectTry}} / {{clusterOnboarding.reconnectTries}}
      </p>
      <p class="reconnectLine">
        {{clusterOnboarding.lastLogMessage}}
      </p>
    </div>
  </div>

</section>
