<div class="main flex-col">
  <mat-dialog-content>
    <h2><strong>{{data.header}}</strong></h2>
    <p class="content">{{data.content}}</p>
    <div *ngIf="data.elements">
      <table class="infotable flex-col" *ngFor="let inf of data.elements">
        <tr  class="elementRow flex-row stretch-row">
          <td style="width: 100%" class="elementText"><p class="contentp">{{inf}}</p></td>
        </tr>
      </table>
    </div>
  </mat-dialog-content>
  <div class="buttondiv flex-row stretch-row">
    <button class="yesButton start-self" (click)="Confirm()">{{'COMMON.OK' | i18next }}</button>
  </div>
</div>
