<section class="flex-row">
  <div class="tasktab flex-col">
    <p class="taskHeader">{{'COMPONENTS.LEVELINGTAB.INFO' | i18next }}</p>
    <p class="taskText">{{'COMPONENTS.LEVELINGTAB.TEXT' | i18next }}</p>
    <div class="leveling">
      <div class="graphics flex-col" [ngClass]="{'graphicsGreen': !ShowLeveling()}">
        <div class="topDiv flex-row" *ngIf="levelling">
          <p class="left">
            <img class="levellingArrowsLeft" alt="{{levelling[1]}}mm {{'COMPONENTS.LEVELINGTAB.INRANGE' | i18next }}" src="/assets/general/arrow_green.svg" *ngIf="SelectedModule.modul.LevelingRequired === false && (levelling[1] >= 10 && levelling[1] <50)">
            <img class="levellingArrowsLeft" alt="{{levelling[1]}}mm {{'COMPONENTS.LEVELINGTAB.INRANGE' | i18next }}"  src="/assets/general/arrow_green_2.svg" *ngIf="SelectedModule.modul.LevelingRequired === false && (levelling[1] >= 50 && levelling[1] <100)">
            <img class="levellingArrowsLeft" alt="{{levelling[1]}}mm {{'COMPONENTS.LEVELINGTAB.INRANGE' | i18next }}"  src="/assets/general/arrow_green_3.svg" *ngIf="SelectedModule.modul.LevelingRequired === false  && (levelling[1] >= 100)">
            <img class="levellingArrowsLeft levelingAltText" alt="{{levelling[1]}}mm {{'COMPONENTS.LEVELINGTAB.UP' | i18next }}"  src="/assets/general/arrow_blue.svg" *ngIf="(SelectedModule.modul.LevelingRequired === true) && (levelling[1] >= 10 && levelling[1] <50)">
            <img class="levellingArrowsLeft levelingAltText" alt="{{levelling[1]}}mm {{'COMPONENTS.LEVELINGTAB.UP' | i18next }}"  src="/assets/general/arrow_blue_2.svg" *ngIf="(SelectedModule.modul.LevelingRequired === true) && (levelling[1] >= 50 && levelling[1] <100)">
            <img class="levellingArrowsLeft levelingAltText" alt="{{levelling[1]}}mm {{'COMPONENTS.LEVELINGTAB.UP' | i18next }}"  src="/assets/general/arrow_blue_3.svg" *ngIf="(SelectedModule.modul.LevelingRequired === true) && (levelling[1] >= 100)">
            </p>
          <p class="right">
            <img class="levellingArrowsLeft" alt="{{levelling[0]}}mm {{'COMPONENTS.LEVELINGTAB.INRANGE' | i18next }}" src="/assets/general/arrow_green.svg" *ngIf="SelectedModule.modul.LevelingRequired  === false && (levelling[0] >= 10 && levelling[0] <50)">
            <img class="levellingArrowsLeft" alt="{{levelling[0]}}mm {{'COMPONENTS.LEVELINGTAB.INRANGE' | i18next }}" src="/assets/general/arrow_green_2.svg" *ngIf="SelectedModule.modul.LevelingRequired === false && (levelling[0] >= 50 && levelling[0] <100)">
            <img class="levellingArrowsLeft" alt="{{levelling[0]}}mm {{'COMPONENTS.LEVELINGTAB.INRANGE' | i18next }}" src="/assets/general/arrow_green_3.svg" *ngIf="SelectedModule.modul.LevelingRequired === false && (levelling[0] >= 100)">
            <img class="levellingArrowsLeft levelingAltText" alt="{{levelling[0]}}mm {{'COMPONENTS.LEVELINGTAB.UP' | i18next }}" src="/assets/general/arrow_blue.svg" *ngIf="(SelectedModule.modul.LevelingRequired === true) && (levelling[0] >= 10 && levelling[0] <50)">
            <img class="levellingArrowsLeft levelingAltText" alt="{{levelling[0]}}mm {{'COMPONENTS.LEVELINGTAB.UP' | i18next }}" src="/assets/general/arrow_blue_2.svg" *ngIf="(SelectedModule.modul.LevelingRequired === true) && (levelling[0] >= 50 && levelling[0] <100)">
            <img class="levellingArrowsLeft levelingAltText" alt="{{levelling[0]}}mm {{'COMPONENTS.LEVELINGTAB.UP' | i18next }}" src="/assets/general/arrow_blue_3.svg" *ngIf="(SelectedModule.modul.LevelingRequired === true) && (levelling[0] >= 100)">
          </p>
        </div>
        <div class="designImage">
          <img src="/assets/general/levelling_graphic.svg" style="width: 100%;">
        </div>
        <!-- 3 Pfeile  >100 mm
             2 Pfeile  50-100 mm
             1 Pfeil 10-50 mm
             Kein Pfeil <10 mm
        -->
        <div class="bottomDiv flex-row" *ngIf="levelling">
          <p class="left">
            <img class="levellingArrowsLeft" alt="{{levelling[2]}}mm {{'COMPONENTS.LEVELINGTAB.INRANGE' | i18next }}" src="/assets/general/arrow_green.svg" *ngIf="SelectedModule.modul.LevelingRequired === false && (levelling[2] >= 10 && levelling[2] <50)">
            <img class="levellingArrowsLeft" alt="{{levelling[2]}}mm {{'COMPONENTS.LEVELINGTAB.INRANGE' | i18next }}" src="/assets/general/arrow_green_2.svg" *ngIf="SelectedModule.modul.LevelingRequired === false && (levelling[2] >= 50 && levelling[2] <100)">
            <img class="levellingArrowsLeft" alt="{{levelling[2]}}mm {{'COMPONENTS.LEVELINGTAB.INRANGE' | i18next }}" src="/assets/general/arrow_green_3.svg" *ngIf="SelectedModule.modul.LevelingRequired === false && (levelling[2] >= 100)">
            <img class="levellingArrowsLeft levelingAltText" alt="{{levelling[2]}}mm {{'COMPONENTS.LEVELINGTAB.UP' | i18next }}" src="/assets/general/arrow_blue.svg" *ngIf="(SelectedModule.modul.LevelingRequired === true) && (levelling[2] >= 10 && levelling[2] <50)">
            <img class="levellingArrowsLeft levelingAltText" alt="{{levelling[2]}}mm {{'COMPONENTS.LEVELINGTAB.UP' | i18next }}" src="/assets/general/arrow_blue_2.svg" *ngIf="(SelectedModule.modul.LevelingRequired === true) && (levelling[2] >= 50 && levelling[2] <100)">
            <img class="levellingArrowsLeft levelingAltText" alt="{{levelling[2]}}mm {{'COMPONENTS.LEVELINGTAB.UP' | i18next }}" src="/assets/general/arrow_blue_3.svg" *ngIf="(SelectedModule.modul.LevelingRequired === true) && (levelling[2] >= 100)">
            </p>
          <p class="right">
            <img class="levellingArrowsLeft" alt="{{levelling[3]}}mm {{'COMPONENTS.LEVELINGTAB.INRANGE' | i18next }}" src="/assets/general/arrow_green.svg" *ngIf="SelectedModule.modul.LevelingRequired === false && (levelling[3] >= 10 && levelling[3] <50)">
            <img class="levellingArrowsLeft" alt="{{levelling[3]}}mm {{'COMPONENTS.LEVELINGTAB.INRANGE' | i18next }}" src="/assets/general/arrow_green_2.svg" *ngIf="SelectedModule.modul.LevelingRequired === false && (levelling[3] >= 50 && levelling[3] <100)">
            <img class="levellingArrowsLeft" alt="{{levelling[3]}}mm {{'COMPONENTS.LEVELINGTAB.INRANGE' | i18next }}" src="/assets/general/arrow_green_3.svg" *ngIf="SelectedModule.modul.LevelingRequired === false && (levelling[3] >= 100)">
            <img class="levellingArrowsLeft levelingAltText" alt="{{levelling[3]}}mm {{'COMPONENTS.LEVELINGTAB.UP' | i18next }}" src="/assets/general/arrow_blue.svg" *ngIf="(SelectedModule.modul.LevelingRequired === true) && (levelling[3] >= 10 && levelling[3] <50)">
            <img class="levellingArrowsLeft levelingAltText" alt="{{levelling[3]}}mm {{'COMPONENTS.LEVELINGTAB.UP' | i18next }}" src="/assets/general/arrow_blue_2.svg" *ngIf="(SelectedModule.modul.LevelingRequired === true) && (levelling[3] >= 50 && levelling[3] <100)">
            <img class="levellingArrowsLeft levelingAltText" alt="{{levelling[3]}}mm {{'COMPONENTS.LEVELINGTAB.UP' | i18next }}" src="/assets/general/arrow_blue_3.svg" *ngIf="(SelectedModule.modul.LevelingRequired === true) && (levelling[3] >= 100)">
            </p>
        </div>
      </div>
    </div>
  </div>
</section>

