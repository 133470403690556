<div class="main flex-col">
  <mat-dialog-content class="flex-col stretch-col">
    <h2><strong>{{'MESSAGEBOX.HEADERS.RECIPENOTVALID' | i18next }}</strong></h2>
    <p class="content">{{'MESSAGEBOX.CONTENT.NOTVALIDFORLIVE' | i18next }}</p>
    <div class="downscrollarrow flipArrow flex-col center-self">
      <button mat-button class="arrowbtn" (click)="ScrollUp()" *ngIf="UpScrolling()">
        <img src="/assets/general/arrow_scroll.svg">
      </button>
    </div>
    <div class="seriallist" #validationContainer>
      <mat-list *ngFor="let val of ValidationInfo" #validationList>
        <div class="flex-row center-self">
          <p class="headerElement">{{val.module}}</p>
          <p class="textElement">{{val.text}}</p>
        </div>
      </mat-list>
    </div>
    <div class="downscrollarrow flex-col center-self">
      <button mat-button class="arrowbtn" (click)="ScrollDown()" *ngIf="DownScrolling()">
        <img src="/assets/general/arrow_scroll.svg">
      </button>
    </div>
  </mat-dialog-content>
  <div class="buttondiv flex-row stretch-row">
    <button class="yesButton start-self" (click)="Confirm()">{{'COMMON.OK' | i18next }}</button>
  </div>
</div>
