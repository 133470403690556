<section class="flex-col">
  <div class="img flex-col center-self">
    <img class="imgfile" src="/assets/line_technology.png">
  </div>

  <div class="hdr">
    <!--wrap; flex-direction.lt-sm: column-->
    <div class="menu gap-50 flex-row justify-center">
      <!--fxFlex.lt-md 0-1-50-50; fxFlex.lt-sm 1-->
      <div class="menuitem flex-0-1-20-50" routerLink="/inventory">
        <div class="flex-col">
          <img class="imgIconView" src="/assets/general/inventory.svg">
          <p class="textfld opticalalign center-self" style="color: white;" >{{'COMPONENTS.HOME.INVENTORY' | i18next }}</p>
        </div>
      </div>
      <!--fxFlex.lt-md 0-1-50-50; fxFlex.sm 1-->
      <div class="menuitem flex-0-1-20-50" routerLink="/design">
        <div class="flex-col">
          <img class="imgIconView" src="/assets/general/design.svg">
          <p class="textfld opticalalign center-self">{{'COMPONENTS.HOME.DESIGN' | i18next }}</p>
        </div>
      </div>
      <!--fxFlex.lt-md 0-1-50-50; fxFlex.sm 1-->
      <div class="menuitem flex-0-1-20-50" routerLink="/live">
        <div class="flex-col">
          <img class="imgIconView" src="/assets/general/live.svg">
          <p class="textfld opticalalign center-self">{{'COMPONENTS.HOME.LIVE' | i18next }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
