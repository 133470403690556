import {Conveyor} from './conveyor';
import {Connections} from './connections';

import {ModuleRotations} from './enums/module-rotations.enum';
import {ModuleConnections} from './enums/module-connections.enum';
import {CustomerModule} from './customer-module';
import {EventEmitter} from '@angular/core';
import {NetworkDevice} from './messaging/network-device';
import {ModuleDefinition} from './module-definition';
import {ModuleHandlingService} from '../services/module-handling.service';
import {ModuleComponent} from './module-component';
import {ModuleGlobalState} from './enums/module-global-state.enum';
import {EndPointTypes} from './enums/end-point-types.enum';
import {ConveyorLenghtType} from './enums/conveyor-lenght-type.enum';


export class ModulePlan {
  modul: ModuleDefinition;
  customerModule: CustomerModule;
  rotation: ModuleRotations;
  connections: Connections[] = [];
  isInialModule = false;
  public seqno = -1;
  public lastRotation = 0;
  public OnNetworkInfoChanged = new EventEmitter<NetworkDevice>();
  public ClusterInitializedChanged = new EventEmitter<boolean>();
  public ConfigFinishedSended = false;
  public changed = false;
  RestrictedConnectionPoints: ModuleConnections[] = [];
  public ModuleStateChanged = new EventEmitter();

  public geometricMaster = false;


  constructor(initial: boolean,
              modul?: ModuleDefinition,
              customermodule?: CustomerModule) {

    if (customermodule !== null && customermodule !== undefined) {
      this.setCustomerModule(customermodule);
    } else {
      this.setModule(modul);
    }

    this.rotation = ModuleRotations.degree_0;
   this.connections = [];

    if (initial) {
      this.SetAsInitial();
    }
    this.CheckForRestrictions();
  }

  public updateClusterInitialized(state: boolean) {
    this.ClusterInitializedChanged.emit(state);
  }

  public setModule (module: ModuleDefinition) {
    this.changed = true;
    this.modul = module;
    this.modul.OnNetworkInfoUpdated.subscribe(this.NetworkInfoChanged.bind(this));
    this.modul.OnResetBeltView.subscribe(this.HandleResetBeltView.bind(this));
  }

  public setCustomerModule (module: CustomerModule) {
    this.changed = true;
    this.customerModule = module;
    this.setModule(module.Module);
  }

  public updateModuleState(state: number) {
    if (this.customerModule) {
      if (this.customerModule.SerialNumber) {
          if (this.customerModule.Module.NetworkInfo) {
            this.customerModule.Module.NetworkInfo.statemodule = state;
            this.ModuleStateChanged.emit();
          }
      }
    }
  }

  public SetHandling(moduleHandling: ModuleHandlingService) {
    if (this.modul) {
      this.modul.SetModuleHandling(moduleHandling);
    }
    if (this.customerModule) {
      this.customerModule.Module.SetModuleHandling(moduleHandling);
    }
  }

  public NetworkInfoChanged() {
      this.OnNetworkInfoChanged.emit(this.modul.NetworkInfo);
  }

  replaceConveyor(conveyor: Conveyor) {
    this.changed = true;
    this.connections = this.connections.filter(ex => ex.moduleConnection !== conveyor.ConnectionPoint);
    this.connections.push(new Connections(conveyor.ConnectionPoint, conveyor));
  }

  public SetAsInitial() {
    this.changed = true;
    this.isInialModule = true;
  }

  public SetInternalBeltInformation(connectionPoint: ModuleConnections, connected: boolean) {
    // GET RIGHT FBI

    if (this.customerModule) {
      if (this.modul) {
        if (this.modul.ConveyorBelts) {

          const internalBeltConveyor = this.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === connectionPoint);
          if (internalBeltConveyor) {

            if (!connected) {
              // CHECK THE OPPOSITE SITE
              if (connectionPoint === ModuleConnections.left_1) {
                if (this.connections.find(ex => ex.moduleConnection === ModuleConnections.right_1
                  && ex.conveyor !== null
                  && ex.conveyor !== undefined)) {
                  return;
                }
              }
              if (connectionPoint === ModuleConnections.right_1) {
                if (this.connections.find(ex => ex.moduleConnection === ModuleConnections.left_1
                  && ex.conveyor !== null
                  && ex.conveyor !== undefined)) {
                  return;
                }
              }
              if (connectionPoint === ModuleConnections.left_2) {
                if (this.connections.find(ex => ex.moduleConnection === ModuleConnections.right_2
                  && ex.conveyor !== null
                  && ex.conveyor !== undefined)) {
                  return;
                }
              }
              if (connectionPoint === ModuleConnections.right_2) {
                if (this.connections.find(ex => ex.moduleConnection === ModuleConnections.left_2
                  && ex.conveyor !== null
                  && ex.conveyor !== undefined)) {
                  return;
                }
              }
              if (connectionPoint === ModuleConnections.left_3) {
                if (this.connections.find(ex => ex.moduleConnection === ModuleConnections.right_3
                  && ex.conveyor !== null
                  && ex.conveyor !== undefined)) {
                  return;
                }
              }
              if (connectionPoint === ModuleConnections.right_3) {
                if (this.connections.find(ex => ex.moduleConnection === ModuleConnections.left_3
                  && ex.conveyor !== null
                  && ex.conveyor !== undefined)) {
                  return;
                }
              }
              if (connectionPoint === ModuleConnections.left) {
                if (this.connections.find(ex => ex.moduleConnection === ModuleConnections.right
                  && ex.conveyor !== null
                  && ex.conveyor !== undefined)) {
                  return;
                }
              }
              if (connectionPoint === ModuleConnections.right) {
                if (this.connections.find(ex => ex.moduleConnection === ModuleConnections.left
                  && ex.conveyor !== null
                  && ex.conveyor !== undefined)) {
                  return;
                }
              }
            }


            const intBelt = internalBeltConveyor.RelatedInternalBelt;
            let option = 0;
            if (connected === true) {
              option = internalBeltConveyor.PositionRelatedInternalBelt;
            } else {
              const internalBelt = internalBeltConveyor.RelatedInternalBelt;
              if (internalBelt) {
                const allConnections = this.modul.ConveyorBelts.filter(ex => ex.RelatedInternalBelt === internalBelt);

                let allTerminated = true;

                for (const con of allConnections) {
                  if (this.connections.find(ex => ex.moduleConnection === con.ConnectionPoint)) {

                  } else {
                    allTerminated = false;
                  }
                }

                if (!allTerminated) {
                  option = -1;
                }

              }


            }
            // SET VALUE

            if (option !== -1 && option !== undefined) {
              if (this.customerModule.Module) {
                // FIND Device
                const device = this.customerModule.Module.Components.find(ex => ex.PlcKey === intBelt);
                if (device) {
                  // FIND PARAMETER
                  const param = device.Configs.find(ex => ex.Name === 'Position');
                  if (param) {
                    param.SetValue(option);
                  }
                }
              }
            }

            // SET ACTIVE
            const blt = this.modul.Components.find(ex =>
              ex.Connections.find(et => et === connectionPoint) !== null &&
              ex.Connections.find(et => et === connectionPoint) !== undefined);
            if (blt) {
               const confg = blt.Configs.find(ex => ex.Name === 'Active');
               if (confg) {
                 if (connected) {
                   confg.SetValue(true);
                 } else {
                   confg.SetValue(false);
                 }

               }
            }

            // SET ADD-ON TYPE
            if (blt) {
              const belt = this.connections.find(ex => ex.moduleConnection === connectionPoint && ex.conveyor != null);
              if (belt != null) {
                const confg = blt.Configs.find(ex => ex.Name === 'TypeOfAddOn');
                if (confg) {
                  if (connected) {
                    confg.SetValue(belt.conveyor.TypeOfAddOn, false);
                  } else {
                    confg.SetValue(belt.conveyor.TypeOfAddOn, false);
                  }
                }

                const confgEBC = blt.Configs.find(ex => ex.Name === 'TypeOfConveyor');
                if (confgEBC) {
                  if (connected) {
                    confgEBC.SetValue(belt.conveyor.Type, false);
                  } else {
                    confgEBC.SetValue(belt.conveyor.Type, false);
                  }
                }
              }

            }



          }
        }
      }
    }
  }

  public SetDirectionForMagnetBelt(conveyor: Conveyor, value: boolean) {
    let conveyorConnectionPoint = this.connections.find(ex => ex.conveyor?.UniqueId === conveyor.UniqueId)?.moduleConnection;
    if (conveyorConnectionPoint) {
      const component = this.customerModule.Module.Components.find(ex => ex.Connections.find(et => et === conveyorConnectionPoint) != null);
      if (component) {
        const parameter = component.Configs.find(ex => ex.Name === 'RotationDirectionLeftAddOn');
        if (parameter) {
          parameter.SetValue(value, false);
        }
      }
    }
  }

  public GetDirectionForMagnetBelt(conveyor: Conveyor): any {
    let conveyorConnectionPoint = this.connections.find(ex => ex.conveyor?.UniqueId === conveyor.UniqueId)?.moduleConnection;
    if (conveyorConnectionPoint) {
      const component = this.customerModule.Module.Components.find(ex => ex.Connections.find(et => et === conveyorConnectionPoint) != null);
      if (component) {
        const parameter = component.Configs.find(ex => ex.Name === 'RotationDirectionLeftAddOn');
        if (parameter) {
          return parameter.getValue();
        }
      }
    }
    return null;
  }

  public removeConveyorConnection(connectionPoint: ModuleConnections) {
    this.connections = this.connections.filter(ex => ex.moduleConnection !== connectionPoint);
    // REMOVE RESTRICED TOO
    this.CheckForRestrictions();

    this.SetInternalBeltInformation(connectionPoint, false);
  }

  public addConntection(conveyor: Conveyor, connectionPoint: ModuleConnections, withoutRestriction = false): void {

    this.changed = true;
    const conveyor1: Conveyor = conveyor;

    const oppositSide = this.getOppositeConnectionPoint(connectionPoint);
    // let continuing = true;

    if (conveyor1 != null) {

      if (oppositSide && conveyor1) {
        this.checkSideChangeRestrictions(conveyor1, connectionPoint);
      }

      if (this.modul.ConveyorBelts.some(x => x.ConnectionPoint === connectionPoint)) {
        if (this.connections.some(ex => ex.moduleConnection === connectionPoint)) {
          this.replaceConveyor(conveyor);
        } else {
          this.connections.push(new Connections(connectionPoint, conveyor1));
        }
      }
    } else {
      if (this.modul.ConveyorBelts.some(x => x.ConnectionPoint === connectionPoint)) {
        if (this.connections.some(ex => ex.moduleConnection === connectionPoint)) {
          this.connections = this.connections.filter(ex => ex.moduleConnection !== connectionPoint);
          this.connections.push(new Connections(connectionPoint, conveyor1));
          // CHECKING FOR Internal belt
          // this.SetInternalBeltInformation(connectionPoint, conveyor1 !== null && conveyor1 !== undefined);

        } else {
          this.connections.push(new Connections(connectionPoint, conveyor1));
          // CHECKING FOR Internal belt
          // this.SetInternalBeltInformation(connectionPoint, conveyor1 !== null && conveyor1 !== undefined);
        }
      }
    }


    if (!withoutRestriction) {
      this.CheckForRestrictions();
    }
    this.SetInternalBeltInformation(connectionPoint, conveyor1 !== null && conveyor1 !== undefined);


  }

  private CheckForRestrictions() {
    if (this.RestrictedConnectionPoints.length > 0) {
      this.connections = this.connections.filter(o => this.RestrictedConnectionPoints.filter(et => et === o.moduleConnection).length <= 0);
    }


    this.RestrictedConnectionPoints = [];

    for (let i = 0; i < this.connections.length; i++) {
      if (this.connections[i].conveyor !== null) {
        const conv = this.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === this.connections[i].moduleConnection);
        if (conv) {
          for (const c of conv.RestrictedConnections) {
            this.RestrictedConnectionPoints.push(c);
          }
        }
      }
    }

    for (const rs of this.RestrictedConnectionPoints) {
      if (this.connections.find(ex => ex.moduleConnection === rs)) {
        const con = this.connections.find(ex => ex.moduleConnection === rs);
        if (con) {
          if (con.conveyor) {
            this.addConntection(null, rs, true);
          }
        }
      } else {
        this.addConntection(null, rs, true);
      }
    }

  }

  private getOppositeConnectionPoint(connectionPoint: ModuleConnections): ModuleConnections {
    let oppositSide: ModuleConnections;

    switch (connectionPoint) {
      case ModuleConnections.center:
      case ModuleConnections.none:
      case ModuleConnections.support: {
        return null;
      }
      case ModuleConnections.right: {
        oppositSide = ModuleConnections.left;
        break;
      }
      case ModuleConnections.right_1: {
        oppositSide = ModuleConnections.left_1;
        break;
      }
      case ModuleConnections.right_2: {
        oppositSide = ModuleConnections.left_2;
        break;
      }
      case ModuleConnections.right_3: {
        oppositSide = ModuleConnections.left_3;
        break;
      }
      case ModuleConnections.left: {
        oppositSide = ModuleConnections.right;
        break;
      }
      case ModuleConnections.left_1: {
        oppositSide = ModuleConnections.right_1;
        break;
      }
      case ModuleConnections.left_2: {
        oppositSide = ModuleConnections.right_2;
        break;
      }
      case ModuleConnections.left_3: {
        oppositSide = ModuleConnections.right_3;
        break;
      }
    }

    return oppositSide;
  }

  private checkSideChangeRestrictions(conveyor: Conveyor, connectionPoint: ModuleConnections): boolean {
    // TODO: check if its possible to change side

    const oppositSide = this.getOppositeConnectionPoint(connectionPoint);



    if (oppositSide) {

      // CHECK IF OPPOSITE SIDE IS ALLOWED
      const beltinfo = this.customerModule.Module.ConveyorBelts.find(ex => ex.ConnectionPoint == connectionPoint);

      if (beltinfo) {
        if (beltinfo.RestrictedConnections) {
          if (!beltinfo.RestrictedConnections.find(ex => ex == oppositSide)) {
            return;
          }
        }
      }


      const oppositeConnection = this.connections.find(ex => ex.moduleConnection === oppositSide);
      const allOtherConnections = this.connections.filter(ex => ex.moduleConnection !== oppositSide && ex.conveyor);

      // CHECK IF ALLOWED BECAUSE OF BELTS
      let changeAllowed = true;

      for (const conn of allOtherConnections) {
        if (conn.moduleConnection) {
          const beltInfo = this.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === conn.moduleConnection);

          if (beltInfo) {
            if (beltInfo.RestrictedConnections.filter(ex => ex === connectionPoint).length > 0) {
              changeAllowed = false;
            }
          }
        }
      }

      if (!changeAllowed) {

        if (this.modul.GetModuleHandling()) {
          this.modul.GetModuleHandling().showMessage('MESSAGEBOX.HEADERS.CHANGEBELTSIDE', 'MESSAGEBOX.CONTENT.BELTSIDECHANGERESTRICTION');
        }

        return false;
      } else {
        if (oppositeConnection) {
          // Check if connection have Module
          if (oppositeConnection.conveyor) {
            if (oppositeConnection.conveyor.EndPointType === EndPointTypes.module) {
              if (this.modul.GetModuleHandling()) {
                this.modul.GetModuleHandling().showMessage('MESSAGEBOX.HEADERS.CHANGEBELTSIDE', 'MESSAGEBOX.CONTENT.BELTSIDECHANGEMODULCONNECTED');
              }
              return false;
            }

            // REMOVE OLD Connect new
            this.removeConveyorConnection(oppositSide);
            return true;

          } else {
            return true;
          }
        } else {
            return true;
        }
      }

    }
    return true;
  }

  public rotateClockwise(): void {
    this.changed = true;
    if (this.rotation === ModuleRotations.degree_0) {
      this.rotation = ModuleRotations.degree_270;
    } else if (this.rotation === ModuleRotations.degree_90) {
      this.rotation = ModuleRotations.degree_0;
    } else if (this.rotation === ModuleRotations.degree_180) {
      this.rotation = ModuleRotations.degree_90;
    } else if (this.rotation === ModuleRotations.degree_270) {
      this.rotation = ModuleRotations.degree_180;
    }
    this.lastRotation = 1;
  }

  public rotateCounterClockwise(): void {
    this.changed = true;
    if (this.rotation === ModuleRotations.degree_0) {
      this.rotation = ModuleRotations.degree_90;
    } else if (this.rotation === ModuleRotations.degree_90) {
      this.rotation = ModuleRotations.degree_180;
    } else if (this.rotation === ModuleRotations.degree_180) {
      this.rotation = ModuleRotations.degree_270;
    } else if (this.rotation === ModuleRotations.degree_270) {
      this.rotation = ModuleRotations.degree_0;
    }
    this.lastRotation = -1;
  }

  public checkIfConfigFinished(): boolean {

    if (!this.customerModule) {
      return false;
    }


    if (!this.customerModule.SerialNumberSetted) {
      this.customerModule.FullyConfigured = false;
      return false;
    }
    if (!this.customerModule.Initialized) {
      this.customerModule.FullyConfigured = false;
      return false;
    }

    this.customerModule.CheckServiceTasks();

    if (this.modul) {
      if (this.modul.Connected) {
        if (this.modul.PositioningRequired) {
          return false;
        }
        if (this.modul.ServiceTaskRequired) {
          return false;
        }
        if (this.modul.NetworkInfo.levellingrequired) {
          return false;
        }

        this.customerModule.FullyConfigured = true;

      }
        return true;
    }

    return false;

  }

  public HandleResetBeltView(component: ModuleComponent) {
    if (component) {
      if (component.Connections) {
        if (component.Connections.length > 0) {
          let haveBelt = false;
          for (const con of component.Connections) {
            // CHECK IF HAVE CONNECTION
            const connected = this.connections.find(ex => ex.moduleConnection === con);

            if (connected) {
              if (connected.conveyor) {
                haveBelt = true;
              }
            }
          }
          if (!haveBelt) {
            for (const con of component.Connections) {
              this.connections = this.connections.filter(ex => ex.moduleConnection !== con);
            }
          }

        }
      }
    }
  }

  public RemoveOnboardingInfo(complete= false) {
    this.customerModule.Module.AddNetworkInfo(null);
    this.customerModule.ActiveErrorNotifications = [];
    this.customerModule.ActiveWarningNotifications = [];
    this.customerModule.InventoryInfo = null;
    this.customerModule.ModuleGlobalState = ModuleGlobalState.Unknown;
    this.customerModule.SafetyConfigActive = false;
    this.customerModule.Module.setOffline();
    this.setConveyorsOffline();
    // SET BELTS OFFLINE



    if (complete) {
      this.customerModule.ResetSetupTasks();
      this.customerModule.FullyConfigured = false;
      this.customerModule.Initialized = false;
      this.customerModule.Module.EmergencyStopRequired = false;
      this.customerModule.Module.PositioningRequired = true;
      this.customerModule.Module.ServiceTaskRequired = true;
      this.customerModule.Module.LevelingRequired = true;
    }
    this.OnNetworkInfoChanged.emit(this.customerModule.Module.NetworkInfo);
  }

  public setModuleNetworkState(networkInfo: any) {
    if (this.customerModule.SerialNumber === networkInfo.serialnumber) {
      // CORRECT
      this.customerModule.Module.AddNetworkInfo(networkInfo as NetworkDevice);

      this.customerModule.Module.Connected = true;
      this.customerModule.InternalBeltStates = networkInfo.belts;

      this.customerModule.Module.EmergencyStopRequired = networkInfo.emergencystoprequired;
      this.customerModule.Module.LevelingRequired = networkInfo.levellingrequired;
      if (networkInfo.switchstate === 'service') {
        this.customerModule.Module.EnableServiceMode();
      } else {
        this.customerModule.Module.DisableServiceMode();
      }

      if (networkInfo.additionalModuleInformations) {
        for (const addi of networkInfo.additionalModuleInformations) {
          // FIND VARIABLE
          if (addi.name) {
            const name = addi.name as string;
            const split = name.split('.');
            if (split.length > 1) {
              const comp = split[0];
              let vari = split[1];

              for (let i = 2; i < split.length; i++) {
                vari = vari + '.' + split[i];
              }

              const component = this.customerModule.Module.Components.find(ex => ex.PlcKey === comp);

              if (component) {
                // FIND VARIABLE
                const configVar = component.Configs.find(ex => ex.UAPath === vari);

                if (configVar) {
                  configVar.SetAdditionalInfo(addi.values);
                }

                const monitorVar = component.Monitorings.find(ex => ex.UAPath === vari);

                if (monitorVar) {
                  monitorVar.SetAdditionalInfo(addi.values);
                }

                const maintVar = component.Maintenance.find(ex => ex.UAPath === vari);

                if (maintVar) {
                  maintVar.SetAdditionalInfo(addi.values);
                }

              }
            }
          }
        }
      }

      this.OnNetworkInfoChanged.emit(this.customerModule.Module.NetworkInfo);
    }

  }

  public moduleOnboardingStarted() {
    this.customerModule.Module.AddNetworkInfo(null);
    this.customerModule.ActiveErrorNotifications = [];
    this.customerModule.ActiveWarningNotifications = [];
    this.customerModule.InventoryInfo = null;
    this.customerModule.ModuleGlobalState = ModuleGlobalState.Unknown;
    this.customerModule.SafetyConfigActive = false;
    this.customerModule.Module.setOffline();
    this.setConveyorsOffline();
    this.customerModule.ResetSetupTasks();
    this.customerModule.FullyConfigured = false;
    this.customerModule.Initialized = false;
    this.customerModule.Module.EmergencyStopRequired = false;
    this.customerModule.Module.PositioningRequired = true;
    this.customerModule.Module.ServiceTaskRequired = true;
    this.customerModule.Module.LevelingRequired = true;
    this.customerModule.Module.DisableServiceMode();
    this.OnNetworkInfoChanged.emit(this.customerModule.Module.NetworkInfo);
  }

  public leaveLiveMode() {
    this.customerModule.Module.AddNetworkInfo(null);
    this.customerModule.ActiveErrorNotifications = [];
    this.customerModule.ActiveWarningNotifications = [];
    this.customerModule.InventoryInfo = null;
    this.customerModule.ModuleGlobalState = ModuleGlobalState.Unknown;
    this.customerModule.SafetyConfigActive = false;
    this.customerModule.Module.setOffline();
    this.customerModule.ResetSetupTasks();
    this.customerModule.FullyConfigured = false;
    this.customerModule.Initialized = false;
    this.customerModule.Module.EmergencyStopRequired = false;
    this.customerModule.Module.PositioningRequired = true;
    this.customerModule.Module.ServiceTaskRequired = true;
    this.customerModule.Module.LevelingRequired = true;
    this.customerModule.SerialNumberSetted = false;
    this.customerModule.SerialNumber = null;
    this.customerModule.Module.DisableServiceMode();
    this.setConveyorsOffline();
    this.OnNetworkInfoChanged.emit(this.customerModule.Module.NetworkInfo);
  }

  public clusterOnboardingStarted() {
    this.customerModule.Module.AddNetworkInfo(null);
    this.customerModule.ActiveErrorNotifications = [];
    this.customerModule.ActiveWarningNotifications = [];
    this.customerModule.Module.setOffline();
    this.setConveyorsOffline();
    this.customerModule.Module.DisableServiceMode();
    this.OnNetworkInfoChanged.emit(this.customerModule.Module.NetworkInfo);
  }

  public clusterConnectionLost() {
    this.customerModule.Module.AddNetworkInfo(null);
    this.customerModule.ActiveErrorNotifications = [];
    this.customerModule.ActiveWarningNotifications = [];
    this.customerModule.Module.setOffline();
    this.customerModule.Module.DisableServiceMode();
    this.setConveyorsOffline();
    this.OnNetworkInfoChanged.emit(this.customerModule.Module.NetworkInfo);

  }

  public setConveyorsOffline() {
    for (const con of this.connections) {
      if (con.conveyor) {

        if (con.conveyor.Conntected) {
          con.conveyor.setConveyorOnlineState(false);
        }
      }
    }
  }

  public setRequestedParameter(msg: any) {

    if(this.customerModule) {
      if(this.customerModule.Module) {
        if(msg.component && msg.parameter && (msg.value !== null && msg.value !== undefined)) {
          const component = this.customerModule.Module.Components.find(ex => ex.PlcKey === msg.component);

          if(component != null) {
            const parameter = component.Configs.find(ex => ex.Parameter === msg.parameter);
            if(parameter) {
              parameter.SetValue(msg.value);
            }
          }
        }
      }
    }
  }


}

