import {EventEmitter, Injectable} from '@angular/core';
import {MessageHandlingService} from './message-handling.service';
import {WebsocketService} from './websocket.service';
import {ModulePlan} from '../../classes/module-plan';
import {ModuleOnboardingErrors} from '../../classes/enums/module-onboarding-errors';
import {WebsocketConnectionErrors} from '../../classes/enums/websocket-connection-errors';
import {WifiManagementService} from './wifi-management.service';
import {WifiStates} from '../../classes/enums/wifi-states';
import {environment} from '../../../environments/environment';
import {InitStateMessage} from '../../classes/init-state-message';
import {ModuleOnboardingActions} from '../../classes/enums/module-onboarding-actions';
import {ActionInitState} from '../../classes/enums/action-init-state.enum';
import {HttpClient} from '@angular/common/http';
import * as uuid from 'uuid';
import {ModuleHandlingService} from '../module-handling.service';
import {BluectrlTranslateService} from '../bluectrl-translate.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {QuestionBoxComponent} from '../../components/dialogs/question-box/question-box.component';
import {MessageBoxComponent} from '../../components/dialogs/message-box/message-box.component';
import {DatabaseService} from '../database.service';
import {ClusterOnboardingService} from './cluster-onboarding.service';
import {ConfirmMessageBoxComponent} from '../../components/dialogs/confirm-message-box/confirm-message-box.component';
import {ViewCode} from '../../classes/enums/view-code.enum';
import {OnboardingLogItem} from '../../classes/onboarding-log-item';
import {OnboardingLogType} from '../../classes/enums/onboarding-log-type';
import {ClusterOnboardingActions} from '../../classes/enums/cluster-onboarding-actions';
import compareVersions from 'compare-versions';
import {ServerCommunicationService} from '../server-communication.service';

@Injectable({
  providedIn: 'root'
})
export class ModuleOnboardingService {

  private ActiveModulePlan: ModulePlan;
  public OnboardingRunning = false;
  private OnboardingStopRequested = false;
  private targetSSID: string;
  private requestHandler: any;
  private timeoutHandler: any;
  public currentOnboardingId: any;
  public OnboardingCancled = new EventEmitter();
  public OnboardingError = new EventEmitter<ModuleOnboardingErrors>();
  public OnboardingProgress = new EventEmitter<InitStateMessage>();
  public ModuleClusterReset = new EventEmitter();
  public ModuleClusterResetFinished = new EventEmitter();
  public WaitingForModuleStop = new EventEmitter();
  public ModulesStopped = new EventEmitter();
  public NewLogMessage = new EventEmitter<OnboardingLogItem>();
  public AppendToLastMessage = new EventEmitter<string>();

  public StandaloneModuleOnboardingFinished = new EventEmitter<{
    MasterSerialNumber: string,
    MasterIpAddress: string,
    ClusterId: string
  }>();

  public ClusterReinitRequested = new EventEmitter<{
    MasterSerialNumber: string,
    MasterIpAddress: string,
    ClusterId: string
  }>();

  public ModuleOnboardingReinitRequest = new EventEmitter();
  public NewOnboardingId = new EventEmitter<any>();

  private wifiChangeRetries = 0;
  private moduleWebsocketInitStarted = false;
  private waitForWebsocketClosed = false;
  private waitForClusterReset = false;
  private waitForClusterRecipeLoaded = false;
  private waitForModuleStop = false;
  private waitForClusterConfig = false;
  private waitForModuleInit = false;
  private wifiChangeRequested = false;
  private currentMsgId: string;
  private standAloneOnboarding = false;
  public currentMasterModule: string;
  private standAloneRecipe: any;
  private standAloneClusterId: string;
  private standAloneProjectId: string;
  private waitForDMSUpdated = false;


  private clusterRequestResponeReceived = false;

  constructor(private messaging: MessageHandlingService,
              private websocket: WebsocketService,
              private wifi: WifiManagementService,
              private http: HttpClient,
              private moduleHandling: ModuleHandlingService,
              private translate: BluectrlTranslateService,
              private dialog: MatDialog,
              private database: DatabaseService,
              private clusterOnboarding: ClusterOnboardingService,
              private server: ServerCommunicationService) {

    this.websocket.WebsocketClosed.subscribe(this.onWebsocketClosed.bind(this));
    this.websocket.WebsocketError.subscribe(this.onWebsocketError.bind(this));
    this.websocket.WebsocketConnected.subscribe(this.onWebsocketConnected.bind(this));
    this.websocket.Disconnected.subscribe(this.onWebsocketDisconnected.bind(this));
    this.websocket.ConnectionError.subscribe(this.onWebsocketConnectionError.bind(this));

    this.messaging.ClusterRequestResponse.subscribe(this.onClusterRequestResponse.bind(this));
    this.messaging.ClusterResetResponse.subscribe(this.onClusterReset.bind(this));
    this.messaging.ModulesStoppedMessage.subscribe(this.onModuleStopped.bind(this));
    this.messaging.ClusterConfigResponse.subscribe(this.OnClusterConfigResponse.bind(this));
    this.messaging.ModuleInitResponse.subscribe(this.onModuleInitResponse.bind(this));
    this.messaging.UpdateSpecificationResponse.subscribe(this.onDmsUpdatedResponse.bind(this));
    this.messaging.ModuleOnboardingLogMessage.subscribe(this.onLogMessage.bind(this));

    this.wifi.WifiChangeError.subscribe(this.onWifiChangeError.bind(this));
    this.wifi.WifiChanged.subscribe(this.onWifiChanged.bind(this));

    this.moduleHandling.ModuleOnboardingRecipeLoaded.subscribe(this.OnboardingRecipeLoaded.bind(this));
    this.moduleHandling.OnProjectLoaded.subscribe(this.onNewProjectLoaded.bind(this));
    this.moduleHandling.NewProjectCreated.subscribe(this.onNewProjectCreated.bind(this));
    this.moduleHandling.OnViewModeChanged.subscribe(this.onViewModeChanged.bind(this));
  }

  private onViewModeChanged(viewMode: ViewCode) {
    if (viewMode !== ViewCode.live) {
      this.CancelOnboarding();
    }
  }

  private onNewProjectCreated() {
    this.CancelOnboarding();
  }

  private onNewProjectLoaded() {
    this.currentMasterModule = null;
  }

  private onWebsocketConnected() {
    if (this.OnboardingRunning && !this.isCancled()) {
      if (this.moduleWebsocketInitStarted) {
        this.sendProgress(ModuleOnboardingActions.CONNECTMODULE, ActionInitState.success);
        // CLUSTER REQUEST
        this.NewLogMessage.emit(new OnboardingLogItem(
          this.translate.GetTranslation('LOGMESSAGES.WEBSOCKETCONNECTIONSUCCESSFULL_1') +
          ' (' + this.ActiveModulePlan.customerModule.SerialNumber + ') ' +
          this.translate.GetTranslation('LOGMESSAGES.WEBSOCKETCONNECTIONSUCCESSFULL_2'),
          OnboardingLogType.INFO));

        this.sendClusterRequest();
      }
    }
  }

  private onWebsocketClosed() {
      if (this.OnboardingRunning && !this.isCancled()) {
        if (this.moduleWebsocketInitStarted) {
          this.stopInitOnError(ModuleOnboardingActions.CONNECTMODULE, false);
        }
      } else if (this.ActiveModulePlan &&
        !this.ActiveModulePlan.customerModule.FullyConfigured &&
        this.ActiveModulePlan.customerModule.Initialized) {
        // TODO: RE-ONBOARDING MODULE
        this.ModuleOnboardingReinitRequest.emit();
      }

  }

  private onWebsocketDisconnected() {
    if (this.OnboardingRunning && !this.isCancled()) {
      if (this.waitForWebsocketClosed) {
        this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.WEBSOCKETCONNECTIONCLOSED'),
          OnboardingLogType.DEBUG));
        this.waitForWebsocketClosed = false;
        this.connectToWebsocket();
      } else {
        this.startOnboardingProcess();
      }
    }
  }

  private onWebsocketConnectionError(error: WebsocketConnectionErrors) {
    if (this.OnboardingRunning && !this.isCancled()) {
      if (this.moduleWebsocketInitStarted) {
        this.stopInitOnError(ModuleOnboardingActions.CONNECTMODULE, false);
      }
    }
  }

  private onWebsocketError() {
    if (this.OnboardingRunning && !this.isCancled()) {
      if (this.moduleWebsocketInitStarted) {
        this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.ERRORONWEBSOCKETCONNECT'),
          OnboardingLogType.DEBUG));
        this.stopInitOnError(ModuleOnboardingActions.CONNECTMODULE, false);
      }
    }
  }

  private onWifiChanged(ssid: string) {
    if (this.OnboardingRunning && !this.isCancled() && !this.moduleWebsocketInitStarted) {
      if (this.wifiChangeRequested) {
        // SUCCESS
        if (this.wifi.getCurrentSSID()) {
          this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.WIFICHANGEDONE') +
            ' ' + this.wifi.getCurrentSSID(), OnboardingLogType.DEBUG));
        } else {
          this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.NOWIFICONNECTION'),
            OnboardingLogType.DEBUG));
        }

        this.wifiChangeRequested = false;
        if (this.targetSSID === this.wifi.getCurrentSSID()) {

          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.SUCCESSFULLWIFICONNECTION_1') +
            ' ' + this.targetSSID + ' ' + this.translate.GetTranslation('LOGMESSAGES.SUCCESSFULLWIFICONNECTION_2'),
            OnboardingLogType.INFO));

          this.sendProgress(ModuleOnboardingActions.CONNECTWIFI, ActionInitState.success);
          this.InitWebsocketConnection();
        } else {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.WIFINOTREACHED_1') +
            ' ' + this.targetSSID + ' ' +
            this.translate.GetTranslation('LOGMESSAGES.WIFINOTREACHED_2'),
            OnboardingLogType.WARNING));
          this.rechangeWifi();
        }
      } else {
        this.NewLogMessage.emit(new OnboardingLogItem(
          this.translate.GetTranslation('LOGMESSAGES.UNREGISTEREDWIFICHANGE'),
          OnboardingLogType.ERROR));
        this.stopInitOnError(ModuleOnboardingActions.CONNECTWIFI);
      }
    } else if (this.OnboardingRunning && !this.isCancled()) {
      if (!this.wifiChangeRequested) {
        this.stopInitOnError(ModuleOnboardingActions.CONNECTWIFI);
      }
    }
  }

  private rechangeWifi() {
    this.wifiChangeRetries += 1;
    if (this.wifiChangeRetries < 7) {
      this.NewLogMessage.emit(new OnboardingLogItem(
        this.translate.GetTranslation('LOGMESSAGES.TRYCHANGEWIFI_1') + ' ' + this.targetSSID +
        ' ' + this.translate.GetTranslation('LOGMESSAGES.TRYCHANGEWIFI_2') + '. ' +
        this.translate.GetTranslation('LOGMESSAGES.TRYCOUNTER') + ' ' +
        (this.wifiChangeRetries + 1 ).toString() + ' / 7' , OnboardingLogType.INFO));

      this.wifiChangeRequested = true;
      this.wifi.changeWifi(this.targetSSID, environment.DefaultModuleWifiKey);
    } else {
      // ERROR
      this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.AUTOWIFICHANGEFAILED'),
        OnboardingLogType.WARNING));
      this.sendProgress(ModuleOnboardingActions.CONNECTWIFI, ActionInitState.wifiSkip);
      this.continueWithoutWifiService();
    }
  }

  private onWifiChangeError(state: WifiStates) {
    if (this.OnboardingRunning && !this.isCancled()  && !this.moduleWebsocketInitStarted) {
      if (this.wifiChangeRequested) {
        // FAILED RETRY
        this.NewLogMessage.emit(new OnboardingLogItem(
          this.translate.GetTranslation('LOGMESSAGES.WIFINOTREACHED_1') +
          ' ' + this.targetSSID + ' ' +
          this.translate.GetTranslation('LOGMESSAGES.WIFINOTREACHED_2'),
          OnboardingLogType.WARNING));
        this.wifiChangeRequested = false;
        this.rechangeWifi();
      }
    }
  }

  public CancelOnboarding(): boolean {
    if (this.OnboardingRunning) {
      this.OnboardingStopRequested = true;
    }

    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
    }
    this.OnboardingCancled.emit();
    return true;
  }

  private resetOnboardingParameters() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
    }
    if (this.requestHandler) {
      clearTimeout(this.requestHandler);
    }
    this.OnboardingRunning = false;
    this.OnboardingStopRequested = false;
    this.targetSSID = null;
    this.currentOnboardingId = null;
    this.wifiChangeRetries = 0;
    this.moduleWebsocketInitStarted = false;
    this.waitForWebsocketClosed = false;
    this.waitForClusterReset = false;
    this.waitForClusterRecipeLoaded = false;
    this.waitForModuleStop = false;
    this.waitForClusterConfig = false;
    this.waitForModuleInit = false;
    this.wifiChangeRequested = false;
    this.waitForDMSUpdated = false;
    this.standAloneRecipe = null;
    this.standAloneOnboarding = false;


  }

  private FinishModuleOnboarding() {
    this.NewLogMessage.emit(new OnboardingLogItem(
      this.translate.GetTranslation('LOGMESSAGES.FINISHMODULEONBOARDING'),
      OnboardingLogType.INFO));
    this.sendProgress(ModuleOnboardingActions.INITENVIRONMENT, ActionInitState.success);
    this.resetOnboardingParameters();
  }

  private AbortOnBoardingProcess() {
    this.resetOnboardingParameters();
    this.clusterOnboarding.resetOnboarding();
    this.ActiveModulePlan.RemoveOnboardingInfo(true);
    this.ActiveModulePlan = null;
    this.targetSSID = null;
    if (this.websocket.IsWebsocketConnected()) {
      this.websocket.Disconnect();
    }
    this.OnboardingCancled.emit();

  }

  private isCancled(): boolean {
    if (this.OnboardingStopRequested) {
      // STOP IT
      this.AbortOnBoardingProcess();
      return true;
    } else {
      return false;
    }
  }

  private onLogMessage(msg: any) {
    if (this.OnboardingRunning && !this.isCancled()) {

      this.NewLogMessage.emit(new OnboardingLogItem(msg.text,
        OnboardingLogType.INFO));

    }
  }

  public StartStandaloneOnbording(moduleplan: ModulePlan, standaloneRecipe: any, clusterId: string, projectId: string) {


    this.resetOnboardingParameters();

    if (this.OnboardingRunning) {
      this.OnboardingError.emit(ModuleOnboardingErrors.OnboardingRunning);
    } else {

      this.clusterOnboarding.resetOnboarding();
      this.standAloneRecipe = standaloneRecipe;
      this.standAloneClusterId = clusterId;
      this.standAloneProjectId = projectId;
      this.standAloneOnboarding = true;

      if (moduleplan.customerModule.SerialNumber) {
        moduleplan.customerModule.SerialNumberSetted = true;
      }

      if (moduleplan.customerModule) {
        if (moduleplan.customerModule.SerialNumberSetted) {
          this.ActiveModulePlan = moduleplan;
          this.targetSSID = environment.ModuleWifiSurFix + this.ActiveModulePlan.customerModule.SerialNumber;
          this.InitOnboarding();
        } else {
          this.OnboardingError.emit(ModuleOnboardingErrors.SerialNumberNotSet);
        }
      } else {
        this.OnboardingError.emit(ModuleOnboardingErrors.NoCustomerModule);
      }
    }
  }

  public StartOnboarding(moduleplan: ModulePlan) {

    if (this.OnboardingRunning || this.isCancled()) {

    }

    this.resetOnboardingParameters();

    if (this.OnboardingRunning) {
      this.OnboardingError.emit(ModuleOnboardingErrors.OnboardingRunning);
    } else {
      this.clusterOnboarding.resetOnboarding();

      if (moduleplan.customerModule) {
        if (moduleplan.customerModule.SerialNumberSetted) {
          this.ActiveModulePlan = moduleplan;
          this.targetSSID = environment.ModuleWifiSurFix + this.ActiveModulePlan.customerModule.SerialNumber;

          this.InitOnboarding();
        } else {
          this.OnboardingError.emit(ModuleOnboardingErrors.SerialNumberNotSet);
        }
      } else {
        this.OnboardingError.emit(ModuleOnboardingErrors.NoCustomerModule);
      }
    }
  }

  private InitOnboarding() {



    if (this.ActiveModulePlan) {
      this.OnboardingRunning = true;
      this.wifiChangeRetries = 0;
      this.currentOnboardingId =  uuid.v4();
      this.NewOnboardingId.emit(this.currentOnboardingId);
      // LETS GO
      this.ActiveModulePlan.RemoveOnboardingInfo(true);
      if (this.websocket.IsWebsocketConnected()) {
        this.websocket.Disconnect();
      } else {
        this.startOnboardingProcess();
      }
    }
  }

  private sendProgress(action: ModuleOnboardingActions, state: ActionInitState, forced: boolean = false) {
    if (this.OnboardingRunning) {
      this.OnboardingProgress.emit(new InitStateMessage(action, state, forced));
    }
  }

  private stopInitOnError(action: ModuleOnboardingActions, forced: boolean = false) {
    if (this.OnboardingRunning && !this.isCancled()) {
      this.sendProgress(action, ActionInitState.failed, forced);
      this.AbortOnBoardingProcess();

    }
  }

  private startOnboardingProcess() {
    if (this.OnboardingRunning && !this.isCancled()) {
      // WIFI CHECK
      if (this.wifi.ServiceAvailable) {
        if (this.wifi.getCurrentSSID() === this.targetSSID) {
          // NEXT STEP
          this.sendProgress(ModuleOnboardingActions.CONNECTWIFI, ActionInitState.success);
          this.InitWebsocketConnection();
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.CURRENTWIFIISCORRECT') + ' ' + this.targetSSID,
            OnboardingLogType.DEBUG));
        } else {
          this.wifiChangeRequested = true;
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.TRYCHANGEWIFI_1') + ' ' + this.targetSSID +
            ' ' + this.translate.GetTranslation('LOGMESSAGES.TRYCHANGEWIFI_2') + '. ' +
            this.translate.GetTranslation('LOGMESSAGES.TRYCOUNTER') + ' ' +
            (this.wifiChangeRetries + 1 ).toString() + ' / 7' , OnboardingLogType.INFO));
          this.wifi.changeWifi(this.targetSSID, environment.DefaultModuleWifiKey);
        }
      } else {
        this.sendProgress(ModuleOnboardingActions.CONNECTWIFI, ActionInitState.noWifiService, false);
      }
    }
  }

  public continueWithoutWifiService() {
    if (this.OnboardingRunning && !this.isCancled()) {
      this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.WIFICHANGENOTPOSSIBLE'),
        OnboardingLogType.WARNING));
      this.InitWebsocketConnection();
    }
  }

  private InitWebsocketConnection() {

    if (this.OnboardingRunning && !this.isCancled()) {
      if (!this.moduleWebsocketInitStarted) {
        this.moduleWebsocketInitStarted  = true;
        // GET INIT INFO
        let moduleRespones = false;

        if (this.requestHandler) {
          clearTimeout(this.requestHandler);
        }

        this.requestHandler = setTimeout(() => {
          try {
            this.NewLogMessage.emit(new OnboardingLogItem(
              this.translate.GetTranslation('LOGMESSAGES.TRYGETCONNECTIONINFO_1') +
              ' ' + this.ActiveModulePlan.customerModule.SerialNumber + ' ' +
              this.translate.GetTranslation('LOGMESSAGES.TRYGETCONNECTIONINFO_2'),
              OnboardingLogType.INFO));

            return this.http.get(environment.DefaultModuleApi, {
              responseType: 'json',
              observe: 'response',
            }).subscribe(response => {
              clearTimeout(this.requestHandler);
              moduleRespones = true;
              this.NewLogMessage.emit(new OnboardingLogItem(
                this.translate.GetTranslation('LOGMESSAGES.GOTCONNECTIONINFO_1') +
                ' ' + this.ActiveModulePlan.customerModule.SerialNumber + ' ' +
                this.translate.GetTranslation('LOGMESSAGES.GOTCONNECTIONINFO_2'),
                OnboardingLogType.DEBUG));
              this.HandleWebsocketInitResponse(response);
            }, () => {
              clearTimeout(this.requestHandler);
              this.NewLogMessage.emit(new OnboardingLogItem(
                this.translate.GetTranslation('LOGMESSAGES.ERRORCONNECTIONINFO'),
                OnboardingLogType.ERROR));
              this.stopInitOnError(ModuleOnboardingActions.CONNECTMODULE, false);
            });
          } catch {
            clearTimeout(this.requestHandler);
            this.NewLogMessage.emit(new OnboardingLogItem(
              this.translate.GetTranslation('LOGMESSAGES.ERRORCONNECTIONINFO'),
              OnboardingLogType.ERROR));
            this.stopInitOnError(ModuleOnboardingActions.CONNECTMODULE, false);
          }
        }, 4000);

        if (this.timeoutHandler) {
          clearTimeout(this.timeoutHandler);
        }
        this.timeoutHandler = setTimeout(() => {
          if (moduleRespones === false) {
            if (this.OnboardingRunning && !this.isCancled()) {
              this.NewLogMessage.emit(new OnboardingLogItem(
                this.translate.GetTranslation('LOGMESSAGES.TIMEOUTCONNECTIONINFO'),
                OnboardingLogType.ERROR));
              this.stopInitOnError(ModuleOnboardingActions.CONNECTMODULE, false);
            }
          }
        }, 10000);


      }
    }
  }

  private HandleWebsocketInitResponse(nodeInfo: any) {
    if (this.OnboardingRunning && !this.isCancled() && this.moduleWebsocketInitStarted) {
      if (nodeInfo) {
        if (nodeInfo.status === 200) {
          let info = nodeInfo.body;

          if (info.Data) {
            info = info.Data;
          }
          // CHECK RESPONSE
          if (info.SerialNumber === 'Not Found') {
            this.NewLogMessage.emit(new OnboardingLogItem(
              this.translate.GetTranslation('LOGMESSAGES.MODULENOTFOUND'),
              OnboardingLogType.ERROR));
            this.stopInitOnError(ModuleOnboardingActions.CONNECTMODULE, false);
          } else if (this.ActiveModulePlan.customerModule.SerialNumber !== info.SerialNumber) {
            this.NewLogMessage.emit(new OnboardingLogItem(
              this.translate.GetTranslation('LOGMESSAGES.MODULENOTFOUND'),
              OnboardingLogType.ERROR));
            this.stopInitOnError(ModuleOnboardingActions.CONNECTMODULE, false);
          } else {
            this.ActiveModulePlan.customerModule.NodeIpAddress = info.ClusterIP;
            // SET WEBSOCKET CONNECTION
            if (this.websocket.IsWebsocketConnected()) {
              // CLOSE BEFORE
              this.waitForWebsocketClosed = true;
              this.websocket.Disconnect();
            } else {
              this.connectToWebsocket();

            }
          }
        } else {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.ERRORCONNECTIONINFO'),
            OnboardingLogType.ERROR));
          this.stopInitOnError(ModuleOnboardingActions.CONNECTMODULE, false);
        }
      } else {
        this.NewLogMessage.emit(new OnboardingLogItem(
          this.translate.GetTranslation('LOGMESSAGES.ERRORCONNECTIONINFO'),
          OnboardingLogType.ERROR));
        this.stopInitOnError(ModuleOnboardingActions.CONNECTMODULE, false);
      }
    }
  }

  private connectToWebsocket() {
    if (this.OnboardingRunning && !this.isCancled() && this.moduleWebsocketInitStarted) {
      this.NewLogMessage.emit(new OnboardingLogItem(
        this.translate.GetTranslation('LOGMESSAGES.TRYTOCONNECTTOMODULE_1') +
        ' (' + this.ActiveModulePlan.customerModule.SerialNumber + ') ' +
        this.translate.GetTranslation('LOGMESSAGES.TRYTOCONNECTTOMODULE_2'),
        OnboardingLogType.INFO));
      this.websocket.ConnectToSecuredServer(this.ActiveModulePlan.customerModule.NodeIpAddress, true);
    }
  }

  private sendClusterRequest() {
    if (this.OnboardingRunning && !this.isCancled() && this.moduleWebsocketInitStarted) {
      if (this.websocket.IsWebsocketConnected()) {
        this.currentMsgId = uuid.v4();
        this.clusterRequestResponeReceived = false;
        this.NewLogMessage.emit(new OnboardingLogItem(
          this.translate.GetTranslation('LOGMESSAGES.REQUESTCURRENTCLUSTER'),
          OnboardingLogType.INFO));
        if (!this.messaging.requestCurrentModuleCluster(this.currentMsgId, this.ActiveModulePlan.customerModule.SerialNumber)) {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.ERRORREQUESTCLUSTER'),
            OnboardingLogType.ERROR));
          this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA, false);
        } else {
          if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
          }
          this.timeoutHandler = setTimeout(() => {
            if (this.OnboardingRunning && !this.isCancled() && this.moduleWebsocketInitStarted) {
              if (!this.clusterRequestResponeReceived) {
                this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
              }
            }
          }, 20000);
        }
      } else {
        this.NewLogMessage.emit(new OnboardingLogItem(
          this.translate.GetTranslation('LOGMESSAGES.ERRORREQUESTCLUSTER'),
          OnboardingLogType.ERROR));
        this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA, false);
      }
    }
  }

  private onClusterRequestResponse(clusterInfo: any) {
    if (this.OnboardingRunning && !this.isCancled() && this.moduleWebsocketInitStarted) {
      if (this.currentMsgId === clusterInfo.msgId) {
        this.clusterRequestResponeReceived = true;

        if (clusterInfo.ctxId === this.ActiveModulePlan.customerModule.SerialNumber) {
          // CHECK IF MODULE HAVE RECIPE
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.CLUSTERINFORECEIVED'),
            OnboardingLogType.DEBUG));

          if (!this.standAloneOnboarding) {

            if (clusterInfo.recipeId === null) {
              // NO RECIPE
              // CONTINUE INIT
              this.sendProgress(ModuleOnboardingActions.REQUESTDATA, ActionInitState.success);
              this.sendClusterConfig();
            } else if (clusterInfo.recipeId === this.moduleHandling.ActiveProject.id && this.moduleHandling.ActiveProject.cluster) {
              if (!this.waitForClusterReset) {

                if (this.moduleHandling.ActiveProject.Modules.filter(ex => ex.customerModule.FullyConfigured).length > 0) {
                  this.resetClusterRequest();
                  return;
                }

                if (this.moduleHandling.ActiveProject.SupportModules) {
                  if (this.moduleHandling.ActiveProject.SupportModules.filter(ex => ex.customerModule.FullyConfigured).length > 0) {
                    this.resetClusterRequest();
                    return;
                  }
                }

                this.NewLogMessage.emit(new OnboardingLogItem(
                  this.translate.GetTranslation('LOGMESSAGES.CONNECTEDTOCURRENTCLUSTER'),
                  OnboardingLogType.DEBUG));

                // NO RESET REQUESTED
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'loginDialogGray';
                dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.SAMECLUSTER');
                const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(result => {
                  if (result === true) {
                    this.checkCurrentModuleRecipe(clusterInfo);
                  } else {
                    // RESET CLUSTER
                    this.resetClusterRequest();
                  }
                });


              } else {
                // RESET ALREADY REQUESTED
                this.resetClusterRequest();
              }

            } else {
              // DIFFERENT CLUSTER
              if (this.moduleHandling.ActiveProject.Modules.filter(ex => ex.customerModule.FullyConfigured).length > 0) {
                this.resetClusterRequest();
                return;
              }

              if (this.moduleHandling.ActiveProject.SupportModules) {
                if (this.moduleHandling.ActiveProject.Modules.filter(ex => ex.customerModule.FullyConfigured).length > 0) {
                  this.resetClusterRequest();
                  return;
                }
              }



              const clusterId = clusterInfo.clusterId as string;

              if (clusterId.startsWith('service') || clusterId.startsWith('temp-')) {
                this.NewLogMessage.emit(new OnboardingLogItem(
                  this.translate.GetTranslation('LOGMESSAGES.MODULEINSERVICEORTEMPCLUSTER'),
                  OnboardingLogType.DEBUG));
                this.resetClusterRequest();
              } else if (!this.waitForClusterReset) {
                // INFORM USER
                this.NewLogMessage.emit(new OnboardingLogItem(
                  this.translate.GetTranslation('LOGMESSAGES.MODULEINDIFFERENTCLUSTER'),
                  OnboardingLogType.DEBUG));
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'loginDialogGray';
                dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MODULEINDIFFERENTCLUSTER');
                const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(result => {
                  if (result === true) {
                    this.resetClusterRequest();
                  } else {
                    // TRY TO LOAD MODULE CLUSTER?
                    const dialogConfig2 = new MatDialogConfig();
                    dialogConfig2.disableClose = true;
                    dialogConfig2.autoFocus = true;
                    dialogConfig2.panelClass = 'loginDialogGray';
                    dialogConfig2.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.LOADCURRENTCLUSTER');
                    const dialogRef2 = this.dialog.open(QuestionBoxComponent, dialogConfig2);
                    dialogRef2.afterClosed().subscribe(result2 => {
                      if (result2 === true) {

                        // TRY TO LOAD IT
                        this.loadRecipeForCurrentModule(clusterInfo);

                      } else {
                        // CANCEL ONBOARDING
                        const dialogRef1 = this.dialog.open(MessageBoxComponent,
                          {
                            width: 200 + 'px',
                            panelClass: 'panelclass',
                            data: this.translate.GetTranslation('MESSAGEBOX.CONTENT.INITCANCELED')
                          });
                        this.NewLogMessage.emit(new OnboardingLogItem(
                          this.translate.GetTranslation('LOGMESSAGES.CANCELONBOARDINGONUSER'),
                          OnboardingLogType.DEBUG));
                        dialogRef1.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});
                        this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
                      }
                    });
                  }
                });
              }
            }
          } else {
            if (clusterInfo.recipeId === null) {
              // NO RECIPE
              // CONTINUE INIT
              this.sendProgress(ModuleOnboardingActions.REQUESTDATA, ActionInitState.success);
              this.sendClusterConfig();
            } else {
              this.resetClusterRequest();
            }
          }
        }
      }
    }
  }

  private checkSerial(serial: string, plan: ModulePlan): boolean {
    if(serial && plan) {

        const key = serial.substring(0, serial.length - 5);

        if (key === plan.customerModule.Module.Key) {
          return true;
        } else {
          return false;
        }
    } else {
      return  false;
    }
  }

  private checkCurrentModuleRecipe(clusterinfo: any) {
    const recipe = clusterinfo.recipe;
    if (recipe) {
      if (recipe.composition && recipe.connection) {
        if (recipe.composition.length === (this.moduleHandling.ActiveProject.Modules.length +
            (this.moduleHandling.ActiveProject.SupportModules ? this.moduleHandling.ActiveProject.SupportModules.length : 0) ) &&
          recipe.connection.length === this.moduleHandling.ActiveProject.Modules.length +
          (this.moduleHandling.ActiveProject.SupportModules ? this.moduleHandling.ActiveProject.SupportModules.length : 0)) {
          for (const md of this.moduleHandling.ActiveProject.Modules) {
            if (md.seqno >= 0) {

              const connect = recipe.connection[md.seqno];
              const seri = recipe.composition[md.seqno];
              if (connect && seri) {
                if (md.seqno >= this.moduleHandling.sequenceNumberToOnboard) {
                  this.moduleHandling.sequenceNumberToOnboard = md.seqno + 1;
                }

                // Check Serial

                if (this.checkSerial(seri, md) === false) {
                  this.NewLogMessage.emit(new OnboardingLogItem(
                    this.translate.GetTranslation('LOGMESSAGES.CONSTELLATIONCHANGED') + ' ' + md.modul.Type + '-' + md.modul.Version,
                    OnboardingLogType.ERROR));

                  recipe.connection = null;
                  recipe.composition = null;
                  this.moduleHandling.ActiveProject.cluster = null;
                  this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
                  return;
                }

                md.customerModule.SerialNumber = seri;
                md.customerModule.NodeIpAddress = connect.node;
                md.customerModule.SerialNumberSetted = true;
                md.customerModule.Initialized = true;
                md.customerModule.Module.PositioningRequired = false;
                md.customerModule.FullyConfigured = true;
                md.customerModule.Module.LevelingRequired = false;
                md.ConfigFinishedSended = true;
                md.customerModule.Module.ServiceTaskRequired = false;
                if (md.modul.NetworkInfo) {
                  md.modul.NetworkInfo.levellingrequired = false;
                }

                if (md.customerModule.ActiveTasks) {
                  for (const tsk of md.customerModule.ActiveTasks) {
                    tsk.Done = true;
                  }
                }


              } else {
                this.NewLogMessage.emit(new OnboardingLogItem(
                  this.translate.GetTranslation('LOGMESSAGES.MODULENOTINCLUSTER') + ' ' + md.modul.Type + '-' + md.modul.Version,
                  OnboardingLogType.ERROR));
                this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
                return;
              }
            } else {
              this.NewLogMessage.emit(new OnboardingLogItem(
                this.translate.GetTranslation('LOGMESSAGES.MODULENOTINCLUSTER') + ' ' + md.modul.Type + '-' + md.modul.Version,
                OnboardingLogType.ERROR));
              this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
              return;
            }
          }

          if (this.moduleHandling.ActiveProject.SupportModules) {
            for (const md of this.moduleHandling.ActiveProject.SupportModules) {
              if (md.seqno >= 0) {


                const connect = recipe.connection[md.seqno];
                const seri = recipe.composition[md.seqno];
                if (connect && seri) {
                  if (md.seqno >= this.moduleHandling.sequenceNumberToOnboard) {
                    this.moduleHandling.sequenceNumberToOnboard = md.seqno + 1;
                  }

                  if (this.checkSerial(seri, md) === false) {
                    this.NewLogMessage.emit(new OnboardingLogItem(
                      this.translate.GetTranslation('LOGMESSAGES.CONSTELLATIONCHANGED') + ' ' + md.modul.Type + '-' + md.modul.Version,
                      OnboardingLogType.ERROR));

                    recipe.connection = null;
                    recipe.composition = null;
                    this.moduleHandling.ActiveProject.cluster = null;
                    this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
                    return;
                  }

                  md.customerModule.SerialNumber = seri;
                  md.customerModule.NodeIpAddress = connect.node;
                  md.customerModule.SerialNumberSetted = true;
                  md.customerModule.Initialized = true;
                  md.customerModule.Module.PositioningRequired = false;
                  md.customerModule.FullyConfigured = true;
                  md.customerModule.Module.LevelingRequired = false;
                  md.ConfigFinishedSended = true;
                  md.customerModule.Module.ServiceTaskRequired = false;
                  if (md.modul.NetworkInfo) {
                    md.modul.NetworkInfo.levellingrequired = false;
                  }

                  if (md.customerModule.ActiveTasks) {
                    for (const tsk of md.customerModule.ActiveTasks) {
                      tsk.Done = true;
                    }
                  }


                } else {
                  this.NewLogMessage.emit(new OnboardingLogItem(
                    this.translate.GetTranslation('LOGMESSAGES.MODULENOTINCLUSTER') + ' ' + md.modul.Type + '-' + md.modul.Version,
                    OnboardingLogType.ERROR));
                  this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
                  return;
                }
              } else {
                this.NewLogMessage.emit(new OnboardingLogItem(
                  this.translate.GetTranslation('LOGMESSAGES.MODULENOTINCLUSTER') + ' ' + md.modul.Type + '-' + md.modul.Version,
                  OnboardingLogType.ERROR));
                this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
                return;
              }
            }
          }

          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.CLUSTERRECIPELOADED'),
            OnboardingLogType.INFO));
          this.sendProgress(ModuleOnboardingActions.REQUESTDATA, ActionInitState.success);
          this.sendProgress(ModuleOnboardingActions.CONFIGURECLUSTER, ActionInitState.success);
          this.FinishModuleOnboarding();

          this.ClusterReinitRequested.emit({
            ClusterId: clusterinfo.clusterId,
            MasterIpAddress: clusterinfo.masterIp,
            MasterSerialNumber: clusterinfo.masterModule
          });

        } else {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.ERRORLOADRECIPE'),
            OnboardingLogType.ERROR));
          this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
        }
      } else {
        this.NewLogMessage.emit(new OnboardingLogItem(
          this.translate.GetTranslation('LOGMESSAGES.ERRORLOADRECIPE'),
          OnboardingLogType.ERROR));
        this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
      }
    } else {
      this.NewLogMessage.emit(new OnboardingLogItem(
        this.translate.GetTranslation('LOGMESSAGES.ERRORLOADRECIPE'),
        OnboardingLogType.ERROR));
      this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
    }
  }

  private loadRecipeForCurrentModule(clusterinfo: any) {
    if (this.OnboardingRunning && !this.isCancled() && this.moduleWebsocketInitStarted) {
      this.database.recipes.toArray().then((recipies) => {
        const existing = recipies.find(ex => ex.id === clusterinfo.recipeId);
        if (existing) {
          // LOAD RECIPE
          const recipe = JSON.parse(JSON.stringify(existing));
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.LOADCLUSTERRECIPE'),
            OnboardingLogType.INFO));
          this.LoadRecipe(recipe, clusterinfo);
        } else {
          // RECIPE NOT AVAILABLE
          const dialogRef1 = this.dialog.open(MessageBoxComponent,
            {
              width: 200 + 'px',
              panelClass: 'panelclass',
              data: this.translate.GetTranslation('MESSAGEBOX.CONTENT.ERRORCLUSTERRECIPEUNKNOWN')
            });
          dialogRef1.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.ERRORLOADRECIPE'),
            OnboardingLogType.ERROR));
          this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
        }
      });
    }
  }

  private OnboardingRecipeLoaded(data: any) {

    if (this.OnboardingRunning && !this.isCancled() && this.moduleWebsocketInitStarted) {
      if (this.waitForClusterRecipeLoaded) {
        this.waitForClusterRecipeLoaded = false;
        if (data.success) {
          // RECIPE IS LOADED
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.CLUSTERRECIPELOADED'),
            OnboardingLogType.INFO));
          this.sendProgress(ModuleOnboardingActions.REQUESTDATA, ActionInitState.success);
          this.sendProgress(ModuleOnboardingActions.CONFIGURECLUSTER, ActionInitState.success);
          this.FinishModuleOnboarding();

          this.ClusterReinitRequested.emit({
            ClusterId: data.ClusterId,
            MasterIpAddress: data.MasterIpAddress,
            MasterSerialNumber: data.MasterSerialNumber
          });
        } else {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.ERRORLOADRECIPE'),
            OnboardingLogType.ERROR));
          this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA, true);
        }
      }
    }
  }

  private onClusterReset(response: any) {
    // MODULE CLUSTER RESETED
    if (this.OnboardingRunning &&
      !this.isCancled() &&
      this.moduleWebsocketInitStarted &&
      this.waitForClusterReset) {
      if (this.currentMsgId === response.msgId) {
        this.waitForClusterReset = false;
        this.ModuleClusterResetFinished.emit();
        if (response.errorcode === 0) {
          // CONTINUE
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.CURRENTCLUSTERRESETED'),
            OnboardingLogType.DEBUG));
          this.sendProgress(ModuleOnboardingActions.REQUESTDATA, ActionInitState.success);
          this.sendClusterConfig();
        } else if (response.errorcode === 1) {
          // MODULE IS CURRENTLY IN USE
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.MODULESRUNNING'),
            OnboardingLogType.WARNING));
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'loginDialogGray';
          dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MODULESRUNNING');
          const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
              this.WaitingForModuleStop.emit();
              this.waitForModuleStop = true;
            } else {
              this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
            }
          });
        } else {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.ERRORCLUSTERESET'),
            OnboardingLogType.ERROR));
          this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
          const dialogRef1 = this.dialog.open(MessageBoxComponent,
            {width: 200 + 'px', panelClass: 'panelclass', data: this.translate.GetTranslation('MESSAGEBOX.CONTENT.ERRORCLUSTERRESET')});
          dialogRef1.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});
        }
      }
    }
  }

  private onModuleStopped(serial: any) {
    if (this.OnboardingRunning &&
      !this.isCancled() &&
      this.moduleWebsocketInitStarted &&
      this.waitForModuleStop) {

      this.waitForModuleStop = false;

      if (serial === this.ActiveModulePlan.customerModule.SerialNumber) {
        this.ModulesStopped.emit();
        this.resetClusterRequest();
      }
    }
  }

  public ManuellModuleStopConfirmation() {
    if (this.OnboardingRunning &&
      !this.isCancled() &&
      this.moduleWebsocketInitStarted &&
      this.waitForModuleStop) {

      this.waitForModuleStop = false;

        this.ModulesStopped.emit();
        this.resetClusterRequest();
    }
  }

  private LoadRecipe(recipe: any, clusterinfo: any) {
    this.waitForClusterRecipeLoaded = true;
    this.moduleHandling.LoadOnboardingClusterRecipe(recipe, clusterinfo.clusterId, clusterinfo.masterModule, clusterinfo.masterIp);
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
    }
    this.timeoutHandler =  setTimeout(() => {
      if (this.OnboardingRunning && !this.isCancled() && this.moduleWebsocketInitStarted) {
        if (this.waitForClusterRecipeLoaded) {
          // ERROR
          clearTimeout(this.timeoutHandler);
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.ERRORLOADRECIPE'),
            OnboardingLogType.ERROR));
          this.stopInitOnError(ModuleOnboardingActions.REQUESTDATA);
        } else {
          clearTimeout(this.timeoutHandler);
        }
      } else {
        clearTimeout(this.timeoutHandler);
      }
    }, 20000);

  }

  private resetClusterRequest() {
    if (this.OnboardingRunning && !this.isCancled() && this.moduleWebsocketInitStarted) {
      this.NewLogMessage.emit(new OnboardingLogItem(
        this.translate.GetTranslation('LOGMESSAGES.RESETCURRENTCLUSTER'),
        OnboardingLogType.INFO));
      this.waitForClusterReset = true;
      this.ModuleClusterReset.emit();
      this.currentMsgId = uuid.v4();
      this.messaging.requestClusterReset(this.currentMsgId, this.ActiveModulePlan.customerModule.SerialNumber);
    }
  }

  private sendClusterConfig() {
    if (this.OnboardingRunning && !this.isCancled() && this.moduleWebsocketInitStarted) {
      // TODO: STEP 4

      this.NewLogMessage.emit(new OnboardingLogItem(
        this.translate.GetTranslation('LOGMESSAGES.SENDCURRENTCLUSTERINFO'),
        OnboardingLogType.INFO));
      // 1. Define Cluster master
      const modules = this.moduleHandling.Modules;
      if (!this.currentMasterModule) {
        let master = '';

        if (!this.standAloneOnboarding) {

          if (modules.length === 1) {
            master = modules[0].modulePlan.customerModule.SerialNumber;
          } else if (modules.length > 1) {
            // SIMPLE VERSION

            // const index = Math.round(this.moduleHandling.ActiveProject.Modules.length / 2);

            const geoMaster = modules.find(ex => ex._modulePlan.geometricMaster == true);

            if (geoMaster) {
              if (geoMaster._modulePlan.customerModule.SerialNumberSetted) {
                master = geoMaster._modulePlan.customerModule.SerialNumber;
              }
            } else {
              const sequenceNumbers = this.moduleHandling.ActiveProject.Modules.map(ex => ex.seqno);

              let maxNumber = 0;

              for(const num of sequenceNumbers) {
                if (num > maxNumber) {
                  maxNumber = num;
                }
              }
              let masterSeqNo = Math.round((maxNumber / 2.0));

              while (!modules.find(ex => ex._modulePlan.seqno == masterSeqNo)) {
                masterSeqNo += 1;

                if (masterSeqNo > maxNumber) {
                  while (!modules.find(ex => ex._modulePlan.seqno == masterSeqNo)) {
                    masterSeqNo -= 1;
                  }
                }
              }

              const modulePlanForMaster = modules.find(ex => ex._modulePlan.seqno == masterSeqNo);

              if (modulePlanForMaster?._modulePlan.customerModule.SerialNumberSetted) {
                master = modulePlanForMaster?._modulePlan.customerModule.SerialNumber;
              }
            }
          }

          this.currentMasterModule = master;
          this.clusterOnboarding.setMasterSerial(master);
          this.moduleHandling.MasterSerialNumber = master;
        } else {
          master = this.ActiveModulePlan.customerModule.SerialNumber;
          this.clusterOnboarding.setMasterSerial(master);
          this.currentMasterModule = master;
          // this.moduleService.MasterSerialNumber = master;

        }
      }


      if (!this.standAloneOnboarding) {
        // 2. Get Modules already initalized

        const availModuls: string[] = [];
        for (const m of modules.filter(ex => ex.modulePlan.customerModule)) {
          availModuls.push(m.modulePlan.modul.Type);
        }

        // 3. get Current recipe


        const recipe = this.moduleHandling.CreateRecipe(this.moduleHandling.ActiveProject);
        const parsedRecipe = JSON.parse(recipe);

        // 4. send message
        this.currentMsgId = uuid.v4();
        this.waitForClusterConfig = true;
        this.messaging.sendClusterRequest(this.currentMsgId,
          this.ActiveModulePlan.customerModule.SerialNumber,
          this.moduleHandling.CurrentClusterId,
          this.moduleHandling.ActiveProject.id,
          this.currentMasterModule,
          parsedRecipe,
          availModuls);

      } else {
        const availModuls: string[] = [];
        availModuls.push(this.ActiveModulePlan.modul.Type);
        const recipe = this.standAloneRecipe;

        // 4. send message
        this.currentMsgId = uuid.v4();
        this.waitForClusterConfig = true;
        this.messaging.sendClusterRequest(this.currentMsgId,
          this.ActiveModulePlan.customerModule.SerialNumber,
          this.standAloneClusterId,
          this.standAloneProjectId,
          this.currentMasterModule,
          {
            tree: recipe.tree,
            composition: recipe.composition,
            connection: recipe.connection
          },
          availModuls);

      }
      // 5. define timeout
      if (this.timeoutHandler) {
        clearTimeout(this.timeoutHandler);
      }
      this.timeoutHandler =  setTimeout(() => {
        if (this.OnboardingRunning && !this.isCancled() && this.moduleWebsocketInitStarted) {
          if (!this.waitForClusterConfig) {
            this.NewLogMessage.emit(new OnboardingLogItem(
              this.translate.GetTranslation('LOGMESSAGES.TIMOUTCLUSTERCONFIG'),
              OnboardingLogType.ERROR));
            this.stopInitOnError(ModuleOnboardingActions.CONFIGURECLUSTER);
          }
        }
        clearTimeout(this.timeoutHandler);
      }, 20000);
    }
  }

  private OnClusterConfigResponse(cluster: any) {
    if (this.OnboardingRunning && !this.isCancled() && this.moduleWebsocketInitStarted) {
      if (this.waitForClusterConfig) {
        this.waitForClusterConfig = false;

        if (cluster.msgId === this.currentMsgId) {
          if (cluster.ctxId === this.ActiveModulePlan.customerModule.SerialNumber) {
            if (cluster.success) {
              this.sendProgress(ModuleOnboardingActions.CONFIGURECLUSTER, ActionInitState.success);
              this.NewLogMessage.emit(new OnboardingLogItem(
                this.translate.GetTranslation('LOGMESSAGES.CLUSTERCONFIGSUCCESS'),
                OnboardingLogType.INFO));
              // NEXT AND FINAL STEP
              this.sendModuleInitRequest();

            } else {
              this.NewLogMessage.emit(new OnboardingLogItem(
                this.translate.GetTranslation('LOGMESSAGES.ERRORCLUSTERCONFIG'),
                OnboardingLogType.ERROR));
              this.stopInitOnError(ModuleOnboardingActions.CONFIGURECLUSTER);
            }
          }
        }
      }
    }
  }

  private sendModuleInitRequest() {
    if (this.OnboardingRunning && !this.isCancled() && this.moduleWebsocketInitStarted) {
      if (!this.waitForModuleInit) {
        this.waitForModuleInit = true;

        this.currentMsgId = uuid.v4();
        this.NewLogMessage.emit(new OnboardingLogItem(
          this.translate.GetTranslation('LOGMESSAGES.RUNMODULEINIT'),
          OnboardingLogType.INFO));
        this.messaging.requestModuleInit(this.currentMsgId, this.ActiveModulePlan.customerModule.SerialNumber);

        // TIMEOUT HANDLER
        if (this.timeoutHandler) {
          clearTimeout(this.timeoutHandler);
        }
        this.timeoutHandler = setTimeout(() => {
          if (this.waitForModuleInit) {
            this.NewLogMessage.emit(new OnboardingLogItem(
              this.translate.GetTranslation('LOGMESSAGES.TIMEOUTMODULEINIT'),
              OnboardingLogType.ERROR));
            this.stopInitOnError(ModuleOnboardingActions.INITENVIRONMENT);
          }
          clearInterval(this.timeoutHandler);
        }, 20000);


      }
    }
  }

  private onModuleInitResponse(init: any) {
    if (this.OnboardingRunning && !this.isCancled() && this.moduleWebsocketInitStarted) {
      if (this.waitForModuleInit) {
        this.waitForModuleInit = false;

        if (init.msgId === this.currentMsgId && init.ctxId === this.ActiveModulePlan.customerModule.SerialNumber) {

          if (init.modules) {

            const currentModule = init.modules.find(ex => ex.serialnumber === this.ActiveModulePlan.customerModule.SerialNumber);
            if (currentModule) {

              this.NewLogMessage.emit(new OnboardingLogItem(
                this.translate.GetTranslation('LOGMESSAGES.DONEMODULEINIT'),
                OnboardingLogType.INFO));

              let error = false;

              if (!this.standAloneOnboarding) {
                for (const device of init.modules) {
                  const module = this.moduleHandling.ActiveProject.Modules
                    .find(ex => ex.customerModule.SerialNumber === device.serialnumber);

                  if (module) {
                    module.setModuleNetworkState(device);
                    if (module.customerModule.SerialNumber === this.currentMasterModule) {
                      this.clusterOnboarding.setMasterIp(module.modul.NetworkInfo.ipaddress as string);
                      this.moduleHandling.MasterIp = module.modul.NetworkInfo.ipaddress as string;
                    }

                    const missingRequired = [];
                    const missingUnRequired = [];
                    const missingComponents = [];

                    // CHECK FEATURES
                    if (device.availableFeatures) {

                      this.NewLogMessage.emit(new OnboardingLogItem(
                        this.translate.GetTranslation('LOGMESSAGES.CHECKREQUIREDFEATURES') +
                        ' ' + module.customerModule.SerialNumber,
                        OnboardingLogType.INFO));

                      for (const comp of module.modul.Components.filter(ex => ex.Features.length > 0)) {
                        if (device.availableFeatures[comp.PlcKey]) {
                          const features = device.availableFeatures[comp.PlcKey];
                          for (const ftr of comp.Features.filter(ex => ex.Required === true &&
                            !ex.Name.startsWith('Service') && ex.Context !== 'None')) {
                            if (!features.find(ex => ex === ftr.Name)) {
                              missingRequired.push(ftr);
                            }
                          }

                          for (const ftr of comp.Features.filter(ex => ex.Required === false &&
                            !ex.Name.startsWith('Service') && ex.Context !== 'None')) {
                            if (!features.find(ex => ex === ftr.Name)) {
                              missingUnRequired.push(ftr);
                            }
                          }
                        } else {
                          missingComponents.push(comp);
                        }
                      }

                      if ((missingRequired.length > 0 || missingComponents.length > 0) && environment.production) {
                        error = true;

                        const defaulttxt = this.translate.GetTranslation('MESSAGEBOX.HEADERS.MISSINGFEATURES');
                        const msgs = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MISSINGFEATURES');
                        const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
                          {panelClass: 'panelclass', data: {header: defaulttxt, content: msgs}});
                        dialogRef.afterClosed().subscribe(() => {
                          this.NewLogMessage.emit(new OnboardingLogItem(
                            this.translate.GetTranslation('LOGMESSAGES.ERRORMODULEINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.MISSINGFEATURE'),
                            OnboardingLogType.ERROR));
                          this.NewLogMessage.emit(new OnboardingLogItem(
                            this.translate.GetTranslation('LOGMESSAGES.FOLLOWINGFEATURES'),
                            OnboardingLogType.ERROR));
                          for (const ft of missingRequired) {
                            this.NewLogMessage.emit(new OnboardingLogItem(
                              ft.Name,
                              OnboardingLogType.ERROR));
                          }
                          this.stopInitOnError(ModuleOnboardingActions.INITENVIRONMENT, true);

                        });
                      } else if (missingUnRequired.length > 0 && environment.production) {
                        error = true;

                        this.NewLogMessage.emit(new OnboardingLogItem(
                          this.translate.GetTranslation('LOGMESSAGES.OPTIONALFEATURESMISSING'),
                          OnboardingLogType.WARNING));
                        this.NewLogMessage.emit(new OnboardingLogItem(
                          this.translate.GetTranslation('LOGMESSAGES.FOLLOWINGFEATURES'),
                          OnboardingLogType.WARNING));
                        for (const ft of missingUnRequired) {
                          this.NewLogMessage.emit(new OnboardingLogItem(
                            ft.Name,
                            OnboardingLogType.WARNING));

                        }
                        this.checkDMSVersion(device.specversion);
                        return;

                        // const dialogConfig = new MatDialogConfig();
                        // dialogConfig.disableClose = true;
                        // dialogConfig.autoFocus = true;
                        // dialogConfig.panelClass = 'loginDialogGray';
                        // dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MISSINGUNREQUIREDFEATURES');
                        // const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
                        // dialogRef.afterClosed().subscribe(result => {
                        //   if (result === true) {
                        //     this.checkDMSVersion(device.specversion);
                        //     return;
                        //   } else {
                        //     this.NewLogMessage.emit(new OnboardingLogItem(
                        //       this.translate.GetTranslation('LOGMESSAGES.ERRORMODULEINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.MISSINGFEATURE'),
                        //       OnboardingLogType.ERROR));
                        //     for (const ft of missingUnRequired) {
                        //       this.NewLogMessage.emit(new OnboardingLogItem(
                        //         ft.Name,
                        //         OnboardingLogType.WARNING));
                        //     }
                        //     this.stopInitOnError(ModuleOnboardingActions.INITENVIRONMENT, true);
                        //   }
                        //
                        // });
                      }
                      if (!environment.production && (missingUnRequired.length > 0 || missingComponents.length > 0 || missingRequired.length > 0)) {
                        this.NewLogMessage.emit(new OnboardingLogItem(
                          this.translate.GetTranslation('LOGMESSAGES.ERRORMODULEINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.MISSINGFEATURE'),
                          OnboardingLogType.ERROR));
                        this.NewLogMessage.emit(new OnboardingLogItem(
                          this.translate.GetTranslation('LOGMESSAGES.FOLLOWINGFEATURES'),
                          OnboardingLogType.ERROR));
                        for (const ft of missingRequired) {
                          this.NewLogMessage.emit(new OnboardingLogItem(
                            ft.Name,
                            OnboardingLogType.ERROR));
                        }
                        this.NewLogMessage.emit(new OnboardingLogItem(
                          this.translate.GetTranslation('LOGMESSAGES.OPTIONALFEATURESMISSING'),
                          OnboardingLogType.WARNING));
                        this.NewLogMessage.emit(new OnboardingLogItem(
                          this.translate.GetTranslation('LOGMESSAGES.FOLLOWINGFEATURES'),
                          OnboardingLogType.WARNING));
                        for (const ft of missingUnRequired) {
                          this.NewLogMessage.emit(new OnboardingLogItem(
                            ft.Name,
                            OnboardingLogType.WARNING));
                        }
                      }

                    }
                  } else if (this.moduleHandling.ActiveProject.SupportModules) {
                    const supportModule = this.moduleHandling.ActiveProject.SupportModules
                      .find(ex => ex.customerModule.SerialNumber === device.serialnumber);

                    if (supportModule) {
                      supportModule.setModuleNetworkState(device);
                      if (supportModule.customerModule.SerialNumber === this.currentMasterModule) {
                        this.clusterOnboarding.setMasterIp(supportModule.modul.NetworkInfo.ipaddress as string);
                        this.moduleHandling.MasterIp = supportModule.modul.NetworkInfo.ipaddress as string;
                      }

                      const missingRequired = [];
                      const missingUnRequired = [];
                      const missingComponents = [];

                      // CHECK FEATURES
                      if (device.availableFeatures) {

                        this.NewLogMessage.emit(new OnboardingLogItem(
                          this.translate.GetTranslation('LOGMESSAGES.CHECKREQUIREDFEATURES') +
                          ' ' + supportModule.customerModule.SerialNumber,
                          OnboardingLogType.INFO));

                        for (const comp of supportModule.modul.Components.filter(ex => ex.Features.length > 0)) {
                          if (device.availableFeatures[comp.PlcKey]) {
                            const features = device.availableFeatures[comp.PlcKey];
                            for (const ftr of comp.Features.filter(ex => ex.Required === true &&
                              !ex.Name.startsWith('Service') && ex.Context !== 'None')) {
                              if (!features.find(ex => ex === ftr.Name)) {
                                missingRequired.push(ftr);
                              }
                            }

                            for (const ftr of comp.Features.filter(ex => ex.Required === false &&
                              !ex.Name.startsWith('Service') && ex.Context !== 'None')) {
                              if (!features.find(ex => ex === ftr.Name)) {
                                missingUnRequired.push(ftr);
                              }
                            }
                          } else {
                            missingComponents.push(comp);
                          }
                        }
                        if (missingRequired.length > 0 || missingComponents.length > 0) {
                          error = true;

                          const defaulttxt = this.translate.GetTranslation('MESSAGEBOX.HEADERS.MISSINGFEATURES');
                          const msgs = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MISSINGFEATURES');
                          const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
                            {panelClass: 'panelclass', data: {header: defaulttxt, content: msgs}});
                          dialogRef.afterClosed().subscribe(() => {
                            this.NewLogMessage.emit(new OnboardingLogItem(
                              this.translate.GetTranslation('LOGMESSAGES.ERRORMODULEINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.MISSINGFEATURE'),
                              OnboardingLogType.ERROR));
                            this.NewLogMessage.emit(new OnboardingLogItem(
                              this.translate.GetTranslation('LOGMESSAGES.FOLLOWINGFEATURES'),
                              OnboardingLogType.ERROR));
                            for (const ft of missingRequired) {
                              this.NewLogMessage.emit(new OnboardingLogItem(
                                ft.Name,
                                OnboardingLogType.ERROR));
                            }

                            this.stopInitOnError(ModuleOnboardingActions.INITENVIRONMENT, true);

                          });
                        } else if (missingUnRequired.length > 0) {
                          error = true;

                          this.NewLogMessage.emit(new OnboardingLogItem(
                            this.translate.GetTranslation('LOGMESSAGES.OPTIONALFEATURESMISSING'),
                            OnboardingLogType.WARNING));
                          this.NewLogMessage.emit(new OnboardingLogItem(
                            this.translate.GetTranslation('LOGMESSAGES.FOLLOWINGFEATURES'),
                            OnboardingLogType.WARNING));
                          for (const ft of missingUnRequired) {
                            this.NewLogMessage.emit(new OnboardingLogItem(
                              ft.Name,
                              OnboardingLogType.WARNING));
                          }
                          this.checkDMSVersion(device.specversion);
                          return;

                          // const dialogConfig = new MatDialogConfig();
                          // dialogConfig.disableClose = true;
                          // dialogConfig.autoFocus = true;
                          // dialogConfig.panelClass = 'loginDialogGray';
                          // dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MISSINGUNREQUIREDFEATURES');
                          // const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
                          // dialogRef.afterClosed().subscribe(result => {
                          //   if (result === true) {
                          //     this.checkDMSVersion(device.specversion);
                          //     return;
                          //   } else {
                          //     this.NewLogMessage.emit(new OnboardingLogItem(
                          //       this.translate.GetTranslation('LOGMESSAGES.ERRORMODULEINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.MISSINGFEATURE'),
                          //       OnboardingLogType.ERROR));
                          //     for (const ft of missingUnRequired) {
                          //       this.NewLogMessage.emit(new OnboardingLogItem(
                          //         ft.Name,
                          //         OnboardingLogType.WARNING));
                          //     }
                          //     this.stopInitOnError(ModuleOnboardingActions.INITENVIRONMENT, true);
                          //   }
                          //
                          // });
                        }

                      }
                    }

                  } else {
                    this.stopInitOnError(ModuleOnboardingActions.INITENVIRONMENT, true);
                  }
                }
              } else {
                const initModule = init.modules.find(ex => ex.serialnumber === this.ActiveModulePlan.customerModule.SerialNumber);

                if (initModule) {
                 this.ActiveModulePlan.setModuleNetworkState(initModule);
                  if (this.ActiveModulePlan.customerModule.SerialNumber === this.currentMasterModule) {
                    this.clusterOnboarding.setMasterIp(this.ActiveModulePlan.modul.NetworkInfo.ipaddress as string);
                    this.StandaloneModuleOnboardingFinished.emit({
                      ClusterId: this.standAloneClusterId,
                      MasterIpAddress: this.ActiveModulePlan.modul.NetworkInfo.ipaddress as string,
                      MasterSerialNumber: this.currentMasterModule
                    });
                    // this.moduleService.MasterIp = this.ActiveModulePlan.modul.NetworkInfo.ipaddress as string;
                  }

                  const missingRequired = [];
                  const missingUnRequired = [];
                  const missingComponents = [];

                  // CHECK FEATURES
                  if (initModule.availableFeatures) {

                    this.NewLogMessage.emit(new OnboardingLogItem(
                      this.translate.GetTranslation('LOGMESSAGES.CHECKREQUIREDFEATURES') +
                      ' ' + this.ActiveModulePlan.customerModule.SerialNumber,
                      OnboardingLogType.INFO));

                    for (const comp of this.ActiveModulePlan.modul.Components.filter(ex => ex.Features.length > 0)) {
                      if (initModule.availableFeatures[comp.PlcKey]) {
                        const features = initModule.availableFeatures[comp.PlcKey];
                        for (const ftr of comp.Features.filter(ex => ex.Required === true &&
                          !ex.Name.startsWith('Service') && ex.Context !== 'None')) {
                          if (!features.find(ex => ex === ftr.Name)) {
                            missingRequired.push(ftr);
                          }
                        }

                        for (const ftr of comp.Features.filter(ex => ex.Required === false &&
                          !ex.Name.startsWith('Service') && ex.Context !== 'None')) {
                          if (!features.find(ex => ex === ftr.Name)) {
                            missingUnRequired.push(ftr);
                          }
                        }
                      } else {
                        missingComponents.push(comp);
                      }
                    }

                    if (missingRequired.length > 0 || missingComponents.length > 0) {
                      error = true;

                      const defaulttxt = this.translate.GetTranslation('MESSAGEBOX.HEADERS.MISSINGFEATURES');
                      const msgs = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MISSINGFEATURES');
                      const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
                        {panelClass: 'panelclass', data: {header: defaulttxt, content: msgs}});
                      dialogRef.afterClosed().subscribe(() => {
                        this.NewLogMessage.emit(new OnboardingLogItem(
                          this.translate.GetTranslation('LOGMESSAGES.ERRORMODULEINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.MISSINGFEATURE'),
                          OnboardingLogType.ERROR));
                        this.stopInitOnError(ModuleOnboardingActions.INITENVIRONMENT, true);

                      });
                    } else if (missingUnRequired.length > 0) {
                      error = true;

                      const dialogConfig = new MatDialogConfig();
                      dialogConfig.disableClose = true;
                      dialogConfig.autoFocus = true;
                      dialogConfig.panelClass = 'loginDialogGray';
                      dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MISSINGUNREQUIREDFEATURES');
                      const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
                      dialogRef.afterClosed().subscribe(result => {
                        if (result === true) {
                          this.checkDMSVersion(initModule.specversion);
                          return;
                        } else {

                          this.NewLogMessage.emit(new OnboardingLogItem(
                            this.translate.GetTranslation('LOGMESSAGES.ERRORMODULEINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.MISSINGFEATURE'),
                            OnboardingLogType.ERROR));
                          this.stopInitOnError(ModuleOnboardingActions.INITENVIRONMENT, true);
                        }

                      });
                    }

                  }
                }
              }



              if (error === false) {

                this.NewLogMessage.emit(new OnboardingLogItem(
                  this.translate.GetTranslation('LOGMESSAGES.FEATURECHECKFINISHED'),
                  OnboardingLogType.INFO));
                this.checkDMSVersion(init.modules.find(ex => ex.serialnumber === this.ActiveModulePlan.customerModule.SerialNumber)?.specversion);
                return;
              }

            } else {
              this.NewLogMessage.emit(new OnboardingLogItem(
                this.translate.GetTranslation('LOGMESSAGES.ERRORMODULEINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.CURRENTMODULEMISSING'),
                OnboardingLogType.ERROR));
              this.stopInitOnError(ModuleOnboardingActions.INITENVIRONMENT);
            }
          } else {
            this.NewLogMessage.emit(new OnboardingLogItem(
              this.translate.GetTranslation('LOGMESSAGES.ERRORMODULEINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.NOMODULES'),
              OnboardingLogType.ERROR));
            this.stopInitOnError(ModuleOnboardingActions.INITENVIRONMENT);
          }

        } else {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.ERRORMODULEINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.WRONGSERIAL'),
            OnboardingLogType.ERROR));
          this.stopInitOnError(ModuleOnboardingActions.INITENVIRONMENT);
        }

      }
    }
  }

  private checkDMSVersion(version: string) {

    if (this.OnboardingRunning && !this.isCancled()) {
      if (version) {

        if (compareVersions(this.server.dmsVersion, version) > 0) {
          this.waitForDMSUpdated = true;

          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.NODEDMSOLD_1') + version + this.translate.GetTranslation('LOGMESSAGES.NODEDMSOLD_2'),
            OnboardingLogType.INFO));
          this.currentMsgId = uuid.v4();
          this.messaging.updateModuleSpecification(this.currentMsgId,
            this.ActiveModulePlan.customerModule.SerialNumber,
            this.server.currentDMS);

          // TIMEOUT HANDLER
          if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
          }
          this.timeoutHandler = setTimeout(() => {
            if (this.OnboardingRunning && !this.isCancled()) {

              if (this.waitForDMSUpdated) {
                this.NewLogMessage.emit(new OnboardingLogItem(
                  this.translate.GetTranslation('LOGMESSAGES.TIMEOUTDMS'),
                  OnboardingLogType.WARNING));
                // this.stopInitOnError(ModuleOnboardingActions.INITENVIRONMENT);
                this.FinishModuleOnboarding();
              }
            }
            clearInterval(this.timeoutHandler);
          }, 10000);

        } else {
          this.FinishModuleOnboarding();
        }


      } else {
        this.FinishModuleOnboarding();
      }
    }
  }

  private onDmsUpdatedResponse(msg: any) {

    if (this.OnboardingRunning && !this.isCancled()) {
      if (msg.msgId === this.currentMsgId) {
        if (this.waitForDMSUpdated) {
          this.waitForDMSUpdated = false;
          if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
          }
          if (msg.success === true) {
            this.NewLogMessage.emit(new OnboardingLogItem(
              this.translate.GetTranslation('LOGMESSAGES.DMSUPDATESUCCESS'),
              OnboardingLogType.INFO));
          } else {
            this.NewLogMessage.emit(new OnboardingLogItem(
              this.translate.GetTranslation('LOGMESSAGES.DMSUPDATEERROR'),
              OnboardingLogType.WARNING));
          }
          this.FinishModuleOnboarding();
        }
      }
    }
  }
}
