import {Component, OnInit, ViewChild} from '@angular/core';
import {BluectrlApiService} from '../../../services/bluectrl-api.service';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MatSort} from '@angular/material/sort';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {MessageBoxComponent} from '../../dialogs/message-box/message-box.component';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {ViewCode} from '../../../classes/enums/view-code.enum';
import {SaveProjectDialogComponent} from '../../dialogs/save-project-dialog/save-project-dialog.component';
import {Project} from '../../../classes/project';
import {NewOrganzationComponent} from '../../dialogs/new-organzation/new-organzation.component';
import {NewModuleComponent} from '../../dialogs/new-module/new-module.component';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {NewUserComponent} from '../../dialogs/new-user/new-user.component';
import {QuestionBoxComponent} from '../../dialogs/question-box/question-box.component';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {CustomerModule} from '../../../classes/customer-module';
import {Wards} from '../../../classes/wards';
import {getLocaleFirstDayOfWeek} from '@angular/common';

@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.css']
})
export class AdminViewComponent implements OnInit {

  public finished = true;
  public organisations: any[];
  public users: any[];
  public modules: any[];

  public organizationDataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sortOrganizations: MatSort;
  displayedOrganizationColumns: string[] = ['Name', 'Delete'];

  public modulesDataSource = new MatTableDataSource<any>();
  displayedModuleColumns: string[] = ['Group', 'Name', 'Serialnumber', 'Organisation', 'Delete'];

  public usersDataSource = new MatTableDataSource<any>();
  displayedUserColumns: string[] = ['Name', 'E-Mail', 'Organization', 'Delete'];

  constructor(public apiService: BluectrlApiService,
              public modulehandling: ModuleHandlingService,
              public translate: BluectrlTranslateService,
              public server: ServerCommunicationService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.modulehandling.SetViewMode(ViewCode.admin);
    if (this.modulehandling.dataLoadedFinishedIndicator === true) {
      this.loadData();
    } else {
      this.modulehandling.DataLoadingFinished.subscribe(this.loadData.bind(this));
    }
  }

  private loadData() {
    this.loadOrganisations();
    this.loadUsers();
    this.loadModules();

    this.organizationDataSource.sort = this.sortOrganizations;
  }


  private loadOrganisations() {
    this.apiService.getOrganizations().subscribe(async (organizations: any) => {
      if (organizations.length > 0) {
        this.organisations = organizations;
        this.organizationDataSource = new MatTableDataSource(this.organisations);
      }
    });
  }

  private loadUsers() {
    this.apiService.getUsers().subscribe(async (users: any) => {
      if (users.length > 0) {
        this.users = users;
        this.usersDataSource = new MatTableDataSource(this.users);
      }
    });
  }

  private loadModules() {

    // this.server.LoadCustomerModules().then(modules => {
    //   this.modules = modules;
    //   this.modulesDataSource = new MatTableDataSource(this.modules);
    // }, error => {
    //   console.log(error);
    // });


     this.apiService.GetAllModules().subscribe((cmodules: any) => {
       if (cmodules) {
         if (cmodules.length > 0) {
           this.modules = [];
           for (const md of cmodules) {
             const productId = md.serial.substr(0, md.serial.length - 5);
             const module = this.server.GetModule(productId);
             if (module !== undefined && module !== null) {
               const modl = module.Copy();
               modl.SetId();
               const customerModule = new CustomerModule(md.owner.id, md.serial, modl);
               customerModule.SetId();
               if (md.owner) {
                 customerModule.Owner = md.owner.id;
                 customerModule.OwnerName = md.owner.name;
                 customerModule.Ward = new Wards(md.owner.id, md.owner.name);
               }
               if (md.holder) {
                 customerModule.Holder = md.holder.id;
                 customerModule.HolderName = md.holder.name;
               }
               this.modules.push(customerModule);
             }
           }
           this.modulesDataSource = new MatTableDataSource(this.modules);
         }
       }
     }, error => {
       console.log(error);
     });
     // this.modules = this.modulehandling.server.CustomerModules;

  }

  public deleteOrganization(id: string) {
    const org = this.organisations.find(ex => ex.id === id);

    if (org) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.REALYDELETEORGANIZATION') + org.name + this.translate.GetTranslation('MESSAGEBOX.CONTENT.REALYDELETE');
      const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        if (data === true) {
          //this.loadModules();

          //this.moduleHandling.SetViewMode(ViewCode.design);
          this.apiService.deleteOrganization(id).subscribe((response: any) => {
            this.server.LoadCustomerModules().then(modules => {
              //this.modules = modules;
              //this.modulesDataSource = new MatTableDataSource(this.modules);
            });
            this.loadData();
          }, error => {
            console.log(error);
            //
            if (error.status === 406) {
              console.log(error.error);
              const users = this.users.filter(ex => ex.organization.id === id);
              const ownerModules = this.modules.filter(ex => ex.Owner === id);
              const holderModules = this.modules.filter(ex => ex.Owner === id && ex.Owner !== ex.Holder);
            }

          });
        }
      });
    }
  }

  public deleteModule(serial: string) {
    const module = this.modules.find(ex => ex.SerialNumber === serial);

    if (module) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.REALYDELETEMODULE') + serial + this.translate.GetTranslation('MESSAGEBOX.CONTENT.REALYDELETE');
      const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        if (data === true) {
          this.loadModules();
           this.apiService.deleteCustomerModule(serial).subscribe((response: any) => {
             this.server.LoadCustomerModules().then(modules => {
               // this.modules = modules;
               // this.modulesDataSource = new MatTableDataSource(this.modules);
             });
             this.loadData();
           }, error => {
             console.log(error);
          });
        }
      });
    }
  }

  public deleteUser(id: string) {

    const user = this.users.find(ex => ex.id === id);

    if (user) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.REALYDELETEUSER') + user.email + this.translate.GetTranslation('MESSAGEBOX.CONTENT.REALYDELETE');
      const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        if (data === true) {
          // this.moduleHandling.SetViewMode(ViewCode.design);
          this.apiService.deleteUser(id).subscribe((response: any) => {
            this.loadData();
          }, error => {
            console.log(error);
          });

        }
      });
    }
  }

  private newOrganisations() {



    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'loginDialog';
    const dialogRef = this.dialog.open(NewOrganzationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.loadData();
    });
  }

  private newModule() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'loginDialog';
    const dialogRef = this.dialog.open(NewModuleComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {

      this.server.LoadCustomerModules().then(modules => {
        // this.modules = modules;
        // this.modulesDataSource = new MatTableDataSource(this.modules);
      });
      this.loadData();
    });
  }

  private NewUser() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'loginDialog';
    const dialogRef = this.dialog.open(NewUserComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {

      this.server.LoadCustomerModules();
      this.loadData();
    });
  }
}
