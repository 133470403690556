import { Injectable } from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {ModuleHandlingService} from './module-handling.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {QuestionBoxComponent} from '../components/dialogs/question-box/question-box.component';
import {environment} from '../../environments/environment';
import {UpdateProgressComponent} from '../components/dialogs/update-progress/update-progress.component';
import {VERSION} from '../../environments/version';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  // https://angular-buch.com/blog/2019-07-progressive-web-app
  // https://angular.io/guide/service-worker-config
  // https://stackoverflow.com/questions/50968902/angular-service-worker-swupdate-available-not-triggered
  public config: any;
  public updateHandler: any;
  private dialogOpen = false;

  constructor(public updates: SwUpdate,
              public moduleHandling: ModuleHandlingService,
              public dialog: MatDialog,
              private httpClient: HttpClient) {
    this.config = VERSION;
    this.moduleHandling.DataLoadingFinished.subscribe(this.onDataLoadingFinished.bind(this));
  }

  private onDataLoadingFinished() {
    if (window.location.href.indexOf('localhost') < 0) {
      this.checkForNewerVersion();
      this.runVersionUpdateHandler();
    }
  }

  public checkForNewerVersion() {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    });

    this.httpClient
      .get<{ version: string }>('/assets/config.json', {headers})
      .subscribe((config: any) => {
        if (config.raw !== this.config.raw && this.dialogOpen === false) {
          this.dialogOpen = true;
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'loginDialogGray';
          dialogConfig.data = 'Newer Software version available do you want to update now?';
          const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(data => {
            this.dialogOpen = false;
            if (data === true) {
              clearInterval(this.updateHandler);
              this.updateApp();
            }
          });
        }
      }, () => {
      });
  }

  public runVersionUpdateHandler() {
    this.updateHandler = setInterval(() => this.checkForNewerVersion(), 120000);

  }

  updateApp() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'loginDialogGray';
    const dialogRef = this.dialog.open(UpdateProgressComponent, dialogConfig);
  }

  public performUpdate() {

      if (this.updates.isEnabled && environment.production) {
        this.updates.activateUpdate().then(() => {
          // this.checkForUpdates();
        });
        setTimeout(() => {
          window.location.replace(window.location.href);
          window.location.reload();
        }, 5000);
      } else {
        setTimeout(() => {
          window.location.replace(window.location.href);
          window.location.reload();
        }, 5000);
      }

  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(event => this.promptUser());
  }

  private promptUser(): void {
    this.updates.activateUpdate().then(() => document.location.reload());
  }


}
