import {Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ModulePlan} from '../../../classes/module-plan';
import {SupportModulePlan} from '../../../classes/support-module-plan';
import {ModulePlanReplacement} from '../../../classes/module-plan-replacement';
import {ModuleConnections} from '../../../classes/enums/module-connections.enum';
import {Conveyor} from '../../../classes/conveyor';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {UserManagementService} from '../../../services/user-management.service';
import {MessageHandlingService} from '../../../services/v2/message-handling.service';
import {ViewCode} from '../../../classes/enums/view-code.enum';
import {ModuleRotations} from '../../../classes/enums/module-rotations.enum';
import {NetworkDevice} from '../../../classes/messaging/network-device';
import {ModeSwitch} from '../../../classes/enums/mode-switch.enum';
import {ModuleDesignComponent} from '../module-design/module-design.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MessageBoxComponent} from '../../dialogs/message-box/message-box.component';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {BlinkingService} from '../../../services/blinking.service';

@Component({
  selector: 'app-support-module-design',
  templateUrl: './support-module-design.component.html',
  styleUrls: ['./support-module-design.component.css']
})
export class SupportModuleDesignComponent implements OnInit, OnDestroy {

  @Output() ShowSupportModuleOnboarding = new EventEmitter();

  @ Input() width = '100px';
  @ Input() height = '100px';
  @ Input() set rawWidth(value: number) {
    this._rawWidth = value;
    this._designWidth = value.toString() + 'px';

    const scf = this.rawWidth / 303;
    this.scl = 'scale(' + scf + ', ' + scf + ')';

  }
  get rawWidth() {
    return this._rawWidth;
  }
  @ Input() set rawHeight(value: number) {
    this._rawHeight = value;
    this._designHeight = value.toString() + 'px';
  }
  get rawHeight() {
    return this._rawHeight;
  }
  @ Input() set modulePlan(value: SupportModulePlan) {
    this._modulePlan = value;
    this._modulePlan.ModuleStateChanged.pipe(takeUntil(this.unsubscribe)).subscribe(this.ModuleRunningState.bind(this));
    // this.moduleHandling.AddModule(this);

    if (this._modulePlan.customerModule !== null && this._modulePlan.customerModule !== undefined) {


      this.handler = setInterval( () => {
        this.yellowLight = !this.yellowLight;

      }, 1000);

    }
    if (this._modulePlan.customerModule && this._modulePlan.customerModule.Module && this._modulePlan.customerModule.Module.NetworkInfo) {
      this.running = this._modulePlan.customerModule.Module.NetworkInfo.statemodule;
      this.UpdateRuningState();
    } else {
      this.running = -1;
      this.UpdateRuningState();
    }
    this.SetModeSwitch();
    this.SetNetworkRevision();

    this._modulePlan.OnNetworkInfoChanged.subscribe(this.onModulePlanNetworkInfoChanged.bind(this));


  }
  get modulePlan() {
    return this._modulePlan;
  }
  @Input() zindex = 10;

  @ Input() set parentModuleDesign(value: ModuleDesignComponent) {
    this._parentModuleDesign = value;
  }
  get parentModuleDesign() {
    return this._parentModuleDesign;
  }

  public scl = 'scale(1, 1)';
  private _msgIdRevision: string;
  defaultWidth = 100;
  defaultHeight = 100;
  public _designWidth = '100px';
  public _designHeight = '100px';
  public _parentModuleDesign: ModuleDesignComponent;
  public _modulePlan: SupportModulePlan;
  public _rawWidth = this.defaultWidth;
  public _rawHeight = this.defaultHeight;
  public opacity = '1';
  public ngClasses = 'sectionDesign';
  public transform = 'scale(0.5,0.5)';
  handler: any;
  RuningStateHandler: any;
  yellowLight = true;
  running = -1;
  public CurrentStateColor = 'transparent';
  private unsubscribe: Subject<void> = new Subject();
  private blinkingActive = false;

  constructor(public moduleHandling: ModuleHandlingService,
              private resolver: ComponentFactoryResolver,
              public dialog: MatDialog,
              private server: ServerCommunicationService,
              private usermanagement: UserManagementService,
              private messageHandling: MessageHandlingService,
              private translate: BluectrlTranslateService,
              private blinkingService: BlinkingService) {
    // this.messageHandling.ModuleStateChanged.pipe(takeUntil(this.unsubscribe)).subscribe(this.ModuleRunningState.bind(this));
    this.messageHandling.ClusterRevisionReceived.pipe(takeUntil(this.unsubscribe)).subscribe(this.RevisionReceived.bind(this));
    this.messageHandling.ModuleModeChanged.pipe(takeUntil(this.unsubscribe)).subscribe(this.UpdateModeSwitch.bind(this));

    this.blinkingService.BlinkOff.pipe(takeUntil(this.unsubscribe)).subscribe(this.SetBlinkingOff.bind(this));
    this.blinkingService.BlinkOn.pipe(takeUntil(this.unsubscribe)).subscribe(this.SetBlinkingOn.bind(this));
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {

  }

  private SetBlinkingOn() {
    if (this.running >= 0 && this.blinkingActive) {
      switch (this.running) {
        case 6:
        case 9:
        case 10: {
          this.CurrentStateColor = 'blue';
          break;
        }
        case 8: {
          this.CurrentStateColor = 'red';
          break;
        }
      }
    }
  }

  private SetBlinkingOff() {
    if (this.running >= 0 && this.blinkingActive) {
      switch (this.running) {
        case 8:
        case 6:
        case 9:
        case 10: {    
          this.CurrentStateColor = 'transparent';
          break;
        }
      }
    }
  }

  public showRemoveIcon() {

    if (this.parentModuleDesign) {
      if (this.parentModuleDesign.IsModuleSelected) {
        return true;
      }
    }

    return false;
  }

  public removeSupportModule() {
    this.moduleHandling.deleteSupportModuleConnection(this.parentModuleDesign.modulePlan);
  }

  private onModulePlanNetworkInfoChanged(data: any) {
    if (this._modulePlan.customerModule && this._modulePlan.customerModule.Module && this._modulePlan.customerModule.Module.NetworkInfo) {
      this.running = this._modulePlan.customerModule.Module.NetworkInfo.statemodule;
      this.UpdateRuningState();
    } else {
      this.running = -1;
      this.UpdateRuningState();
    }
    this.SetModeSwitch();
    this.SetNetworkRevision();
  }

  public ShowOnboardingView() {

    if (!this.parentModuleDesign.IsModuleSelected) {
      if (!this.modulePlan.customerModule.FullyConfigured) {
        if (this.moduleHandling.sequenceNumberToOnboard < this.modulePlan.seqno) {
          // SHOW MESSAGE
          const dialogRef = this.dialog.open(MessageBoxComponent,
            {width: 200 + 'px', panelClass: 'panelclass', data: {text: this.translate.GetTranslation('MESSAGEBOX.CONTENT.KEEPONBOARDINGORDER'), timeout: 1000}});
          dialogRef.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});
        } else {
          this.moduleHandling.SetModuleActive(this.parentModuleDesign);
        }
      } else {
        this.moduleHandling.SetModuleActive(this.parentModuleDesign);
      }

    } else {
      if (!this.modulePlan.customerModule.FullyConfigured) {
        if (this.moduleHandling.sequenceNumberToOnboard < this.modulePlan.seqno) {
          // SHOW MESSAGE
          const dialogRef = this.dialog.open(MessageBoxComponent,
            {width: 200 + 'px', panelClass: 'panelclass', data: {text: this.translate.GetTranslation('MESSAGEBOX.CONTENT.KEEPONBOARDINGORDER'), timeout: 1000}});
          dialogRef.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});
        } else {
          this.ShowSupportModuleOnboarding.emit();
        }

      }
    }


  }

  private SetNetworkRevision() {
    if (this._modulePlan.customerModule) {
      if (this._modulePlan.modul) {
        if (this.modulePlan.modul.NetworkInfo) {
          this.modulePlan.customerModule.CurrentRevision = this.modulePlan.modul.NetworkInfo.revision;
        }
      }
    }
  }

  public ShowInfoPanel(): boolean {
    if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
      return false;
    } else {
      if (this._modulePlan.customerModule) {
        if (this._modulePlan.modul) {
          if (this._modulePlan.modul.NetworkInfo) {
            if (this._modulePlan.modul.NetworkInfo.emergencystoprequired === true) {
              return true;
            } else if (this._modulePlan.modul.NetworkInfo.levellingrequired &&
              this._modulePlan.modul.NetworkInfo.levellingrequired === true) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  public RevisionReceived(msg: any) {
    if (this.moduleHandling.CurrentViewMode === ViewCode.live) {
      if (this._modulePlan) {
        if (this._modulePlan.customerModule) {

          if (msg.msgId === this._msgIdRevision) {
            this._msgIdRevision = null;
            this._modulePlan.customerModule.CurrentRevision = msg.revision;
            this._modulePlan.customerModule.UpdateRevision = msg.update;

          }

        }
      }
    }
  }

  private SetModeSwitch() {
    if (this._modulePlan) {
      if (this._modulePlan.customerModule) {
        if (this._modulePlan.customerModule.Module.NetworkInfo) {
          if (this._modulePlan.customerModule.Module.NetworkInfo.switchstate === 'service') {
            this._modulePlan.customerModule.Module.EnableServiceMode();
          } else {
            this._modulePlan.customerModule.Module.DisableServiceMode();
          }
        }
      }
    }
  }

  private UpdateModeSwitch(msg: any) {
    if (this._modulePlan) {
      if (this._modulePlan.customerModule) {
        if (this._modulePlan.customerModule.Module.NetworkInfo) {
          if (this._modulePlan.customerModule.SerialNumber === msg.ctxId) {
            this._modulePlan.customerModule.Module.NetworkInfo.switchstate = msg.state;
            this.SetModeSwitch();
          }
        }
      }
    }
  }

  private ModeSwitchChanged(netDevice: NetworkDevice) {
    if (this._modulePlan.customerModule) {
      if (this._modulePlan.customerModule.SerialNumber === netDevice.serialnumber) {
        if (this._modulePlan.customerModule.Module) {
          if (this._modulePlan.customerModule.Module.NetworkInfo !== null &&
            this._modulePlan.customerModule.Module.NetworkInfo !== undefined) {
            this._modulePlan.customerModule.Module.AddNetworkInfo(netDevice);
            this.SetModeSwitch();
          }
        }
      }
    }
  }

  public userDarkTheme(): boolean {
    return this.moduleHandling.CurrentViewMode !== ViewCode.live;
  }

  public runningModeLive(): boolean {

    if (this.moduleHandling.CurrentViewMode === ViewCode.live) {
      if (this.modulePlan) {
        if (this.modulePlan.customerModule) {
          if (this.modulePlan.customerModule.Module) {
            if (this.moduleHandling.ClusterInitialized) {
              return this.modulePlan.customerModule.Module.Connected;
            }
          }
        }
      }
    } else {
      this.blinkingActive = false;
      return false;
    }
    this.blinkingActive = false;
    return false;
  }
  public UpdateRuningState() {
    if (this.running >= 0) {
      switch (this.running) {
        case 0: {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = false;
          this.CurrentStateColor = 'red';
          break;
        }
        case 1: {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = false;
          this.CurrentStateColor = 'yellow';
          break;
        }
        case 2: {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = false;
          this.CurrentStateColor = 'green';
          break;
        }
        case 3: {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = false;
          this.CurrentStateColor = 'yellow';
          break;
        }
        case 4: {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = false;
          this.CurrentStateColor = 'blue';
          break;
        }
        case 6:
        case 9:
        case 10: {
          // clearInterval(this.RuningStateHandler);
          this.CurrentStateColor = 'blue';
          this.blinkingActive = true;
          // this.RuningStateHandler = setInterval( () => {
          //   if (this.CurrentStateColor ===  'blue') {
          //     this.CurrentStateColor = 'transparent';
          //   } else {
          //     this.CurrentStateColor = 'blue';
          //
          //   }
          // }, 500);
          break;
        }
        case 7: {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = false;
          this.CurrentStateColor = 'white';
          break;
        }
        case 8: {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = true;
          // this.RuningStateHandler = setInterval( () => {
          //   if (this.CurrentStateColor ===  'red') {
          //     this.CurrentStateColor = 'transparent';
          //   } else {
          //     this.CurrentStateColor = 'red';
          //
          //   }
          // }, 500);
          break;
        }
        default: {
          this.blinkingActive = false;
          clearInterval(this.RuningStateHandler);
          this.CurrentStateColor = 'transparent';
          break;
        }
      }
      return;
    }
  }

  public ModuleRunningState() {
    if (this._modulePlan.customerModule) {
      if (this._modulePlan.customerModule.SerialNumber) {
        if (this._modulePlan.customerModule.Module.NetworkInfo) {
          // this._modulePlan.customerModule.Module.NetworkInfo.statemodule = state.state;
          this.running = this._modulePlan.customerModule.Module.NetworkInfo.statemodule;
          this.UpdateRuningState();
          return;
        } else {
          this.running = -1;
          this.UpdateRuningState();
        }
      }
    }
  }

  // public ModuleRunningState(state: any) {
  //   if (this._modulePlan.customerModule) {
  //     if (this._modulePlan.customerModule.SerialNumber) {
  //       if (state.ctxId === this._modulePlan.customerModule.SerialNumber) {
  //         if (this._modulePlan.customerModule.Module.NetworkInfo) {
  //           this._modulePlan.customerModule.Module.NetworkInfo.statemodule = state.state;
  //           this.running = state.state;
  //           this.UpdateRuningState();
  //           return;
  //         }
  //         this.running = -1;
  //       }
  //     }
  //   }
  // }

  ModuleClicked() {
    if (!this.parentModuleDesign.IsModuleSelected) {
      this.moduleHandling.SetModuleActive(this.parentModuleDesign);
    } else if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
      this.moduleHandling.OpenSupportModuleSettings();
    } else if (this.modulePlan.customerModule && this.modulePlan.customerModule.SerialNumberSetted) {
      // Not init?
      if (!this.modulePlan.customerModule.FullyConfigured) {
        if (this.moduleHandling.sequenceNumberToOnboard < this.modulePlan.seqno) {
          // SHOW MESSAGE
          const dialogRef = this.dialog.open(MessageBoxComponent,
            {width: 200 + 'px', panelClass: 'panelclass', data: {text: this.translate.GetTranslation('MESSAGEBOX.CONTENT.KEEPONBOARDINGORDER'), timeout: 1000}});
          dialogRef.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});
        } else {
          this.ShowSupportModuleOnboarding.emit();
        }
      } else {
        this.moduleHandling.OpenSupportModuleSettings();
      }


    }
  }
  public ShowSpinner(): boolean {
    if (this.moduleHandling.CurrentViewMode === ViewCode.live) {
      if (this.modulePlan.customerModule) {
        if (this.modulePlan.customerModule.FullyConfigured) {
          if (!this.moduleHandling.ClusterInitialized) {
            // return !this.modulePlan.modul.Connected;
            return true;
          }
        }
        return false;
      }
    }
    return false;
  }

  public ShowService(): boolean {
    if (this.ShowSpinner() || this.ShowOnboarding()) {
      return false;
    }

    if ((this.moduleHandling.ClusterInitialized || this.moduleHandling.DemoView) && this.moduleHandling.CurrentViewMode === ViewCode.live) {
      if (this.modulePlan.customerModule.Module.CurrentMode === ModeSwitch.SERVICE) {
        return true;
      }
    }
    return false;
  }

  public ShowOnboarding(): boolean {
    if (this.moduleHandling.CurrentViewMode === ViewCode.live) {
      if (this._modulePlan) {
        if (this.modulePlan.customerModule) {

          if (!this.modulePlan.customerModule.FullyConfigured) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    } else {
      return false;
    }
  }

  public ShowEmergencyStopTestRequired(): boolean {
    if (this._modulePlan?.modul?.EmergencyStopRequired == true && this.runningModeLive()) {
      return true;
    }

    return false;
  }

  public ShowEmergencyStopNotOk(): boolean {
    if (this._modulePlan?.modul?.EmergencyStopRequired == false && this._modulePlan?.modul.NetworkInfo?.emergencystopok == false && this.runningModeLive()) {
      return true;
    }

    return false;
  }
}
