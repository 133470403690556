<section class="flex-col center-self" *ngIf="ShowBar">
  <div class="flex-row gap-5 flex-container center-self">
    <button mat-button (click)="OpenSerialSelection()">
      Module Setup
    </button>
    <button mat-button (click)="OpenInitCluster()">
      Init Cluster
    </button>
    <button mat-button (click)="openModuleSettings()">
      Module Settings
    </button>
    <button mat-button (click)="openModuleState()">
      Module State
    </button>
    <button mat-button (click)="openSupportModuleState()">
      Support Module State
    </button>
  </div>
</section>
